import {
  Button,
  Icon,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import FileSaver from "file-saver";
import $ from "jquery";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import PDFViewer from "pdf-viewer-reactjs";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Alert,
  Card,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import FileViewer from "react-file-viewer";
import SVG from "react-inlinesvg";
import QRCode from "react-qr-code";
import CreatableSelect from "react-select/creatable";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import {
  ActivityFunction,
  currencyFormat,
  momentDate,
  momentDateOnly,
  strip,
} from "../../../../../../../_metronic/_helpers/ATHelpers";
import {
  AttachementsComponent,
  CheckboxLargeWB,
  DenseTable,
  EnhancedTable,
} from "../../../../../../../_metronic/_partials/controls";
import { Notification } from "../../../../../Notification";
import "../../customers-table/FileViewerStyle.css";
import { PeoplesComponent } from "../Functions/Main";
import { IsValid, IsValidColumn } from "../Functions/Validation";
const adapter = new LocalStorage("db");
const db = low(adapter);
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));
export default function DetailMangment({
  Id,
  setShow,
  isReporting,
  isDashboard,
  isLulusDashboardException,
}) {
  const [DetailActivityRatesInfo, setDetailActivityRatesInfo] = useState(null);
  const [Detail, setDetail] = useState();
  const [Attachments, setAttachmentsList] = useState();
  const classes = useStyles();

  useEffect(() => {
    GetModel();
    GetAttachments();
  }, []);
  const [Users, setUsers] = useState([]);
  useEffect(() => {
    GetUsers(setUsers);
  }, []);

  // Fetch data related to fuel surcharge
  const [fuelSurcharge, setFuelSurcharge] = useState(0);
  const [method, setMethod] = useState("");
  const [distance, setDistance] = useState(0);
  const [surcharge, setSurcharge] = useState(0);

  useEffect(() => {
    GetFuelSurcharge();
  }, []);
  async function GetFuelSurcharge() {
    return await fetch(`${window.$apiurl}/GetFuelSurcharge/` + Id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        if (a.fuelSurcharge) {
          setFuelSurcharge(a.fuelSurcharge);
          setDistance(a.distance);
          setSurcharge(a.surcharge);
          setMethod(a.method);
          if (method === "") {
            setMethod("Standard");
          } else {
            setMethod(a.method);
          }
        } else {
          Notification("error", "Fuel Surcharge :" + a.error.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetUsers() {
    return await fetch(`${window.$apiurl}/users`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          setUsers(res[1] ?? []);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //const  users = await GetUsers();

  const [customerName, setCustomerName] = useState("");
  async function GetModel() {
    setDetailActivityRatesInfo(null);
    return await fetch(`${window.$apiurl}/receiptsmanagement/details/` + Id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        var a = res[1];
        setCustomerName(a?.CustomerName);
        if (a?.CustomerName === "Lulus") {
          await GetExceptionList(Id, true);
        }
        a.Charges.forEach((x) => {
          x.InvoiceDate = momentDate(x?.InvoiceDate);
          x.Payable = x?.Payable || 0;
          x.FreightChargeName = x.FreightChargeName ?? "-";
        });

        a.Charges.push({
          BillAmount: (a?.TotalReceivable).toFixed(2),
          ChargeDescription: "",
          FreightChargeName: "Total :",
          Code: "",
          InvoiceDate: "",
          InvoiceNumber: "",
          Margin: (a?.Revenue).toFixed(2),
          MarginPercentage:
            a?.TotalReceivable === 0
              ? 0
              : ((a?.Revenue / a?.TotalReceivable) * 100).toFixed(2),
          PayableAmount: (a?.TotalPayable).toFixed(2),
          PayableCode: (a?.TotalPayable).toFixed(2),
          VendorName: "",
          VendorReference: "",
        });

        a.Vendors.forEach((x) => {
          x.Name = x.VendorAddress?.CompanyName ?? "";
        });

        if (a?.Status?.length > 0)
          a.Status.sort((a, b) => a.Position - b.Position).forEach((x) => {
            /**/ if (x.Name === "Dispatched") x.value = a?.DispatchDate;
            else if (x.Name === "Released") x.value = a?.ReleaseDate;
            else if (x.Name === "Appointed") x.value = a?.AppointementDate;
            else if (x.Name === "Picked Up") x.value = a?.PickupDate;
            else if (x.Name === "Load Received") x.value = a?.ReceivedDate;
            else if (x.Name === "Devanned") x.value = a?.DevanDate;
            else if (x.Name === "Closed") x.value = a?.ClosedDate;
            else if (x.Name === "Terminated") x.value = a?.TerminatedDate;
            else if (x.Name === "New") x.value = a?.Created;
            else if (x.Name === "Planned") x.value = a?.PlannedDate;
            else if (x.Name === "QC") x.value = a?.QcDate;
            else if (x.Name === "Ready to process") x.value = a?.ProcessedDate;
          });

        a.Notes.forEach((x) => {
          x.Created = momentDate(x?.Created);
          x.Body = strip(x?.Body);
        });
        a.attachments.forEach((x) => {
          x.Created = momentDate(x?.Created);
        });

        a.ReceiptItems.forEach((x) => {
          x.AstrayQuantity = x?.AstrayQuantity ?? 0;
          x.PoExpectedDate = momentDateOnly(x?.PoExpectedDate);
          x.ReceivedDate = momentDateOnly(x?.ReceivedDate);
          x.ProcessedDate = momentDateOnly(x?.ProcessedDate);
          x.Weight = currencyFormat(x?.Weight);
          x.Cube = currencyFormat(x?.Cube);
          x.IsHot = x.IsHot ? (
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/fire.svg")}
              />
            </span>
          ) : (
            <></>
          );
          x.IsHazardousMaterial = x.IsHazardousMaterial ? (
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/296395.svg")}
              />
            </span>
          ) : (
            <></>
          );

          x.Pieces2 = x?.UnitCount * x?.Pieces;
          x.Location = (
            <>
              {x.Location}
              {x.LocationCode && (
                <OverlayTrigger
                  overlay={
                    <Tooltip id="products-edit-tooltip">
                      {x.LocationCode}
                    </Tooltip>
                  }
                >
                  <a
                    href={() => false}
                    className="btn btn-icon btn-light btn-hover-light btn-sm"
                  >
                    <span className="svg-icon svg-icon-md svg-icon-info">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              )}
            </>
          );
        });

        a.Activities.sort(function(a, b) {
          return new Date(b.Created) - new Date(a.Created);
        });
        a.Activities.forEach((x, i) => {
          x.id = i;
        });
        if (isDashboard) {
          setDetailActivityRatesInfo(
            a?.Activities.filter(
              (a) => a.Action === "Container return attempt has been added"
            ) || []
          );
        }else if (isReporting) {
          setDetailActivityRatesInfo(
            a?.Activities.filter(
              (a) => a.Action === "Container return attempt has been added"
            ) || []
          );
        } else {
          setDetailActivityRatesInfo(a?.Activities || []);
        }
        setDetail(a);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetAttachments() {
    return await fetch(
      `${window.$apiurl}/transload/receipts/getattachments/` + Id,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        a.forEach((x) => {
          x.DateCreated = momentDate(x?.Created);
        });
        setAttachmentsList(a || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const [exceptionsRmList, setExceptionList] = useState([]);
  const [checkedIsActive, setIsActiveFilter] = useState(true);

  async function filterDisposition(value) {
    try {
      const exceptions = await GetExceptionList(Id, checkedIsActive);
      let currentList = [];
      if (value === "") {
        currentList = exceptions.filter(
          (x) =>
            String(x.Disposition).includes(String("")) &&
            (checkedIsActive
              ? String(x.isActive).includes(String(true))
              : String(x.isActive).includes(String(false)))
        );
      } else {
        currentList = exceptions.filter(
          (x) =>
            String(x.Disposition).includes(String(value)) &&
            (checkedIsActive
              ? String(x.isActive).includes(String(true))
              : String(x.isActive).includes(String(false)))
        );
      }
      setExceptionList(currentList);
    } catch (err) {}
  }
  async function GetExceptionList(id, isActive) {
    return await fetch(
      `${window.$apiurl}/sku/exceptions?id=${id}&isActive=${isActive}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        a.forEach((x) => {
          x.RequestDate = momentDate(x?.RequestDate);
        });
        setExceptionList(a || []);
        return a;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function getExtension(path) {
    var basename = path.split(/[\\/]/).pop(),
      pos = basename.lastIndexOf(".");
    if (basename === "" || pos < 1) return "";
    return basename.slice(pos + 1);
  }
  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };
  const [type, setFileType] = useState("");
  const [filePath, setFilePath] = useState("");
  async function SecureFileFetch(receiptId, attachmentId) {
    return await fetch(
      window.$apiurl +
        `/generic/attachment/secureFile/${receiptId}/${attachmentId}?isReceipt=true&isQuote=false`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        try {
          const data = res[1];
          const fileType = getExtension(data.AttachmentFile);
          setFileType(fileType.toLowerCase());
          setFilePath(data.AttachmentFile);
          setDisabledDownload(false);
        } catch (err) {}
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const [showAttachment, setShowAttachment] = useState(false);
  const [disabledDownload, setDisabledDownload] = useState(true);
  function CardFunction({ x, index, New, id }) {
    return (
      <>
        <Card
          className={
            "mb-2 m-auto border-secondary " + (index % 2 === 0 && "bg-light")
          }
        >
          <Card.Body>
            <Card.Title className="d-inline mr-5">
              <i class="fas fa-user mr-2"></i> {x?.CreatedBy}
            </Card.Title>
            <p className="mb-2 d-inline card-subtitle text-primary ">
              {momentDate(x?.Created)}
            </p>
            <Card.Text className="mt-5 ml-5">
              <b className="text-primary mr-5 ">Download :</b>
              <b>
                <a
                  href={() => false}
                  onClick={async () => {
                    setShowAttachment(true);
                    await SecureFileFetch(id, x.Id);
                  }}
                  role="button"
                  className="btn btn-light-primary btn-elevate py-1"
                >
                  {x?.FileName}
                </a>
              </b>

              {/* Detail Attachments */}
              <div className={"row mt-md-7"}>
                <div className="col-lg-6">
                  <b className="text-primary mr-5">Attachment Type :</b>{" "}
                  <b> {x?.Type}</b>
                </div>
                <div className="col-lg-6">
                  <b className="text-primary mr-5">Access Level :</b>{" "}
                  <b> {x?.AccessLevel}</b>
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <br />
      </>
    );
  }

  const [showModalRemoveException, setModalRemoveException] = useState(false);
  const [exceptionId, setExceptionId] = useState(null);
  const [notesExceptionShow, setNotesExceptionsModal] = useState(false);
  const [exceptionNotesList, setExceptionNotesList] = useState([]);

  async function RemoveException() {
    return fetch(window.$apiurl + "/sku/exceptions/remove/" + exceptionId, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  }
  const [dispositionFilter, setDispositionFilter] = useState({
    value: "",
    label: "Select",
  });
  const listDisposition = [
    { value: "New", label: "New" },
    { value: "Accept", label: "Accept" },
    { value: "Hold", label: "Hold" },
    { value: "Reject", label: "Reject" },
    { value: "Other", label: "Other" },
  ];
  const [QRCodeShow, setQRCodeShow] = useState(false);
  return (
    <>
      <Modal.Header>
        <div className="row">
          <div className="col-xl-10 col-lg-10 col-md-12 col-12">
            <div className="row">
              <div className="col-xl-6 col-lg-5 col-md-12 col-12">
                {IsValid("Order Number", Detail?.OrderNumebr)}
              </div>
              <div className="col-xl-6 col-lg-7 col-md-12 col-12">
                {IsValid(
                  "Receive Number",
                  Detail?.ReceiptNumber +
                    (Detail?.EquipmentRef ? " - " + Detail?.EquipmentRef : "")
                )}
              </div>
              <div className="col-xl-6 col-lg-5 col-md-12 col-12">
                {IsValid("Invoice No", Detail?.InvoiceNumber)}
              </div>
              <div className="col-xl-6 col-lg-7 col-md-12 col-12">
                {IsValid("Invoice Date", momentDate(Detail?.InvoiceDate))}
              </div>
            </div>
          </div>
          <div
            className="col-xl-2 col-lg-2 col-md-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-5 text-center align-self-center"
            style={{ cursor: "pointer" }}
          >
            <QRCode
              value={`{"id" : ${Detail?.ReceiptNumber}, "Type" : "Receipt", "Name" : "${Detail?.EquipmentRef}"}`}
              size={65}
              fgColor="#3699FF"
              onClick={() => {
                setQRCodeShow(true);
              }}
            />
          </div>
          <Modal
            size="lg"
            show={QRCodeShow}
            onHide={() => {
              setQRCodeShow(false);
            }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title>
                <h4> QR code for receipt {Detail?.ReceiptNumber} </h4>
              </Modal.Title>
              <IconButton
                aria-label="Close"
                onClick={() => {
                  setQRCodeShow(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <div className="modal-body text-center">
              <QRCode
                value={`{"id" : ${Detail?.ReceiptNumber}, "Type" : "Receipt", "Name" : "${Detail?.ReceiptNumber}"}`}
                size={500}
              />
            </div>
          </Modal>
        </div>
        <IconButton
          className="receive-btn"
          aria-label="Close"
          onClick={() => {
            setShow(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>

      <Modal.Body>
        <Accordion defaultActiveKey="0">
          <>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none "
              variant="link"
              eventKey={isReporting ? "44" : "0"}
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Container Basic</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={isReporting ? "44" : "0"}>
              <div className="form-group row word-break">
                <div className="col-lg-6 col-md-12">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Customer
                  </div>
                  {IsValid("Customer Code", Detail?.CustomerCode)}
                  {IsValid("Customer Name", Detail?.CustomerName)}
                  {IsValid("Customer Address", Detail?.CustomerAddress1)}
                  {IsValid(
                    "City,State,Zip Code",
                    Detail?.CustomerCity +
                      ", " +
                      Detail?.CustomerState +
                      ", " +
                      Detail?.CustomerZipCode
                  )}
                  {IsValid("Customer Ref No 1", Detail?.CustomerReference1)}
                  {IsValid("Customer Ref No 2", Detail?.CustomerReference2)}
                  {IsValid("Fuel Surcharge Method ", method)}
                </div>

                <div className="col-lg-6 col-md-12">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Bill To
                  </div>
                  {IsValid("Bill To Code", Detail?.BillToCode)}
                  {IsValid("Bill To Name", Detail?.BillToName)}
                  {IsValid("Bill To Address1", Detail?.BillToAddress1)}
                  {IsValid(
                    "City,State,Zip Code",
                    Detail?.BillToCity +
                      ", " +
                      Detail?.BillToState +
                      ", " +
                      Detail?.BillToZipCode
                  )}
                  {IsValid("Billing Ref No 1", Detail?.BillingReference1)}
                  {IsValid("Billing Ref No 2", Detail?.BillingReference2)}
                </div>

                <div className="col-lg-6 col-md-12 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Pickup Location
                  </div>
                  {IsValid("Pickup Location Code", Detail?.ShipperCode)}
                  {IsValid("Pickup Location Name", Detail?.ShipperName)}
                  {IsValid("Pickup Location Address1", Detail?.ShipperAddress1)}
                  {IsValid(
                    "City,State,Zip Code",
                    Detail?.ShipperCity +
                      ", " +
                      Detail?.ShipperState +
                      ", " +
                      Detail?.ShipperZipCode
                  )}
                  {IsValid(
                    "Pickup Appointment",
                    momentDate(Detail?.PickupOnDate)
                  )}
                  {/* {IsValid("Pickup Date", momentDate(Detail?.PickupDate))} */}
                  {IsValid("Origins Shipper", Detail?.OriginsShipper)}
                </div>

                <div className="col-lg-6 col-md-12 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Ship to Location
                  </div>
                  {IsValid("Ship to Location Code", Detail?.ConsigneeCode)}
                  {IsValid("Ship to Location Name", Detail?.ConsigneeName)}
                  {IsValid(
                    "Ship to Location Address1",
                    Detail?.ConsigneeAddress1
                  )}
                  {IsValid(
                    "City,State,Zip Code",
                    Detail?.ConsigneeCity +
                      ", " +
                      Detail?.ConsigneeState +
                      ", " +
                      Detail?.ConsigneeZipCode
                  )}
                  {IsValid(
                    "delivery appointment",
                    momentDate(Detail?.DeliveryOnDate)
                  )}
                  {/*  {IsValid("delivery Date", momentDate(Detail?.DeliveryDate))} */}
                </div>
                <div className="col-lg-6 col-md-12 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Dates
                  </div>

                  {/* {IsValid("Order Created", momentDate(Detail?.Created))} */}
                  {Detail?.Status?.length > 0 &&
                    Detail?.Status.map((x) =>
                      IsValid(x.Name, momentDate(x?.value))
                    )}

                  {/* {IsValid("Billed", momentDate(Detail?.InvoiceDate))} */}
                  {/* {IsValid("Delivery Apt", momentDate(Detail?.DeliveryOnDate))}
                    {Detail?.ModeId === 31 && (
                      <>
                        {IsValid("Planned Date", momentDate(Detail?.PlannedDate))}
                        {IsValid("Qc Date", momentDate(Detail?.QcDate))}
                        {IsValid(
                          "Processed Date",
                          momentDate(Detail?.ProcessedDate)
                        )}
                      </>
                    )} */}
                </div>
                {Detail?.ServiceName === "Drayage" && (
                  <>
                    <div className="col-lg-6 col-md-12 mt-5">
                      <div
                        className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                        style={{ fontSize: "15px" }}
                      >
                        Oder Details - Drayage
                      </div>
                      {IsValidColumn("Mode Name", Detail?.ModeName)}
                      {IsValidColumn("Vessel", Detail?.Vessel)}
                      {IsValidColumn("Seal Number", Detail?.SealNumber)}
                      {IsValidColumn("Service Level", Detail?.ServiceLevel)}
                      {IsValidColumn("Voyage Number", Detail?.VesselVoyage)}
                      {IsValidColumn("Cartons", Detail?.Cartons)}
                      {IsValidColumn("Equipment Type", Detail?.EquipmentType)}
                      {IsValidColumn(
                        "ETA",
                        momentDate(Detail?.Created)?.slice(0, 10)
                      )}
                      {IsValidColumn("Weight", Detail?.TotalWeight1)}
                      {IsValidColumn("Equipment Number", Detail?.EquipmentRef)}
                      {IsValidColumn("MBL", Detail?.MasterBill)}
                      {IsValidColumn("Cube", Detail?.TotalCube1)}
                    </div>
                  </>
                )}
                {Detail?.ServiceName === "Domestic Transportation" && (
                  <div className="col-lg-6 col-md-12 mt-5">
                    <div
                      className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                      style={{ fontSize: "15px" }}
                    >
                      Order Details - LTL
                    </div>
                    {IsValidColumn("Mode Name", Detail?.ModeName)}{" "}
                    {IsValidColumn("Cartons", Detail?.Cartons)}
                    {IsValidColumn("Service Level", Detail?.ServiceLevel)}
                    {IsValidColumn("Pallets", Detail?.Pallets)}
                    {IsValidColumn("Equipment Type", Detail?.EquipmentType)}
                    {IsValidColumn("Weight", Detail?.TotalWeight1)}
                    {IsValidColumn("Equipment Number", Detail?.EquipmentTypeId)}
                    {IsValidColumn("Cube", Detail?.TotalCube1)}
                  </div>
                )}

                <div className="col-lg-6 col-md-12 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Totals
                  </div>
                  {IsValid("Cube", Detail?.TotalCube1?.toFixed(2) + " CBM")}
                  {IsValid("Weight", Detail?.TotalWeight1?.toFixed(2) + " KGS")}
                  {IsValid("Cartons", Detail?.Cartons)}
                  {IsValid("Pallets", Detail?.Pallets)}

                  {isReporting === true
                    ? ""
                    : IsValid("Bill Amount", Detail?.TotalReceivable)}
                  {isReporting === true
                    ? ""
                    : IsValid("Payable Amount", Detail?.TotalPayable)}
                  {IsValid("Surcharge", surcharge + " cents")}
                  {IsValid("Distance", distance + " miles")}
                  {IsValid("Total Fuel Surchage", fuelSurcharge + " $")}
                </div>

                <div className="col-lg-6 col-md-12 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Vessel Voyage
                  </div>

                  {IsValid("Vessel Name", Detail?.Vessel)}
                  {IsValid("Voyage Number", Detail?.VesselVoyage)}
                  {IsValid("Eta", momentDate(Detail?.Eta))}
                </div>
                <div className="col-lg-6 col-md-12 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Others *
                  </div>

                  {IsValid("Master Bill", Detail?.MasterBill)}
                  {IsValid("Service", Detail?.ServiceName)}
                  {IsValid("Mode", Detail?.ModeName)}
                  {IsValid("Service Level", Detail?.ServiceLevel)}
                  {IsValid("Equipment Size", Detail?.EquipmentSize)}
                  {IsValid("Equipment Type", Detail?.EquipmentType)}
                </div>
              </div>
            </Accordion.Collapse>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey={isReporting ? "0" : "44"}
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Line Item Detail</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={isReporting ? "0" : "44"}>
              <div className="form-group row">
                <div className="col-lg-12 mt-5">
                  {Detail?.CustomerName === "Lulus" ? (
                    <EnhancedTable
                      rowsName={[
                        { Name: "Pallets", Title: "Pallet #" },
                        { Name: "Id", Title: "Ln" },
                        { Name: "PurchaseOrder", Title: "Purchase Order" },
                        { Name: "Sku", Title: "Sku" },
                        { Name: "Description", Title: "Description" },
                        { Name: "Location", Title: "Location" },
                        { Name: "UnitCount", Title: "Unit Count" },
                        { Name: "Weight", Title: "Weight" },
                        { Name: "Cube", Title: "Cube" },
                        { Name: "Color", Title: "Color" },
                        { Name: "Size", Title: "Size" },
                        { Name: "ProductType", Title: "ProductType" },
                        { Name: "ReceivedQuantity", Title: "Cartons Count" },
                        {
                          Name: "IsHazardousMaterial",
                          Title: "Is HazMat",
                        },
                        { Name: "IsHot", Title: "Is Hot" },
                        { Name: "Pieces", Title: "Case pack" },
                        { Name: "Pieces2", Title: "Pieces" },
                        { Name: "ShortQuantity", Title: "ShortQuantity" },
                        { Name: "OverQuantity", Title: "OverQuantity" },
                        { Name: "DamagedQuantity", Title: "DamagedQuantity" },
                        { Name: "AstrayQuantity", Title: "AstrayQuantity" },
                        { Name: "ProcessedDate", Title: "Processed Date" },
                        { Name: "ReceivedDate", Title: "Received Date" },
                        { Name: "BuyerId", Title: "BuyerId" },
                        { Name: "PoEta", Title: "Po Eta" },
                        { Name: "Department", Title: "Department" },
                        { Name: "PoExpectedDate", Title: "PoExpectedDate" },
                        {
                          Name: "ThirdPartyQuantity",
                          Title: "ThirdParty Quantity",
                        },
                        { Name: "ThirdPartyName", Title: "ThirdParty Name" },
                        { Name: "ReferenceNumber", Title: "Reference N." },
                        { Name: "Shipped", Title: "Shipped" },
                        { Name: "Shipment", Title: "Shipment" },
                      ]}
                      rows={Detail?.ReceiptItems || []}
                    />
                  ) : (
                    <EnhancedTable
                      rowsName={[
                        { Name: "PurchaseOrder", Title: "Purchase Order" },
                        { Name: "Id", Title: "Ln" },
                        { Name: "Sku", Title: "Sku" },
                        { Name: "Description", Title: "Description" },
                        { Name: "Location", Title: "DC Location" },
                        { Name: "UnitCount", Title: "Unit Count" },
                        { Name: "Weight", Title: "Weight" },
                        { Name: "Cube", Title: "Cube" },
                        {
                          Name: "IsHazardousMaterial",
                          Title: "Is HazMat",
                        },
                        { Name: "IsHot", Title: "Is Hot" },
                        { Name: "Pieces", Title: "Case pack" },
                        { Name: "Pieces2", Title: "Pieces" },
                        { Name: "ShortQuantity", Title: "ShortQuantity" },
                        { Name: "OverQuantity", Title: "OverQuantity" },
                        { Name: "DamagedQuantity", Title: "DamagedQuantity" },
                        { Name: "AstrayQuantity", Title: "AstrayQuantity" },
                        { Name: "ProcessedDate", Title: "Processed Date" },
                        { Name: "ReceivedDate", Title: "Received Date" },
                        { Name: "BuyerId", Title: "BuyerId" },
                        { Name: "PoEta", Title: "Po Eta" },
                        { Name: "Department", Title: "Department" },
                        { Name: "PoExpectedDate", Title: "PoExpectedDate" },
                        {
                          Name: "ThirdPartyQuantity",
                          Title: "ThirdParty Quantity",
                        },
                        { Name: "ThirdPartyName", Title: "ThirdParty Name" },
                        { Name: "ReferenceNumber", Title: "Reference N." },
                        { Name: "Shipped", Title: "Shipped" },
                        { Name: "Shipment", Title: "Shipment" },
                        { Name: "Pallets", Title: "Pallet #" },
                      ]}
                      rows={Detail?.ReceiptItems || []}
                    />
                  )}
                </div>
              </div>
            </Accordion.Collapse>
            {new URLSearchParams(window.location.search).get("type") !==
              "PortView" &&
            window.location.href.indexOf("GlobalDashboard") === -1 &&
            window.location.href.indexOf("LulusDashboard") === -1 &&
            window.location.href.indexOf("Search") === -1 ? (
              <Accordion.Toggle
                as={Button}
                className="w-100 text-left p-0 position-relative text-decoration-none"
                variant="link"
                eventKey="2"
              >
                <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                  <h5 className="font-weight-bold">Rates</h5>
                </Alert>
                <Icon
                  className={clsx(
                    "fa fa-chevron-down position-absolute text-white"
                  )}
                  style={{ fontSize: 17, right: "1rem", top: "20%" }}
                />
              </Accordion.Toggle>
            ) : (
              ""
            )}

            <Accordion.Collapse eventKey="2">
              <div className="form-group row">
                <div className="col-lg-12 mt-5">
                  <DenseTable
                    rowsName={[
                      { Name: "Code", Title: "Charge Code" },
                      {
                        Name: "ChargeDescription",
                        Title: "Charge Description",
                      },
                      {
                        Name: "FreightChargeName",
                        Title: "Freight Charge",
                      },
                      { Name: "BillAmount", Title: "Billing" },
                      { Name: "PayableAmount", Title: "Payable Amount" },
                      { Name: "Margin", Title: "Margin" },
                      { Name: "MarginPercentage", Title: "Margin %" },
                      { Name: "InvoiceNumber", Title: "Invoice #" },
                      { Name: "InvoiceDate", Title: "Invoice Date" },
                      { Name: "VendorName", Title: "Vendor Name" },
                      { Name: "VendorReference", Title: "Vendor #" },
                    ]}
                    rows={Detail?.Charges || []}
                    lastBold={true}
                  />
                </div>
              </div>
            </Accordion.Collapse>

            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="33"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Vendors</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="33">
              <>
                <div className="form-group row">
                  <div className="col-lg-12 mt-5">
                    <DenseTable
                      rowsName={[
                        { Name: "Id", Title: "#" },
                        { Name: "Name", Title: "Vendor Name" },
                        { Name: "FreightChargeName", Title: "Freight Charge" },
                        { Name: "ContactName", Title: "Contact Name" },
                        { Name: "VendorInstructions", Title: "Instructions" },
                        { Name: "VendorNumber", Title: "Vendor ref N" },
                        /* { Name: "CarrierId", Title: "Carrier Id" },
                          { Name: "ContactId", Title: "Contact Id" }, */
                        { Name: "Description", Title: "Description" },
                        { Name: "DriverName", Title: "Driver Name" },
                        { Name: "DriverPhone", Title: "Driver Phone" },
                        { Name: "TruckNumber", Title: "Truck Number" },
                      ]}
                      rows={Detail?.Vendors ?? []}
                    />
                  </div>
                </div>
              </>
            </Accordion.Collapse>

            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="3"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Notes</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <>
                <div className="form-group row">
                  <div className="col-lg-12 mt-5">
                    {isReporting === true ? (
                      <DenseTable
                        rowsName={[
                          { Name: "Id", Title: "#" },
                          { Name: "Title", Title: "Title" },
                          { Name: "Body", Title: "Body" },
                          { Name: "CreatedBy", Title: "Created By" },
                          { Name: "Category", Title: "Category" },
                          { Name: "Created", Title: "Created" },
                        ]}
                        rows={
                          Detail?.Notes.filter(
                            (word) => word.AccessLevelId === 4
                          ) || []
                        }
                      />
                    ) : (
                      <DenseTable
                        rowsName={[
                          { Name: "Id", Title: "#" },
                          { Name: "Title", Title: "Title" },
                          { Name: "Body", Title: "Body" },
                          { Name: "CreatedBy", Title: "Created By" },
                          { Name: "Category", Title: "Category" },
                          { Name: "Created", Title: "Created" },
                          { Name: "AccessLevel", Title: "Access Level" },
                        ]}
                        rows={Detail?.Notes || []}
                      />
                    )}
                  </div>
                </div>
              </>
            </Accordion.Collapse>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="4"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Attachments</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <>
                <div className="form-group row">
                  <div className="col-lg-12 mt-5">
                    {/* Header */}
                    <div className="card-header border-bottom">
                      <h4 className="card-title font-weight-bolder text-dark">
                        Receipt Attachments
                      </h4>
                    </div>
                    {isDashboard && (
                      <AttachementsComponent
                        id={Id}
                        isDashboard={true}
                        post={
                          "/transload/receipts/addattachment?receiptId=" + Id
                        }
                        get={"/receiptsmanagement/getreceiptattachments/" + Id}
                      />
                    )}

                    {isReporting === true
                      ? Attachments?.length > 0 &&
                        Attachments?.filter(
                          (word) => word.AccessLevel === "Public"
                        ).map((x, index) => (
                          <CardFunction
                            x={x}
                            key={index}
                            index={index}
                            id={Detail?.ReceiptNumber}
                          />
                        ))
                      : !isDashboard &&
                        Attachments?.length > 0 &&
                        Attachments?.map((x, index) => (
                          <CardFunction
                            x={x}
                            key={index}
                            index={index}
                            id={Detail?.ReceiptNumber}
                          />
                        ))}
                  </div>
                </div>

                {/* Modal Attachment Viewer */}
                <Modal
                  size="xl"
                  show={showAttachment}
                  onHide={() => {
                    setShowAttachment(false);
                  }}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header>
                    <Modal.Title>
                      Attachment Viewer for RM #: {Detail?.ReceiptNumber}
                    </Modal.Title>
                    <IconButton
                      aria-label="Close"
                      onClick={() => {
                        setShowAttachment(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Modal.Header>
                  <Modal.Body>
                    <>
                      <div className="form-group row mb-5">
                        <div className="col-lg-8"></div>
                        <div
                          className="col-lg-4"
                          style={{ textAlignLast: "right" }}
                        >
                          <button
                            type="submit"
                            disabled={disabledDownload}
                            className="btn btn-primary btn-elevate"
                            onClick={() => {
                              const fileName = filePath.replace(
                                /^.*[\\\/]/,
                                ""
                              );
                              console.log(fileName);
                              FileSaver(filePath, fileName);
                            }}
                          >
                            Download
                          </button>{" "}
                        </div>
                      </div>
                      {type === "pdf" ? (
                        <div className="form-group row" key={Math.random()}>
                          <div
                            className="col-lg-12 pdfViewer"
                            key={Math.random()}
                          >
                            <PDFViewer
                              document={{
                                url: filePath,
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="form-group row" key={Math.random()}>
                          <div className="col-lg-12" key={Math.random()}>
                            <FileViewer
                              fileType={type}
                              filePath={filePath}
                              onError={onError}
                              className="w-100"
                            />
                          </div>
                        </div>
                      )}
                    </>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={() => {
                        setShowAttachment(false);
                      }}
                      className="btn btn-light btn-elevate w-25"
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>
              </>
            </Accordion.Collapse>
            {new URLSearchParams(window.location.search).get("type") !==
              "PortView" &&
            window.location.href.indexOf("LulusDashboard") === -1 &&
            window.location.href.indexOf("Search") === -1 ? (
              <Accordion.Toggle
                as={Button}
                className="w-100 text-left p-0 position-relative text-decoration-none"
                variant="link"
                eventKey="26"
              >
                <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                  <h5 className="font-weight-bold">ACTIVITIES</h5>
                </Alert>
                <Icon
                  className={clsx(
                    "fa fa-chevron-down position-absolute text-white"
                  )}
                  style={{ fontSize: 17, right: "1rem", top: "20%" }}
                />
              </Accordion.Toggle>
            ) : (
              ""
            )}
            <Accordion.Collapse eventKey="26">
              {DetailActivityRatesInfo !== null ? (
                <ActivityFunction Activitys={DetailActivityRatesInfo || []} />
              ) : (
                <div className={`h-60 card card-custom`}>
                  <div className="card-body pt-5 row now-rapTable">
                    <div className="col-xl-12" style={{ textAlign: "center" }}>
                      Loading Data ...
                    </div>
                  </div>
                </div>
              )}
            </Accordion.Collapse>

            {customerName === "Lulus" && (
              <>
                <Accordion.Toggle
                  as={Button}
                  className="w-100 text-left p-0 position-relative text-decoration-none"
                  variant="link"
                  eventKey="27"
                >
                  <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                    <h5 className="font-weight-bold">Exceptions</h5>
                  </Alert>
                  <Icon
                    className={clsx(
                      "fa fa-chevron-down position-absolute text-white"
                    )}
                    style={{ fontSize: 17, right: "1rem", top: "20%" }}
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="27">
                  <>
                    <div className="form-group row p-4">
                      <div className="col-lg-4">
                        <label>Disposition</label>
                        <CreatableSelect
                          isClearable
                          name="Dispostions"
                          id="Dispostions"
                          options={listDisposition}
                          value={dispositionFilter}
                          onChange={async (e) => {
                            if (e !== null) {
                              setDispositionFilter(e);
                              await filterDisposition(e.value);
                            } else {
                              setDispositionFilter(null);
                              await filterDisposition("");
                            }
                          }}
                        />
                      </div>
                      <div className="col-lg-2 mt-md-8">
                        <CheckboxLargeWB
                          className="font-size-md"
                          text="Is Active"
                          checked={checkedIsActive}
                          onChange={async (e) => {
                            setIsActiveFilter(e.target.checked);
                            await GetExceptionList(Id, e.target.checked);
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-12">
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Request Id</TableCell>
                              <TableCell>Request Date</TableCell>
                              <TableCell>FileName</TableCell>
                              <TableCell>Exception Type</TableCell>
                              <TableCell>Disposition</TableCell>
                              <TableCell>Notes</TableCell>
                              {!db.getState().User.IsCustomer &&
                                !isLulusDashboardException &&
                                exceptionsRmList.some(
                                  (x) => x.isActive === true
                                ) && <TableCell>Actions</TableCell>}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {exceptionsRmList.map((x) => (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  {x.RequestId}
                                </TableCell>
                                <TableCell>
                                  {momentDate(x?.RequestDate)}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <a
                                    href={x?.PhotoFile}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-light-primary btn-elevate py-1"
                                  >
                                    {x?.FileName}
                                  </a>
                                </TableCell>
                                <TableCell>{x.ExcpetionType}</TableCell>
                                <TableCell>{x.Disposition}</TableCell>
                                <TableCell>
                                  <a
                                    href={() => false}
                                    onClick={async () => {
                                      try {
                                        const currentList = await GetExceptionList(
                                          Id,
                                          checkedIsActive
                                        );
                                        const notesExceptions = currentList.find(
                                          (c) => c.RequestId === x.RequestId
                                        );
                                        console.log(notesExceptions);
                                        setExceptionId(x.RequestId);
                                        notesExceptions.Notes.length > 0 &&
                                          notesExceptions.Notes.forEach(
                                            (item) => {
                                              if (item.AccessLevelId === 4) {
                                                item.AccessLevel = "Public";
                                              } else {
                                                item.AccessLevel = "Private";
                                              }
                                              item.CreatedAt = momentDate(
                                                item?.CreatedAt
                                              );
                                            }
                                          );
                                        setExceptionNotesList(
                                          notesExceptions?.Notes || []
                                        );
                                        setNotesExceptionsModal(true);
                                      } catch (err) {}
                                    }}
                                    role="button"
                                    className="btn btn-light-primary btn-elevate py-1"
                                  >
                                    Show Notes
                                  </a>
                                </TableCell>
                                {!db.getState().User.IsCustomer &&
                                  !isLulusDashboardException &&
                                  x?.isActive && (
                                    <TableCell>
                                      <button
                                        type="button"
                                        className="btn btn-light-danger ml-3 pr-3"
                                        onClick={(e) => {
                                          setModalRemoveException(true);
                                          setExceptionId(x?.RequestId);
                                        }}
                                      >
                                        {x?.Disposition === "New" ? (
                                          <>
                                            <i className="fas fa-trash"></i>{" "}
                                            Delete{" "}
                                          </>
                                        ) : (
                                          <>
                                            <i className="fas fa-times"></i>{" "}
                                            Close{" "}
                                          </>
                                        )}
                                      </button>
                                    </TableCell>
                                  )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </>
                </Accordion.Collapse>
              </>
            )}
            {/* <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="111"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Ticketing system</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="111">
              <>
                <TickettingSystem />
              </>
            </Accordion.Collapse> */}
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="29"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">
                  <i class="fas fa-arrow-alt-circle-down text-white mr-5"></i>{" "}
                  PO Locations
                </h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="29">
              <PoLocations Id={Detail?.ReceiptNumber} />
            </Accordion.Collapse>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="28"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100 d-none">
                <h5 className="font-weight-bold">
                  <i class="fas fa-user text-white mr-5"></i> PEOPLES
                </h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="28">
              <PeoplesComponent />
            </Accordion.Collapse>
            {/* PO ACTIVITIES */}
          </>
        </Accordion>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          onClick={() => {
            setShow(false);
          }}
          className="btn btn-light btn-elevate w-25"
        >
          Close
        </button>
      </Modal.Footer>

      {/* Modal Remove Exception */}
      <Modal
        size="lg"
        show={showModalRemoveException}
        onHide={() => {
          setModalRemoveException(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>Remove Exception ID #: {exceptionId}</Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={(e) => {
              setModalRemoveException(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          Are you sure that you want to Remove this Exception ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-danger btn-elevate"
            onClick={(e) => {
              setModalRemoveException(false);
            }}
          >
            No
          </Button>

          <Button
            variant="secondary"
            className="btn btn-light btn-elevate ml-5"
            onClick={async () => {
              await RemoveException();
              await GetExceptionList(Detail?.ReceiptNumber, checkedIsActive);
              setIsActiveFilter(false);
              Notification(
                "success",
                `Exception #: ${exceptionId} has been removed from Receipt #: ${Detail?.Id} successfully`
              );
              setModalRemoveException(false);
              window.$FilterExceptionsOn = true;
              $("#ReLoadTableId").trigger("click");
              if (
                document.getElementsByClassName("text-info")[1]?.parentElement
                  ?.nodeName === "LI"
              ) {
                document
                  .getElementsByClassName("text-info")[1]
                  .parentElement.click();
              } else if (
                document.getElementsByClassName("text-info")[0]?.parentElement
                  ?.nodeName === "LI"
              ) {
                document
                  .getElementsByClassName("text-info")[0]
                  .parentElement.click();
              }
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={notesExceptionShow} size="xl">
        <Modal.Header>
          <Modal.Title>Exception's Notes #: {exceptionId}</Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={(e) => {
              setNotesExceptionsModal(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <div className="col-lg-12 mt-5">
              <DenseTable
                rowsName={[
                  { Name: "Id", Title: "#" },
                  { Name: "Body", Title: "Body" },
                  { Name: "CreatedBy", Title: "Created By" },
                  { Name: "CategoryName", Title: "Category" },
                  { Name: "CreatedAt", Title: "Created" },
                  { Name: "AccessLevel", Title: "Access Level" },
                ]}
                rows={exceptionNotesList || []}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              setNotesExceptionsModal(false);
            }}
            className="btn btn-light btn-elevate w-25"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

async function GetLocations(
  Id,
  setList,
  setLocationsType,
  setLocationSelected
) {
  return await fetch(
    `${window.$apiurl}/transload/GetLocationsByReceipt?id=${Id}`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      try {
        var list = res[1];
        setLocationsType(list?.locationsType ?? []);
        setList(list?.viewModal ?? []);
        setLocationSelected(list?.locations ?? []);
      } catch (error) {
        Notification("error", "error");
      }
    })
    .catch((error) => {
      Notification("error", "error");
    });
}

function PoLocations({ Id }) {
  const [list, setList] = useState([]);
  const [LocationsType, setLocationsType] = useState([]);
  const [LocationSelected, setLocationSelected] = useState([]);

  useEffect(() => {
    window.LocatiosType = [];
    if (Id) GetLocations(Id, setList, setLocationsType, setLocationSelected);
  }, [Id]);

  useEffect(() => {
    if (LocationSelected?.length > 0) {
      setTimeout(() => {
        var el = document.getElementById("timelinehorizontal");
        if (el) {
          el.scrollLeft = 10000;
          el.removeAttribute("style");
        }
      }, 3000);
    }
  }, [LocationSelected]);

  return (
    <div className="row">
      {/* timeline */}
      <div className="col-lg-12 p-5">
        <div
          className="text-left"
          id="timelinehorizontal"
          style={{ opacity: 0 }}
        >
          <ul className="timeline" id="timeline">
            {LocationSelected.map((x, index) => (
              <li
                className={
                  "li" +
                  (LocationSelected.length === index + 1 ? " complete" : "")
                }
                key={index}
              >
                <div className="timestamp">
                  <span className="author">
                    <b>
                      {x?.User ?? "Empty"} moved {x?.EquipmentRef} to:
                    </b>
                  </span>
                  <span className="date">{momentDate(x?.Created)}</span>
                </div>
                <div className="status">
                  {(x?.Locations ?? []).map((child, index1) => (
                    <h4 className="d-block" key={index1}>
                      {child?.LocationName}{" "}
                      <small>- {child?.LocationType}</small>
                    </h4>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* timeline */}
    </div>
  );
}
