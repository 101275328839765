/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
//Date Range Picker Google
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
//////
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import { Notification } from "../../../../app/modules/Notification";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import low from "lowdb";
import {
  makeStyles,
  useTheme,
  withStyles,
  emphasize,
} from "@material-ui/core/styles";
import Menu2 from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import {
  ProgressBar,
  OverlayTrigger,
  Tooltip,
  Button,
  Modal,
  Alert,
  Accordion,
  ButtonToolbar,
} from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import { Export } from "../../../../_metronic/_helpers/ATHelpers";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LocalStorage from "lowdb/adapters/LocalStorage";
import CloseIcon from "@material-ui/icons/Close";
import { currencyFormat } from "../../../_helpers/ATHelpers";
import ModelManagmentFirstDashboard from "../../../../app/modules/eMPower/pages/ReceiptsManagementNewVersion/customers-table/Shipment/ModelManagmentFirstDashboard";
import { ModelManagmentFirstDashboardSm } from "../../../../app/modules/eMPower/pages/NewShipmentsBeta/customers-table/ModelShipmentManagment";
import moment from "moment";
const adapter = new LocalStorage("db");
const db = low(adapter);

var getDate = function(d) {
  if (d === null || d === "" || d === undefined) return "";
  var format = "MM/DD/YYYY";
  if (
    moment(d)
      .startOf("day")
      .valueOf() === moment(d).valueOf()
  ) {
    format = "MM/DD/YYYY";
  }
  return moment(d).format(format);
};
var getDateName = function(d) {
  if (d === null || d === "" || d === undefined) return "";
  var format = "dddd, MMMM DD, YYYY";
  return moment(d).format(format);
};
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu2
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "transparent",
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

function createData(name, calories, fat) {
  return { name, calories, fat };
}
const rows = [
  createData("Cupcake", 305, 3.7),
  createData("Donut", 452, 25.0),
  createData("Eclair", 262, 16.0),
  createData("Frozen yoghurt", 159, 6.0),
  createData("Gingerbread", 356, 16.0),
  createData("Honeycomb", 408, 3.2),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Jelly Bean", 375, 0.0),
  createData("KitKat", 518, 26.0),
  createData("Lollipop", 392, 0.2),
  createData("Marshmallow", 318, 0),
  createData("Nougat", 360, 19.0),
  createData("Oreo", 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

export function PerformanceGrid({ className, customerId, SearchBy }) {
  const [showTransload, setShowTransload] = React.useState(false);
  const [performancetavselected, setperformancetavselected] = React.useState(0);
  const [showInBound, setShowInBound] = React.useState(false);
  const [showOutBound, setShowOutBound] = React.useState(false);
  const [value, onChange] = useState([
    moment()
      .add(-7, "days")
      .format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [valuesTab, setvaluesTab] = React.useState(0);
  const [showContainerPort, setShowContainerPort] = React.useState(false);
  const [
    TransloadPerformanceReportLoadingSpinner2,
    setTransloadPerformanceReportLoadingSpinner2,
  ] = React.useState(false);
  const [ContainersDetails, setContainersDetails] = useState([]);
  const [PerformanceReportData, setPerformanceReportData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  function handleChange(event, newValue) {
    setvaluesTab(newValue);
  }
  const [pageContainer, setPageContainer] = React.useState(0);
  const [rowsPerPageContainer, setRowsPerPageContainer] = React.useState(10);

  //Use State Google Picker Date Range
  const [startDateValue, setStartDate] = React.useState(
    new Date()
      .addDays(-7)
      .toISOString()
      .split("T")[0]
  );
  const [endDateValue, setEndDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    if (customerId !== undefined) {
      setTransloadPerformanceReportLoadingSpinner2(true);
      var startDate = moment(startDateValue).format("YYYY-MM-DD");
      var endDate = moment(endDateValue).format("YYYY-MM-DD");
      var SearchText = document.getElementById("searchGlobalText").value;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-FP-API-KEY", "iphone");
      var requestOptions = {
        method: "GET",
        withCredentials: true,
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        `${window.$apiurl}/dashboardtransportation/performancereport?customerId=${customerId}&keyword=${SearchBy}${SearchText}&startDate=${startDate}&endDate=${endDate}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          setTransloadPerformanceReportLoadingSpinner2(false);
          var obj = JSON.parse(result);
          setPerformanceReportData(obj.Performance || []);
        })
        .catch((error) => console.log("error", error));
    }
  }, [customerId]);
  const [
    pageInboundContainerDetails,
    setPageInboundContainerDetails,
  ] = React.useState(0);
  const [
    rowsPerPageInboundContainerDetails,
    setRowsPerPageInboundContainerDetails,
  ] = React.useState(10);

  const [
    pageOutboundContainerDetails,
    setPageOutboundContainerDetails,
  ] = React.useState(0);
  const [
    rowsPerPageOutboundContainerDetails,
    setRowsPerPageOutboundContainerDetails,
  ] = React.useState(10);
  var totalALLContainers0 =
    PerformanceReportData[0]?.AllContainers?.Whithin24Hours +
      PerformanceReportData[0]?.AllContainers?.Whithin48Hours +
      PerformanceReportData[0]?.AllContainers?.Whithin72Hours +
      PerformanceReportData[0]?.AllContainers?.MoreThan72Hours || 0;
  var totalALLContainers1 =
    PerformanceReportData[1]?.AllContainers?.Whithin24Hours +
      PerformanceReportData[1]?.AllContainers?.Whithin48Hours +
      PerformanceReportData[1]?.AllContainers?.Whithin72Hours +
      PerformanceReportData[1]?.AllContainers?.MoreThan72Hours || 0;

  var totalALLContainers2 =
    PerformanceReportData[2]?.AllContainers?.Whithin24Hours +
      PerformanceReportData[2]?.AllContainers?.Whithin48Hours +
      PerformanceReportData[2]?.AllContainers?.Whithin72Hours +
      PerformanceReportData[2]?.AllContainers?.MoreThan72Hours || 0;
  var totalHotContainers0 =
    PerformanceReportData[0]?.HotContainers?.Whithin24Hours +
      PerformanceReportData[0]?.HotContainers?.Whithin48Hours +
      PerformanceReportData[0]?.HotContainers?.Whithin72Hours +
      PerformanceReportData[0]?.HotContainers?.MoreThan72Hours || 0;
  var totalHotContainers1 =
    PerformanceReportData[1]?.HotContainers?.Whithin24Hours +
      PerformanceReportData[1]?.HotContainers?.Whithin48Hours +
      PerformanceReportData[1]?.HotContainers?.Whithin72Hours +
      PerformanceReportData[1]?.HotContainers?.MoreThan72Hours || 0;
  var totalHotContainers2 =
    PerformanceReportData[2]?.HotContainers?.Whithin24Hours +
      PerformanceReportData[2]?.HotContainers?.Whithin48Hours +
      PerformanceReportData[2]?.HotContainers?.Whithin72Hours +
      PerformanceReportData[2]?.HotContainers?.MoreThan72Hours || 0;
  totalALLContainers0 = Number.isNaN(totalALLContainers0)
    ? 0
    : totalALLContainers0;

  totalALLContainers1 = Number.isNaN(totalALLContainers1)
    ? 0
    : totalALLContainers1;

  totalALLContainers2 = Number.isNaN(totalALLContainers2)
    ? 0
    : totalALLContainers2;

  totalHotContainers0 = Number.isNaN(totalHotContainers0)
    ? 0
    : totalHotContainers0;

  totalHotContainers1 = Number.isNaN(totalHotContainers1)
    ? 0
    : totalHotContainers1;

  totalHotContainers2 = Number.isNaN(totalHotContainers2)
    ? 0
    : totalHotContainers2;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);
  const emptyRowsContainer =
    rowsPerPageContainer -
    Math.min(
      rowsPerPageContainer,
      rows?.length - pageContainer * rowsPerPageContainer
    );

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }

  function handleChangePageContainer(event, newPage) {
    setPageContainer(newPage);
  }

  function handleChangeRowsPerPageContainer(event) {
    setRowsPerPageContainer(parseInt(event.target.value, 10));
  }

  function handleChangePageInboundContainerDetails(event, newPage) {
    setPageInboundContainerDetails(newPage);
  }

  function handleChangeRowsPerPageInboundContainerDetails(event) {
    setRowsPerPageInboundContainerDetails(parseInt(event.target.value, 10));
  }
  function handleChangePageOutboundContainerDetails(event, newPage) {
    setPageOutboundContainerDetails(newPage);
  }

  function handleChangeRowsPerPageOutboundContainerDetails(event) {
    setRowsPerPageOutboundContainerDetails(parseInt(event.target.value, 10));
  }
  const classes = useStyles();
  return (
    <>
      <Modal show={showContainerPort} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="col-lg-12 mt-5">
              <div className="react-bootstrap-table table-responsive">
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className="bg-light font-weight-bold">
                        Line
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Item #
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        PO #
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Description
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        SKU
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Weight
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Cube
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Size
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Color
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Whs
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Qty Recv
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Qty Shpd
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ContainersDetails?.slice(
                      pageContainer * rowsPerPageContainer,
                      pageContainer * rowsPerPageContainer +
                        rowsPerPageContainer
                    ).map((item, index) => (
                      <TableRow>
                        <TableCell>{item.Line}</TableCell>
                        <TableCell>{item.Item}</TableCell>
                        <TableCell>{item.Po}</TableCell>
                        <TableCell>{item.Description}</TableCell>
                        <TableCell>{item.Sku}</TableCell>
                        <TableCell>{currencyFormat(item.Weight)}</TableCell>
                        <TableCell>{currencyFormat(item.Cube)}</TableCell>
                        <TableCell>{item.Size}</TableCell>
                        <TableCell>{item.Color}</TableCell>
                        <TableCell>{item.Whs}</TableCell>
                        <TableCell>{item.QtyRecv}</TableCell>
                        <TableCell>{item.QtyShpd}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell className="bg-light">
                        <strong>Totals</strong>
                      </TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light">
                        <strong>
                          {ContainersDetails.reduce(
                            (a, b) => a + (b["Weight"] || 0),
                            0
                          ).toFixed(2) || 0}{" "}
                          KGM
                        </strong>
                      </TableCell>
                      <TableCell className="bg-light">
                        <strong>
                          {ContainersDetails.reduce(
                            (a, b) => a + (b["Cube"] || 0),
                            0
                          ).toFixed(2) || 0}{" "}
                          CBM
                        </strong>
                      </TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light">
                        <strong>
                          {ContainersDetails.reduce(
                            (a, b) => a + (b["QtyRecv"] || 0),
                            0
                          ).toFixed(2) || 0}
                        </strong>
                      </TableCell>
                      <TableCell className="bg-light"></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {ContainersDetails?.length > 0 ? (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={ContainersDetails?.length}
                    rowsPerPage={rowsPerPageContainer}
                    page={pageContainer}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePageContainer}
                    onChangeRowsPerPage={handleChangeRowsPerPageContainer}
                    ActionsComponent={TablePaginationActions}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setShowContainerPort(false);
              setPageContainer(0);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showTransload} size="xl">
        <Modal.Header>
          <Modal.Title>Transload Performance Detail</Modal.Title>
          <ButtonToolbar
            style={{ right: "79px", position: "absolute" }}
            className="btn-text-primary"
            className={classes.button}
          >
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              className="btn btn-outline-success btn-sm text-hover-white"
              onClick={handleClick}
            >
              Export as
              <i className="far fa-save ml-3 text-success text-hover-white"></i>
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].PerformanceReportDetails.map((item) => {
                    var onj = {
                      Container: item.Container,
                      Released: item.ReleaseDate,
                      Received: item.Received,
                      Devanned: item.DevanDate,
                      TransloadTime: item.TransloadTime,
                      Notes: item.CustomerNote,
                    };
                    data.push(onj);
                  });
                  Export(data, "excel", `Transload Performance Detail _`);
                }}
              >
                <ListItemIcon>
                  <i className="far fa-file-excel"></i>
                </ListItemIcon>
                <ListItemText primary="Excel" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].PerformanceReportDetails.map((item) => {
                    var onj = {
                      Container: item.Container,
                      Released: item.ReleaseDate,
                      Received: item.Received,
                      Devanned: item.DevanDate,
                      TransloadTime: item.TransloadTime,
                      Notes: item.CustomerNote,
                    };
                    data.push(onj);
                  });
                  Export(data, "csv", `Transload Performance Detail _`);
                }}
              >
                <ListItemIcon>
                  <i className="fas fa-file-csv"></i>
                </ListItemIcon>
                <ListItemText primary="CSV" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].PerformanceReportDetails.map((item) => {
                    var onj = {
                      Container: item.Container,
                      Released: item.ReleaseDate,
                      Received: item.Received,
                      Devanned: item.DevanDate,
                      TransloadTime: item.TransloadTime,
                      Notes: item.CustomerNote,
                    };
                    data.push(onj);
                  });
                  Export(data, "json", `Transload Performance Detail _`);
                }}
              >
                <ListItemIcon>
                  <i className="far fa-file-code"></i>
                </ListItemIcon>
                <ListItemText primary="Json" />
              </StyledMenuItem>
            </StyledMenu>
          </ButtonToolbar>

          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setPage(0);
              setShowTransload(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="col-lg-12 mt-5">
              <div className="react-bootstrap-table table-responsive">
                <Table className={classes.table} size="normal">
                  <TableHead className="mb-4">
                    <TableRow>
                      <TableCell className="bg-light">Container</TableCell>
                      <TableCell className="bg-light">Released</TableCell>
                      <TableCell className="bg-light">Received</TableCell>
                      <TableCell className="bg-light">Devanned</TableCell>
                      <TableCell className="bg-light">Transload time</TableCell>
                      <TableCell className="bg-light">Notes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PerformanceReportData[
                      performancetavselected
                    ]?.PerformanceReportDetails?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    ).map((item, index) => (
                      <TableRow>
                        <TableCell
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {
                            <ModelManagmentFirstDashboard
                              container={item.Container}
                              receiptId={item.Id}
                              isReporting={true}
                            />
                          }
                        </TableCell>
                        <TableCell
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {getDateName(item.ReleaseDate)}
                        </TableCell>
                        <TableCell
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {getDateName(item.Received)}
                        </TableCell>
                        <TableCell
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {getDateName(item.DevanDate)}
                        </TableCell>
                        <TableCell
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {item.TransloadTime}
                        </TableCell>

                        <TableCell
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {item.CustomerNote !== null &&
                          item.CustomerNote !== "" &&
                          item.CustomerNote !== undefined ? (
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ pointerEvents: "none" }}
                                  id="products-delete-tooltip"
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.CustomerNote,
                                    }}
                                  />
                                </Tooltip>
                              }
                            >
                              <a className="btn btn-icon btn-light btn-hover-warning btn-sm ml-1">
                                <span className="svg-icon svg-icon-md svg-icon-warning">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Clipboard.svg"
                                    )}
                                  />
                                </span>
                              </a>
                            </OverlayTrigger>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {PerformanceReportData[performancetavselected]
                  ?.PerformanceReportDetails && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={
                      PerformanceReportData[performancetavselected]
                        ?.PerformanceReportDetails?.length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setShowTransload(false);
              setPage(0);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showInBound} size="xl">
        <Modal.Header>
          <Modal.Title>Inbound Container Details</Modal.Title>
          <ButtonToolbar
            style={{ right: "79px", position: "absolute" }}
            className="btn-text-primary"
            className={classes.button}
          >
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              className="btn btn-outline-success btn-sm text-hover-white"
              onClick={handleClick}
            >
              Export as
              <i className="far fa-save ml-3 text-success text-hover-white"></i>
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].DimensionInboundDetails.map((item) => {
                    var onj = {
                      Container: item.Container,
                      Received: item.Received,
                      Released: item.ReleaseDate,
                      Devanned: item.DevanDate,
                      "Cube (CBM)": item.Cube,
                      "Weight (KGM)": item.Weight,
                    };
                    data.push(onj);
                  });
                  Export(data, "excel", `Inbound Container Details _`);
                }}
              >
                <ListItemIcon>
                  <i className="far fa-file-excel"></i>
                </ListItemIcon>
                <ListItemText primary="Excel" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].DimensionInboundDetails.map((item) => {
                    var onj = {
                      Container: item.Container,
                      Received: item.Received,
                      Released: item.ReleaseDate,
                      Devanned: item.DevanDate,
                      "Cube (CBM)": item.Cube,
                      "Weight (KGM)": item.Weight,
                    };
                    data.push(onj);
                  });
                  Export(data, "csv", `Inbound Container Details _`);
                }}
              >
                <ListItemIcon>
                  <i className="fas fa-file-csv"></i>
                </ListItemIcon>
                <ListItemText primary="CSV" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].DimensionInboundDetails.map((item) => {
                    var onj = {
                      Container: item.Container,
                      Received: item.Received,
                      Released: item.ReleaseDate,
                      Devanned: item.DevanDate,
                      "Cube (CBM)": item.Cube,
                      "Weight (KGM)": item.Weight,
                    };
                    data.push(onj);
                  });
                  Export(data, "json", `Inbound Container Details _`);
                }}
              >
                <ListItemIcon>
                  <i className="far fa-file-code"></i>
                </ListItemIcon>
                <ListItemText primary="Json" />
              </StyledMenuItem>
            </StyledMenu>
          </ButtonToolbar>

          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setShowInBound(false);
              setPageInboundContainerDetails(0);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="col-lg-12 mt-5">
              <div className="react-bootstrap-table table-responsive">
                <Table className={classes.table} size="normal">
                  <TableHead className="mb-4">
                    <TableRow>
                      <TableCell className="bg-light">Container</TableCell>
                      <TableCell className="bg-light">Received</TableCell>
                      <TableCell className="bg-light">Released</TableCell>
                      <TableCell className="bg-light">Devanned</TableCell>
                      <TableCell className="bg-light">Cube (CBM)</TableCell>
                      <TableCell className="bg-light">Weight (KGM)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PerformanceReportData[
                      performancetavselected
                    ]?.DimensionInboundDetails?.slice(
                      pageInboundContainerDetails *
                        rowsPerPageInboundContainerDetails,
                      pageInboundContainerDetails *
                        rowsPerPageInboundContainerDetails +
                        rowsPerPageInboundContainerDetails
                    ).map((item, index) => (
                      <TableRow>
                        <TableCell>
                          <ModelManagmentFirstDashboard
                            container={item.Container}
                            receiptId={item.Receipt}
                            isReporting={true}
                          />
                        </TableCell>
                        <TableCell>{getDateName(item.Received)}</TableCell>
                        <TableCell>{getDateName(item.ReleaseDate)}</TableCell>
                        <TableCell>{getDateName(item.DevanDate)}</TableCell>
                        <TableCell>
                          {item.Cube != null
                            ? currencyFormat(item.Cube)
                            : "0.00"}
                        </TableCell>
                        <TableCell>
                          {item.Weight != null
                            ? currencyFormat(item.Weight)
                            : "0.00"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {PerformanceReportData[performancetavselected]
                  ?.DimensionInboundDetails && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={
                      PerformanceReportData[performancetavselected]
                        ?.DimensionInboundDetails?.length
                    }
                    rowsPerPage={rowsPerPageInboundContainerDetails}
                    page={pageInboundContainerDetails}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePageInboundContainerDetails}
                    onChangeRowsPerPage={
                      handleChangeRowsPerPageInboundContainerDetails
                    }
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setShowInBound(false);
              setPageInboundContainerDetails(0);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showOutBound} size="xl">
        <Modal.Header>
          <Modal.Title>Outbound Trailer Details</Modal.Title>
          <ButtonToolbar
            style={{ right: "79px", position: "absolute" }}
            className="btn-text-primary"
            className={classes.button}
          >
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              className="btn btn-outline-success btn-sm text-hover-white"
              onClick={handleClick}
            >
              Export as
              <i className="far fa-save ml-3 text-success text-hover-white"></i>
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].DimensionOutboundDetails.map((item) => {
                    var onj = {
                      Shipment: item.Shipment,
                      Trailer: item.Trailer,
                      Released: item.ReleaseDate,
                      Devanned: item.DevanDate,
                      Loaded: item.Loaded,
                      Delivered: item.Delivred,
                      "Cube (CBM)": item.Cube,
                      "Weight (KGM)": item.Weight,
                    };
                    data.push(onj);
                  });
                  Export(data, "excel", `Outbound Trailer Details _`);
                }}
              >
                <ListItemIcon>
                  <i className="far fa-file-excel"></i>
                </ListItemIcon>
                <ListItemText primary="Excel" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].DimensionOutboundDetails.map((item) => {
                    var onj = {
                      Shipment: item.Shipment,
                      Trailer: item.Trailer,
                      Released: item.ReleaseDate,
                      Devanned: item.DevanDate,
                      Loaded: item.Loaded,
                      Delivered: item.Delivred,
                      "Cube (CBM)": item.Cube,
                      "Weight (KGM)": item.Weight,
                    };
                    data.push(onj);
                  });
                  Export(data, "csv", `Outbound Trailer Details _`);
                }}
              >
                <ListItemIcon>
                  <i className="fas fa-file-csv"></i>
                </ListItemIcon>
                <ListItemText primary="CSV" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].DimensionOutboundDetails.map((item) => {
                    var onj = {
                      Shipment: item.Shipment,
                      Trailer: item.Trailer,
                      Released: item.ReleaseDate,
                      Devanned: item.DevanDate,
                      Loaded: item.Loaded,
                      Delivered: item.Delivred,
                      "Cube (CBM)": item.Cube,
                      "Weight (KGM)": item.Weight,
                    };
                    data.push(onj);
                  });
                  Export(data, "json", `Outbound Trailer Details _`);
                }}
              >
                <ListItemIcon>
                  <i className="far fa-file-code"></i>
                </ListItemIcon>
                <ListItemText primary="Json" />
              </StyledMenuItem>
            </StyledMenu>
          </ButtonToolbar>
          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setShowOutBound(false);
              setPageOutboundContainerDetails(0);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="col-lg-12 mt-5">
              <div className="react-bootstrap-table table-responsive">
                <Table className={classes.table} size="normal">
                  <TableHead className="mb-4">
                    <TableRow>
                      <TableCell className="bg-light">Shipment</TableCell>
                      <TableCell className="bg-light">Trailer</TableCell>
                      <TableCell className="bg-light">Released</TableCell>
                      <TableCell className="bg-light">Devanned</TableCell>
                      <TableCell className="bg-light">Loaded</TableCell>
                      <TableCell className="bg-light">Delivered</TableCell>
                      <TableCell className="bg-light">Cube (CBM)</TableCell>
                      <TableCell className="bg-light">Weight (KGM)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PerformanceReportData[
                      performancetavselected
                    ]?.DimensionOutboundDetails?.slice(
                      pageOutboundContainerDetails *
                        rowsPerPageOutboundContainerDetails,
                      pageOutboundContainerDetails *
                        rowsPerPageOutboundContainerDetails +
                        rowsPerPageOutboundContainerDetails
                    ).map((item, index) => (
                      <TableRow>
                        <TableCell>{item.Shipment}</TableCell>
                        <TableCell>
                          <ModelManagmentFirstDashboardSm
                            trailer={item.Trailer}
                            id={item.Shipment}
                            isDashboard={true}
                          />
                        </TableCell>
                        <TableCell>{getDateName(item.ReleaseDate)}</TableCell>
                        <TableCell>{getDateName(item.DevanDate)}</TableCell>
                        <TableCell>{getDateName(item.Loaded)}</TableCell>
                        <TableCell>{getDateName(item.Delivred)}</TableCell>
                        <TableCell>
                          {item.Cube != null
                            ? currencyFormat(item.Cube)
                            : "0.00"}
                        </TableCell>
                        <TableCell>
                          {item.Weight != null
                            ? currencyFormat(item.Weight)
                            : "0.00"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {PerformanceReportData[performancetavselected]
                  ?.DimensionOutboundDetails && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={
                      PerformanceReportData[performancetavselected]
                        ?.DimensionOutboundDetails?.length
                    }
                    rowsPerPage={rowsPerPageOutboundContainerDetails}
                    page={pageOutboundContainerDetails}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePageOutboundContainerDetails}
                    onChangeRowsPerPage={
                      handleChangeRowsPerPageOutboundContainerDetails
                    }
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setShowOutBound(false);
              setPageOutboundContainerDetails(0);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className={`card card-custom ${className}`}>
        {TransloadPerformanceReportLoadingSpinner2 && (
          <ProgressBar
            variant="success"
            animated
            now={100}
            style={{ height: "3px", width: "100%" }}
          />
        )}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Performance Report
          </h3>
        </div>
        <div className="card-body d-flex flex-column">
          <div className="form-group row">
            <div className="col-md-10">
              <RangeDatePicker
                startDate={startDateValue}
                endDate={endDateValue}
                onChange={(startDate, endDate) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                  onChange(startDate, endDate);
                }}
                dateFormat="MM/DD/YYYY"
                minDate={new Date(1900, 0, 1)}
                maxDate={new Date(2100, 0, 1)}
                startDatePlaceholder="Start Date"
                endDatePlaceholder="End Date"
                disabled={false}
                className="my-own-class-name"
                startWeekDay="monday"
              />
            </div>
            <div className="col-md-2 mt-4">
              <button
                className="btn btn-primary font-weight-bolder font-size-sm"
                id="SearchPerformanceReport"
                onClick={() => {
                  if (
                    customerId === 0 ||
                    customerId === null ||
                    customerId === undefined
                  ) {
                    Notification("errorClick", "Please select customer");
                    return;
                  }
                  setTransloadPerformanceReportLoadingSpinner2(true);
                  var startDate = moment(startDateValue).format("YYYY-MM-DD");
                  var endDate = moment(endDateValue).format("YYYY-MM-DD");
                  var SearchText = document.getElementById("searchGlobalText")
                    .value;
                  var myHeaders = new Headers();
                  myHeaders.append(
                    "Authorization",
                    `bearer ${db.read().getState().Token}`
                  );
                  myHeaders.append("Content-Type", "application/json");
                  myHeaders.append("X-FP-API-KEY", "iphone");
                  var requestOptions = {
                    method: "GET",
                    withCredentials: true,
                    headers: myHeaders,
                    redirect: "follow",
                  };
                  fetch(
                    `${window.$apiurl}/dashboardtransportation/performancereport?customerId=${customerId}&keyword=${SearchBy}${SearchText}&startDate=${startDate}&endDate=${endDate}`,
                    requestOptions
                  )
                    .then((response) => response.text())
                    .then((result) => {
                      setTransloadPerformanceReportLoadingSpinner2(false);
                      var obj = JSON.parse(result);
                      setPerformanceReportData(obj.Performance);
                    })
                    .catch((error) => console.log("error", error));
                }}
              >
                Search
              </button>
            </div>
          </div>
          <div className="form-group row mb-5 mt-4">
            <div className="col-md-12">
              <Tabs
                value={valuesTab}
                onChange={handleChange}
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
                className="p-0"
              >
                <Tab
                  className="p-0"
                  label={
                    <React.Fragment>
                      <span
                        className="font-weight-boldest"
                        style={{
                          fontSize: 16,
                          paddingRight: "3rem",
                          textTransform: "initial",
                        }}
                      >
                        Released to Devan
                      </span>
                    </React.Fragment>
                  }
                />
                <Tab
                  label={
                    <React.Fragment>
                      <span
                        className="font-weight-boldest"
                        style={{
                          fontSize: 16,
                          paddingRight: "3rem",
                          textTransform: "initial",
                        }}
                      >
                        Devanned to Loaded
                      </span>
                    </React.Fragment>
                  }
                />
                {/* <Tab
                  label={
                    <React.Fragment>
                      <span
                        className="font-weight-boldest"
                        style={{
                          fontSize: 16,
                          paddingRight: "3rem",
                          textTransform: "initial",
                        }}
                      >
                        Shipped to RDD
                      </span>
                    </React.Fragment>
                  }
                /> */}
              </Tabs>
            </div>
          </div>
          {valuesTab === 0 && (
            <>
              <div className="form-group row mb-5">
                <div className="col-md-6 pr-0 mb-7 text-right">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="font-weight-bold px-1"
                            colSpan="3"
                          >
                            <strong>ALL Containers</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="">Category</TableCell>
                          <TableCell className="">Quantity</TableCell>
                          <TableCell className="">Percent</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>within 24 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[0]?.AllContainers
                                ?.Whithin24Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.AllContainers
                              ?.Whithin24Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[0]?.AllContainers
                                    ?.Whithin24Hours /
                                    totalALLContainers0) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>within 48 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[0]?.AllContainers
                                ?.Whithin48Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.AllContainers
                              ?.Whithin48Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[0]?.AllContainers
                                    ?.Whithin48Hours /
                                    totalALLContainers0) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>within 72 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[0]?.AllContainers
                                ?.Whithin72Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.AllContainers
                              ?.Whithin72Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[0]?.AllContainers
                                    ?.Whithin72Hours /
                                    totalALLContainers0) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>More Than 72 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[0]?.AllContainers
                                ?.MoreThan72Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.AllContainers
                              ?.MoreThan72Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[0]?.AllContainers
                                    ?.MoreThan72Hours /
                                    totalALLContainers0) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="bg-light">
                            <strong>Total</strong>
                          </TableCell>
                          <TableCell className="bg-light">
                            <strong>{totalALLContainers0}</strong>
                          </TableCell>
                          <TableCell className="bg-light">
                            <strong>
                              {PerformanceReportData[0]?.AllContainers
                                ?.MoreThan72Hours === 0 &&
                              PerformanceReportData[0]?.AllContainers
                                ?.Whithin24Hours === 0 &&
                              PerformanceReportData[0]?.AllContainers
                                ?.Whithin48Hours === 0 &&
                              PerformanceReportData[0]?.AllContainers
                                ?.Whithin72Hours === 0
                                ? "0"
                                : "100"}
                              %
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <button
                    className="btn btn-primary font-weight-bolder font-size-sm mt-5"
                    onClick={(e) => {
                      setShowTransload(true);
                      setperformancetavselected(0);
                    }}
                  >
                    View Details
                  </button>
                </div>
                <div className="col-md-6 pl-0 mb-7 text-right">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="font-weight-boldest"
                            colSpan="3"
                          >
                            <strong>
                              Hot Containers (
                              <i className="fab fa-gripfire text-warning"></i>)
                            </strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="">Category</TableCell>
                          <TableCell className="">Quantity</TableCell>
                          <TableCell className="">Percent</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>within 24 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[0]?.HotContainers
                                ?.Whithin24Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.HotContainers
                              ?.Whithin24Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[0]?.HotContainers
                                    ?.Whithin24Hours /
                                    totalHotContainers0) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>within 48 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[0]?.HotContainers
                                ?.Whithin48Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.HotContainers
                              ?.Whithin48Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[0]?.HotContainers
                                    ?.Whithin48Hours /
                                    totalHotContainers0) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>within 72 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[0]?.HotContainers
                                ?.Whithin72Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.HotContainers
                              ?.Whithin72Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[0]?.HotContainers
                                    ?.Whithin72Hours /
                                    totalHotContainers0) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>More Than 72 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[0]?.HotContainers
                                ?.MoreThan72Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.HotContainers
                              ?.MoreThan72Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[0]?.HotContainers
                                    ?.MoreThan72Hours /
                                    totalHotContainers0) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="bg-light">
                            <strong>Total</strong>
                          </TableCell>
                          <TableCell className="bg-light">
                            <strong>{totalHotContainers0}</strong>
                          </TableCell>
                          <TableCell className="bg-light">
                            <strong>
                              {PerformanceReportData[0]?.HotContainers
                                ?.MoreThan72Hours === 0 &&
                              PerformanceReportData[0]?.HotContainers
                                ?.Whithin24Hours === 0 &&
                              PerformanceReportData[0]?.HotContainers
                                ?.Whithin48Hours === 0 &&
                              PerformanceReportData[0]?.HotContainers
                                ?.Whithin72Hours === 0
                                ? "0"
                                : "100"}
                              %
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6 pr-0 text-right">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="font-weight-boldest"
                            colSpan="3"
                          >
                            <strong>Inbound Container Dimensions</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="">Total Inbound</TableCell>
                          <TableCell className="">Average Cartons</TableCell>
                          <TableCell className="">Average Cube (CBM)</TableCell>
                          <TableCell className="">
                            Average Weight (KGM)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {PerformanceReportData[0]?.DimensionsInbound
                              ?.Total !== null
                              ? PerformanceReportData[0]?.DimensionsInbound
                                  ?.Total || 0
                              : 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.DimensionsInbound
                              ?.AverageCartons !== null
                              ? PerformanceReportData[0]?.DimensionsInbound
                                  ?.AverageCartons || 0
                              : 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.DimensionsInbound
                              ?.AverageCubeCbm !== null
                              ? PerformanceReportData[0]?.DimensionsInbound?.AverageCubeCbm.toFixed(
                                  2
                                ) || 0
                              : 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.DimensionsInbound
                              ?.AverageWeightKgm !== null
                              ? PerformanceReportData[0]?.DimensionsInbound?.AverageWeightKgm.toFixed(
                                  2
                                ) || 0
                              : 0}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <button
                    className="btn btn-primary font-weight-bolder font-size-sm mt-5"
                    onClick={(e) => {
                      setShowInBound(true);
                      setperformancetavselected(0);
                    }}
                  >
                    View Details
                  </button>
                </div>
                <div className="col-md-6 pl-0 text-right">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="font-weight-boldest"
                            colSpan="3"
                          >
                            <strong>Outbound Trailer Dimensions</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="">Total Outbound</TableCell>
                          <TableCell className="">Average Cartons</TableCell>
                          <TableCell className="">Average Cube (CBM)</TableCell>
                          <TableCell className="">
                            Average Weight (KGM)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {PerformanceReportData[0]?.DimensionsOutbound
                              ?.Total || 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.DimensionsOutbound
                              ?.AverageCartons !== null
                              ? PerformanceReportData[0]?.DimensionsOutbound
                                  ?.AverageCartons || 0
                              : 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.DimensionsOutbound?.AverageCubeCbm?.toFixed(
                              2
                            ) || 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[0]?.DimensionsOutbound?.AverageWeightKgm?.toFixed(
                              2
                            ) || 0}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <button
                    className="btn btn-primary font-weight-bolder font-size-sm mt-5"
                    onClick={(e) => {
                      setShowOutBound(true);
                      setperformancetavselected(0);
                    }}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </>
          )}
          {valuesTab === 1 && (
            <>
              <div className="form-group row mb-5">
                <div className="col-md-6 pr-0 mb-7 text-right">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="font-weight-bold px-1"
                            colSpan="3"
                          >
                            <strong>ALL Containers</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="">Category</TableCell>
                          <TableCell className="">Quantity</TableCell>
                          <TableCell className="">Percent</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>within 24 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[1]?.AllContainers
                                ?.Whithin24Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.AllContainers
                              ?.Whithin24Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[1]?.AllContainers
                                    ?.Whithin24Hours /
                                    totalALLContainers1) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>within 48 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[1]?.AllContainers
                                ?.Whithin48Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.AllContainers
                              ?.Whithin48Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[1]?.AllContainers
                                    ?.Whithin48Hours /
                                    totalALLContainers1) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>within 72 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[1]?.AllContainers
                                ?.Whithin72Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.AllContainers
                              ?.Whithin72Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[1]?.AllContainers
                                    ?.Whithin72Hours /
                                    totalALLContainers1) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>More Than 72 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[1]?.AllContainers
                                ?.MoreThan72Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.AllContainers
                              ?.MoreThan72Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[1]?.AllContainers
                                    ?.MoreThan72Hours /
                                    totalALLContainers1) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="bg-light">
                            <strong>Total</strong>
                          </TableCell>
                          <TableCell className="bg-light">
                            <strong>{totalALLContainers1}</strong>
                          </TableCell>
                          <TableCell className="bg-light">
                            <strong>
                              {PerformanceReportData[1]?.AllContainers
                                ?.MoreThan72Hours === 0 &&
                              PerformanceReportData[1]?.AllContainers
                                ?.Whithin24Hours === 0 &&
                              PerformanceReportData[1]?.AllContainers
                                ?.Whithin48Hours === 0 &&
                              PerformanceReportData[1]?.AllContainers
                                ?.Whithin72Hours === 0
                                ? "0"
                                : "100"}
                              %
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <button
                    className="btn btn-primary font-weight-bolder font-size-sm mt-5"
                    onClick={(e) => {
                      setShowTransload(true);
                      setperformancetavselected(1);
                    }}
                  >
                    View Details
                  </button>
                </div>
                <div className="col-md-6 pl-0 mb-7 text-right">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="font-weight-boldest"
                            colSpan="3"
                          >
                            <strong>
                              Hot Containers (
                              <i className="fab fa-gripfire text-warning"></i>)
                            </strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="">Category</TableCell>
                          <TableCell className="">Quantity</TableCell>
                          <TableCell className="">Percent</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>within 24 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[1]?.HotContainers
                                ?.Whithin24Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.HotContainers
                              ?.Whithin24Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[1]?.HotContainers
                                    ?.Whithin24Hours /
                                    totalHotContainers1) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>within 48 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[1]?.HotContainers
                                ?.Whithin48Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.HotContainers
                              ?.Whithin48Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[1]?.HotContainers
                                    ?.Whithin48Hours /
                                    totalHotContainers1) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>within 72 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[1]?.HotContainers
                                ?.Whithin72Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.HotContainers
                              ?.Whithin72Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[1]?.HotContainers
                                    ?.Whithin72Hours /
                                    totalHotContainers1) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>More Than 72 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[1]?.HotContainers
                                ?.MoreThan72Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.HotContainers
                              ?.MoreThan72Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[1]?.HotContainers
                                    ?.MoreThan72Hours /
                                    totalHotContainers1) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="bg-light">
                            <strong>Total</strong>
                          </TableCell>
                          <TableCell className="bg-light">
                            <strong>{totalHotContainers1}</strong>
                          </TableCell>
                          <TableCell className="bg-light">
                            <strong>
                              {PerformanceReportData[1]?.HotContainers
                                ?.MoreThan72Hours === 0 &&
                              PerformanceReportData[1]?.HotContainers
                                ?.Whithin24Hours === 0 &&
                              PerformanceReportData[1]?.HotContainers
                                ?.Whithin48Hours === 0 &&
                              PerformanceReportData[1]?.HotContainers
                                ?.Whithin72Hours === 0
                                ? "0"
                                : "100"}
                              %
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6 pr-0 text-right">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="font-weight-boldest"
                            colSpan="3"
                          >
                            <strong>Inbound Container Dimensions</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="">Total Inbound</TableCell>
                          <TableCell className="">Average Cartons</TableCell>
                          <TableCell className="">Average Cube (CBM)</TableCell>
                          <TableCell className="">
                            Average Weight (KGM)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {PerformanceReportData[1]?.DimensionsInbound
                              ?.Total !== null
                              ? PerformanceReportData[1]?.DimensionsInbound
                                  ?.Total || 0
                              : 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.DimensionsInbound
                              ?.AverageCartons !== null
                              ? PerformanceReportData[1]?.DimensionsInbound
                                  ?.AverageCartons || 0
                              : 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.DimensionsInbound
                              ?.AverageCubeCbm !== null
                              ? PerformanceReportData[1]?.DimensionsInbound?.AverageCubeCbm.toFixed(
                                  2
                                ) || 0
                              : 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.DimensionsInbound
                              ?.AverageWeightKgm !== null
                              ? PerformanceReportData[1]?.DimensionsInbound?.AverageWeightKgm.toFixed(
                                  2
                                ) || 0
                              : 0}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <button
                    className="btn btn-primary font-weight-bolder font-size-sm mt-5"
                    onClick={(e) => {
                      setShowInBound(true);
                      setperformancetavselected(1);
                    }}
                  >
                    View Details
                  </button>
                </div>
                <div className="col-md-6 pl-0 text-right">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="font-weight-boldest"
                            colSpan="3"
                          >
                            <strong>Outbound Trailer Dimensions</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="">Total Outbound</TableCell>
                          <TableCell className="">Average Cartons</TableCell>
                          <TableCell className="">Average Cube (CBM)</TableCell>
                          <TableCell className="">
                            Average Weight (KGM)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {PerformanceReportData[1]?.DimensionsOutbound
                              ?.Total || 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.DimensionsOutbound
                              ?.AverageCartons !== null
                              ? PerformanceReportData[1]?.DimensionsOutbound
                                  ?.AverageCartons || 0
                              : 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.DimensionsOutbound?.AverageCubeCbm?.toFixed(
                              2
                            ) || 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[1]?.DimensionsOutbound?.AverageWeightKgm?.toFixed(
                              2
                            ) || 0}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <button
                    className="btn btn-primary font-weight-bolder font-size-sm mt-5"
                    onClick={(e) => {
                      setShowOutBound(true);
                      setperformancetavselected(1);
                    }}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </>
          )}
          {valuesTab === 2 && (
            <>
              <div className="form-group row mb-5">
                <div className="col-md-6 pr-0 mb-7 text-right">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="font-weight-bold px-1"
                            colSpan="3"
                          >
                            <strong>ALL Containers</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="">Category</TableCell>
                          <TableCell className="">Quantity</TableCell>
                          <TableCell className="">Percent</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>within 24 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[2]?.AllContainers
                                ?.Whithin24Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.AllContainers
                              ?.Whithin24Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[2]?.AllContainers
                                    ?.Whithin24Hours /
                                    totalALLContainers2) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>within 48 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[2]?.AllContainers
                                ?.Whithin48Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.AllContainers
                              ?.Whithin48Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[2]?.AllContainers
                                    ?.Whithin48Hours /
                                    totalALLContainers2) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>within 72 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[2]?.AllContainers
                                ?.Whithin72Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.AllContainers
                              ?.Whithin72Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[2]?.AllContainers
                                    ?.Whithin72Hours /
                                    totalALLContainers2) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>More Than 72 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[2]?.AllContainers
                                ?.MoreThan72Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.AllContainers
                              ?.MoreThan72Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[2]?.AllContainers
                                    ?.MoreThan72Hours /
                                    totalALLContainers2) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="bg-light">
                            <strong>Total</strong>
                          </TableCell>
                          <TableCell className="bg-light">
                            <strong>{totalALLContainers2}</strong>
                          </TableCell>
                          <TableCell className="bg-light">
                            <strong>
                              {PerformanceReportData[2]?.AllContainers
                                ?.MoreThan72Hours === 0 &&
                              PerformanceReportData[2]?.AllContainers
                                ?.Whithin24Hours === 0 &&
                              PerformanceReportData[2]?.AllContainers
                                ?.Whithin48Hours === 0 &&
                              PerformanceReportData[2]?.AllContainers
                                ?.Whithin72Hours === 0
                                ? "0"
                                : "100"}
                              %
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <button
                    className="btn btn-primary font-weight-bolder font-size-sm mt-5"
                    onClick={(e) => {
                      setShowTransload(true);
                      setperformancetavselected(2);
                    }}
                  >
                    View Details
                  </button>
                </div>
                <div className="col-md-6 pl-0 mb-7 text-right">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="font-weight-boldest"
                            colSpan="3"
                          >
                            <strong>
                              Hot Containers (
                              <i className="fab fa-gripfire text-warning"></i>)
                            </strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="">Category</TableCell>
                          <TableCell className="">Quantity</TableCell>
                          <TableCell className="">Percent</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>within 24 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[2]?.HotContainers
                                ?.Whithin24Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.HotContainers
                              ?.Whithin24Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[2]?.HotContainers
                                    ?.Whithin24Hours /
                                    totalHotContainers2) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>within 48 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[2]?.HotContainers
                                ?.Whithin48Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.HotContainers
                              ?.Whithin48Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[2]?.HotContainers
                                    ?.Whithin48Hours /
                                    totalHotContainers2) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>within 72 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[2]?.HotContainers
                                ?.Whithin72Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.HotContainers
                              ?.Whithin72Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[2]?.HotContainers
                                    ?.Whithin72Hours /
                                    totalHotContainers2) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>More Than 72 Hours</TableCell>
                          <TableCell>
                            {
                              PerformanceReportData[2]?.HotContainers
                                ?.MoreThan72Hours
                            }
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.HotContainers
                              ?.MoreThan72Hours !== undefined
                              ? currencyFormat(
                                  (PerformanceReportData[2]?.HotContainers
                                    ?.MoreThan72Hours /
                                    totalHotContainers2) *
                                    100
                                )
                              : "0.00"}
                            %
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="bg-light">
                            <strong>Total</strong>
                          </TableCell>
                          <TableCell className="bg-light">
                            <strong>{totalHotContainers2}</strong>
                          </TableCell>
                          <TableCell className="bg-light">
                            <strong>
                              {PerformanceReportData[2]?.HotContainers
                                ?.MoreThan72Hours === 0 &&
                              PerformanceReportData[2]?.HotContainers
                                ?.Whithin24Hours === 0 &&
                              PerformanceReportData[2]?.HotContainers
                                ?.Whithin48Hours === 0 &&
                              PerformanceReportData[2]?.HotContainers
                                ?.Whithin72Hours === 0
                                ? "0"
                                : "100"}
                              %
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6 pr-0 text-right">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="font-weight-boldest"
                            colSpan="3"
                          >
                            <strong>Inbound Container Dimensions</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="">Total Inbound</TableCell>
                          <TableCell className="">Average Cartons</TableCell>
                          <TableCell className="">Average Cube (CBM)</TableCell>
                          <TableCell className="">
                            Average Weight (KGM)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {PerformanceReportData[2]?.DimensionsInbound
                              ?.Total !== null
                              ? PerformanceReportData[2]?.DimensionsInbound
                                  ?.Total || 0
                              : 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.DimensionsInbound
                              ?.AverageCartons !== null
                              ? PerformanceReportData[2]?.DimensionsInbound
                                  ?.AverageCartons || 0
                              : 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.DimensionsInbound
                              ?.AverageCubeCbm !== null
                              ? PerformanceReportData[2]?.DimensionsInbound?.AverageCubeCbm.toFixed(
                                  2
                                ) || 0
                              : 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.DimensionsInbound
                              ?.AverageWeightKgm !== null
                              ? PerformanceReportData[2]?.DimensionsInbound?.AverageWeightKgm.toFixed(
                                  2
                                ) || 0
                              : 0}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <button
                    className="btn btn-primary font-weight-bolder font-size-sm mt-5"
                    onClick={(e) => {
                      setShowInBound(true);
                      setperformancetavselected(2);
                    }}
                  >
                    View Details
                  </button>
                </div>
                <div className="col-md-6 pl-0 text-right">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="font-weight-boldest"
                            colSpan="3"
                          >
                            <strong>Outbound Trailer Dimensions</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="">Total Outbound</TableCell>
                          <TableCell className="">Average Cartons</TableCell>
                          <TableCell className="">Average Cube (CBM)</TableCell>
                          <TableCell className="">
                            Average Weight (KGM)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            {PerformanceReportData[2]?.DimensionsOutbound
                              ?.Total || 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.DimensionsOutbound
                              ?.AverageCartons !== null
                              ? PerformanceReportData[2]?.DimensionsOutbound
                                  ?.AverageCartons || 0
                              : 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.DimensionsOutbound?.AverageCubeCbm?.toFixed(
                              2
                            ) || 0}
                          </TableCell>
                          <TableCell>
                            {PerformanceReportData[2]?.DimensionsOutbound?.AverageWeightKgm?.toFixed(
                              2
                            ) || 0}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <button
                    className="btn btn-primary font-weight-bolder font-size-sm mt-5"
                    onClick={(e) => {
                      setShowOutBound(true);
                      setperformancetavselected(2);
                    }}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
