/* eslint-disable react-hooks/exhaustive-deps */
// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import {
  IconButton,
  makeStyles,
  LinearProgress,
  Input,
  TextField,
} from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import CreatableSelect from "react-select/creatable";
import CloseIcon from "@material-ui/icons/Close";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Spinner,
  Col,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import SVG from "react-inlinesvg";
import Select from "react-select";
import {
  sortCaret,
  toAbsoluteUrl,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import {
  currencyFormat,
  DateTime30GL,
  RequiredHtml,
} from "../../../../../../_metronic/_helpers/ATHelpers";
import { ChargesFunction } from "../../Transload/FindOrders/product-Containers/remark-edit-dialog/RemarkEditForm";
import SelectPagination from "../../../../../../_metronic/_partials/widgets/Select/SelectPagination";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CheckboxLarge,
  CheckboxLargeWB,
  LoadingDialog,
} from "../../../../../../_metronic/_partials/controls";
import { Notification } from "../../../../Notification";
import { Formik } from "formik";
import axios from "axios";
import moment from "moment";
import GoogleMapReact from "google-map-react";
import { OrderAttachementsV2 } from "../../Transload/FindOrders/product-edit/OrderAttachements";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { MultiStopsIndexShipping } from "../MultiStops";
import { LockPayblesButton } from "../../Transload/FindOrders/product-Containers/remark-edit-dialog/ChargesFunction";
var Spinners = require("react-spinkit");

const AlertListData = [
  {
    Alerts: "Weather Delay",
  },
  {
    Alerts: "Breakdown",
  },
];

const adapter = new LocalStorage("db");
const db = low(adapter);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
}));

export function ServiceOrderFtlDetail({
  setFieldValue,
  values,
  statSHipmentId,
  setCharges,
  ReloadCalc,
  SO,
  getAssignment,
  initProduct,
  ViewModelOptions,
  GetViewModel,
  GetSoData,
  setShowLoadBidsModal,
  ShowLoadBidsModal,
  loaderSoStop,
  setLoaderSoStop,
}) {
  const rowClasses = (row) => (row.TraceType === "Alert" ? "warning" : "");
  const rowClassesBids = (row) => (row.IsAccepted === true ? "success" : "");
  //stops
  useEffect(() => {
    if (SO) {
      GetItemsTms(SO);
      GetStops(SO);
      GetStopsNewIntegration(SO);
    }
  }, [SO]);

  useEffect(() => {
    window.ARCartons = undefined;
    window.ARTotalWeight1 = undefined;
    window.ARTotalWeight2 = undefined;
    window.ARTotalCube1 = undefined;
  }, []);

  const [ChargesTotal, setChargesTotal] = useState();
  const [PayblesTotal, setPayblesTotal] = useState();
  const [DataListBids, setDataListBids] = useState([]);
  const [LoadingBidsStatus, setLoadingBidsStatus] = useState(false);
  //Multi Stops
  const [listMultiStopsShipping, setMultiStopsDataShipping] = useState([]);
  const [showSaveArrows, setShowSaveButton] = useState(false);

  function GetLoadBidsData() {
    setLoadingBidsStatus(true);
    fetch(window.$apiurl + `/tms/Loadbids/${SO}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          const result = res[1];
          setDataListBids(result?.Bids || []);
        }
        setLoadingBidsStatus(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    if (values?.StatusName === "New") {
      GetLoadBidsData();
    }
  }, [values.StatusName]);

  const [addressesStops, setAddressesStops] = useState([]);
  const [addressesStopsFetch, setAddressesStopsFetch] = useState([]);
  const [TrackingDataGrid, setTrackingDataGrid] = useState([]);
  const [TrackingDataPoliLyne, setTrackingDataPoliLyne] = useState([]);
  const [TrackingStatusOptions, setTrackingStatusOptions] = useState([]);
  const [phoneNumberStop, setPhoneNumberStop] = useState("");
  const [zipCodeStop, setZipCodeStop] = useState("");
  const [stateStop, setStateStop] = useState("");
  const [cityStop, setCityStop] = useState("");
  const [addressStop, setAddressStop] = useState("");
  const [companyNameStop, setCompanyNameStop] = useState("");
  const [faxStop, setFaxStop] = useState("");
  const [contactName, setContactName] = useState("");
  const [EmailStop, setEmailStop] = useState("");
  const [stopAddressValue, setStopAddressValue] = useState({
    value: "",
    label: "Select",
  });
  const [modalAddresses, setmodalAddresses] = useState(false);
  //Fetch Dynamic Address

  // useEffect(() => {
  //   setShowHideBids(false);
  //   setTimeout(() => {
  //     setShowHideBids(true);
  //   }, 1);
  // }, [initProduct]);

  const [consignee, setConsignee] = useState({
    ConsigneeId: 0,
    ConsigneeAddress: "",
    ConsigneeCity: "",
    ConsigneeState: "",
    ConsigneeZipCode: "",
    ConsigneePhoneNumber: "",
    ConsigneeFax: "",
    ConsigneeContactName: "",
    ConsigneeEmail: "",
    ReceivingNote: "",
  });
  const [TrackingObject, setTrackingObject] = useState({
    ShipmentId: 0,
    TraceCode: "",
    EtaDate:
      TrackingDataGrid?.CurrentEtaDate !== null &&
      TrackingDataGrid?.CurrentEtaDate !== "" &&
      TrackingDataGrid?.CurrentEtaDate !== undefined
        ? TrackingDataGrid?.CurrentEtaDate
        : values.DeliveryAppointmentDate !== null &&
          values.DeliveryAppointmentDate !== undefined &&
          values.DeliveryAppointmentDate !== ""
        ? values.DeliveryAppointmentDate
        : "",
    TimeZone: "",
    Country: "",
    City: "",
    State: "",
    NextTrackingDue:
      new Date().toISOString().substr(0, 10) +
      "T" +
      new Date(new Date().setTime(new Date().getTime() + 6 * 60 * 60 * 1000))
        .getHours()
        .toString()
        .padStart(2, "0") +
      ":" +
      (new Date(
        new Date().setTime(new Date().getTime() + 6 * 60 * 60 * 1000)
      ).getMinutes() > 30
        ? "30"
        : "00") +
      ":00",
    Note: "",
  });
  useEffect(() => {
    setTrackingObject({
      ShipmentId: 0,
      TraceCode: "",
      EtaDate:
        TrackingDataGrid?.CurrentEtaDate !== null &&
        TrackingDataGrid?.CurrentEtaDate !== "" &&
        TrackingDataGrid?.CurrentEtaDate !== undefined
          ? TrackingDataGrid?.CurrentEtaDate
          : values.DeliveryAppointmentDate !== null &&
            values.DeliveryAppointmentDate !== undefined &&
            values.DeliveryAppointmentDate !== ""
          ? values.DeliveryAppointmentDate
          : "",
      TimeZone: "",
      Country: "",
      City: "",
      State: "",
      NextTrackingDue:
        new Date().toISOString().substr(0, 10) +
        "T" +
        new Date(new Date().setTime(new Date().getTime() + 6 * 60 * 60 * 1000))
          .getHours()
          .toString()
          .padStart(2, "0") +
        ":" +
        (new Date(
          new Date().setTime(new Date().getTime() + 6 * 60 * 60 * 1000)
        ).getMinutes() > 30
          ? "30"
          : "00") +
        ":00",
      Note: "",
    });
  }, [TrackingDataGrid]);
  const [shipper, setShipper] = useState({
    ShipperId: 0,
    ShipperAddress: "",
    ShipperCity: "",
    ShipperState: "",
    ShipperZipCode: "",
    ShipperPhoneNumber: "",
    ShipperFax: "",
    ShipperContactName: "",
    ShipperEmail: "",
    ShippingNote: "",
  });
  const [shipper2, setShipper2] = useState({
    ShipperId: 0,
    ShipperAddress: "",
    ShipperCity: "",
    ShipperState: "",
    ShipperZipCode: "",
    ShipperPhoneNumber: "",
    ShipperFax: "",
    ShipperContactName: "",
    ShipperEmail: "",
    ShippingNote: "",
  });
  async function fetchSelectedAddress(id, isShipper, iShipper2) {
    try {
      const result = await GetShipperAddress(id);
      if (isShipper) {
        let currentAddress = { ...(iShipper2 ? shipper2 : shipper) };
        currentAddress = {
          ShipperId: result?.Id,
          ShipperAddress: result?.Address,
          ShipperCity: result?.City,
          ShipperState: result?.State,
          ShipperZipCode: result?.ZipCode,
          ShipperPhoneNumber: result?.PhoneNumber,
          ShipperFax: result?.Fax,
          ShipperContactName: result?.ContactName,
          ShipperEmail: result?.Email,
          ShippingNote:
            values.ShippingNote === "" || values.ShippingNote === null
              ? result?.Note
              : values.ShippingNote,
        };
        if (!iShipper2) {
          setFieldValue("ShipperCity", result?.City);
          setFieldValue("ShipperState", result?.State);
          setFieldValue("ShipperId", result?.Id);
          setFieldValue(
            "ShippingNote",
            values.ShippingNote === "" || values.ShippingNote === null
              ? result?.Note
              : values.ShippingNote
          );
          await getAssignment(
            {
              CustomerId: values.CustomerId ?? null,
              ShipperCity: result?.City,
              ShipperState: result?.State,
              ConsigneeCity: values.ConsigneeCity,
              ConsigneeState: values.ConsigneeState,
            },
            initProduct,
            true
          );
        }
        if (iShipper2) {
          setShipper2(currentAddress);
        } else {
          setShipper(currentAddress);
        }
      } else {
        setFieldValue("ConsigneeCity", result?.City);
        setFieldValue("ConsigneeState", result?.State);
        setFieldValue("ConsigneeId", result?.Id);

        setFieldValue(
          "ReceivingNote",
          values.ReceivingNote === "" || values.ReceivingNote === null
            ? result?.Note
            : values.ReceivingNote
        );
        let currentAddress = { ...consignee };
        currentAddress = {
          ConsigneeId: result?.Id,
          ConsigneeAddress: result?.Address,
          ConsigneeCity: result?.City,
          ConsigneeState: result?.State,
          ConsigneeZipCode: result?.ZipCode,
          ConsigneePhoneNumber: result?.PhoneNumber,
          ConsigneeFax: result?.Fax,
          ConsigneeContactName: result?.ContactName,
          ConsigneeEmail: result?.Email,
          ReceivingNote:
            values.ReceivingNote === "" || values.ReceivingNote === null
              ? result?.Note
              : values.ReceivingNote,
        };
        await getAssignment(
          {
            CustomerId: values.CustomerId ?? null,
            ShipperCity: values?.ShipperCity,
            ShipperState: values?.ShipperState,
            ConsigneeCity: result?.City,
            ConsigneeState: result?.State,
          },
          initProduct,
          true
        );
        setConsignee(currentAddress);
      }
    } catch (err) {}
  }
  const [StopAddressValues, setStopAddressValues] = useState([]);

  async function GetShipperAddress(id) {
    return await fetch(window.$apiurl + `/Tms/Address/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          const result = res[1];
          setStopAddressValues(res[1]);

          return result;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //******************************************************* */
  async function fetchSelectedAddressOnLoad(id, isShipper) {
    try {
      const result = await GetShipperAddress(id);
      if (isShipper) {
        let currentAddress = { ...shipper };
        currentAddress = {
          ShipperId: result?.Id,
          ShipperAddress: result?.Address,
          ShipperCity: result?.City,
          ShipperState: result?.State,
          ShipperZipCode: result?.ZipCode,
          ShipperPhoneNumber: result?.PhoneNumber,
          ShipperFax: result?.Fax,
          ShipperContactName: result?.ContactName,
          ShipperEmail: result?.Email,
          ShippingNote: result?.Note,
        };
        setShipper(currentAddress);
      } else {
        let currentAddress = { ...consignee };
        currentAddress = {
          ConsigneeId: result?.Id,
          ConsigneeAddress: result?.Address,
          ConsigneeCity: result?.City,
          ConsigneeState: result?.State,
          ConsigneeZipCode: result?.ZipCode,
          ConsigneePhoneNumber: result?.PhoneNumber,
          ConsigneeFax: result?.Fax,
          ConsigneeContactName: result?.ContactName,
          ConsigneeEmail: result?.Email,
          ReceivingNote: result?.Note,
        };
        setConsignee(currentAddress);
      }
    } catch (err) {}
  }
  async function fetchSelectedAddressOnLoadShipper2(id) {
    try {
      const result = await GetShipperAddress(id);
      let currentAddress = { ...shipper2 };
      currentAddress = {
        ShipperId: result?.Id,
        ShipperAddress: result?.Address,
        ShipperCity: result?.City,
        ShipperState: result?.State,
        ShipperZipCode: result?.ZipCode,
        ShipperPhoneNumber: result?.PhoneNumber,
        ShipperFax: result?.Fax,
        ShipperContactName: result?.ContactName,
        ShipperEmail: result?.Email,
        ShippingNote: result?.Note,
      };
      setShipper2(currentAddress);
    } catch (err) {}
  }
  useEffect(() => {
    fetchSelectedAddressOnLoadShipper2(values.EmptyReturnLocationId);
  }, [values.EmptyReturnLocationId]);
  useEffect(() => {
    fetchSelectedAddressOnLoad(values.ShipperId, true);
  }, [values.ShipperId]);
  useEffect(() => {
    fetchSelectedAddressOnLoad(values.ConsigneeId, false);
  }, [values.ConsigneeId]);

  function handleSubmitNewBids() {
    if (
      BidsInfo.VendorId === null ||
      BidsInfo.VendorId === "" ||
      BidsInfo.VendorId === undefined
    ) {
      Notification("errorClick", "please select carrier");
      return;
    }
    if (
      BidsInfo.PickupAppointmentDate === null ||
      BidsInfo.PickupAppointmentDate === "" ||
      BidsInfo.PickupAppointmentDate === undefined
    ) {
      Notification("errorClick", "please Fill Pickup Appointment Date");
      return;
    }
    if (
      BidsInfo.DeliveryDate === null ||
      BidsInfo.DeliveryDate === "" ||
      BidsInfo.DeliveryDate === undefined
    ) {
      Notification("errorClick", "please Fill Delivery Date");
      return;
    }
    //return;

    setLoadingPost(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(BidsInfo);

    var requestOptions = {
      method: "Post",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/tms/PostBidNew`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          Notification("success", "Bid has been Created successfully");
          GetLoadBidsData();

          if (BidsInfo.IsTendered) {
            BidsStatuChange2(
              2,
              true,
              BidsInfo.ShipmentId,
              BidsInfo.VendorId,
              BidsInfo.VendorName,
              BidsInfo.Amount
            );
          } else {
            setBidsInfo({
              ShipmentId: SO,
              VendorId: "",
              VendorName: "",
              SpecialReq: "",
              Amount: null,
              PickupAppointmentDate: null,
              DeliveryDate: null,
              IsTendered: false,
            });
          }

          setShowLoadBids(false);
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Notification("errorClick", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "Bad Request");
          }
        }
        setLoadingPost(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  //Tracking Section
  function handleSubmit() {
    if (
      document.getElementById("LocationCity").value === "" ||
      document.getElementsByName("LocationState")[0].value === ""
    ) {
      Notification("error", "State and City are required");
      return;
    }
    if (document.getElementsByName("StatusCode")[0].value === "") {
      Notification("error", "Trace Code is required");
      return;
    }
    if (
      TrackingObject.EtaDate === null ||
      TrackingObject.EtaDate === undefined ||
      TrackingObject.EtaDate === ""
    ) {
      Notification("error", "EtaDate and time is required");
      return;
    }
    setLoadingPost(true);
    const StatusCode = document.getElementsByName("StatusCode")[0].value;

    var nextTracking = TrackingObject.NextTrackingDue;
    var _Note = TrackingObject.Note;

    var EtaDate = moment(TrackingObject.EtaDate).format("YYYY-MM-DD");
    var EtaTime = moment(TrackingObject.EtaDate).format("HH:mm:ss");
    const LocationCity = document.getElementById("LocationCity").value;
    const LocationState = document.getElementsByName("LocationState")[0].value;
    const LocationCountry = document.getElementsByName("Countrytracking")[0]
      .value;
    const data = {
      ShipmentId: SO,
      StatusCode: StatusCode,
      TraceDate: null,
      TraceTime: null,
      EtaDate: EtaDate,
      EtaTime: EtaTime,
      LocationCity: LocationCity,
      LocationState: LocationState,
      LocationCountry: LocationCountry,
      NextTracking: nextTracking,
      Note: _Note,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var raw = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      withCredentials: true,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${window.$apiurl}/tracking`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setLoadingPost(false);
          setShowTrace(false);
          Notification(
            "success",
            "Trace Log Add Successfuly on Shipment ID " + SO
          );
          //fetchClassificationsAsync();
          GetTrackingGridData();
          setTrackingObject({
            ShipmentId: 0,
            TraceCode: "",
            EtaDate: "",
            TimeZone: "",
            Country: "",
            City: "",
            State: "",
            NextTrackingDue:
              new Date().toISOString().substr(0, 10) +
              "T" +
              new Date(
                new Date().setTime(new Date().getTime() + 6 * 60 * 60 * 1000)
              )
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              (new Date(
                new Date().setTime(new Date().getTime() + 6 * 60 * 60 * 1000)
              ).getMinutes() > 30
                ? "30"
                : "00") +
              ":00",
            Note: "",
          });
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Notification("errorClick", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "Bad Request");
          }
          setLoadingPost(false);
        }
      })
      .catch((error) => {
        setShowTrace(false);
        setLoadingPost(false);
        Notification("error", error);
      });
  }
  //End Tracking Section
  async function GetTrackingStatus() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/tracking/statuses`, requestOptions)
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          const a = res[1];
          setTrackingStatusOptions(a || []);
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function GetTrackingGridData() {
    setLoadingBidsStatus(true);
    return await fetch(window.$apiurl + `/Tms/tracking/${SO}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          const a = res[1];
          setTrackingDataGrid(a);
          setTrackingDataPoliLyne(a.Polyline || []);
        }
        setLoadingBidsStatus(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    GetTrackingGridData();
    GetTrackingStatus();
  }, []);
  const renderMarkers = (map, maps) => {
    var data = TrackingDataPoliLyne.map((item) => {
      return {
        lat: Number(item.Lat),
        lng: Number(item.Lng),
        name: item.Address,
        Type: item.TraceType,
      };
    });
    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();

    calculateAndDisplayRoute(directionsService, directionsRenderer);

    function calculateAndDisplayRoute(directionsService, directionsRenderer) {
      window.gMap = map;
      for (var i = 0; i < data.length; i++) {
        if (data[i].Type === "MANUAL") {
          new maps.Marker({
            position: data[i],
            map: map,
            title: data[i].name,
            icon: {
              url: "https://maps.google.com/mapfiles/ms/icons/orange-dot.png",
            },
          });
        } else {
          new maps.Marker({
            position: data[i],
            map: map,
            title: data[i].name,
            icon: {
              url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
            },
          });
        }
      }
      for (var j = 0, parts = [], max = 22 - 1; j < data.length; j = j + max) {
        parts.push(data.slice(j, j + max + 1));
      }

      for (var k = 0; k < parts.length; k++) {
        // Waypoints does not include first station (origin) and last station (destination)
        var waypoints = [];
        for (var l = 1; l < parts[k].length - 1; l++) {
          waypoints.push({ location: parts[k][l], stopover: false });
        }
        // Service options
        var service_options = {
          origin: parts[k][0],
          destination: data[data.length - 1],
          waypoints: waypoints,
          travelMode: "DRIVING",
        };
        // Send request
        directionsService.route(service_options, (response, status) => {
          if (String(status) !== "OK") {
            console.log("Directions request failed due to " + status);
            return;
          }
          if (!window.gRenderers) window.gRenderers = [];
          window.gRenderers.push(directionsRenderer);
          directionsRenderer.setMap(map);
          directionsRenderer.setOptions({
            suppressMarkers: true,
            preserveViewport: true,
          });
          directionsRenderer.setDirections(response);
        });
      }
    }
    directionsRenderer.setMap(map);
  };
  async function GetViewModelStops() {
    return await fetch(window.$apiurl + `/loadboard/GetViewModel`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];

        const list2 = [];
        a?.Addresses.length > 0 &&
          a.Addresses.map((item) => {
            list2.push({
              value: item.Id,
              label: `${item.Code} - ${item.CompanyName}`,
            });
            return null;
          });
        setAddressesStops(list2);
        setAddressesStopsFetch(a?.Addresses);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const [Stops, setStopsList] = useState([]);

  async function GetStops(SO) {
    return await fetch(window.$apiurl + `/loadboard/GetStops/` + SO, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        const stops = a?.Stops;
        stops.length > 0 &&
          stops.map((item, index) => {
            item.Index = index;
            return null;
          });
        setStopsList(stops || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetStopsNewIntegration(SO, isLoaded) {
    return await fetch(window.$apiurl + `/ShipmentStop/GetStops/` + SO, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        const stops = a?.Stops;
        if (isLoaded) {
          setLoaderStops(false);
        }
        stops !== undefined &&
          stops !== null &&
          stops.length > 0 &&
          stops.forEach((item, index) => {
            item.Title = `${
              item?.Type === "Pick" ? "Pickup" : "Drop"
            } - Stop | Company Name : ${item.CompanyName}`;
            item.IsCollapsed = true;
            item.ClassName = item.Id;
            item.Position = index + 1;
          });
        if (stops?.length > 0) {
          const stopsAll = stops;
          stopsAll.push.apply(
            stopsAll,
            listMultiStopsShipping?.filter((x) => x.IsNew === true)
          );
          setMultiStopsDataShipping(stopsAll);
        } else {
          setMultiStopsDataShipping([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const [loaderStops, setLoaderStops] = useState(false);
  useEffect(() => {
    if (loaderStops) {
      GetStopsNewIntegration(SO, true);
    }
  }, [loaderStops]);
  useEffect(() => {
    if (loaderSoStop) {
      GetSoData(SO, true);
    }
  }, [loaderSoStop]);

  const columnsItems = [
    {
      dataField: "Id",
      text: "Id",
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "PurchaseOrder",
      text: "Purchase Order",
      sortCaret: sortCaret,
    },
    {
      dataField: "UnitOfMeasure",
      text: "Unit Of Measure",
      sortCaret: sortCaret,
    },
    {
      dataField: "Sku",
      text: "Sku",
      sortCaret: sortCaret,
    },
    {
      dataField: "Upc",
      text: "Upc",
      sortCaret: sortCaret,
    },
    {
      dataField: "Container",
      text: "Container",
      sortCaret: sortCaret,
    },
    {
      dataField: "Description",
      text: "Description",
      sortCaret: sortCaret,
    },
    {
      dataField: "Quantity",
      text: "Quantity",
      sortCaret: sortCaret,
    },
    {
      dataField: "Weight",
      text: "Weight",
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return currencyFormat(row.Weight);
      },
    },
    {
      dataField: "Cube",
      text: "Cube",
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return currencyFormat(row.Cube);
      },
    },
    {
      dataField: "IsHazmat",
      text: "IsHazmat",
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          row.IsHazmat && (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Hazardous Material</Tooltip>
              }
            >
              <a
                href={() => false}
                className="btn btn-icon btn-light btn-hover-light btn-sm mr-2"
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/296395.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
          )
        );
      },
    },
    {
      dataField: "IsHot",
      text: "IsHot",
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          row.IsHot && (
            <OverlayTrigger
              overlay={<Tooltip id="products-edit-tooltip">Hot</Tooltip>}
            >
              <a
                href={() => false}
                className="btn btn-icon btn-light btn-hover-light btn-sm mr-2"
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/fire.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
          )
        );
      },
    },
    {
      dataField: "DamagedQuantity",
      text: "DamagedQuantity",
      sortCaret: sortCaret,
    },
    {
      dataField: "CasePack",
      text: "CasePack",
      sortCaret: sortCaret,
    },
  ];
  function calculateTotalItems(list, column) {
    try {
      let result = 0;
      if (list !== null && list !== undefined && list?.length > 0) {
        list.forEach((item) => {
          result += item[column];
        });
      }
      return currencyFormat(result);
    } catch (err) {
      console.log(err);
    }
  }
  const [dataItems, setDataItems] = useState([]);
  async function PostStop1(data) {
    return fetch(`${window.$apiurl}/loadboard/AddPickStop/` + SO, {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        await GetStops(SO);
        setStopsList(Stops);
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res;

        return a;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostStop2(data) {
    return fetch(`${window.$apiurl}/loadboard/AddDelStop/` + SO, {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        await GetStops(SO);
        setStopsList(Stops);
        const statusCode = response.status;
        const data = await response.json();

        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res;
        return a;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  const [ShowInfo, setShowInfo] = useState({
    ShipmentId: null,
    EventName: "",
    Note: "",
  });
  function BidsStatuChange2(Type, value, Id, vendorId, vendorName, Amount) {
    setLoadingBidsStatus(true);
    var myHeaders2 = new Headers();
    myHeaders2.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders2.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: Id,
      Value: value,
      Type: Type,
    });

    var requestOptions2 = {
      method: "POST",
      headers: myHeaders2,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/tms/BidStatus`, requestOptions2)
      .then((response) => response.text())
      .then(async (result) => {
        await GetLoadBidsData();
        await GetSoData(SO);
        setLoadingBidsStatus(false);
        if (Type === 2 && value === true) {
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            `bearer ${db.read().getState().Token}`
          );

          var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };

          fetch(
            `${window.$apiurl}/tms/getemail/${SO}?vendorId=${vendorId}`,
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              let dataemail = JSON.parse(result);
              setEmailAddressList(dataemail?.Emails || []);
              setSubjectData(
                `Mitco Carrier Offer: SO # ${SO} – ${dataemail?.Shipment?.FromCity}/${dataemail?.Shipment?.FromState} to ${dataemail?.Shipment?.ToCity}/${dataemail?.Shipment?.ToState}`
              );
              setBodyData(
                `<p>Hello ${vendorName},</p><p>Please see below load request and reply back confirming acceptance or rejection, thank you!</p><p>&nbsp;</p><p><strong>Shipper</strong>: ${
                  dataemail?.Shipment?.FromCity !== null
                    ? dataemail?.Shipment?.FromCity
                    : ""
                }/${
                  dataemail?.Shipment?.FromState !== null
                    ? dataemail?.Shipment?.FromState
                    : ""
                }</p><p><strong>Receiver</strong>: ${
                  dataemail?.Shipment?.ToCity !== null
                    ? dataemail?.Shipment?.ToCity
                    : ""
                }/${
                  dataemail?.Shipment?.ToState !== null
                    ? dataemail?.Shipment?.ToState
                    : ""
                }</p><p><strong>Ship Date Range</strong>: ${
                  dataemail?.Shipment?.ShipmentDate !== null
                    ? moment(dataemail?.Shipment?.ShipmentDate).format(
                        "YYYY-MM-DD"
                      )
                    : ""
                } to ${
                  dataemail?.Shipment?.ReqShipDate !== null
                    ? moment(dataemail?.Shipment?.ReqShipDate).format(
                        "YYYY-MM-DD"
                      )
                    : ""
                }</p><p><strong>MABD</strong>: ${
                  dataemail?.Shipment?.MustArriveByDate !== null
                    ? moment(dataemail?.Shipment?.MustArriveByDate).format(
                        "YYYY-MM-DD"
                      )
                    : ""
                }</p><p><strong>Commodity</strong>: ${
                  dataemail?.Shipment?.Commodities !== undefined &&
                  dataemail?.Shipment?.Commodities !== null
                    ? dataemail?.Shipment?.Commodities?.join(", ")
                    : ""
                }</p><p><strong>Pallets</strong>: ${
                  dataemail?.Shipment?.Pallets !== null
                    ? dataemail?.Shipment?.Pallets
                    : ""
                }</p><p><strong>Pieces</strong>: ${
                  dataemail?.Shipment?.Pieces !== null
                    ? dataemail?.Shipment?.Pieces
                    : ""
                }</p><p><strong>Weight</strong>: ${
                  dataemail?.Shipment?.Weight !== null
                    ? dataemail?.Shipment?.Weight
                    : ""
                }</p><p><strong>Special Requirements</strong>: [${
                  dataemail?.Shipment?.SpecialReq !== null
                    ? dataemail?.Shipment?.SpecialReq
                    : ""
                }]</p><p>&nbsp;</p><p><strong>Requested Rate</strong>: ${Amount}</p><p>&nbsp;</p><p>Thank you!</p>`
              );
              setshowModalSend(true);
            })
            .catch((error) => console.log("error", error));
        }
      })
      .catch((error) => console.log("error", error));
  }
  function BidsStatuChange(Type, value, Id, vendorId) {
    setLoadingBidsStatus(true);
    var myHeaders2 = new Headers();
    myHeaders2.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders2.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: Id,
      Value: value,
      Type: Type,
    });

    var requestOptions2 = {
      method: "POST",
      headers: myHeaders2,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/tms/BidStatus`, requestOptions2)
      .then((response) => response.text())
      .then(async (result) => {
        await GetLoadBidsData();
        await GetSoData(SO);
        setLoadingBidsStatus(false);
        if (Type === 2 && value === true) {
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            `bearer ${db.read().getState().Token}`
          );

          var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };

          fetch(
            `${window.$apiurl}/tms/getemail/${SO}?vendorId=${vendorId}`,
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              let dataemail = JSON.parse(result);
              setEmailAddressList(dataemail?.Emails || []);
              setSubjectData(
                `Mitco Carrier Offer: SO # ${SO} – ${dataemail?.Shipment?.FromCity}/${dataemail?.Shipment?.FromState} to ${dataemail?.Shipment?.ToCity}/${dataemail?.Shipment?.ToState}`
              );
              setBodyData(
                `<p>Hello ${
                  DataListBids.find((a) => a.Id === Id).Carrier
                },</p><p>Please see below load request and reply back confirming acceptance or rejection, thank you!</p><p>&nbsp;</p><p><strong>Shipper</strong>: ${
                  dataemail?.Shipment?.FromCity !== null
                    ? dataemail?.Shipment?.FromCity
                    : ""
                }/${
                  dataemail?.Shipment?.FromState !== null
                    ? dataemail?.Shipment?.FromState
                    : ""
                }</p><p><strong>Receiver</strong>: ${
                  dataemail?.Shipment?.ToCity !== null
                    ? dataemail?.Shipment?.ToCity
                    : ""
                }/${
                  dataemail?.Shipment?.ToState !== null
                    ? dataemail?.Shipment?.ToState
                    : ""
                }</p><p><strong>Ship Date Range</strong>: ${
                  dataemail?.Shipment?.ShipmentDate !== null
                    ? moment(dataemail?.Shipment?.ShipmentDate).format(
                        "YYYY-MM-DD"
                      )
                    : ""
                } to ${
                  dataemail?.Shipment?.ReqShipDate !== null
                    ? moment(dataemail?.Shipment?.ReqShipDate).format(
                        "YYYY-MM-DD"
                      )
                    : ""
                }</p><p><strong>MABD</strong>: ${
                  dataemail?.Shipment?.MustArriveByDate !== null
                    ? moment(dataemail?.Shipment?.MustArriveByDate).format(
                        "YYYY-MM-DD"
                      )
                    : ""
                }</p><p><strong>Commodity</strong>: ${
                  dataemail?.Shipment?.Commodities !== undefined &&
                  dataemail?.Shipment?.Commodities !== null
                    ? dataemail?.Shipment?.Commodities?.join(", ")
                    : ""
                }</p><p><strong>Pallets</strong>: ${
                  dataemail?.Shipment?.Pallets !== null
                    ? dataemail?.Shipment?.Pallets
                    : ""
                }</p><p><strong>Pieces</strong>: ${
                  dataemail?.Shipment?.Pieces !== null
                    ? dataemail?.Shipment?.Pieces
                    : ""
                }</p><p><strong>Weight</strong>: ${
                  dataemail?.Shipment?.Weight !== null
                    ? dataemail?.Shipment?.Weight
                    : ""
                }</p><p><strong>Special Requirements</strong>: [${
                  dataemail?.Shipment?.SpecialReq !== null
                    ? dataemail?.Shipment?.SpecialReq
                    : ""
                }]</p><p>&nbsp;</p><p><strong>Requested Rate</strong>: ${
                  DataListBids.find((a) => a.Id === Id).BidAmount
                }</p><p>&nbsp;</p><p>Thank you!</p>`
              );
              setshowModalSend(true);
            })
            .catch((error) => console.log("error", error));
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function SendBidsNotification() {
    if (ToData === null) {
      Notification("error", "Please fill email Info");
      return;
    }
    // server request for deleting product by id
    setisLoadingSend(true);
    var data = {};
    if (window.SendDispatchShipmentsList !== undefined) {
      data = {
        Ids: window.SendDispatchShipmentsList?.map(function(item) {
          return item.id;
        }),
        Emails: ToData,
        Subject: SubjectData,
        Body: BodyData,
        IncludeCc: includCCData,
        Attachments: AttachmentData,
        Bol: false,
        OutboundDispatch: false,
        ShipmentManifest: false,
      };
    } else {
      data = {
        Emails: ToData,
        Subject: SubjectData,
        Body: BodyData,
        IncludeCc: includCCData,
        Attachments: AttachmentData,
        Bol: false,
        OutboundDispatch: false,
        ShipmentManifest: false,
      };
    }

    var raw = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    };
    var url = "";
    if (window.SendDispatchShipmentsList !== undefined) {
      url = "/shipmentsmanagement/sendMultiEmail";
    } else {
      url = `/shipments/sendEmail/` + SO;
    }
    let response = await fetch(`${window.$apiurl}${url}`, requestOptions);
    if (response.status === 200) {
      Notification("success", "Email sent successfuly for Shipment ID " + SO);
      setToData(null);
      setToDataDefeault(null);
      setSubjectData(null);
      setBodyData("");
      setincludCCData(false);
      setAttachmentData([]);
    } else {
      Notification("error", "invalid data");
    }
    setBidsInfo({
      ShipmentId: SO,
      VendorId: "",
      VendorName: "",
      SpecialReq: "",
      Amount: null,
      PickupAppointmentDate: null,
      DeliveryDate: null,
      IsTendered: false,
    });
    setisLoadingSend(false);
    setshowModalSend(false);
  }
  const [BidsInfo, setBidsInfo] = useState({
    ShipmentId: SO,
    VendorId: "",
    VendorName: "",
    SpecialReq: "",
    Amount: null,
    PickupAppointmentDate: null,
    DeliveryDate: null,
    IsTendered: false,
  });
  const classes = useStyles();
  const [modalDeleteTracking, setModalTrackingDelete] = useState(false);
  const [rowItem, setRowItem] = useState(null);
  async function DeleteTracking(id) {
    return await fetch(`${window.$apiurl}/tracking/${id}`, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then(async (res) => {
        Notification("success", "Success");
        await GetTrackingGridData();
        setModalTrackingDelete(false);
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
  const columns = [
    {
      dataField: "LocationCity",
      text: "Location",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.LocationCity !== null
          ? row.LocationCity + ", " + row.LocationState
          : "";
      },
    },
    {
      dataField: "CreatedBy",
      text: "Entred By",
      sort: true,
      headerSortingClasses,
    },
    {
      dataField: "LeftOver",
      text: "LeftOver Miles",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "EventName",
      text: "Current Status",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Dates",
      text: "Dates",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.TraceDate !== null && row.TraceDate !== undefined
          ? moment(row.TraceDate).format("MM-DD-YYYY")
          : "";
      },
    },
    {
      dataField: "TraceDate",
      text: "Time",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.TraceDate !== null && row.TraceDate !== undefined
          ? moment(row.TraceDate).format("hh:mm A")
          : "";
      },
    },
    {
      dataField: "Note",
      text: "Note",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Actions",
      text: "Actions",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <button
            className="btn btn-light-danger"
            onClick={() => {
              setRowItemTracking(row);
              setModalTrackingDelete(true);
            }}
          >
            <i className="fas fa-trash"></i>
          </button>
        );
      },
    },
  ];
  const Bidscolumns = [
    {
      dataField: "Id",
      text: "Id",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Carrier",
      text: "Carrier",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "AddedBy",
      text: "Added By",
      sort: true,
      headerSortingClasses,
    },
    {
      dataField: "PickupAppointmentDate",
      text: "PU Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.PickupAppointmentDate !== null &&
          row.PickupAppointmentDate !== undefined
          ? moment(row.PickupAppointmentDate).format("MM-DD-YYYY")
          : "";
      },
    },
    {
      dataField: "DeliveryDate",
      text: "DEL Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.DeliveryDate !== null && row.DeliveryDate !== undefined
          ? moment(row.DeliveryDate).format("MM-DD-YYYY")
          : "";
      },
    },
    {
      dataField: "BidAmount",
      text: "Rate",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "SpecialReq",
      text: "Spec. Req.",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "IsAccepted",
      text: "Accepted",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row, iggndex, dscdcdsc) => {
        return (
          <CheckboxLargeWB
            className="font-size-md"
            checked={row.IsAccepted}
            disabled={row.IsClosed}
            color="info"
            onChange={() => {
              if (!row.IsClosed) {
                let IsAccepted = !row.IsAccepted;
                BidsStatuChange(1, IsAccepted, row.Id, row.VendorId);
              }
            }}
          />
        );
      },
    },
    {
      dataField: "IsTendered",
      text: "Offer",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <CheckboxLargeWB
            className="font-size-md"
            checked={row.IsTendered}
            disabled={row.IsClosed}
            color="info"
            isTms={true}
            onChange={() => {
              if (!row.IsClosed) {
                let IsTendered = !row.IsTendered;
                BidsStatuChange(2, IsTendered, row.Id, row.VendorId);
              }
            }}
          />
        );
      },
    },
    {
      dataField: "IsBooked",
      text: "Book",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row, index) => {
        return (
          <CheckboxLargeWB
            className="font-size-md"
            checked={row.IsBooked}
            disabled={row.IsClosed}
            color="info"
            isTms={true}
            onChange={() => {
              setShowLoadBidsModal(false);
              if (!row.IsClosed) {
                let IsBooked = !row.IsBooked;
                BidsStatuChange(3, IsBooked, row.Id, row.VendorId);
              }
            }}
          />
        );
      },
    },
    {
      dataField: "IsClosed",
      text: "Closed",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row, index) => {
        return (
          <CheckboxLargeWB
            className="font-size-md"
            checked={row.IsClosed}
            color="info"
            isTms={true}
            onChange={() => {
              let IsClosed = !row.IsClosed;
              BidsStatuChange(4, IsClosed, row.Id, row.VendorId);
            }}
          />
        );
      },
    },
  ];

  const [ShowTrace, setShowTrace] = useState();
  const [ShowLoadBids, setShowLoadBids] = useState();
  const [ShowAlert, setShowAlert] = useState();
  const [isNewAlert, setIsNewAlert] = useState(false); // SHIPPING
  const [ShowMapView, setShowMapView] = useState(false); // SHIPPINGsetShowLoadBids
  const [ShowStop, setShowStop] = useState(false); // SHIPPING
  const [ShowStop2, setShowStop2] = useState(false); // RECEIVING
  const [isLinearProgress, setIsLinearProgress] = useState(false);
  const [AttachmentTypes, setAttachmentTypes] = useState([]);
  const [AccessLevels, setAccessLevels] = useState([]);
  const [IsShipperSelected, setIsShipperSelected] = useState(false);
  const [Country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [countryIdFetch, setCountryValue] = useState({
    value: "US",
    label: "United States",
  });
  const [stateId, setStateId] = React.useState({
    value: "",
    label: "Select",
  });
  const [AddressObjectPost, setAddressObjectPost] = useState({
    Name: "",
    Address: "",
    City: "",
    State: "",
    PostalCode: "",
    CountryId: "US",
    Phone: "",
    Fax: "",
    Note: "",
    AddressTypeId: null,
    IsActive: true,
    Charges: [],
    ContactName: "",
    Email: "",
    CustomerId: null,
  });
  function GetAttachmentsViewModel() {
    fetch(window.$apiurl + `/api/shipmentviewmodel`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        setAttachmentTypes(res[1]?.AttachmentTypes || []);
        setAccessLevels(res[1]?.AccessLevels || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const fetchViewModelAsync = async () => {
    try {
      const result = await axios.get("api/ManageAddress/GetAllApi");
      setCountry(result.data["Countries"] || []);
    } catch (e) {}
  };
  useEffect(() => {
    fetchViewModelAsync();
    GetAttachmentsViewModel();
  }, []);
  const [itemsTms, setItemsTms] = React.useState([
    {
      Id: null,
      FlatbedTypeId: null,
      FlatbedType: null,
      FlatbedRequired: null,
      PackagingGroupId: null,
      Packaging: null,
      SpaceReq: "",
      UnitCount: 1,
      CubeType: "CBM",
      Cube: 1,
      WeightType: "LBS",
      PurchaseOrder: "",
      UnitValue: "in/lbs",
      IsPost: false,
      IsEditMode: false,
      Pieces: 1,
      UnitOfMeasureId: 4,
      WeightMode: "each",
      WeightModeValue: { value: "each", label: "each" },
      Weight: "1",
      Length: "",
      Height: "",
      Width: "",
      FreightClass: "",
      FreightClassValue: { value: "", label: "Select" },
      Nmfc: "",
      Quantity: 0,
      IsHazardousMateriel: false,
      StopId: null,
      PalletTypeId: null,
      DeliveryStopId: null,
      StopValue: { value: "", label: "Select" },
      StopValueDelivery: { value: "", label: "Select" },
      UnitOfMeasureValue: { value: 4, label: "Pallet" },
      ShipmentId: SO,
    },
  ]);
  function addClick() {
    setItemsTms((prevState) => [
      ...prevState,
      {
        UnitCount: 1,
        CubeType: "CBM",
        PurchaseOrder: "",
        Cube: 1,
        WeightType: "LBS",
        IsPost: true,
        IsEditMode: false,
        UnitValue: "in/lbs",
        Pieces: 1,
        UnitOfMeasureId: 4,
        FlatbedRequired: null,
        FlatbedTypeId: null,
        FlatbedType: null,
        TempControl: null,
        PackagingGroupId: null,
        Packaging: null,
        TempMax: null,
        TempMin: null,
        ShipmentId: statSHipmentId,
        WeightMode: "each",
        WeightModeValue: { value: "each", label: "each" },
        Weight: "1",
        Length: "",
        Height: "",
        Width: "",
        FreightClass: "",
        FreightClassValue: { value: "", label: "Select" },
        Nmfc: "",
        IsHazardousMateriel: false,
        StopId: null,
        PalletTypeId: null,
        DeliveryStopId: null,
        StopValue: { value: "", label: "Select" },
        StopValueDelivery: { value: "", label: "Select" },
        UnitOfMeasureValue: { value: 4, label: "Pallet" },
      },
    ]);
  }
  function removeClick(i) {
    let data = [...itemsTms];
    data.splice(i, 1);
    setItemsTms(data);
  }
  async function PostItemTms(data) {
    if (
      data.SpaceReq === null ||
      data.SpaceReq === undefined ||
      data.SpaceReq === ""
    ) {
      Notification("errorClick", "Space Req is required");
      return false;
    }
    if (
      data.Commodity === null ||
      data.Commodity === undefined ||
      data.Commodity === ""
    ) {
      Notification("errorClick", "Commodity is required");
      return false;
    }
    if (
      data.Weight === null ||
      data.Weight === undefined ||
      data.Weight === ""
    ) {
      Notification("errorClick", "Weight is required");
      return false;
    }
    if (
      data.HandlingAmount === null ||
      data.HandlingAmount === undefined ||
      data.HandlingAmount === ""
    ) {
      Notification("errorClick", "Handling Amount is required");
      return false;
    }
    if (
      data.PackagingGroupId === null ||
      data.PackagingGroupId === undefined ||
      data.PackagingGroupId === ""
    ) {
      Notification("errorClick", "Packaging is required");
      return false;
    }

    return fetch(window.$apiurl + `/tms/items`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", `Item has been added successfully`);
        return res;
      })
      .catch((error) => {});
  }
  const [EmailAddressList, setEmailAddressList] = useState([]);
  const [ToDataDefeault, setToDataDefeault] = useState([]);
  const [ToData, setToData] = useState(null);
  const [includCCData, setincludCCData] = useState(false);
  const [SubjectData, setSubjectData] = useState(null);
  const [BodyData, setBodyData] = useState("");
  const [AttachmentData, setAttachmentData] = useState([]);
  const [isLoadingSend, setisLoadingSend] = useState(false);

  const [LoadingPost, setLoadingPost] = useState(false);
  const [showModalSend, setshowModalSend] = useState(false);
  const handleCountryChange = async (event) => {
    setStates([]);
    if (event !== "" && event !== null) {
      const result = await axios.get(`api/companyinfo/getstates/${event}`);
      if (result.status === 200) {
        if (result.data.length > 0) {
          const states = [];
          window.$StatesAddress = result.data;
          result.data.length > 0 &&
            result.data.map((item) => {
              states.push({ value: item.Id, label: item.Name });
              return null;
            });
          setStates(states);
        } else {
          Notification("warning", "This Country doesn't have any state.");
        }
      }
    }
  };

  async function GetItemsTms(id) {
    return await fetch(window.$apiurl + `/tms/items/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        const items = a?.Items;
        items?.length > 0 &&
          items.map((item) => {
            item.UnitCount = item.Quantity;
            item.FreightClassValue = {
              value: item.Class === "" ? "" : item.Class,
              label: item.Class === "" ? "Select" : item.Class,
            };
            item.WeightModeValue = {
              value: item.WeightMode === "" ? "each" : item.WeightMode,
              label: item.WeightMode === "" ? "each" : item.WeightMode,
            };
            item.FreightClass = item.Class;
            item.StopValue = { value: item.StopId, label: item.Stop };
            item.StopValueDelivery = {
              value: item.DeliveryStopId,
              label: item.DeliveryStop,
            };
            item.UnitOfMeasureValue = {
              value: item.UnitOfMeasure,
              label: item.UnitOfMeasure,
            };
            item.UnitValue = "in/lbs";
            item.CubeType = "CBM";
            item.WeightType = item.WeightType;
            item.UnitOfMeasureId = item.UnitOfMeasureId || 4;
            item.Pieces = 1;
            item.ShipmentId = statSHipmentId;
            item.IsHazardousMateriel = item.IsHazmat;
            return null;
          });
        setItemsTms(items || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function PutItemTms(id, data) {
    return fetch(window.$apiurl + `/tms/items/${id}`, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {});
  }
  async function DeleteItemTms(id) {
    return fetch(window.$apiurl + `/tms/items/${id}`, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {});
  }
  async function ClassCalculatorFunctionFetch(
    length,
    width,
    height,
    weight,
    index
  ) {
    return await fetch(
      window.$apiurl +
        `/FreightClass/CalculateClass?length=${length}&width=${width}&height=${height}&weight=${weight}
        &isMeter=false`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        let values = [...itemsTms];
        if (a) {
          try {
            values[index].FreightClass = a?.Result;
            values[index].FreightClassValue = {
              value: a?.Result,
              label: a?.Result,
            };
          } catch (err) {}
        } else {
          values[index].FreightClass = 0;
          values[index].FreightClassValue = {
            value: 0,
            label: 0,
          };
        }
        setItemsTms(values);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function ItemsTmsComponent(values) {
    return (
      itemsTms?.length > 0 &&
      itemsTms.map((el, i) => (
        <div className="card mt-md-9 bg-light" key={i}>
          <div className="card-body">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-lg-6">
                    <span
                      class="SoCommodity label label-info label-inline mr-2 mb-3 p-4"
                      style={{ fontSize: "14px" }}
                    >
                      {el?.IsPost ? "New Item" : `Item #: ${el?.Id}`}
                    </span>
                  </div>
                  <div className="col-lg-6" style={{ textAlignLast: "right" }}>
                    <button
                      onClick={async () => {
                        if (el?.Id) {
                          await DeleteItemTms(el?.Id);
                          await GetItemsTms(SO);
                        } else {
                          removeClick(i);
                        }
                        Notification("success", "Success");
                      }}
                      type="button"
                      class="btn btn-light-danger mr-2"
                    >
                      <i
                        class="fas fa-times fa-sm"
                        style={{ paddingRight: "initial" }}
                      ></i>
                    </button>
                  </div>
                </div>

                <div className="p-2">
                  {/* First Row */}
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label>
                        PO# <span className="text-danger">*</span>
                      </label>
                      <input
                        component={Input}
                        placeholder="PO#"
                        className="form-control"
                        value={el?.PurchaseOrder}
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].PurchaseOrder = e.target.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        type="text"
                        name="PurchaseOrder"
                      />
                    </div>
                    {el?.IsPost === true ? (
                      <div className="col-lg-3">
                        <label>Packaging</label>
                        <span className="text-danger">*</span>
                        <Select
                          name="PackagingGroupId"
                          onChange={(e) => {
                            try {
                              let values = [...itemsTms];
                              values[i].PackagingGroupId = e.value;
                              values[i].IsEditMode = true;
                              setItemsTms(values);
                            } catch (err) {}
                          }}
                          options={ViewModelOptions?.PackagingGroups?.map(
                            (x) => {
                              return {
                                value: x?.Id,
                                label: x?.Name,
                              };
                            }
                          )}
                        />
                      </div>
                    ) : (
                      <div className="col-lg-3">
                        <label>Packaging</label>
                        <span className="text-danger">*</span>
                        <Select
                          name="PackagingGroupId"
                          value={ViewModelOptions?.PackagingGroups?.filter(
                            (a) => a.Id === el?.PackagingGroupId
                          ).map((x) => {
                            return {
                              value: x?.Id,
                              label: x?.Name,
                            };
                          })}
                          onChange={(e) => {
                            try {
                              let values = [...itemsTms];
                              values[i].PackagingGroupId = e.value;
                              values[i].IsEditMode = true;
                              setItemsTms(values);
                            } catch (err) {}
                          }}
                          options={ViewModelOptions?.PackagingGroups?.map(
                            (x) => {
                              return {
                                value: x?.Id,
                                label: x?.Name,
                              };
                            }
                          )}
                        />
                      </div>
                    )}
                    {el?.IsPost === true ? (
                      <div className="col-lg-3">
                        <label>Pallet Types</label>{" "}
                        <span className="text-danger">*</span>
                        <Select
                          name="PalletTypeId"
                          onChange={(e) => {
                            try {
                              let values = [...itemsTms];
                              values[i].PalletTypeId = e.value;
                              values[i].IsEditMode = true;
                              setItemsTms(values);
                            } catch (err) {}
                          }}
                          options={ViewModelOptions?.PalletTypes?.map((x) => {
                            return {
                              value: x?.Id,
                              label: x?.Name,
                            };
                          })}
                        />
                      </div>
                    ) : (
                      <div className="col-lg-3">
                        <label>Pallet Types</label>{" "}
                        <span className="text-danger">*</span>
                        <Select
                          name="PackagingGroupId"
                          value={ViewModelOptions?.PalletTypes?.filter(
                            (a) => a.Id === el?.PalletTypeId
                          ).map((x) => {
                            return {
                              value: x?.Id,
                              label: x?.Name,
                            };
                          })}
                          onChange={(e) => {
                            try {
                              let values = [...itemsTms];
                              values[i].PalletTypeId = e.value;
                              values[i].IsEditMode = true;
                              setItemsTms(values);
                            } catch (err) {}
                          }}
                          options={ViewModelOptions?.PalletTypes?.map((x) => {
                            return {
                              value: x?.Id,
                              label: x?.Name,
                            };
                          })}
                        />
                      </div>
                    )}
                    <div className="col-lg-3">
                      <label>Handling Amount</label>
                      <span className="text-danger">*</span>
                      <input
                        component={Input}
                        placeholder="Amount"
                        className="form-control"
                        type="text"
                        value={el?.HandlingAmount}
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].HandlingAmount = e.target.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        name="HandlingAmount"
                      />
                    </div>
                    <div className="col-lg-3 mt-5">
                      <label>Pieces</label>
                      <input
                        type="number"
                        className="form-control"
                        name="Quantity"
                        component={Input}
                        placeholder="Unit Count"
                        required={true}
                        value={el.Quantity}
                        //defaultValue={el?.UnitCount}
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].Quantity = e.target.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        min="0"
                      />
                    </div>
                    <div className="col-lg-3 mt-5">
                      <label>Weight Type</label>

                      <Select
                        name="WeightType"
                        value={[
                          { label: "LBS", value: "LBS" },
                          { label: "KGS", value: "KGS" },
                        ].filter((a) => a.value === el.WeightType)}
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].WeightType = e.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        options={[
                          { label: "LBS", value: "LBS" },
                          { label: "KGS", value: "KGS" },
                        ]}
                      />
                    </div>
                    <div className="col-lg-3 mt-5">
                      <label>Weight</label>
                      <span className="text-danger">*</span>
                      <input
                        type="number"
                        name="Weight"
                        id="WeightId"
                        className="form-control"
                        component={Input}
                        placeholder="Weight"
                        value={el?.Weight}
                        min="0"
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].Weight = e.target.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                            //Class Calculator
                            if (
                              el?.Length !== "" &&
                              el?.Width !== "" &&
                              el?.Height !== "" &&
                              el?.Weight !== ""
                            ) {
                              ClassCalculatorFunctionFetch(
                                el?.Length,
                                el?.Width,
                                el?.Height,
                                el?.Weight,
                                i
                              );
                            }
                          } catch (err) {}
                        }}
                      />
                    </div>

                    <div className="col-lg-3 mt-5">
                      <label>Commodity</label>
                      <span className="text-danger">*</span>
                      <input
                        component={Input}
                        placeholder="Commodity"
                        className="form-control"
                        value={el?.Commodity}
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].Commodity = e.target.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        type="text"
                        name="Commodity"
                      />
                    </div>
                    <div className="col-lg-3 mt-5">
                      <label>
                        Space Req <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Space Req"
                        className="form-control"
                        value={el?.SpaceReq}
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].SpaceReq = e.target.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        name="SpaceReq"
                      />
                    </div>
                    <div className="col-lg-3 mt-5">
                      <label>SKU</label>
                      <input
                        component={Input}
                        placeholder="SKU"
                        className="form-control"
                        value={el?.Sku}
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].Sku = e.target.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        type="text"
                        name="Sku"
                      />
                    </div>
                    <div className="col-lg-3 mt-5">
                      <label>PickUp</label>
                      <Select
                        name="PickUpStop"
                        value={[
                          {
                            Id: null,
                            CompanyName: values.ShipperCompanyName,
                            Type: "Pick",
                          },
                          ...listMultiStopsShipping,
                        ]
                          .filter((a) => a.Id === el?.StopId)
                          .map((b) => {
                            return { label: b.CompanyName, value: b.Id };
                          })}
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].StopId = e.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        options={[
                          {
                            Id: null,
                            CompanyName: values.ShipperCompanyName,
                            Type: "Pick",
                          },
                          ...listMultiStopsShipping,
                        ]
                          .filter((a) => a.Type === "Pick")
                          .map((b) => {
                            return { label: b.CompanyName, value: b.Id };
                          })}
                      />
                    </div>
                    <div className="col-lg-3 mt-5">
                      <label>Drop</label>

                      <Select
                        name="DropStop"
                        value={[
                          {
                            Id: null,
                            CompanyName: values.ConsigneeCompanyName,
                            Type: "Drop",
                          },
                          ...listMultiStopsShipping,
                        ]
                          .filter((a) => a.Id === el?.DeliveryStopId)
                          .map((b) => {
                            return { label: b.CompanyName, value: b.Id };
                          })}
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].DeliveryStopId = e.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        options={[
                          {
                            Id: null,
                            CompanyName: values.ConsigneeCompanyName,
                            Type: "Drop",
                          },
                          ...listMultiStopsShipping,
                        ]
                          .filter((a) => a.Type === "Drop")
                          .map((b) => {
                            return { label: b.CompanyName, value: b.Id };
                          })}
                      />
                    </div>

                    <div className="col-lg-3 mt-md-4 mt-7 pt-7">
                      <CheckboxLargeWB
                        className="font-size-md"
                        text="Flatbed Required"
                        name="FlatbedRequired"
                        id="FlatbedRequired"
                        checked={el?.FlatbedRequired}
                        onChange={(e) => {
                          let values = [...itemsTms];
                          values[i].FlatbedRequired = e.target.checked;
                          values[i].IsEditMode = true;
                          setItemsTms(values);
                        }}
                      />
                    </div>
                    {el?.FlatbedRequired === true && el?.IsPost ? (
                      <>
                        <div className="col-lg-3  mt-7">
                          <label>Flatbed Type</label>

                          <Select
                            name="FlatbedTypeId"
                            onChange={(e) => {
                              try {
                                let values = [...itemsTms];
                                values[i].FlatbedTypeId = e.value;
                                values[i].IsEditMode = true;
                                setItemsTms(values);
                              } catch (err) {}
                            }}
                            options={ViewModelOptions?.FlatbedTypes?.map(
                              (x) => {
                                return {
                                  value: x?.Id,
                                  label: x?.Name,
                                };
                              }
                            )}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {el?.FlatbedRequired === true && !el?.IsPost ? (
                      <>
                        <div className="col-lg-3  mt-7">
                          <label>Flatbed Type</label>

                          <Select
                            name="FlatbedTypeId"
                            value={ViewModelOptions?.FlatbedTypes?.filter(
                              (a) => a.Id === el?.FlatbedTypeId
                            ).map((x) => {
                              return {
                                value: x?.Id,
                                label: x?.Name,
                              };
                            })}
                            onChange={(e) => {
                              try {
                                let values = [...itemsTms];
                                values[i].FlatbedTypeId = e.value;
                                values[i].IsEditMode = true;
                                setItemsTms(values);
                              } catch (err) {}
                            }}
                            options={ViewModelOptions?.FlatbedTypes?.map(
                              (x) => {
                                return {
                                  value: x?.Id,
                                  label: x?.Name,
                                };
                              }
                            )}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <div className="form-group row">
                   
                  </div> */}
                  <div className="form-group row mb-0">
                    <div className="col-lg-3 mt-md-4">
                      <CheckboxLargeWB
                        className="font-size-md"
                        text="Temp Control"
                        name="TempControl"
                        id="TempControl"
                        checked={el?.TempControl}
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].TempControl = e.target.checked;
                            values[i].IsEditMode = true;

                            setItemsTms(values);
                          } catch (err) {}
                        }}
                      />
                    </div>
                    {el?.TempControl === true ? (
                      <>
                        <div className="col-lg-3">
                          <label>Temp Min</label>
                          <input
                            type="number"
                            className="form-control"
                            name="TempMin"
                            component={Input}
                            placeholder="Min"
                            required={true}
                            value={el?.TempMin}
                            onChange={(e) => {
                              try {
                                let values = [...itemsTms];
                                values[i].TempMin = e.target.value;
                                values[i].IsEditMode = true;

                                setItemsTms(values);
                              } catch (err) {}
                            }}
                          />
                        </div>
                        <div className="col-lg-3">
                          <label>Temp Max</label>
                          <input
                            type="number"
                            className="form-control"
                            name="TempMin"
                            component={Input}
                            placeholder="Max"
                            required={true}
                            value={el?.TempMax}
                            onChange={(e) => {
                              try {
                                let values = [...itemsTms];
                                values[i].TempMax = e.target.value;
                                values[i].IsEditMode = true;

                                setItemsTms(values);
                              } catch (err) {}
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))
    );
  }
  function postAddress() {
    setLoadingPost(true);
    let value = { ...AddressObjectPost };
    value.AddressTypeId = IsShipperSelected === true ? 11 : 5;
    return fetch(window.$apiurl + "/address", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },

      body: JSON.stringify(value),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (res) => {
        if (res[0] === 200) {
          Notification("success", "Address has been created successfully");
          setLoadingPost(false);
          setmodalAddresses(false);
          await GetViewModel();
          if (IsShipperSelected) {
            setFieldValue("ShipperId", res[1].Id);
          } else {
            setFieldValue("ConsigneeId", res[1].Id);
          }

          setAddressObjectPost({
            Name: "",
            Address: "",
            City: "",
            State: "",
            PostalCode: "",
            CountryId: "US",
            Phone: "",
            Fax: "",
            Note: "",
            AddressTypeId: null,
            IsActive: true,
            Charges: [],
            CustomerId: null,
            ContactName: "",
            Email: "",
          });
        } else {
          if (
            res[1].Message !== null &&
            res[1].Message !== undefined &&
            res[1].Message !== ""
          ) {
            Notification("errorClick", res[1].Message);
          } else {
            Notification("errorClick", "Invalid Data");
          }
          setLoadingPost(false);
        }
      })
      .catch((error) => {
        Notification("errorClick", "Invalid Data");
        setLoadingPost(false);
      });
  }
  const [isCollapsedShipping, setIsCollapsedShipping] = useState(false);
  const [isCollapsedTracking, setIsCollapsedTracking] = useState(false);
  const [isCollapsedReceiving2, setIsCollapsedReceiving2] = useState(false);
  const [isCollapsedBids, setIsCollapsedBids] = useState(false);
  const [isCollapsedInvoincing, setIsCollapsedInvoicing] = useState(false);
  const [isCollapsedAttachments, setIsCollapsedAttachments] = useState(false);
  const [isCollapsedCommodity, setIsCollapsedCommodity] = useState(false);
  const [isCollapsedCommodityDrag, setIsCollapsedCommodityDrag] = useState(
    false
  );
  const [isCollapsedReceiving, setIsCollapsedReceiving] = useState(false);
  async function SavePositions(dataPut) {
    return await fetch(window.$apiurl + `/ShipmentStop/MoveStops`, {
      method: "PUT",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Stops: dataPut }),
    })
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 200) {
          setLoaderStops(true);
          setShowSaveButton(false);
          Notification(
            "success",
            `Position of Stops has been updated successfully`
          );
        }
        const data = await response.json();
        if (data !== null && data !== undefined && data?.Message) {
          Notification("errorClick", data?.Message);
        }
        return Promise.all([statusCode, data]);
      })
      .then((res) => {})
      .catch((error) => {
        console.error(error);
      });
  }
  const [showModalPosition, setShowModalConfirmationPosition] = useState(false);
  const [loadingPosition, setLoadingPosition] = useState(false);
  const [rowItemTracking, setRowItemTracking] = useState(null);
  async function SavePositionsReceivingShipping(isReceiving, id) {
    return await fetch(
      window.$apiurl +
        `/ShipmentStop/MarkStopMain/${id}?isReceiving=${isReceiving}&isShipping=${!isReceiving}`,
      {
        method: "PUT",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 200) {
          setLoaderStops(true);
          setLoaderSoStop(true);
          setLoadingPosition(false);
          setShowModalConfirmationPosition(false);
          Notification(
            "success",
            `Position of Stops has been updated successfully`
          );
        }
        const data = await response.json();
        if (data !== null && data !== undefined && data?.Message) {
          Notification("errorClick", data?.Message);
          setLoadingPosition(false);
        }
        return Promise.all([statusCode, data]);
      })
      .then((res) => {})
      .catch((error) => {
        console.error(error);
        setLoadingPosition(false);
      });
  }
  function fetchNewStop(isReceiving) {
    try {
      let valuesCc = [...listMultiStopsShipping];
      valuesCc.push({
        Title: `${isReceiving ? "Drop" : "Pickup"} : New Stop`,
        IsCollapsed: false,
        ShipmentId: SO,
        IsNew: true,
        Position: 0,
        Type: `${isReceiving ? "Drop" : "Pick"}`,
        TypeId: isReceiving ? 1296 : 1295,
        ClassName: `${
          isReceiving ? "classReceiving" : "classShipping"
        }-${listMultiStopsShipping?.length + 1}`,
      });
      setMultiStopsDataShipping(valuesCc);

      //scroll
      setTimeout(function() {
        try {
          document
            .querySelector(
              `.${
                isReceiving ? "classReceiving" : "classShipping"
              }-${listMultiStopsShipping?.length + 1}`
            )
            .scrollIntoView({ behavior: "smooth" });
        } catch (err) {
          console.log(err);
        }
      }, 100);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      {/* //ZBAABIT */}
      <Modal
        size="xl"
        show={showModalSend}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Carrier Offer Email
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              setBidsInfo({
                ShipmentId: SO,
                VendorId: "",
                VendorName: "",
                SpecialReq: "",
                Amount: null,
                PickupAppointmentDate: null,
                DeliveryDate: null,
                IsTendered: false,
              });
              setshowModalSend(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <p className="mb-3">To: </p>
                {EmailAddressList && (
                  <CreatableSelect
                    isMulti
                    name="To"
                    id="To"
                    value={ToDataDefeault}
                    options={EmailAddressList.map((a) => {
                      return { value: a.Email, label: a.Email };
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                      var obj = [];
                      var objDefeault = [];
                      if (e) {
                        e.map((item) => {
                          obj.push({
                            Email: item.value,
                            Name: item.value,
                          });
                          objDefeault.push({
                            value: item.value,
                            label: item.value,
                          });
                          return null;
                        });
                      }
                      setToDataDefeault(objDefeault);
                      setToData(obj);
                    }}
                  />
                )}

                <CheckboxLarge
                  Size="12px"
                  name="includCC"
                  text="Include me on CC"
                  onChange={(event) => {
                    setincludCCData(event.target.checked);
                  }}
                />
              </div>
              <div className="col-lg-12 mt-5">
                <p className="mb-3">Subject: </p>
                <input
                  name="Subject"
                  className="form-control"
                  placeholder="Subject"
                  value={SubjectData}
                  onChange={(event) => {
                    setSubjectData(event.target.value);
                  }}
                />
              </div>
              <div className="col-lg-12 mt-5">
                <p className="mb-3">Body: </p>

                <CKEditor
                  name="Body"
                  Id="Body"
                  label="Body"
                  editor={ClassicEditor}
                  config={{
                    removePlugins: [
                      "Image",
                      "ImageCaption",
                      "ImageStyle",
                      "ImageToolbar",
                      "ImageUpload",
                      "MediaEmbed",
                    ],
                  }}
                  data={BodyData}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    setBodyData(data);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                setshowModalSend(false);
                setBidsInfo({
                  ShipmentId: SO,
                  VendorId: "",
                  VendorName: "",
                  SpecialReq: "",
                  Amount: null,
                  PickupAppointmentDate: null,
                  DeliveryDate: null,
                  IsTendered: false,
                });
              }}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={SendBidsNotification}
              disabled={isLoadingSend}
              className="btn btn-delete btn-primary"
            >
              Send Email
              {isLoadingSend && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalAddresses}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Create new Address
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              setmodalAddresses(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <form className="form form-label-right">
            <div className="form-group row">
              <div className={"col-lg-12"}>
                <label>Organisation Name</label>
                <input
                  name="Name"
                  className="form-control"
                  placeholder="Organisation Name"
                  label="Organisation Name"
                  value={AddressObjectPost.Name}
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.Name = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  required={true}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-lg-12 px-0">
                <label>
                  Address <RequiredHtml />
                </label>
                <input
                  name="Address"
                  as="textarea"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.Address = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <label>City</label>

                <input
                  name="City"
                  className="form-control"
                  placeholder="City"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.City = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  label="City"
                />
              </div>
              <div className="col-lg-4">
                <label>Choose a state</label>
                <Select
                  name="State"
                  label="State"
                  id="State"
                  options={states}
                  value={stateId}
                  onChange={(e) => {
                    setStateId(e);
                    let value = { ...AddressObjectPost };
                    if (e !== null) {
                      value.State = e?.label;
                    } else {
                      value.State = "";
                    }
                    setAddressObjectPost(value);
                  }}
                />
              </div>
              <div className="col-lg-4">
                <label>Zip Code</label>

                <input
                  name="PostalCode"
                  className="form-control"
                  placeholder="Zip Code"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.PostalCode = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  label="Zip Code"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <label>
                  Country <RequiredHtml />
                </label>
                <CreatableSelect
                  name="CountryId"
                  value={
                    countryIdFetch.value === ""
                      ? { value: "US", label: "United States" }
                      : countryIdFetch
                  }
                  options={Country.map((x) => {
                    return { value: x?.Id, label: x?.Name };
                  })}
                  onChange={(newValue) => {
                    setCountryValue(newValue);
                    handleCountryChange(newValue?.value);
                    let value = { ...AddressObjectPost };
                    if (newValue !== null) {
                      value.CountryId = newValue?.value;
                    } else {
                      value.CountryId = "";
                    }
                    setAddressObjectPost(value);
                  }}
                />
              </div>
              <div className="col-lg-4">
                <label>Phone</label>

                <input
                  name="Phone"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.Phone = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  className="form-control"
                  placeholder="Phone"
                  label="Phone"
                />
              </div>
              <div className="col-lg-4">
                <label>Fax</label>

                <input
                  name="Fax"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.Fax = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  className="form-control"
                  placeholder="Fax"
                  label="Fax"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <label>Contact Name</label>

                <input
                  name="ContactName"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.ContactName = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  className="form-control"
                  placeholder="Contact Name"
                  label="Contact Name"
                />
              </div>
              <div className="col-lg-4">
                <label>Contact Email</label>

                <input
                  name="Email"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.Email = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  className="form-control"
                  placeholder="Contact Email"
                  label="Contact Email"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <label>Customer</label>
                <Select
                  name="CustomerId"
                  options={
                    ViewModelOptions?.Customers?.length > 0
                      ? ViewModelOptions?.Customers.map((item) => {
                          return {
                            value: item.Id,
                            label: item.Name,
                          };
                        })
                      : []
                  }
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.CustomerId = e?.value;
                    setAddressObjectPost(value);
                  }}
                  isClearable
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-lg-12 px-0">
                <label>Note</label>
                <input
                  name="Note"
                  as="textarea"
                  className="form-control"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.Note = e.target.value;
                    setAddressObjectPost(value);
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              setmodalAddresses(false);
            }}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="submit"
            disabled={LoadingPost}
            onClick={() => {
              postAddress();
            }}
            className="btn btn-primary btn-elevate"
          >
            Save
            {LoadingPost && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
      {/* Modal Confirmation */}
      <Modal
        show={showModalPosition}
        size={"lg"}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            Are you sure to mark this{" "}
            {rowItem?.Type === "Pick" ? "Shipping " : "Receiving "} Detail as{" "}
            {rowItem?.Type} Stop '{rowItem?.CompanyName}' ?
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setShowModalConfirmationPosition(false)}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              disabled={loadingPosition}
              onClick={async () => {
                setLoadingPosition(true);
                await SavePositionsReceivingShipping(
                  rowItem?.Type === "Pick" ? false : true,
                  rowItem?.Id
                );
              }}
              className={`${
                loadingPosition ? "spinner spinner-white spinner-right" : ""
              } btn btn-light-primary btn-elevate`}
            >
              Set Position
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete Stop */}

      <Modal
        size="lg"
        show={ShowStop}
        onClick={async () => {
          try {
            // await GetViewModelStops();
          } catch (err) {}
        }}
        onHide={() => {
          setShowStop(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Pickup Stop
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              setShowStop(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <Formik>
            {({ setFieldValue }) => (
              <Form className="form form-label-right">
                <div className="form-group row mb-5">
                  <div className="col-lg-12">
                    <SelectPagination
                      name="AddressId"
                      label="Addresses"
                      value={stopAddressValue}
                      TextFieldProps={{
                        InputLabelProps: {
                          htmlFor: "react-select-single",
                          shrink: true,
                        },
                      }}
                      options={ViewModelOptions?.Shippers.map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                        };
                      })}
                      onChange={(event) => {
                        try {
                          if (event !== null && event.value !== "") {
                            setCompanyNameStop(StopAddressValues?.CompanyName);
                            setAddressStop(StopAddressValues?.Address);
                            setCityStop(StopAddressValues?.City);
                            setStateStop(StopAddressValues?.State);
                            setZipCodeStop(StopAddressValues?.ZipCode);
                            setPhoneNumberStop(StopAddressValues?.PhoneNumber);
                            setContactName(StopAddressValues?.ContactName);
                            setStopAddressValue(event);
                          } else {
                            setCompanyNameStop("");
                            setAddressStop("");
                            setCityStop("");
                            setStateStop("");
                            setZipCodeStop("");
                            setPhoneNumberStop("");
                            setStopAddressValue({
                              value: "",
                              label: "Select",
                            });
                          }
                        } catch (err) {}
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-6">
                    <label>Street Address</label>
                    <input
                      type="text"
                      value={addressStop}
                      onChange={(e) => {
                        setAddressStop(e.target.value);
                      }}
                      className="form-control"
                      name="StreetAddress"
                      disabled={true}
                      placeholder="Street Address"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>Contact Name</label>
                    <input
                      type="text"
                      value={contactName}
                      onChange={(e) => {
                        setContactName(e.target.value);
                      }}
                      className="form-control"
                      name="ContactName"
                      disabled={true}
                      placeholder="Contact Name"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label>Contact Phone</label>
                    <input
                      type="tel"
                      value={phoneNumberStop}
                      onChange={(e) => {
                        setPhoneNumberStop(e.target.value);
                      }}
                      className="form-control"
                      name="Phone"
                      disabled={true}
                      placeholder="Phone"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>City</label>
                    <input
                      type="text"
                      value={cityStop}
                      onChange={(e) => {
                        setCityStop(e.target.value);
                      }}
                      className="form-control"
                      name="City"
                      placeholder="City"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label>Contact State</label>
                    <input
                      type="text"
                      value={stateStop}
                      onChange={(e) => {
                        setStateStop(e.target.value);
                      }}
                      className="form-control"
                      name="state"
                      disabled={true}
                      placeholder="state"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>Fax</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="Fax"
                      value={faxStop}
                      onChange={(e) => {
                        setFaxStop(e.target.value);
                      }}
                      placeholder="Fax"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <div className="col-lg-6">
                    <label>Zip</label>
                    <input
                      type="text"
                      value={zipCodeStop}
                      onChange={(e) => {
                        setZipCodeStop(e.target.value);
                      }}
                      className="form-control"
                      name="Zip"
                      placeholder="Zip"
                      disabled={true}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label>Contact Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="Email"
                      value={EmailStop}
                      onChange={(e) => {
                        setEmailStop(e.target.value);
                      }}
                      placeholder="Contact Email"
                      disabled={true}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              setShowStop2(false);
              setStopAddressValue({
                value: "",
                label: "Select",
              });
              setCompanyNameStop("");
              setAddressStop("");
              setStateStop("");
              setEmailStop("");
              setZipCodeStop("");
              setFaxStop("");
              setPhoneNumberStop("");
              setCityStop("");
            }}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <button
            type="button"
            onClick={async () => {
              try {
                const objectPost = {
                  AddressId: stopAddressValue.value,
                  CompanyName: companyNameStop,
                  StreetAddress: addressStop,
                  ContactName: contactName,
                  Phone: phoneNumberStop,
                  Fax: faxStop,
                  City: cityStop,
                  State: stateStop,
                  ZipCode: zipCodeStop,
                  Country: "US",
                  Email: EmailStop,
                };
                const result = await PostStop1(objectPost);
                if (result !== undefined && result[0] === 400) {
                  if (result[1].Message) {
                    Notification("error", result[1].Message);
                  } else {
                    Notification("error", "error");
                  }
                }
                if (result === undefined) {
                  Notification("success", "Success");
                  await GetStops(SO);
                  setShowStop(false);
                }
                setStopAddressValue({
                  value: "",
                  label: "Select",
                });
                setCompanyNameStop("");
                setAddressStop("");
                setStateStop("");
                setEmailStop("");
                setZipCodeStop("");
                setFaxStop("");
                setPhoneNumberStop("");
                setCityStop("");
              } catch (err) {}
            }}
            className="btn btn-primary"
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={ShowStop2}
        onClick={async () => {
          try {
            // await GetViewModelStops();
          } catch (err) {}
        }}
        onHide={() => {
          setShowStop2(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Delivery Stop
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              setShowStop2(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>

        <Modal.Body>
          <Formik>
            {({ setFieldValue }) => (
              <Form className="form form-label-right">
                <div className="form-group row mb-5">
                  <div className="col-lg-12">
                    <SelectPagination
                      name="AddressId"
                      label="Addresses"
                      value={stopAddressValue}
                      TextFieldProps={{
                        InputLabelProps: {
                          htmlFor: "react-select-single",
                          shrink: true,
                        },
                      }}
                      options={ViewModelOptions?.Consignees.map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                        };
                      })}
                      onChange={(event) => {
                        try {
                          if (event !== null && event.value !== "") {
                            setCompanyNameStop(StopAddressValues?.CompanyName);
                            setAddressStop(StopAddressValues?.Address);
                            setCityStop(StopAddressValues?.City);
                            setStateStop(StopAddressValues?.State);
                            setZipCodeStop(StopAddressValues?.ZipCode);
                            setPhoneNumberStop(StopAddressValues?.PhoneNumber);
                            setContactName(StopAddressValues?.ContactName);
                            setStopAddressValue(event);
                          } else {
                            setCompanyNameStop("");
                            setAddressStop("");
                            setCityStop("");
                            setStateStop("");
                            setZipCodeStop("");
                            setPhoneNumberStop("");
                            setStopAddressValue({
                              value: "",
                              label: "Select",
                            });
                          }
                        } catch (err) {}
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-6">
                    <label>Street Address</label>
                    <input
                      type="text"
                      value={addressStop}
                      onChange={(e) => {
                        setAddressStop(e.target.value);
                      }}
                      className="form-control"
                      name="StreetAddress"
                      disabled={true}
                      placeholder="Street Address"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>Contact Name</label>
                    <input
                      type="text"
                      value={contactName}
                      onChange={(e) => {
                        setContactName(e.target.value);
                      }}
                      className="form-control"
                      name="ContactName"
                      disabled={true}
                      placeholder="Contact Name"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label>Contact Phone</label>
                    <input
                      type="tel"
                      value={phoneNumberStop}
                      onChange={(e) => {
                        setPhoneNumberStop(e.target.value);
                      }}
                      className="form-control"
                      name="Phone"
                      disabled={true}
                      placeholder="Phone"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>City</label>
                    <input
                      type="text"
                      value={cityStop}
                      onChange={(e) => {
                        setCityStop(e.target.value);
                      }}
                      className="form-control"
                      name="City"
                      placeholder="City"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label>Contact State</label>
                    <input
                      type="text"
                      value={stateStop}
                      onChange={(e) => {
                        setStateStop(e.target.value);
                      }}
                      className="form-control"
                      name="state"
                      disabled={true}
                      placeholder="state"
                    />
                  </div>
                  <div className="col-lg-6">
                    <label>Fax</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="Fax"
                      value={faxStop}
                      onChange={(e) => {
                        setFaxStop(e.target.value);
                      }}
                      placeholder="Fax"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <div className="col-lg-6">
                    <label>Zip</label>
                    <input
                      type="text"
                      value={zipCodeStop}
                      onChange={(e) => {
                        setZipCodeStop(e.target.value);
                      }}
                      className="form-control"
                      name="Zip"
                      placeholder="Zip"
                      disabled={true}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label>Contact Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="Email"
                      value={EmailStop}
                      onChange={(e) => {
                        setEmailStop(e.target.value);
                      }}
                      placeholder="Contact Email"
                      disabled={true}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              setShowStop2(false);
              setStopAddressValue({
                value: "",
                label: "Select",
              });
              setCompanyNameStop("");
              setAddressStop("");
              setStateStop("");
              setEmailStop("");
              setZipCodeStop("");
              setFaxStop("");
              setPhoneNumberStop("");
              setCityStop("");
            }}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <button
            type="button"
            onClick={async () => {
              try {
                const objectPost = {
                  AddressId: stopAddressValue.value,
                  CompanyName: companyNameStop,
                  StreetAddress: addressStop,
                  ContactName: contactName,
                  Phone: phoneNumberStop,
                  Fax: faxStop,
                  City: cityStop,
                  State: stateStop,
                  ZipCode: zipCodeStop,
                  Country: "US",
                  Email: EmailStop,
                };
                const result = await PostStop2(objectPost);
                if (result !== undefined && result[0] === 400) {
                  if (result[1].Message) {
                    Notification("error", result[1].Message);
                  } else {
                    Notification("error", "error");
                  }
                }
                if (result === undefined) {
                  Notification("success", "Success");
                  await GetStops(SO);
                  setShowStop2(false);
                }
                setStopAddressValue({
                  value: "",
                  label: "Select",
                });
                setCompanyNameStop("");
                setAddressStop("");
                setStateStop("");
                setEmailStop("");
                setZipCodeStop("");
                setFaxStop("");
                setPhoneNumberStop("");
                setCityStop("");
              } catch (err) {}
            }}
            className="btn btn-primary"
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={ShowTrace}
        onClose={setShowTrace}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Trace Log
          </Modal.Title>
          <IconButton
            className={classes.button}
            aria-label="Close"
            onClick={() => {
              setShowTrace(false);
              setTrackingObject({
                ShipmentId: 0,
                TraceCode: "",
                EtaDate: "",
                TimeZone: "",
                Country: "",
                City: "",
                State: "",
                NextTrackingDue:
                  new Date().toISOString().substr(0, 10) +
                  "T" +
                  new Date(
                    new Date().setTime(
                      new Date().getTime() + 6 * 60 * 60 * 1000
                    )
                  )
                    .getHours()
                    .toString()
                    .padStart(2, "0") +
                  ":" +
                  (new Date(
                    new Date().setTime(
                      new Date().getTime() + 6 * 60 * 60 * 1000
                    )
                  ).getMinutes() > 30
                    ? "30"
                    : "00") +
                  ":00",
                Note: "",
              });
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row">
            <div className="col-lg-6">
              <label>
                <b>Shipment ID - Trailer Number</b>
              </label>
              <input
                id="ShipmentId2"
                name="ShipmentId"
                value={SO}
                type="text"
                disabled={true}
                className="form-control"
              />
            </div>
            <div className="col-lg-6">
              <label>
                <b>Choose</b> a Trace Code
              </label>
              <Select
                name="StatusCode"
                id="StatusCode"
                defaultValue={TrackingStatusOptions.filter(
                  (a) => a.Code === "33"
                ).map((x) => {
                  return {
                    value: x.Code,
                    label: x.EventName,
                  };
                })}
                options={TrackingStatusOptions.map((x) => {
                  return {
                    value: x.Code,
                    label: x.EventName,
                  };
                })}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-6 D-margin">
              <label>
                <b>Choose</b> a ETA Date and Time
              </label>

              <DateTime30GL
                defaultvalue={TrackingObject.EtaDate}
                onChange={(newValue) => {
                  var trackingObject = { ...TrackingObject };
                  trackingObject.EtaDate = newValue;
                  setTrackingObject(trackingObject);
                }}
              />
            </div>
            <div className="col-lg-3">
              <label>
                <b>Choose</b> a Time Zone
              </label>
              <Select
                name="TimeZone"
                id="TimeZone"
                defaultValue={{
                  value: "PST",
                  label: "PST (GMT -8)",
                }}
                inputId="react-select-single"
                placeholder="TimeZone"
                options={[
                  {
                    value: "CST",
                    label: "CST (GMT -6)",
                  },
                  {
                    value: "MST",
                    label: "MST (GMT -7)",
                  },
                  {
                    value: "PST",
                    label: "PST (GMT -8)",
                  },
                  {
                    value: "AST",
                    label: "AST (GMT -9)",
                  },
                  {
                    value: "HST",
                    label: "HST (GMT -10)",
                  },
                ]}
              />
            </div>
            <div className="col-lg-3">
              <label>
                <b>Choose</b> a Country
              </label>
              <CreatableSelect
                name="Countrytracking"
                id="Countrytracking"
                value={
                  countryIdFetch.value === ""
                    ? { value: "US", label: "United States" }
                    : countryIdFetch
                }
                options={Country.map((x) => {
                  return { value: x?.Id, label: x?.Name };
                })}
                onChange={(newValue) => {
                  setCountryValue(newValue);
                  handleCountryChange(newValue?.value);
                  let value = { ...AddressObjectPost };
                  if (newValue !== null) {
                    value.CountryId = newValue?.value;
                  } else {
                    value.CountryId = "";
                  }
                  setAddressObjectPost(value);
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <label>
                <b>Location City</b>
              </label>
              <input id="LocationCity" type="text" className="form-control" />
            </div>
            <div className="col-lg-3">
              <label>
                <b>Choose</b> a state
              </label>
              <Select
                name="LocationState"
                id="LocationState"
                options={states.map((x) => {
                  return {
                    value: x.label,
                    label: x.label,
                  };
                })}
              />
            </div>
            <div className="col-lg-6 D-margin">
              <label>
                <b>Choose</b> a Next Tracking Due Date and Time
              </label>

              <DateTime30GL
                defaultvalue={TrackingObject.NextTrackingDue}
                onChange={(newValue) => {
                  var trackingObject = { ...TrackingObject };
                  trackingObject.NextTrackingDue = newValue;
                  setTrackingObject(trackingObject);
                }}
              />
            </div>
            <div className="col-lg-12 mt-5">
              <label>Note</label>
              <textarea
                className="form-control"
                maxlength="28"
                rows="3"
                onChange={(e) => {
                  var trackingObject = { ...TrackingObject };
                  trackingObject.Note = e.target.value;
                  setTrackingObject(trackingObject);
                }}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            disabled={LoadingPost}
            onClick={() => {
              handleSubmit();
            }}
            className="btn btn-primary btn-elevate"
          >
            Save
            {LoadingPost && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={ShowLoadBids}
        onClose={setShowLoadBids}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add New Bid
          </Modal.Title>
          <IconButton
            className={classes.button}
            aria-label="Close"
            onClick={() => {
              setBidsInfo({
                ShipmentId: SO,
                VendorId: "",
                VendorName: "",
                SpecialReq: "",
                Amount: null,
                PickupAppointmentDate: null,
                DeliveryDate: null,
                IsTendered: false,
              });
              setShowLoadBids(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row">
            <div className="col-md-12">
              <label for="exampleTextarea">Carrier</label>

              <Select
                isClearable
                name="VendorId"
                id="VendorId"
                label="Carrier Name"
                inputId="react-select-single"
                options={ViewModelOptions?.Vendors?.map((a) => {
                  return { value: a.Id, label: a.Code + "-" + a.Name };
                })}
                onChange={(e) => {
                  if (e !== null) {
                    let value = { ...BidsInfo };
                    value.VendorId = e?.value;
                    value.VendorName = e?.label;
                    setBidsInfo(value);
                  }
                }}
              />
            </div>

            <div className="col-md-6 mt-5">
              <label for="exampleTextarea">Special Req</label>
              <input
                type="text"
                className="form-control"
                name="SpecialReq"
                id="SpecialReq"
                onChange={(e) => {
                  let value = { ...BidsInfo };
                  value.SpecialReq = e?.target.value;
                  setBidsInfo(value);
                }}
              />
            </div>
            <div className="col-md-6 mt-5">
              <label for="exampleTextarea">Rate</label>
              <input
                type="number"
                className="form-control"
                name="Amount"
                id="Amount"
                onChange={(e) => {
                  let value = { ...BidsInfo };
                  value.Amount = e?.target.value;
                  setBidsInfo(value);
                }}
              />
            </div>
            <div className="col-md-6 mt-5">
              <label for="exampleTextarea">PU Date</label>

              <input
                className="form-control"
                id="PickupAppointmentDate"
                name="PickupAppointmentDate"
                type="date"
                onChange={(e) => {
                  let value = { ...BidsInfo };
                  value.PickupAppointmentDate = e?.target.value;
                  setBidsInfo(value);
                }}
              />
            </div>
            <div className="col-md-6 mt-5">
              <label for="exampleTextarea">DEL Date</label>

              <input
                className="form-control"
                id="DeliveryDate"
                name="DeliveryDate"
                type="date"
                onChange={(e) => {
                  let value = { ...BidsInfo };
                  value.DeliveryDate = e?.target.value;
                  setBidsInfo(value);
                }}
              />
            </div>
            <div className="col-md-6 mt-5">
              <CheckboxLargeWB
                className="font-size-md"
                text="Offer to Carrier"
                color="success"
                checked={BidsInfo.IsTendered}
                onChange={(e) => {
                  let value = { ...BidsInfo };
                  value.IsTendered = e?.target.checked;
                  setBidsInfo(value);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            disabled={LoadingPost}
            onClick={() => {
              handleSubmitNewBids();
            }}
            className="btn btn-primary btn-elevate"
          >
            Save
            {LoadingPost && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="md"
        show={ShowAlert}
        onHide={setShowAlert}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">Add Alert</Modal.Title>
          <IconButton
            className={classes.button}
            aria-label="Close"
            onClick={() => {
              setShowInfo({ ShipmentId: null, EventName: "", Note: "" });
              setShowAlert(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <Select
              isClearable
              name="LoadBoard"
              id="Alert"
              label="Alert Name"
              defaultValue={{
                value: ShowInfo.Alerts,
                label: ShowInfo.Alerts,
              }}
              inputId="react-select-single"
              options={AlertListData.map((a) => {
                return { value: a.Alerts, label: a.Alerts };
              })}
              onChange={(e) => {
                if (e !== null) {
                  let value = { ...ShowInfo };
                  value.EventName = e.value;
                  setShowInfo(value);
                }
              }}
            />
          </div>
          <div className="form-group mb-1">
            <label for="exampleTextarea">Add Note</label>
            <textarea
              className="form-control"
              id="exampleTextarea"
              rows="3"
              value={ShowInfo.Notes}
              onChange={(e) => {
                let value = { ...ShowInfo };
                value.Note = e.target.value;
                setShowInfo(value);
              }}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            onClick={() => setShowAlert(false)}
            className="btn btn-outline btn-elevate"
          >
            Cancel
          </button>
          {!isNewAlert ? (
            <button
              type="submit"
              onClick={() => setShowAlert(false)}
              className="btn btn-danger btn-elevate"
            >
              Clear
            </button>
          ) : (
            <button
              type="submit"
              disabled={LoadingPost}
              onClick={() => {
                setLoadingPost(true);
                var myHeaders = new Headers();
                myHeaders.append(
                  "Authorization",
                  `bearer ${db.read().getState().Token}`
                );
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("X-FP-API-KEY", "iphone");
                var raw = JSON.stringify(ShowInfo);
                var requestOptions = {
                  method: "POST",
                  withCredentials: true,
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow",
                };
                fetch(`${window.$apiurl}/Tracking/Alert`, requestOptions)
                  .then(async (response) => {
                    const statusCode = await response.status;
                    const contentType = response.headers.get("content-type");
                    if (
                      contentType &&
                      contentType.indexOf("application/json") !== -1
                    ) {
                      return response.json().then((data) => [statusCode, data]);
                    } else {
                      return response
                        .text()
                        .then((text) => [statusCode, { Message: "" }]);
                    }
                  })
                  .then((result) => {
                    if (result[0] === 200) {
                      setLoadingPost(false);
                      setShowAlert(false);
                      Notification(
                        "success",
                        "Alert Add Successfuly on Shipment ID " + SO
                      );
                      GetTrackingGridData();
                    } else {
                      if (
                        result[1].ModelState !== null &&
                        result[1].ModelState !== undefined &&
                        result[1].ModelState !== ""
                      ) {
                        let modelState = result[1].ModelState;
                        if (modelState)
                          Object.keys(modelState).forEach(function(k) {
                            modelState[k].forEach((element) => {
                              Notification("errorClick", element);
                            });
                          });
                      } else if (
                        result[1].Message !== null &&
                        result[1].Message !== undefined &&
                        result[1].Message !== ""
                      ) {
                        Notification("errorClick", result[1].Message);
                      } else {
                        Notification("errorClick", "Bad Request");
                      }
                      setLoadingPost(false);
                    }
                  })
                  .catch((error) => {
                    Notification("error", error);
                  });
                setShowAlert(false);
              }}
              className="btn btn-primary btn-elevate"
            >
              Save
              {LoadingPost && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        size="xl"
        show={ShowMapView}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">Map View</Modal.Title>
          <IconButton
            className={classes.button}
            aria-label="Close"
            onClick={() => {
              setShowMapView(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-8 ">
              <div
                className={`card card-custom gutter-b`}
                style={{ height: "500px" }}
              >
                {/* Header */}
                <div className="card-header align-items-center border-bottom">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="font-weight-bolder text-dark">
                      TRACKING MAP
                    </span>
                  </h3>
                </div>
                {/* Body */}
                {TrackingDataPoliLyne ? (
                  TrackingDataPoliLyne.length !== 0 ? (
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyA1A9EPSRfIpJkN2v8CweA_W2_BUGCIdU8",
                      }}
                      defaultCenter={{
                        lat: 37.923984,
                        lng: -97.539071,
                      }}
                      defaultZoom={4}
                      onGoogleApiLoaded={({ map, maps }) =>
                        renderMarkers(map, maps)
                      }
                    ></GoogleMapReact>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col-lg-4 ">
              <div
                className={`card card-custom gutter-b tracking-list `}
                style={{ height: "500px" }}
              >
                {/* Header */}
                <div className="card-header align-items-center border-bottom">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="font-weight-bolder text-dark">
                      TRACKING
                    </span>
                  </h3>
                </div>
                {/* Body */}
                <div className="card-body py-5 " style={{ overflow: "auto" }}>
                  <div className="timeline timeline-5 my-5">
                    <div className="timeline-item align-items-start">
                      <div className="timeline-badge">
                        <i className="fa fa-genderless text-warning icon-xxl"></i>
                      </div>

                      <div className="timeline-content d-block">
                        <span className="mr-4 font-weight-bolder text-dark">
                          {values?.ShipperCity}, {values?.ShipperState}
                        </span>
                        <div className="timeline-content text-muted my-3 mx-0">
                          Start Tracking:{" "}
                          {values?.ShipperArrivalDate !== null
                            ? moment(values?.ShipperArrivalDate).format(
                                "MM/DD/YYYY, hh:mm A"
                              )
                            : ""}
                        </div>
                      </div>
                    </div>
                    {TrackingDataGrid?.Tracking
                      ? TrackingDataGrid?.Tracking.filter(
                          (a) => a.TraceType !== "Alert"
                        ).map((item, index) => (
                          <div
                            key={index}
                            className="timeline-item align-items-start"
                          >
                            <div className="timeline-badge">
                              <i
                                className={
                                  item.TraceType === "MANUAL"
                                    ? "fa fa-genderless text-warning icon-xxl"
                                    : "fa fa-genderless text-success icon-xxl"
                                }
                              ></i>
                            </div>

                            <div className="timeline-content d-block">
                              <span className="mr-4 font-weight-bolder text-dark">
                                {item.LocationCity}, {item.LocationState}
                              </span>
                              <div className="timeline-content text-muted my-3 mx-0">
                                {item.EventName}:{" "}
                                {item.TraceDate !== null
                                  ? moment(item.TraceDate).format(
                                      "MM-DD-YYYY hh:mm A"
                                    )
                                  : ""}
                              </div>
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {TrackingDataPoliLyne?.length > 0 ? (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA1A9EPSRfIpJkN2v8CweA_W2_BUGCIdU8",
              }}
              defaultCenter={{
                lat: 37.923984,
                lng: -97.539071,
              }}
              defaultZoom={4}
              onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            ></GoogleMapReact>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            onClick={() => setShowMapView(false)}
            className="btn btn-outline btn-elevate"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="xl"
        show={ShowLoadBidsModal}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">Load Bids</Modal.Title>
          <IconButton
            className={classes.button}
            aria-label="Close"
            onClick={() => {
              setShowLoadBidsModal(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="row col-md-12">
            <div class="d-flex flex-wrap">
              <p className=" mt-3 pr-3">
                Max Buy <span className="text-danger">*</span>:
              </p>

              <span className="text-muted font-weight-bold">
                <input
                  type="number"
                  className="form-control"
                  name="MaxBuy"
                  id="MaxBuy"
                  value={values?.MaxBuy}
                  onChange={(e) => {
                    setFieldValue("MaxBuy", e.target.value);
                  }}
                />
              </span>
            </div>
          </div>
          <div
            className={`col-lg-12 bg-light-${
              values?.IsCanceled
                ? "danger"
                : values.FreightChargeId !== 98
                ? "warning"
                : "info"
            } ${
              values?.IsCanceled
                ? "text-danger"
                : values.FreightChargeId !== 98
                ? "text-warning"
                : "text-info"
            } m-0 mb-2 p-0`}
          >
            <small style={{ fontSize: "120%" }} className="pr-3">
              Shipment Status:{" "}
              <b>
                {" "}
                {values?.StatusName === "Dispatched"
                  ? "Out for Pickup"
                  : values?.StatusName}{" "}
                {values?.IsCanceled && "(Canceled)"}
              </b>
            </small>
          </div>
          {LoadingBidsStatus === true ? (
            <div
              className="col-lg-12 col-xxl-12 row mt-2"
              style={{ left: "44%" }}
            >
              <Spinners name="folding-cube" color="blue" />
            </div>
          ) : (
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                pagination={paginationFactory({
                  showTotal: true,
                  sizePerPageList: [
                    { text: "5", value: 5 },
                    { text: "10", value: 10 },
                    { text: "25", value: 25 },
                  ],
                })}
                defaultSorted={[
                  { dataField: "PickupAppointmentDate", order: "desc" },
                ]}
                bordered={false}
                rowClasses={rowClassesBids}
                keyField="Id"
                data={DataListBids || []}
                columns={Bidscolumns}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            onClick={() => setShowLoadBidsModal(false)}
            className="btn btn-outline btn-elevate"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      {values?.StatusName !== "New" && (
        <Card>
          <CardHeader title="Tracking Details">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-success mr-4"
                onClick={() => {
                  setCountryValue({
                    value: "US",
                    label: "United States",
                  });

                  handleCountryChange("US");
                  setShowTrace(true);
                }}
              >
                <i className="fas fa-plus mr-3" />
                Add Trace Log
              </button>
              <button
                type="button"
                className="btn btn-outline-warning mr-4"
                onClick={() => {
                  setIsNewAlert(true);
                  setShowInfo({
                    ShipmentId: SO,
                    EventName: "",
                    Note: "",
                  });
                  setShowAlert(true);
                }}
              >
                <i className="fas fa-exclamation-triangle mr-3" />
                Add Alert
              </button>
              <button
                type="button"
                className="btn btn-outline-primary  mr-4"
                onClick={() => {
                  setShowMapView(true);
                }}
              >
                <i className="fas fa-map mr-3" />
                Map View
              </button>
              <button
                type="button"
                className="btn btn-outline-success"
                id="refreshTrackingTMS"
                onClick={() => {
                  GetTrackingGridData();
                }}
              >
                Refresh
              </button>
              <button
                className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
                onClick={() => {
                  try {
                    setIsCollapsedTracking(isCollapsedTracking ? false : true);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                {isCollapsedTracking ? (
                  <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
                ) : (
                  <i
                    class="fas fa-chevron-down"
                    style={{ fontSize: "18px" }}
                  ></i>
                )}
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody className={isCollapsedTracking ? "d-none" : ""}>
            <div
              className={`col-lg-12 bg-light-${
                values?.IsCanceled
                  ? "danger"
                  : values.FreightChargeId !== 98
                  ? "warning"
                  : "info"
              } ${
                values?.IsCanceled
                  ? "text-danger"
                  : values.FreightChargeId !== 98
                  ? "text-warning"
                  : "text-info"
              } m-0 mb-2 p-0`}
            >
              <small style={{ fontSize: "120%" }} className="pr-3">
                Shipment Status:{" "}
                <b>
                  {" "}
                  {values?.StatusName === "Dispatched"
                    ? "Out for Pickup"
                    : values?.StatusName}{" "}
                  {values?.IsCanceled && "(Canceled)"}
                </b>
              </small>
              <small style={{ fontSize: "120%" }} className="pr-3">
                Latest Event:{" "}
                <b>
                  {values?.IsCanceled
                    ? "Cancelled"
                    : TrackingDataGrid?.CurrentEvent}
                </b>
              </small>
              <small style={{ fontSize: "120%" }} className="pr-3">
                Latest Location:{" "}
                <b>
                  {TrackingDataGrid?.CurrentCity !== undefined
                    ? TrackingDataGrid?.CurrentCity +
                      (TrackingDataGrid?.CurrentState !== null &&
                      TrackingDataGrid?.CurrentState !== undefined
                        ? ", "
                        : "") +
                      TrackingDataGrid?.CurrentState
                    : ""}
                </b>
              </small>
              <small style={{ fontSize: "120%" }} className="pr-3">
                Latest Trace date:{" "}
                <b>
                  {TrackingDataGrid?.CurrentDate !== null &&
                  TrackingDataGrid?.CurrentDate !== undefined
                    ? moment(TrackingDataGrid?.CurrentDate).format(
                        "MM-DD-YYYY hh:mm A"
                      )
                    : ""}
                </b>
              </small>
            </div>
            {LoadingBidsStatus === true ? (
              <div
                className="col-lg-12 col-xxl-12 row mt-2"
                style={{ left: "44%" }}
              >
                <Spinners name="folding-cube" color="blue" />
              </div>
            ) : (
              <>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom table-vertical-center"
                  bootstrap4
                  pagination={paginationFactory({
                    showTotal: true,
                  })}
                  bordered={false}
                  rowClasses={rowClasses}
                  keyField="Id"
                  data={TrackingDataGrid?.Tracking || []}
                  columns={columns}
                />
                <Modal
                  show={modalDeleteTracking}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      Tracking Delete #: {rowItemTracking?.Id}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <span>
                      Are you sure to permanently delete selected Tracking?
                    </span>
                  </Modal.Body>
                  <Modal.Footer>
                    <div>
                      <button
                        type="button"
                        onClick={() => setModalTrackingDelete(false)}
                        className="btn btn-light btn-elevate"
                      >
                        Cancel
                      </button>
                      <> </>
                      <button
                        type="button"
                        onClick={async () =>
                          await DeleteTracking(rowItemTracking?.Id)
                        }
                        className="btn btn-danger btn-elevate"
                      >
                        Delete
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </>
            )}
          </CardBody>
        </Card>
      )}
      {values?.StatusName === "New" && (
        <Card>
          <CardHeader title="Load Bids">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-success mr-4"
                onClick={() => {
                  setShowLoadBids(true);
                }}
              >
                <i className="fas fa-plus mr-3" />
                New
              </button>
              <button
                type="button"
                className="btn btn-primary mr-4"
                onClick={() => {
                  GetLoadBidsData();
                }}
              >
                Refresh
              </button>
              <button
                className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
                onClick={() => {
                  try {
                    setIsCollapsedBids(isCollapsedBids ? false : true);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                {isCollapsedBids ? (
                  <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
                ) : (
                  <i
                    class="fas fa-chevron-down"
                    style={{ fontSize: "18px" }}
                  ></i>
                )}
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody className={isCollapsedBids ? "d-none" : ""}>
            <div className="row col-md-12">
              <div class="d-flex flex-wrap">
                <p className=" mt-3 pr-3">
                  Max Buy <span className="text-danger">*</span>:
                </p>
                <span className="text-muted font-weight-bold">
                  <input
                    type="number"
                    className="form-control"
                    name="MaxBuy"
                    id="MaxBuy"
                    value={values?.MaxBuy}
                    onChange={(e) => {
                      setFieldValue("MaxBuy", e.target.value);
                    }}
                  />
                </span>
              </div>
            </div>
            <div
              className={`col-lg-12 bg-light-${
                values?.IsCanceled
                  ? "danger"
                  : values.FreightChargeId !== 98
                  ? "warning"
                  : "info"
              } ${
                values?.IsCanceled
                  ? "text-danger"
                  : values.FreightChargeId !== 98
                  ? "text-warning"
                  : "text-info"
              } m-0 mb-2 p-0`}
            >
              <small style={{ fontSize: "120%" }} className="pr-3">
                Shipment Status:{" "}
                <b>
                  {" "}
                  {values?.StatusName === "Dispatched"
                    ? "Out for Pickup"
                    : values?.StatusName}{" "}
                  {values?.IsCanceled && "(Canceled)"}
                </b>
              </small>
            </div>
            {LoadingBidsStatus === true ? (
              <div
                className="col-lg-12 col-xxl-12 row mt-2"
                style={{ left: "44%" }}
              >
                <Spinners name="folding-cube" color="blue" />
              </div>
            ) : (
              <>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom table-vertical-center"
                  bootstrap4
                  pagination={paginationFactory({
                    showTotal: true,
                    sizePerPageList: [
                      { text: "5", value: 5 },
                      { text: "10", value: 10 },
                      { text: "25", value: 25 },
                    ],
                  })}
                  defaultSorted={[
                    { dataField: "PickupAppointmentDate", order: "desc" },
                  ]}
                  bordered={false}
                  rowClasses={rowClassesBids}
                  keyField="Id"
                  data={DataListBids || []}
                  columns={Bidscolumns}
                />
              </>
            )}
          </CardBody>
        </Card>
      )}
      <div
        className="label label-lg label-primary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
        style={{ fontSize: "15px" }}
      >
        Shipping - Receiving
      </div>
      <Card>
        <CardHeader
          title={`${
            ViewModelOptions?.Shippers.length > 0 &&
            ViewModelOptions?.Shippers.filter(
              (a) => a.Id === values?.ShipperId
            )[0]?.Name
              ? `Shipping Details | Company Name :  ${
                  ViewModelOptions?.Shippers.filter(
                    (a) => a.Id === values?.ShipperId
                  )[0]?.Name
                }`
              : `Shipping Details | Select a company`
          }`}
        >
          {values.ModeName !== "Drayage" ? (
            <CardHeaderToolbar>
              <button
                className="btn btn-light-primary py-3 pl-5 pr-4"
                onClick={() => {
                  try {
                    const currentItem =
                      listMultiStopsShipping.length > 0 &&
                      listMultiStopsShipping[0];
                    if (
                      currentItem &&
                      currentItem.Type === "Pick" &&
                      !currentItem?.IsNew
                    ) {
                      setRowItem(currentItem);
                      setShowModalConfirmationPosition(true);
                    } else {
                      Notification(
                        "errorClick",
                        "Stop should be a pickup type to set position"
                      );
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                <i
                  class="fas fa-arrow-circle-down"
                  style={{ fontSize: "18px" }}
                ></i>
              </button>
              <button
                type="button"
                className="btn btn-success mr-4 ml-4"
                onClick={async () => {
                  try {
                    fetchNewStop(false);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                <i className="fas fa-plus mr-3" />
                Add Stop
              </button>
              {showSaveArrows && (
                <button
                  type="button"
                  className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
                  onClick={async () => {
                    const listPut = [];
                    listMultiStopsShipping.length > 0 &&
                      listMultiStopsShipping.forEach((item, index) => {
                        listPut.push({
                          StopId: item.Id,
                          Position: index + 1,
                        });
                      });
                    await SavePositions(listPut);
                  }}
                >
                  <i className="fas fa-save mr-3" />
                  Save Positions
                </button>
              )}
              <button
                className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
                onClick={() => {
                  try {
                    setIsCollapsedShipping(isCollapsedShipping ? false : true);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                {isCollapsedShipping ? (
                  <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
                ) : (
                  <i
                    class="fas fa-chevron-down"
                    style={{ fontSize: "18px" }}
                  ></i>
                )}
              </button>
            </CardHeaderToolbar>
          ) : (
            <CardHeaderToolbar>
              <button
                className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
                onClick={() => {
                  try {
                    setIsCollapsedShipping(isCollapsedShipping ? false : true);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                {isCollapsedShipping ? (
                  <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
                ) : (
                  <i
                    class="fas fa-chevron-down"
                    style={{ fontSize: "18px" }}
                  ></i>
                )}
              </button>
            </CardHeaderToolbar>
          )}
        </CardHeader>
        <CardBody className={isCollapsedShipping ? "d-none" : ""}>
          <div className="row mb-5">
            <Col xl="12" lg="12" md="12" className="mb-5">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Company Name <span className="text-danger">*</span> :
                  </a>
                  <span className="font-weight-bold">
                    <CreatableSelect
                      className="mt-4"
                      isClearable
                      name="ShipperId"
                      id="ShipperId"
                      label="Company"
                      value={ViewModelOptions?.Shippers.filter(
                        (a) => a.Id === values?.ShipperId
                      ).map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                          ValueId: a.ValueId,
                        };
                      })}
                      options={ViewModelOptions?.Shippers.map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                          ValueId: a.ValueId,
                        };
                      })}
                      onChange={async (newValue) => {
                        if (newValue !== null) {
                          if (newValue?.__isNew__) {
                            setmodalAddresses(true);
                            setCountryValue({
                              value: "US",
                              label: "United States",
                            });

                            handleCountryChange("US");
                            var value = {
                              ...AddressObjectPost,
                            };
                            value.Name = newValue.label;
                            setAddressObjectPost(value);
                            setIsShipperSelected(true);
                          } else {
                            setFieldValue("ShipperId", newValue?.value);
                            setFieldValue(
                              "ShipperCompanyName",
                              newValue?.label
                            );
                          }
                          if (newValue?.ValueId !== null) {
                            setFieldValue("ShipmentTypeId", 1358);
                          } else {
                            setFieldValue("ShipmentTypeId", 1357);
                          }
                        } else {
                          setFieldValue("ShipperId", null);
                          setFieldValue("ShipperCompanyName", "");

                          let values = { ...shipper };
                          values = {
                            ShipperId: 0,
                            ShipperAddress: "",
                            ShipperCity: "",
                            ShipperState: "",
                            ShipperZipCode: "",
                            ShipperPhoneNumber: "",
                            ShipperFax: "",
                            ShipperContactName: "",
                            ShipperEmail: "",
                          };
                          setShipper(values);
                        }
                        if (!values?.IsEditedReceiving2) {
                          setFieldValue(
                            "EmptyReturnLocationId",
                            newValue?.value ?? null
                          );
                        }
                        if (!newValue?.__isNew__) {
                          await fetchSelectedAddress(
                            newValue !== null ? newValue.value : null,
                            true,
                            false
                          );
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <div className="col-xl-4 col-lg-4 col-md-4 border-right">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Street Address :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ShipperAddress"
                      disabled={true}
                      id="ShipperAddress"
                      value={shipper?.ShipperAddress}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    City :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ShipperCity"
                      disabled={true}
                      id="ShipperCity"
                      value={shipper?.ShipperCity}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    State :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ShipperState"
                      disabled={true}
                      id="ShipperState"
                      value={shipper?.ShipperState}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Zip :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ShipperZipCode"
                      id="ShipperZipCode"
                      disabled={true}
                      value={shipper?.ShipperZipCode}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 border-right">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Name :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ShipperContactName"
                      disabled={true}
                      id="ShipperContactName"
                      label="Contact"
                      value={shipper?.ShipperContactName}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Phone :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ShipperPhoneNumber"
                      id="ShipperPhoneNumber"
                      disabled={true}
                      value={shipper?.ShipperPhoneNumber}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Fax :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ShipperFax"
                      id="ShipperFax"
                      disabled={true}
                      value={shipper?.ShipperFax}
                      label="Fax"
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Email :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="email"
                      className="form-control mt-4"
                      name="ShipperEmail"
                      disabled={true}
                      id="ShipperEmail"
                      value={shipper?.ShipperEmail}
                      label="Email"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 border-right">
              {values.ModeName === "Drayage" ? (
                <div className=" flex-wrap align-items-center pb-3">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary font-size-lg mr-3"
                    >
                      LFD:
                    </a>
                    <span className="text-muted font-weight-bold">
                      <TextField
                        id="LastFreeDate"
                        type="date"
                        value={String(
                          moment(values.LastFreeDate).format("YYYY-MM-DD")
                        )}
                        onChange={(e) => {
                          setFieldValue("LastFreeDate", e.target.value);
                        }}
                        className="form-control mt-4"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div className=" flex-wrap align-items-center pb-3">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary font-size-lg mr-3"
                    >
                      Req. Ship Date:<span className="text-danger">*</span>
                    </a>
                    <span className="text-muted font-weight-bold">
                      <TextField
                        id="ReqShipDate"
                        type="date"
                        value={String(
                          moment(values.ReqShipDate).format("YYYY-MM-DD")
                        )}
                        onChange={(e) => {
                          setFieldValue("ReqShipDate", e.target.value);
                          if (
                            values?.ShipmentDate === "" ||
                            values?.ShipmentDate === undefined ||
                            values?.ShipmentDate === null
                          ) {
                            setFieldValue("ShipmentDate", e.target.value);
                          }
                        }}
                        className="form-control mt-4"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </span>
                  </div>
                </div>
              )}
              {values.ModeName === "Drayage" ? (
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Container # :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="text"
                        className="form-control mt-4"
                        name="EmptyReturnNumber"
                        id="EmptyReturnNumber"
                        value={values?.EmptyReturnNumber}
                        onChange={(e) => {
                          setFieldValue("EmptyReturnNumber", e.target.value);
                          setFieldValue("Equipment", e.target.value);
                        }}
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      PU # :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="text"
                        className="form-control mt-4"
                        name="ShipperNumber"
                        id="ShipperNumber"
                        value={values?.ShipperNumber}
                        onChange={(e) => {
                          setFieldValue("ShipperNumber", e.target.value);
                        }}
                      />
                    </span>
                  </div>
                </div>
              )}
              {values.ModeName !== "Drayage" && (
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Seal # :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="text"
                        className="form-control mt-4"
                        name="SealNumber"
                        id="SealNumber"
                        value={values.SealNumber}
                        onChange={(e) => {
                          setFieldValue("SealNumber", e.target.value);
                        }}
                      />
                    </span>
                  </div>
                </div>
              )}

              {values.ModeName !== "Drayage" && (
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Special Req :
                    </a>
                    <span className="font-weight-bold">
                      <Select
                        isMulti
                        className="basic-multi-select mt-4"
                        classNamePrefix="select"
                        name="ShipperSpecialReq"
                        id="ShipperSpecialReq"
                        value={ViewModelOptions?.SpecialReqs.filter(function(
                          el
                        ) {
                          return (
                            values?.ShipperSpecialReq?.indexOf(el.Name) >= 0
                          );
                        }).map((a) => {
                          return {
                            value: a.Name,
                            label: a.Name,
                          };
                        })}
                        options={ViewModelOptions?.SpecialReqs.map((a) => {
                          return {
                            value: a.Name,
                            label: a.Name,
                          };
                        })}
                        onChange={(newValue) => {
                          if (newValue !== null) {
                            setFieldValue(
                              "ShipperSpecialReq",
                              newValue.map((a) => a.value)
                            );
                          } else {
                            setFieldValue("ShipperSpecialReq", []);
                          }
                        }}
                        label="Load Board"
                        inputId="react-select-single"
                      />
                    </span>
                  </div>
                </div>
              )}

              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <CheckboxLargeWB
                    className="font-size-md"
                    text="APPT"
                    name="ShipperApptRequired"
                    id="ShipperApptRequired"
                    checked={
                      values.ModeName === "Drayage"
                        ? values.ShipperApptRequired === false
                          ? false
                          : true
                        : values.ShipperApptRequired === true
                        ? true
                        : false
                    }
                    onChange={(event) => {
                      setFieldValue(
                        "ShipperApptRequired",
                        event.target.checked
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 border-right">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Note :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <textarea
                      className="form-control"
                      id="ShippingNote"
                      rows="3"
                      value={values.ShippingNote}
                      onChange={(e) => {
                        setFieldValue("ShippingNote", e.target.value);
                      }}
                    ></textarea>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {values.ModeName === "Drayage" && (
            <Row className=" mb-5">
              <Col xl="4" lg="4" md="4">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Vessel Eta :
                      <br />
                    </a>
                    <span className="font-weight-bold">
                      <DateTime30GL
                        defaultvalue={values.VesselEta}
                        onChange={(newValue) => {
                          setFieldValue("VesselEta", newValue);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </Col>
              <Col xl="4" lg="4" md="4">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Available Eta :
                      <br />
                    </a>
                    <span className="font-weight-bold">
                      <DateTime30GL
                        defaultvalue={values.AvailableEta}
                        onChange={(newValue) => {
                          setFieldValue("AvailableEta", newValue);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          )}

          <Row className="pt-5  mb-5">
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-3 font-size-lg mr-3"
                  >
                    PU APPT Date :
                    <br />
                  </a>
                  <span className="font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.PUAppointment}
                      onChange={(newValue) => {
                        setFieldValue("PUAppointment", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="2" lg="2" md="3" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-3 font-size-lg mr-3"
                  >
                    PU APPT to Time :
                    <br />
                  </a>
                  <span className="font-weight-bold">
                    <input
                      className={`form-control mt-4`}
                      value={
                        values.PUAppointementTo === null ||
                        values.PUAppointementTo === ""
                          ? ""
                          : values.PUAppointementTo.length > 4
                          ? moment(values.PUAppointementTo).format("HH:mm")
                          : values.PUAppointementTo
                      }
                      placeholder="00:00"
                      onKeyUp={(e) => {
                        if (e.keyCode === 8) {
                          setFieldValue("", "");
                          return;
                        }
                      }}
                      onChange={(e) => {
                        var value = e.target?.value;
                        if (!value || value === "") {
                          setFieldValue("PUAppointementTo", "");
                        }
                        if (value.length === 2) value += ":";
                        if (value.length > 5) value = value[5];
                        if (value.length > 4) {
                          setFieldValue(
                            "PUAppointementTo",
                            moment().format("YYYY-MM-DDT") + value
                          );
                        } else {
                          setFieldValue("PUAppointementTo", value);
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="3">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="EnteredBy"
                    id="EnteredBy"
                    disabled={true}
                    value={values.PUAppointmentEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            {values.ModeName !== "Drayage" && (
              <Col xl="2" lg="2" md="2" className="mt-5 pt-5">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <CheckboxLargeWB
                      className="font-size-md"
                      name="ShipperDt"
                      text="D/T"
                      id="ShipperDt"
                      checked={values.ShipperDt === true ? true : false}
                      onChange={(event) => {
                        setFieldValue("ShipperDt", event.target.checked);
                      }}
                    />
                  </div>
                </div>
              </Col>
            )}

            {values.ShipperDt === true ? (
              <>
                <Col xl="4" lg="4" md="4" className="">
                  <div className=" flex-wrap align-items-center pb-5">
                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                      <a
                        href={() => false}
                        className="text-dark font-weight-bold text-hover-primary mb-3 font-size-lg mr-3"
                      >
                        Empty Date:
                        <br />
                      </a>
                      <span className="font-weight-bold">
                        <DateTime30GL
                          defaultvalue={values.PUAppointmentDt}
                          onChange={(newValue) => {
                            setFieldValue("PUAppointmentDt", newValue);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </Col>
                {/* <Col xl="4" lg="4" md="4" className="">
                  <div className=" flex-wrap align-items-center pb-5">
                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                      <a
                        href={() => false}
                        className="text-dark font-weight-bold text-hover-primary mb-3 font-size-lg mr-3"
                      >
                        Recovery Date :
                        <br />
                      </a>
                      <span className="font-weight-bold">
                        <DateTime30GL
                          defaultvalue={values.PUAppointmentRecoveryDate}
                          onChange={(newValue) => {
                            setFieldValue(
                              "PUAppointmentRecoveryDate",
                              newValue
                            );
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </Col> */}
              </>
            ) : (
              ""
            )}
          </Row>
          <Row className=" mb-5">
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Arrival Date :
                    <br />
                  </a>
                  <span className="font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.ShipperArrivalDate}
                      onChange={(newValue) => {
                        setFieldValue("ShipperArrivalDate", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="EnteredBy"
                    id="EnteredBy"
                    disabled={true}
                    value={values.ShipperArrivalDateEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entry Date/Time :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ShipperArrivalDateEnteredDate"
                      id="ShipperArrivalDateEnteredDate"
                      disabled={true}
                      value={
                        values.ShipperArrivalDateEnteredDate === null
                          ? ""
                          : moment(values.ShipperArrivalDateEnteredDate).format(
                              "MM-DD-YYYY, h:mm A"
                            )
                      }
                      label="Entered By"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className=" mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Loaded Date :
                    <br />
                  </a>
                  <span className="font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.LoadedDate}
                      disabled={true}
                      onChange={(newValue) => {
                        setFieldValue("LoadedDate", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="LoadedDateEnteredBy"
                    id="LoadedDateEnteredBy"
                    disabled={true}
                    value={values.LoadedDateEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entry Date/Time :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="LoadedDateEnteredDate"
                      id="LoadedDateEnteredDate"
                      disabled={true}
                      value={
                        values.LoadedDateEnteredDate === null
                          ? ""
                          : moment(values.LoadedDateEnteredDate).format(
                              "MM-DD-YYYY, h:mm A"
                            )
                      }
                      label="Entered By"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className=" mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Departure Date :
                    <br />
                  </a>
                  <span className="font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.ShipperDepartureDate}
                      onChange={(newValue) => {
                        setFieldValue("ShipperDepartureDate", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="EnteredBy"
                    id="EnteredBy"
                    disabled={true}
                    value={values.ShipperDepartureDateEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entry Date/Time :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ShipperDepartureDateEnteredDate"
                      id="ShipperDepartureDateEnteredDate"
                      disabled={true}
                      value={
                        values.ShipperDepartureDateEnteredDate === null
                          ? ""
                          : moment(
                              values.ShipperDepartureDateEnteredDate
                            ).format("MM-DD-YYYY, h:mm A")
                      }
                      label="Entered By"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* Multi Stops Shipping */}
      {values.ModeName !== "Drayage" && (
        <MultiStopsIndexShipping
          list={listMultiStopsShipping}
          showSaveArrows={showSaveArrows}
          setLoaderSoStop={setLoaderSoStop}
          setShowSaveButton={setShowSaveButton}
          So={SO}
          setLoaderStops={setLoaderStops}
          setMultiStopsData={setMultiStopsDataShipping}
          values={values}
          ViewModelOptions={ViewModelOptions}
        />
      )}
      <Card>
        <CardHeader
          title={`${
            ViewModelOptions?.Consignees.length > 0 &&
            ViewModelOptions?.Consignees.filter(
              (a) => a.Id === values?.ConsigneeId
            )[0]?.Name
              ? `Receiving ${
                  values.ModeName === "Drayage" ? "1" : ""
                } Details | Company Name :  ${
                  ViewModelOptions?.Consignees.filter(
                    (a) => a.Id === values?.ConsigneeId
                  )[0]?.Name
                }`
              : `Receiving ${
                  values.ModeName === "Drayage" ? "1" : ""
                } Details | Select a company`
          }`}
        >
          {values.ModeName !== "Drayage" ? (
            <CardHeaderToolbar>
              <button
                className="btn btn-light-primary py-3 pl-5 pr-4"
                onClick={() => {
                  try {
                    const currentItem =
                      listMultiStopsShipping.length > 0 &&
                      listMultiStopsShipping[
                        listMultiStopsShipping?.length - 1
                      ];
                    if (
                      currentItem &&
                      currentItem.Type === "Drop" &&
                      !currentItem?.IsNew
                    ) {
                      setRowItem(currentItem);
                      setShowModalConfirmationPosition(true);
                    } else {
                      Notification(
                        "errorClick",
                        "Stop should be a drop type to set position"
                      );
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                <i
                  class="fas fa-arrow-circle-up"
                  style={{ fontSize: "18px" }}
                ></i>
              </button>
              <button
                type="button"
                className="btn btn-success mr-4 ml-4"
                onClick={() => {
                  fetchNewStop(true);
                }}
              >
                <i className="fas fa-plus mr-3" />
                Add Stop
              </button>
              {showSaveArrows && (
                <button
                  type="button"
                  className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
                  onClick={async () => {
                    const listPut = [];
                    listMultiStopsShipping.length > 0 &&
                      listMultiStopsShipping.forEach((item, index) => {
                        listPut.push({
                          StopId: item.Id,
                          Position: index + 1,
                        });
                      });
                    await SavePositions(listPut);
                  }}
                >
                  <i className="fas fa-save mr-3" />
                  Save Positions
                </button>
              )}
              <button
                className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
                onClick={() => {
                  try {
                    setIsCollapsedReceiving(
                      isCollapsedReceiving ? false : true
                    );
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                {isCollapsedReceiving ? (
                  <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
                ) : (
                  <i
                    class="fas fa-chevron-down"
                    style={{ fontSize: "18px" }}
                  ></i>
                )}
              </button>
            </CardHeaderToolbar>
          ) : (
            <CardHeaderToolbar>
              <button
                className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
                onClick={() => {
                  try {
                    setIsCollapsedReceiving(
                      isCollapsedReceiving ? false : true
                    );
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                {isCollapsedReceiving ? (
                  <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
                ) : (
                  <i
                    class="fas fa-chevron-down"
                    style={{ fontSize: "18px" }}
                  ></i>
                )}
              </button>
            </CardHeaderToolbar>
          )}
        </CardHeader>
        <CardBody className={isCollapsedReceiving ? "d-none" : ""}>
          <div className="row mb-5">
            <Col xl="12" lg="12" md="12">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Company Name <span className="text-danger">*</span> :
                  </a>
                  <span className="font-weight-bold">
                    <CreatableSelect
                      isClearable
                      className="mt-4"
                      name="ConsigneeId"
                      id="ConsigneeId"
                      value={ViewModelOptions?.Consignees.filter(
                        (a) => a.Id === values?.ConsigneeId
                      ).map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                        };
                      })}
                      options={ViewModelOptions?.Consignees.map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                        };
                      })}
                      onChange={async (newValue) => {
                        if (newValue !== null) {
                          if (newValue?.__isNew__) {
                            setmodalAddresses(true);
                            setCountryValue({
                              value: "US",
                              label: "United States",
                            });

                            handleCountryChange("US");
                            var value = {
                              ...AddressObjectPost,
                            };
                            value.Name = newValue.label;
                            setAddressObjectPost(value);
                            setIsShipperSelected(false);
                          } else {
                            setFieldValue("ConsigneeId", newValue?.value);
                            setFieldValue(
                              "ConsigneeCompanyName",
                              newValue?.label
                            );
                          }
                        } else {
                          setFieldValue("ConsigneeId", null);
                          setFieldValue("ConsigneeCompanyName", "");

                          let values = { ...consignee };
                          values = {
                            ConsigneeId: 0,
                            ConsigneeAddress: "",
                            ConsigneeCity: "",
                            ConsigneeState: "",
                            ConsigneeZipCode: "",
                            ConsigneePhoneNumber: "",
                            ConsigneeFax: "",
                            ConsigneeContactName: "",
                            ConsigneeEmail: "",
                          };
                          setConsignee(values);
                        }
                        if (!newValue?.__isNew__) {
                          await fetchSelectedAddress(
                            newValue !== null ? newValue.value : null,
                            false,
                            false
                          );
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <div className="col-xl-4 col-lg-4 col-md-4 border-right">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Street Address :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ConsigneeStreet"
                      id="ConsigneeStreet"
                      disabled={true}
                      label="Street"
                      value={consignee?.ConsigneeAddress}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    City :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ConsigneeCity"
                      disabled={true}
                      id="ConsigneeCity"
                      value={consignee?.ConsigneeCity}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    State :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ConsigneeState"
                      id="ConsigneeState"
                      disabled={true}
                      value={consignee?.ConsigneeState}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Zip :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ConsigneeZipCode"
                      id="ConsigneeZipCode"
                      disabled={true}
                      value={consignee?.ConsigneeZipCode}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 border-right">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Name :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ConsigneeContactName"
                      disabled={true}
                      value={consignee?.ConsigneeContactName}
                      id="ConsigneeContactName"
                      label="Contact"
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Phone :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ConsigneePhoneNumber"
                      disabled={true}
                      id="ConsigneePhoneNumber"
                      value={consignee?.ConsigneePhoneNumber}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Fax :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ConsigneeFax"
                      id="ConsigneeFax"
                      disabled={true}
                      value={consignee?.ConsigneeFax}
                      label="Fax"
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Email :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="email"
                      className="form-control mt-4"
                      name="EmailText"
                      id="EmailText"
                      disabled={true}
                      value={consignee?.ConsigneeEmail}
                      label="Email"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 border-right">
              <div className=" flex-wrap align-items-center pb-3">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary font-size-lg mr-3"
                  >
                    MABD:<span className="text-danger">*</span>
                  </a>
                  <span className="text-muted font-weight-bold">
                    <TextField
                      id="MustArriveByDate"
                      type="date"
                      value={String(
                        moment(values.MustArriveByDate).format("YYYY-MM-DD")
                      )}
                      onChange={(e) => {
                        setFieldValue("MustArriveByDate", e.target.value);
                      }}
                      className="form-control mt-4"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </span>
                </div>
              </div>

              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    DEL # :
                  </a>
                  <span className="text-muted font-weight-bold">
                    {" "}
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ConsigneeNumber"
                      id="ConsigneeNumber"
                      value={values?.ConsigneeNumber}
                      label="PU"
                      onChange={(e) => {
                        setFieldValue("ConsigneeNumber", e.target.value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Special Req :
                  </a>
                  <span className="font-weight-bold">
                    <Select
                      isMulti
                      className="basic-multi-select mt-4"
                      classNamePrefix="select"
                      name="ConsigneeSpecialReq"
                      id="ConsigneeSpecialReq"
                      value={ViewModelOptions?.SpecialReqs.filter(function(el) {
                        return (
                          values?.ConsigneeSpecialReq?.indexOf(el.Name) >= 0
                        );
                      }).map((a) => {
                        return {
                          value: a.Name,
                          label: a.Name,
                        };
                      })}
                      options={ViewModelOptions?.SpecialReqs.map((a) => {
                        return {
                          value: a.Name,
                          label: a.Name,
                        };
                      })}
                      onChange={(newValue) => {
                        if (newValue !== null) {
                          setFieldValue(
                            "ConsigneeSpecialReq",
                            newValue.map((a) => a.value)
                          );
                        } else {
                          setFieldValue("ConsigneeSpecialReq", []);
                        }
                      }}
                      label="Load Board"
                      inputId="react-select-single"
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <CheckboxLargeWB
                    className="font-size-md"
                    text="APPT"
                    name="ConsigneeApptRequired"
                    id="ConsigneeApptRequired"
                    checked={
                      values.ConsigneeApptRequired === true ? true : false
                    }
                    onChange={(event) => {
                      setFieldValue(
                        "ConsigneeApptRequired",
                        event.target.checked
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 border-right">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Note :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <textarea
                      className="form-control"
                      id="ReceivingNote"
                      rows="3"
                      value={values.ReceivingNote}
                      onChange={(e) => {
                        setFieldValue("ReceivingNote", e.target.value);
                      }}
                    ></textarea>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Row className="pt-5  mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    DEL APPT Date:
                  </a>
                  <span className=" font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.DeliveryAppointmentDate}
                      onChange={(newValue) => {
                        setFieldValue("DeliveryAppointmentDate", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="2" lg="2" md="3" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    DEL to Time :
                  </a>
                  <span className=" font-weight-bold">
                    <input
                      className={`form-control mt-4`}
                      value={
                        values.DeliveryAppointmentDateTo === null ||
                        values.DeliveryAppointmentDateTo === ""
                          ? ""
                          : values.DeliveryAppointmentDateTo.length > 4
                          ? moment(values.DeliveryAppointmentDateTo).format(
                              "HH:mm"
                            )
                          : values.DeliveryAppointmentDateTo
                      }
                      placeholder="00:00"
                      onKeyUp={(e) => {
                        if (e.keyCode === 8) {
                          setFieldValue("", "");
                          return;
                        }
                      }}
                      onChange={(e) => {
                        var value = e.target?.value;
                        if (!value || value === "") {
                          setFieldValue("DeliveryAppointmentDateTo", "");
                        }
                        if (value.length === 2) value += ":";
                        if (value.length > 5) value = value[5];
                        if (value.length > 4) {
                          setFieldValue(
                            "DeliveryAppointmentDateTo",
                            moment().format("YYYY-MM-DDT") + value
                          );
                        } else {
                          setFieldValue("DeliveryAppointmentDateTo", value);
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="3">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="EnteredBy"
                    id="EnteredBy"
                    disabled={true}
                    value={values.DeliveryAppointmentDateEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="2" lg="2" md="2" className="mt-5 pt-5">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <CheckboxLargeWB
                    className="font-size-md"
                    name="ConsigneeDt"
                    text="D/T"
                    id="ConsigneeDt"
                    checked={values.ConsigneeDt === true ? true : false}
                    onChange={(event) => {
                      setFieldValue("ConsigneeDt", event.target.checked);
                    }}
                  />
                </div>
              </div>
            </Col>
            {values.ConsigneeDt === true ? (
              <>
                <Col xl="4" lg="4" md="4" className="">
                  <div className=" flex-wrap align-items-center pb-5">
                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                      <a
                        href={() => false}
                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                      >
                        Empty Date:
                      </a>
                      <span className=" font-weight-bold">
                        <DateTime30GL
                          defaultvalue={values.DeliveryAppointmentRecoveryDate}
                          onChange={(newValue) => {
                            setFieldValue(
                              "DeliveryAppointmentRecoveryDate",
                              newValue
                            );
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
          <Row className=" mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Arrival Date :
                  </a>
                  <span className="font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.ConsigneeArrivalDate}
                      onChange={(newValue) => {
                        setFieldValue("ConsigneeArrivalDate", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="EnteredBy"
                    id="EnteredBy"
                    disabled={true}
                    value={values.ConsigneeArrivalDateEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entry Date/Time :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ConsigneeArrivalDateEnteredDate"
                      id="ConsigneeArrivalDateEnteredDate"
                      disabled={true}
                      value={
                        values.ConsigneeArrivalDateEnteredDate === null
                          ? ""
                          : moment(
                              values.ConsigneeArrivalDateEnteredDate
                            ).format("MM-DD-YYYY, h:mm A")
                      }
                      label="Entered By"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className=" mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Departure Date :
                  </a>
                  <span className="font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.ConsigneeDepartureDate}
                      onChange={(newValue) => {
                        setFieldValue("ConsigneeDepartureDate", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="EnteredBy"
                    id="EnteredBy"
                    disabled={true}
                    value={values.ConsigneeDepartureDateEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entry Date/Time :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ConsigneeDepartureDateEnteredDate"
                      id="ConsigneeDepartureDateEnteredDate"
                      disabled={true}
                      value={
                        values.ConsigneeDepartureDateEnteredDate === null
                          ? ""
                          : moment(
                              values.ConsigneeDepartureDateEnteredDate
                            ).format("MM-DD-YYYY, h:mm A")
                      }
                      label="Entered By"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className=" mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    POD :
                  </a>
                  <span className="font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="Pod"
                      id="Pod"
                      value={values?.Pod}
                      label="Pod"
                      onChange={(e) => {
                        setFieldValue("Pod", e.target.value);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {values.ModeName === "Drayage" && (
        <Card>
          <CardHeader
            title={`${
              ViewModelOptions?.Shippers.length > 0 &&
              ViewModelOptions?.Shippers.filter(
                (a) => a.Id === values?.EmptyReturnLocationId
              )[0]?.Name
                ? `Receiving 2 Details | Company Name :  ${
                    ViewModelOptions?.Shippers.filter(
                      (a) => a.Id === values?.EmptyReturnLocationId
                    )[0]?.Name
                  }`
                : `Receiving 2 Details | Select a company`
            }`}
          >
            {values.ModeName !== "Drayage" ? (
              <CardHeaderToolbar>
                <button
                  type="button"
                  className="btn btn-success mr-4"
                  onClick={async () => {
                    await GetViewModelStops();
                    setShowStop(true);
                  }}
                >
                  <i className="fas fa-plus mr-3" />
                  Add Stops
                </button>
              </CardHeaderToolbar>
            ) : (
              <CardHeaderToolbar>
                <button
                  className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
                  onClick={() => {
                    try {
                      setIsCollapsedReceiving2(
                        isCollapsedReceiving2 ? false : true
                      );
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                >
                  {isCollapsedReceiving2 ? (
                    <i
                      class="fas fa-chevron-up"
                      style={{ fontSize: "18px" }}
                    ></i>
                  ) : (
                    <i
                      class="fas fa-chevron-down"
                      style={{ fontSize: "18px" }}
                    ></i>
                  )}
                </button>
              </CardHeaderToolbar>
            )}
          </CardHeader>
          <CardBody className={isCollapsedReceiving2 ? "d-none" : ""}>
            <div className="row mb-5">
              <Col xl="12" lg="12" md="12" className="mb-5">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Company Name :
                    </a>
                    <span className="font-weight-bold">
                      <CreatableSelect
                        className="mt-4"
                        isClearable
                        name="EmptyReturnLocationId"
                        id="EmptyReturnLocationId"
                        label="Company"
                        value={ViewModelOptions?.Shippers.filter(
                          (a) => a.Id === values?.EmptyReturnLocationId
                        ).map((a) => {
                          return {
                            value: a.Id,
                            label: a.Name,
                          };
                        })}
                        options={ViewModelOptions?.Shippers.map((a) => {
                          return {
                            value: a.Id,
                            label: a.Name,
                          };
                        })}
                        onChange={async (newValue) => {
                          if (newValue !== null) {
                            if (newValue?.__isNew__) {
                              setmodalAddresses(true);
                              setCountryValue({
                                value: "US",
                                label: "United States",
                              });

                              handleCountryChange("US");
                              var value = {
                                ...AddressObjectPost,
                              };
                              value.Name = newValue.label;
                              setAddressObjectPost(value);
                            } else {
                              setFieldValue(
                                "EmptyReturnLocationId",
                                newValue?.value
                              );
                            }
                          } else {
                            setFieldValue("EmptyReturnLocationId", null);
                            let values = { ...shipper2 };
                            values = {
                              ShipperId: 0,
                              ShipperAddress: "",
                              ShipperCity: "",
                              ShipperState: "",
                              ShipperZipCode: "",
                              ShipperPhoneNumber: "",
                              ShipperFax: "",
                              ShipperContactName: "",
                              ShipperEmail: "",
                            };
                            setShipper2(values);
                          }
                          setFieldValue("IsEditedReceiving2", true);
                          if (!newValue?.__isNew__) {
                            await fetchSelectedAddress(
                              newValue !== null ? newValue.value : null,
                              true,
                              true
                            );
                          }
                        }}
                      />
                    </span>
                  </div>
                </div>
              </Col>
              <div className="col-xl col-lg col-md-5 border-right">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Street Address :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="text"
                        className="form-control mt-4"
                        name="ShipperAddress"
                        disabled={true}
                        id="ShipperAddress"
                        value={shipper2?.ShipperAddress}
                      />
                    </span>
                  </div>
                </div>
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      City :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="text"
                        className="form-control mt-4"
                        name="ShipperCity"
                        disabled={true}
                        id="ShipperCity"
                        value={shipper2?.ShipperCity}
                      />
                    </span>
                  </div>
                </div>
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      State :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="text"
                        className="form-control mt-4"
                        name="ShipperState"
                        disabled={true}
                        id="ShipperState"
                        value={shipper2?.ShipperState}
                      />
                    </span>
                  </div>
                </div>
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Zip :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="text"
                        className="form-control mt-4"
                        name="ShipperZipCode"
                        id="ShipperZipCode"
                        disabled={true}
                        value={shipper2?.ShipperZipCode}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl col-lg col-md-5 border-right">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Contact Name :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="text"
                        className="form-control mt-4"
                        name="ShipperContactName"
                        disabled={true}
                        id="ShipperContactName"
                        label="Contact"
                        value={shipper2?.ShipperContactName}
                      />
                    </span>
                  </div>
                </div>
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Contact Phone :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="tel"
                        className="form-control mt-4"
                        name="ShipperPhoneNumber"
                        id="ShipperPhoneNumber"
                        disabled={true}
                        value={shipper2?.ShipperPhoneNumber}
                      />
                    </span>
                  </div>
                </div>
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Contact Fax :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="tel"
                        className="form-control mt-4"
                        name="ShipperFax"
                        id="ShipperFax"
                        disabled={true}
                        value={shipper2?.ShipperFax}
                        label="Fax"
                      />
                    </span>
                  </div>
                </div>
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Contact Email :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="email"
                        className="form-control mt-4"
                        name="ShipperEmail"
                        disabled={true}
                        id="ShipperEmail"
                        value={shipper2?.ShipperEmail}
                        label="Email"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl col-lg col-md-5 border-right">
                <div className=" flex-wrap align-items-center pb-3">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary font-size-lg mr-3"
                    >
                      Reported Empty:
                    </a>
                    <span className="text-muted font-weight-bold">
                      <TextField
                        id="ReportedEmpty"
                        type="date"
                        value={String(
                          moment(values.ReportedEmpty).format("YYYY-MM-DD")
                        )}
                        onChange={(e) => {
                          setFieldValue("ReportedEmpty", e.target.value);
                        }}
                        className="form-control mt-4"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      DEL # :
                    </a>
                    <span className="text-muted font-weight-bold">
                      {" "}
                      <input
                        type="text"
                        className="form-control mt-4"
                        name="ConsigneeNumber"
                        id="ConsigneeNumber"
                        value={values?.ConsigneeNumber}
                        label="PU"
                        onChange={(e) => {
                          setFieldValue("ConsigneeNumber", e.target.value);
                        }}
                      />
                    </span>
                  </div>
                </div>

                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <CheckboxLargeWB
                      className="font-size-md"
                      text="APPT"
                      name="EmptyReturnApptRequired"
                      id="EmptyReturnApptRequired"
                      checked={
                        values.EmptyReturnApptRequired === false ? false : true
                      }
                      onChange={(event) => {
                        setFieldValue(
                          "EmptyReturnApptRequired",
                          event.target.checked
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Row className="pt-5  mb-5">
              <Col xl="4" lg="4" md="4" className="">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      DEL APPT Date:
                    </a>
                    <span className=" font-weight-bold">
                      <DateTime30GL
                        defaultvalue={values.EmptyReturnDeliveryAppointmentDate}
                        onChange={(newValue) => {
                          setFieldValue(
                            "EmptyReturnDeliveryAppointmentDate",
                            newValue
                          );
                        }}
                      />
                    </span>
                  </div>
                </div>
              </Col>
              <Col xl="2" lg="2" md="3" className="">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      DEL to Time :
                    </a>
                    <span className=" font-weight-bold">
                      <input
                        className={`form-control mt-4`}
                        value={
                          values?.EmptyReturnDeliveryAppointmentDateTo ===
                            null ||
                          values?.EmptyReturnDeliveryAppointmentDateTo === ""
                            ? ""
                            : values?.EmptyReturnDeliveryAppointmentDateTo
                                ?.length > 4
                            ? moment(
                                values?.EmptyReturnDeliveryAppointmentDateTo
                              ).format("HH:mm")
                            : values?.EmptyReturnDeliveryAppointmentDateTo
                        }
                        placeholder="00:00"
                        onKeyUp={(e) => {
                          if (e.keyCode === 8) {
                            setFieldValue("", "");
                            return;
                          }
                        }}
                        onChange={(e) => {
                          var value = e.target?.value;
                          if (!value || value === "") {
                            setFieldValue(
                              "EmptyReturnDeliveryAppointmentDateTo",
                              ""
                            );
                          }
                          if (value.length === 2) value += ":";
                          if (value.length > 5) value = value[5];
                          if (value.length > 4) {
                            setFieldValue(
                              "EmptyReturnDeliveryAppointmentDateTo",
                              moment().format("YYYY-MM-DDT") + value
                            );
                          } else {
                            setFieldValue(
                              "EmptyReturnDeliveryAppointmentDateTo",
                              value
                            );
                          }
                        }}
                      />
                    </span>
                  </div>
                </div>
              </Col>
              <Col xl="4" lg="4" md="3">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Entered By :
                    </a>
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="EmptyReturndeliveryAppointmentDateEnteredBy"
                      id="EmptyReturndeliveryAppointmentDateEnteredBy"
                      disabled={true}
                      value={values.EmptyReturndeliveryAppointmentDateEnteredBy}
                      label="Entered By"
                    />
                  </div>
                </div>
              </Col>
              {/* <Col xl="2" lg="2" md="2" className="mt-5 pt-5">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <CheckboxLargeWB
                    className="font-size-md"
                    name="ConsigneeDt"
                    text="D/T"
                    id="ConsigneeDt"
                    checked={values.ConsigneeDt === true ? true : false}
                    onChange={(event) => {
                      setFieldValue("ConsigneeDt", event.target.checked);
                    }}
                  />
                </div>
              </div>
            </Col> */}
            </Row>
            <Row className=" mb-5">
              <Col xl="4" lg="4" md="4" className="">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Arrival Date :
                    </a>
                    <span className="font-weight-bold">
                      <DateTime30GL
                        defaultvalue={values.EmptyReturnArrivalDate}
                        onChange={(newValue) => {
                          setFieldValue("EmptyReturnArrivalDate", newValue);
                          setFieldValue("EmptyReturnDepartureDate", newValue);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </Col>
              <Col xl="4" lg="4" md="4">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Entered By :
                    </a>
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="EmptyReturnArrivalDateEnteredBy"
                      id="EmptyReturnArrivalDateEnteredBy"
                      disabled={true}
                      value={values.EmptyReturnArrivalDateEnteredBy}
                      label="Entered By"
                    />
                  </div>
                </div>
              </Col>
              <Col xl="4" lg="4" md="4">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Entry Date/Time :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="tel"
                        className="form-control mt-4"
                        name="EmptyReturnArrivalDateEnteredDate"
                        id="EmptyReturnArrivalDateEnteredDate"
                        disabled={true}
                        value={
                          values.EmptyReturnArrivalDateEnteredDate === null
                            ? ""
                            : moment(
                                values.EmptyReturnArrivalDateEnteredDate
                              ).format("MM-DD-YYYY, h:mm A")
                        }
                        label="Entered By"
                      />
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className=" mb-5">
              <Col xl="4" lg="4" md="4" className="">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Departure Date :
                    </a>
                    <span className="font-weight-bold">
                      <DateTime30GL
                        defaultvalue={values.EmptyReturnDepartureDate}
                        onChange={(newValue) => {
                          setFieldValue("EmptyReturnDepartureDate", newValue);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </Col>
              <Col xl="4" lg="4" md="4">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Entered By :
                    </a>
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="EmptyReturnDepartureDateEnteredBy"
                      id="EmptyReturnDepartureDateEnteredBy"
                      disabled={true}
                      value={values.EmptyReturnDepartureDateEnteredBy}
                      label="Entered By"
                    />
                  </div>
                </div>
              </Col>
              <Col xl="4" lg="4" md="4">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      Entry Date/Time :
                    </a>
                    <span className="text-muted font-weight-bold">
                      <input
                        type="tel"
                        className="form-control mt-4"
                        name="EmptyReturnDepartureDateEnteredDate"
                        id="EmptyReturnDepartureDateEnteredDate"
                        disabled={true}
                        value={
                          values.EmptyReturnDepartureDateEnteredDate === null
                            ? ""
                            : moment(
                                values.EmptyReturnDepartureDateEnteredDate
                              ).format("MM-DD-YYYY, h:mm A")
                        }
                        label="Entered By"
                      />
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className=" mb-5">
              <Col xl="4" lg="4" md="4" className="">
                <div className=" flex-wrap align-items-center pb-5">
                  <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                    <a
                      href={() => false}
                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                    >
                      POD :
                    </a>
                    <span className="font-weight-bold">
                      <input
                        type="text"
                        className="form-control mt-4"
                        name="EmptyReturnPod"
                        id="EmptyReturnPod"
                        value={values?.EmptyReturnPod}
                        label="Pod"
                        onChange={(e) => {
                          setFieldValue("EmptyReturnPod", e.target.value);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}

      <Card>
        <CardHeader title="Commodity Details">
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={addClick.bind(this)}
              className="btn btn-light-success py-3 pl-md-8 pr-md-8"
            >
              <i
                className={`fas fa-plus fa-sm`}
                style={{
                  paddingRight: "initial",
                }}
              ></i>{" "}
            </button>
            <button
              type="button"
              onClick={async () => {
                setIsLinearProgress(true);
                await GetItemsTms(SO);
                setIsLinearProgress(false);
              }}
              className="btn btn-light-info py-3 pl-md-8 pr-md-8 ml-5"
            >
              <i
                className={`fas fa-sync-alt fa-sm`}
                style={{
                  paddingRight: "initial",
                }}
              ></i>{" "}
            </button>
            {itemsTms.length > 0 && (
              <button
                type="button"
                disabled={
                  !itemsTms.some((x) => (x?.Id && x.IsEditMode) || x?.IsPost)
                }
                class="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
                id="tmsItemsSave"
                onClick={async () => {
                  try {
                    itemsTms?.length > 0 &&
                      itemsTms.map(async (el, i) => {
                        if (el?.Id && el?.IsEditMode) {
                          el.ShipmentId = SO;
                          await PutItemTms(el?.Id, el);
                          Notification(
                            "success",
                            `Item #: ${el?.Id} has been updated successfully`
                          );
                        }
                        if (el?.IsPost) {
                          el.ShipmentId = SO;

                          await PostItemTms(el);
                        }
                        if (i === itemsTms.length - 1) {
                          setTimeout(async () => {
                            await GetItemsTms(SO);
                          }, 500);
                        }
                      });
                  } catch (err) {}
                }}
              >
                <i
                  class="fas fa-save fa-sm"
                  style={{
                    paddingRight: "initial",
                  }}
                ></i>
              </button>
            )}
            <button
              className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
              onClick={() => {
                try {
                  setIsCollapsedCommodity(isCollapsedCommodity ? false : true);
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {isCollapsedCommodity ? (
                <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
              ) : (
                <i class="fas fa-chevron-down" style={{ fontSize: "18px" }}></i>
              )}
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className={isCollapsedCommodity ? "d-none" : ""}>
          {isLinearProgress && <LinearProgress />}
          <div className="col-xl-12 col-lg-12">
            {ItemsTmsComponent(values)}
            <div className="form-group row mt-5">
              <div className="col">
                <LoadingDialog />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      {/* Drag and Drop / Items */}
      <Card>
        <CardHeader title="Warehouse Items">
          <CardHeaderToolbar>
            <button
              className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
              onClick={() => {
                try {
                  setIsCollapsedCommodityDrag(
                    isCollapsedCommodityDrag ? false : true
                  );
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {isCollapsedCommodityDrag ? (
                <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
              ) : (
                <i class="fas fa-chevron-down" style={{ fontSize: "18px" }}></i>
              )}
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className={isCollapsedCommodityDrag ? "d-none" : ""}>
          <div className="col-xl-12 col-lg-12">
            <Row className="mb-md-15">
              <Col>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center"
                  bootstrap4
                  id="SPFsList"
                  keyField="Id"
                  noDataIndication={
                    <h4 style={{ textAlign: "center" }} className="text-muted">
                      {"There is no data available at the moment"}.
                    </h4>
                  }
                  data={initProduct?.ShipmentItems}
                  pagination={paginationFactory({
                    showTotal: true,
                    sizePerPage: 5,
                    sizePerPageList: [
                      { text: "5", value: 5 },
                      { text: "10", value: 10 },
                    ],
                  })}
                  columns={columnsItems}
                ></BootstrapTable>
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="font-weight-boldest">Total Quantity :</label>
                <div
                  className="label label-lg label-light-primary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                  style={{ fontSize: "15px" }}
                >
                  {calculateTotalItems(initProduct?.ShipmentItems, "Quantity")}
                </div>
              </Col>
              <Col>
                {" "}
                <Col>
                  <label className="font-weight-boldest">Total Weight :</label>
                  <div
                    className="label label-lg label-light-primary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    {calculateTotalItems(initProduct?.ShipmentItems, "Weight")}
                  </div>
                </Col>
              </Col>
              <Col>
                {" "}
                <Col>
                  <label className="font-weight-boldest">Total Cube :</label>
                  <div
                    className="label label-lg label-light-primary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    {calculateTotalItems(initProduct?.ShipmentItems, "Cube")}
                  </div>
                </Col>
              </Col>
            </Row>
            <div className="form-group row mt-5">
              <div className="col">
                <LoadingDialog />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      {values?.FreightChargeId === 98 && (
        <Card>
          <CardHeader className="border-bottom-0" title="AP/Invoicing">
            <CardHeaderToolbar>
              <button
                className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
                onClick={() => {
                  try {
                    setIsCollapsedInvoicing(
                      isCollapsedInvoincing ? false : true
                    );
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                {isCollapsedInvoincing ? (
                  <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
                ) : (
                  <i
                    class="fas fa-chevron-down"
                    style={{ fontSize: "18px" }}
                  ></i>
                )}
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody className={isCollapsedInvoincing ? "d-none" : ""}>
            <div className="row mb-md-20">
              <div className="col">
                <ChargesValues
                  values={values}
                  ChargesId={(values?.Id ?? "") + "Charges"}
                />
                <h3 className="mb-3">Bill To Rating</h3>
                {values?.CustomerId && (
                  <>
                    <ChargesFunction
                      isInbound={false}
                      isPayable={false}
                      type="Charges"
                      ChargesId={(values?.Id ?? "") + "Charges"}
                      list={values?.Charges ?? []}
                      ReloadCalc={ReloadCalc}
                      setChargesTotal={setChargesTotal}
                      setChergesFun={(a) => {
                        setCharges(a);
                      }}
                      values={values}
                    />
                  </>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardHeader
          className="border-bottom-0"
          title={
            <>
              Vendors Rating
              {values?.Id && (
                <LockPayblesButton
                  isPrimary={true}
                  Id={values?.Id}
                  IsReceipt={false}
                  FreightChargeId={values?.FreightChargeId}
                  ArePayablesReadOnly={values?.ArePayablesReadOnly}
                  setArePayablesReadOnly={(e) => {
                    setFieldValue("ArePayablesReadOnly", e);
                  }}
                />
              )}
            </>
          }
        >
          <CardHeaderToolbar>
            <button
              className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
              onClick={() => {
                try {
                  setIsCollapsedInvoicing(isCollapsedInvoincing ? false : true);
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {isCollapsedInvoincing ? (
                <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
              ) : (
                <i class="fas fa-chevron-down" style={{ fontSize: "18px" }}></i>
              )}
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className={isCollapsedInvoincing ? "d-none" : ""}>
          <div className="row border-bottom pb-4">
            <div className="col">
              {values?.Vendors.filter(
                (x) => x?.CarrierId !== null && x?.CarrierId !== ""
              ).length > 0 ? (
                <>
                  {values?.Vendors.filter(
                    (x) => x?.CarrierId !== null && x?.CarrierId !== ""
                  ).map((el, i) => (
                    <>
                      {i > 0 && <br />}

                      <h4>
                        Vendor Name :{" "}
                        <b className="text-primary">
                          {el?.VendorName ?? "Empty"}
                        </b>
                      </h4>
                      <ChargesValues
                        values={values}
                        ChargesId={(values?.Id ?? "") + "Payables" + i}
                        index={i}
                      />
                      <div className="bg-light position-relative rounded my-5 p-5">
                        <b
                          id={`vendor${values?.Id ?? ""}${i}`}
                          style={{
                            position: "absolute",
                            left: "-100px",
                            top: "-250px",
                            width: "0px",
                            opacity: "0",
                          }}
                        />
                        <ChargesFunction
                          isInbound={false}
                          isPayable={true}
                          type="Payables"
                          ChargesId={(values?.Id ?? "") + "Payables" + i}
                          list={el.Payables ?? []}
                          ReloadCalc={ReloadCalc}
                          colorTable={"bg-white"}
                          IsReadOnly={values?.ArePayablesReadOnly ?? false}
                          setChargesTotal={setPayblesTotal}
                          setChergesFun={(a = []) =>
                            setFieldValue(`Vendors[${i}].Payables`, a)
                          }
                        />
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <Nodatafound />
              )}
            </div>

            <div className="col-12 text-center mt-8">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Margin Amount :
                  </a>
                  <span className="text-muted font-weight-bold">
                    ${" "}
                    {currencyFormat(
                      parseFloat(
                        ChargesTotal === null ||
                          ChargesTotal === "" ||
                          ChargesTotal === undefined
                          ? 0
                          : ChargesTotal
                      ) -
                        parseFloat(
                          PayblesTotal === null ||
                            PayblesTotal === "" ||
                            PayblesTotal === undefined
                            ? 0
                            : PayblesTotal
                        )
                    )}
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-10">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Margin Percentage :
                  </a>
                  <span className="text-muted font-weight-bold">
                    {currencyFormat(
                      ((parseFloat(
                        ChargesTotal === null ||
                          ChargesTotal === "" ||
                          ChargesTotal === undefined
                          ? 0
                          : ChargesTotal
                      ) -
                        parseFloat(
                          PayblesTotal === null ||
                            PayblesTotal === "" ||
                            PayblesTotal === undefined
                            ? 0
                            : PayblesTotal
                        )) /
                        parseFloat(
                          ChargesTotal === "" ||
                            ChargesTotal === null ||
                            ChargesTotal === undefined
                            ? 0
                            : ChargesTotal
                        )) *
                        100
                    )}
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardHeader className="border-bottom-0" title="Attachments">
          <CardHeaderToolbar>
            <button
              className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
              onClick={() => {
                try {
                  setIsCollapsedAttachments(
                    isCollapsedAttachments ? false : true
                  );
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {isCollapsedAttachments ? (
                <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
              ) : (
                <i class="fas fa-chevron-down" style={{ fontSize: "18px" }}></i>
              )}
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className={isCollapsedAttachments ? "d-none" : ""}>
          {values !== null &&
          AttachmentTypes.length > 0 &&
          AccessLevels.length > 0 ? (
            <OrderAttachementsV2
              id={SO}
              AttachmentList={values.Attachments || []}
              AttachmentTypes={AttachmentTypes}
              AccessLevels={AccessLevels}
              Type="shipment"
            />
          ) : (
            ""
          )}
        </CardBody>
      </Card>
    </>
  );
}

const ChargesValues = ({ values, ChargesId, index = 0 }) => {
  function IdAddCharges(id) {
    return id + "" + ChargesId;
  }
  return (
    <div>
      <input
        type="hidden"
        id={IdAddCharges("C_CustomerId")}
        value={values?.CustomerId ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_ServiceId")}
        value={values?.ServiceId ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_ModeId")}
        value={values?.ModeId ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_ServiceLevelId")}
        value={values?.ServiceLevelId ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_EquipmentTypeId")}
        value={values?.EquipmentTypeId ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_EquipmentSizeId")}
        value={values?.EquipmentSizeId ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_PuAppointment")}
        value={values?.PuAppointment ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_ReceivedDate")}
        value={values?.ReceivedDate ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_PickedUpDate")}
        value={values?.PickedUpDate ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_fromCity")}
        value={values?.ShipperCity ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_fromAddress")}
        value={values?.ShipperAddress ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_fromState")}
        value={values?.ShipperState ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_fromZipCode")}
        value={values?.ShipperZipCode ?? ""}
      />

      <input
        type="hidden"
        id={IdAddCharges("C_toCity")}
        value={values?.ConsigneeCity ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_toAddress")}
        value={values?.ConsigneeAddress ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_toState")}
        value={values?.ConsigneeState ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_toZipCode")}
        value={values?.ConsigneeZipCode ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_CarrierId")}
        value={values?.Vendors[index]?.CarrierId ?? ""}
      />
    </div>
  );
};

function Nodatafound() {
  return (
    <div className="w-100 row mt-5 pt-5">
      <div className="col-lg-3" />
      <div className="col-lg-2 text-right">
        <i
          className="fas fa-quote-left"
          style={{ fontSize: "70px", opacity: 0.3 }}
        />
      </div>
      <div className="col-lg-4 mt-2">
        <p className="h1 mt-5" style={{ opacity: 0.3 }}>
          No Vendor found
        </p>
      </div>
      <div className="col-lg-3" />
    </div>
  );
}
