import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CheckboxLarge,
  CheckboxLargeWB,
  Pagination,
} from "../../../../../_metronic/_partials/controls";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { currencyFormat } from "../../../../../_metronic/_helpers/ATHelpers";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  toAbsoluteUrl,
} from "../../../../../_metronic/_helpers";
import CloseIcon from "@material-ui/icons/Close";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CreatableSelect from "react-select/creatable";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "./CustomersUIContext";
import { Helmet } from "react-helmet";
import {
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";
import { DropdownCustomTo } from "../../../../../_metronic/_partials/dropdowns";
import SVG from "react-inlinesvg";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import {
  Table,
  makeStyles,
  TableBody,
  TableCell,
  IconButton,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Select from "react-select";
import { Notification } from "../../../Notification";
import { useHistory } from "react-router";
import { ClickFun } from "../../../../SpecificTabs";
import moment from "moment";
const adapter = new LocalStorage("db");
const db = low(adapter);
var expendedDefault = [];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
}));

//Pagination Option
var totalItemsNew = 0;
var totalItemsBooked = 0;
var totalItemsAtShipper = 0;
var totalItemsNotConfirmed = 0;

export function CustomersCard() {
  const history = useHistory();
  const [ShowData, setShowData] = useState("");
  const [loadingDataNew, setloadingDataNew] = useState(false);
  const [loadingDataBooked, setloadingDataBooked] = useState(false);
  const [loadingDataNotConfirmed, setloadingDataNotConfirmed] = useState(false);
  const [loadingDataAtShipper, setloadingDataAtShipper] = useState(false);
  const [ShowAddOfferModal, setShowAddOfferModal] = useState(false);
  const [LoadingPost, setLoadingPost] = useState(false);
  const customersUIContext = useCustomersUIContext();
  const [NewOrderData, setNewOrderData] = useState([]);
  const [NotConfirmedData, setNotConfirmedData] = useState([]);
  const [BookedData, setBookedData] = useState([]);
  const [AtShipperData, setAtShipperData] = useState([]);
  const [dataExpandedPo, setDataExpandedRow] = useState([]);

  const [EmailAddressList, setEmailAddressList] = useState([]);
  const [ToDataDefeault, setToDataDefeault] = useState([]);
  const [ToData, setToData] = useState(null);
  const [includCCData, setincludCCData] = useState(false);
  const [SubjectData, setSubjectData] = useState(null);
  const [BodyData, setBodyData] = useState("");
  const [AttachmentData, setAttachmentData] = useState([]);
  const [isLoadingSend, setisLoadingSend] = useState(false);
  const [showModalSend, setshowModalSend] = useState(false);
  const [DataListBids, setDataListBids] = useState([]);

  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
    };
  }, [customersUIContext]);

  const [PaginationProperty, setPaginationProperty] = useState({
    pageNumber: 1,
    pageSize: 4,
    sortField: "Id",
    sortOrder: "desc",
  });

  const [paginationOptionsNew, setpaginationOptionsNew] = useState({
    custom: true,
    totalSize: totalItemsNew,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "4", value: 4 },
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      {
        text: String(totalItemsNew),
        value: totalItemsNew,
      },
    ],
    sizePerPage: 4,
    page: 1,
    fromLoading: true,
  });
  const [paginationOptionsBooked, setpaginationOptionsBooked] = useState({
    custom: true,
    totalSize: totalItemsBooked,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "4", value: 4 },
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      {
        text: String(totalItemsBooked),
        value: totalItemsBooked,
      },
    ],
    sizePerPage: 4,
    page: 1,
  });
  const [
    paginationOptionsNotConfirmed,
    setpaginationOptionsNotConfirmed,
  ] = useState({
    custom: true,
    totalSize: totalItemsNotConfirmed,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "4", value: 4 },
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      {
        text: String(totalItemsNotConfirmed),
        value: totalItemsNotConfirmed,
      },
    ],
    sizePerPage: 4,
    page: 1,
  });
  const [paginationOptionsAtShipper, setpaginationOptionsAtShipper] = useState({
    custom: true,
    totalSize: totalItemsAtShipper,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "4", value: 4 },
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      {
        text: String(totalItemsAtShipper),
        value: totalItemsAtShipper,
      },
    ],
    sizePerPage: 4,
    page: 1,
  });

  const GetHandlerTableChangeNew = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationOptionsNew };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      values.fromLoading = false;
      setpaginationOptionsNew(values);
      if (
        newState.sortField !== paginationOptionsNew.sortField ||
        newState.sortOrder !== paginationOptionsNew.sortOrder
      ) {
        GetDataByStatus(
          "New",
          paginationOptionsNew.page,
          paginationOptionsNew.sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
    if (type === "pagination") {
      let values = { ...paginationOptionsNew };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      values.fromLoading = false;
      setpaginationOptionsNew(values);
      GetDataByStatus(
        "New",
        newState.page === undefined ? 1 : newState.page,
        newState.sizePerPage,
        paginationOptionsNew.sortField,
        paginationOptionsNew.sortOrder
      );
    }
  };
  const GetHandlerTableChangeBooked = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationOptionsBooked };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      setpaginationOptionsBooked(values);
      if (
        newState.sortField !== paginationOptionsBooked.sortField ||
        newState.sortOrder !== paginationOptionsBooked.sortOrder
      ) {
        GetDataByStatus(
          "Order",
          paginationOptionsBooked.page,
          paginationOptionsBooked.sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
    if (type === "pagination") {
      let values = { ...paginationOptionsBooked };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      setpaginationOptionsBooked(values);
      GetDataByStatus(
        "Order",
        newState.page === undefined ? 1 : newState.page,
        newState.sizePerPage,
        paginationOptionsBooked.sortField,
        paginationOptionsBooked.sortOrder
      );
    }
  };
  const GetHandlerTableChangeNotConfirmed = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationOptionsNotConfirmed };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      setpaginationOptionsNotConfirmed(values);
      if (
        newState.sortField !== paginationOptionsNotConfirmed.sortField ||
        newState.sortOrder !== paginationOptionsNotConfirmed.sortOrder
      ) {
        GetDataByStatus(
          "NotConfirmed",
          paginationOptionsNotConfirmed.page,
          paginationOptionsNotConfirmed.sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
    if (type === "pagination") {
      let values = { ...paginationOptionsNotConfirmed };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      setpaginationOptionsNotConfirmed(values);
      GetDataByStatus(
        "NotConfirmed",
        newState.page === undefined ? 1 : newState.page,
        newState.sizePerPage,
        paginationOptionsNotConfirmed.sortField,
        paginationOptionsNotConfirmed.sortOrder
      );
    }
  };
  const GetHandlerTableChangeAtShipper = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationOptionsAtShipper };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      setpaginationOptionsAtShipper(values);
      if (
        newState.sortField !== paginationOptionsAtShipper.sortField ||
        newState.sortOrder !== paginationOptionsAtShipper.sortOrder
      ) {
        GetDataByStatus(
          "Bids",
          paginationOptionsAtShipper.page,
          paginationOptionsAtShipper.sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
    if (type === "pagination") {
      let values = { ...paginationOptionsAtShipper };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      setpaginationOptionsAtShipper(values);
      GetDataByStatus(
        "Bids",
        newState.page === undefined ? 1 : newState.page,
        newState.sizePerPage,
        paginationOptionsAtShipper.sortField,
        paginationOptionsAtShipper.sortOrder
      );
    }
  };

  const FilterTable = [
    {
      name: "New Assignments",
      color: "primary",
    },
    {
      name: "Order Bids",
      color: "info",
    },
    {
      name: "Bids Accepted",
      color: "success",
    },
    {
      name: "Booked - Not Confirmed",
      color: "warning",
    },
  ];
  const columnsNew = [
    {
      dataField: "Id",
      text: "SO#",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <Dropdown className="dropdown-inline position-unset" alignRight>
            <Dropdown.Toggle
              className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
              variant="transparent"
              id="dropdown-toggle-top"
              as={DropdownCustomTo}
            >
              {row.Id}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right topFixedMenu">
              <ul className="navi navi-hover py-2">
                <li className="navi-item">
                  <a
                    href={() => false}
                    className="navi-link"
                    onClick={() => {
                      let value = { ...BidsInfo };
                      value.ShipmentId = row.Id;
                      setBidsInfo(value);
                      setShowAddOfferModal(true);
                    }}
                  >
                    <span className="navi-icon">
                      <i className="fas fa-plus text-success"></i>
                    </span>
                    <span className="navi-text">Add offer</span>
                  </a>
                </li>
                <li className="navi-item">
                  <a
                    href={() => false}
                    className="navi-link"
                    onClick={(e) => {
                      /* window.TMSEditSOCommingFrom = "CapacityBoard";
                      customersUIProps.openEditCustomerDialog(row.Id); */
                      ClickFun(
                        e,
                        `/TransportManagementSystems/SoDetail/`,
                        row.Id
                      );
                    }}
                  >
                    <span className="navi-icon">
                      <i className="fas fa-file-alt text-primary"></i>
                    </span>
                    <span className="navi-text">View / Edit</span>
                  </a>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
      formatExtraData: {
        openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
      },
      classes: "text-center pr-0 text-primary font-weight-bolder",
      headerClasses: "text-center pr-3",
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Mode",
      text: "Mode",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Pu",
      text: "Pu Location",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.FromCity + ", " + row.FromState;
      },
    },
    {
      dataField: "Del",
      text: "Del Location",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.ToCity + ", " + row.ToState;
      },
    },
    {
      dataField: "TotalPayable",
      text: "Req Bid",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return currencyFormat(row.TotalPayable);
      },
    },
    {
      dataField: "TotalReceivables",
      text: "Cust Rate",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return currencyFormat(row.TotalReceivables);
      },
    },
  ];
  const columnsOrder = [
    {
      dataField: "Po",
      text: "Details",
      sort: true,
      sortCaret: sortCaret,
      style: {
        width: "85px",
      },
      formatter: (cell, row, index) => {
        return (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="customers-delete-tooltip">Bids Details</Tooltip>
              }
            >
              <a
                href={() => false}
                className="btn btn-icon btn-light btn-hover-info btn-sm"
                onClick={async () => {
                  if (expendedDefault[0] !== row?.Id) {
                    expendedDefault = [row?.Id];
                    setDataExpandedRow([]);
                  } else {
                    expendedDefault = [];
                    setDataExpandedRow([]);
                  }
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-info">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </>
        );
      },
    },
    {
      dataField: "Id",
      text: "SO#",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <Dropdown className="dropdown-inline position-unset" alignRight>
            <Dropdown.Toggle
              className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
              variant="transparent"
              id="dropdown-toggle-top"
              as={DropdownCustomTo}
            >
              {row.Id}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right topFixedMenu">
              <ul className="navi navi-hover py-2">
                <li className="navi-item">
                  <a
                    href={() => false}
                    className="navi-link"
                    onClick={() => {
                      setShowAddOfferModal(true);
                      let value = { ...BidsInfo };
                      value.ShipmentId = row.Id;
                      setBidsInfo(value);
                    }}
                  >
                    <span className="navi-icon">
                      <i className="fas fa-plus text-success"></i>
                    </span>
                    <span className="navi-text">Add offer</span>
                  </a>
                </li>
                <li className="navi-item">
                  <a
                    href={() => false}
                    className="navi-link"
                    onClick={(e) => {
                      ClickFun(
                        e,
                        `/TransportManagementSystems/SoDetail/`,
                        row.Id
                      );
                    }}
                  >
                    <span className="navi-icon">
                      <i className="fas fa-file-alt text-primary"></i>
                    </span>
                    <span className="navi-text">View / Edit</span>
                  </a>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Mode",
      text: "Mode",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Pu",
      text: "Pu Location",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.FromCity + ", " + row.FromState;
      },
    },
    {
      dataField: "Del",
      text: "Del Location",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.ToCity + ", " + row.ToState;
      },
    },
    {
      dataField: "TotalPayable",
      text: "Bid Amount",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return currencyFormat(row.TotalPayable);
      },
    },
    {
      dataField: "TotalReceivables",
      text: "Cust Rate",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return currencyFormat(row.TotalReceivables);
      },
    },
  ];
  const columnsBids = [
    {
      dataField: "Id",
      text: "SO#",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <Dropdown className="dropdown-inline position-unset" alignRight>
            <Dropdown.Toggle
              className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
              variant="transparent"
              id="dropdown-toggle-top"
              as={DropdownCustomTo}
            >
              {row.Id}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right topFixedMenu">
              <ul className="navi navi-hover py-2">
                <li className="navi-item">
                  <a
                    href={() => false}
                    className="navi-link"
                    onClick={(e) => {
                      ClickFun(
                        e,
                        `/TransportManagementSystems/SoDetail/`,
                        row.Id
                      );
                    }}
                  >
                    <span className="navi-icon">
                      <i className="fas fa-file-alt text-primary"></i>
                    </span>
                    <span className="navi-text">View / Edit</span>
                  </a>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Mode",
      text: "Mode",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Pu",
      text: "Pu Location",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.FromCity + ", " + row.FromState;
      },
    },
    {
      dataField: "Del",
      text: "Del Location",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.ToCity + ", " + row.ToState;
      },
    },
    {
      dataField: "TotalPayable",
      text: "Accepted Bid",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return currencyFormat(row.TotalPayable);
      },
    },
    {
      dataField: "TotalReceivables",
      text: "Cust Rate",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return currencyFormat(row.TotalReceivables);
      },
    },
  ];
  const columnsNoteCofirmed = [
    {
      dataField: "Id",
      text: "SO#",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <Dropdown className="dropdown-inline position-unset" alignRight>
            <Dropdown.Toggle
              className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
              variant="transparent"
              id="dropdown-toggle-top"
              as={DropdownCustomTo}
            >
              {row.Id}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right topFixedMenu">
              <ul className="navi navi-hover py-2">
                <li className="navi-item">
                  <a
                    href={() => false}
                    className="navi-link"
                    onClick={(e) => {
                      ClickFun(
                        e,
                        `/TransportManagementSystems/SoDetail/`,
                        row.Id
                      );
                    }}
                  >
                    <span className="navi-icon">
                      <i className="fas fa-file-alt text-primary"></i>
                    </span>
                    <span className="navi-text">View / Edit</span>
                  </a>
                </li>
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Mode",
      text: "Mode",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Pu",
      text: "Pu Location",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.FromCity + ", " + row.FromState;
      },
    },
    {
      dataField: "Del",
      text: "Del Location",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.ToCity + ", " + row.ToState;
      },
    },
  ];
  //End Configuration
  async function GetDataByStatus(
    StatusId,
    page,
    sizePerPage,
    sortField,
    sortOrder
  ) {
    switch (StatusId) {
      case "New":
        setloadingDataNew(true);
        break;
      case "Order":
        setloadingDataBooked(true);
        break;
      case "Bids":
        setloadingDataAtShipper(true);
        break;
      case "NotConfirmed":
        setloadingDataNotConfirmed(true);
        break;
      default:
        break;
    }
    let filter = customersUIProps.queryParams.filter;
    var Customers = filter.CustomerId;
    var search = "";

    if (Customers !== "" && Customers !== null && Customers !== undefined) {
      var listCustomers = Customers.map((a) => a.value);
      Customers = "&CustomerId=" + listCustomers.join("&CustomerId=");
    }
    var Modes = filter.ModeId;

    if (Modes !== "" && Modes !== null && Modes !== undefined) {
      var listModes = Modes.map((a) => a.value);
      Modes = "&ModeId=" + listModes.join("&ModeId=");
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${window.$apiurl}/tms/CapacityLoadBoard?search=${search}&userAssignee=${filter.userAssignee
      }&shipDateFilter=${filter.shipDateFilter ?? null}${Customers === null ? "" : Customers}
      ${Modes === null ? "" : Modes
      }&pageNumber=${page}&pageSize=${sizePerPage}&sortField=${sortField}&sortOrder=${sortOrder}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        var dataObj = JSON.parse(result);
        if (StatusId === "New") {
          paginationOptionsNew.totalSize =
            dataObj?.New?.Pagination?.TotalItemCount;
          paginationOptionsNew.sizePerPageList = [
            { text: "4", value: 4 },
            { text: "10", value: 10 },
            { text: "25", value: 25 },
            {
              text: String(dataObj?.New?.Pagination?.TotalItemCount),
              value: dataObj?.New?.Pagination?.TotalItemCount,
            },
          ];
          setNewOrderData(dataObj?.New?.Shipments);
        } else if (StatusId === "Order") {
          //booked
          paginationOptionsBooked.totalSize =
            dataObj?.OrderBids?.Pagination?.TotalItemCount;
          paginationOptionsBooked.sizePerPageList = [
            { text: "4", value: 4 },
            { text: "10", value: 10 },
            { text: "25", value: 25 },
            {
              text: String(dataObj?.OrderBids?.Pagination?.TotalItemCount),
              value: dataObj?.OrderBids?.Pagination?.TotalItemCount,
            },
          ];
          setBookedData(dataObj?.OrderBids?.Shipments);
        } else if (StatusId === "NotConfirmed") {
          //booked
          paginationOptionsNotConfirmed.totalSize =
            dataObj?.NotConfirmed?.Pagination?.TotalItemCount;
          paginationOptionsNotConfirmed.sizePerPageList = [
            { text: "4", value: 4 },
            { text: "10", value: 10 },
            { text: "25", value: 25 },
            {
              text: String(dataObj?.NotConfirmed?.Pagination?.TotalItemCount),
              value: dataObj?.NotConfirmed?.Pagination?.TotalItemCount,
            },
          ];
          setNotConfirmedData(dataObj?.NotConfirmed?.Shipments);
        } else if (StatusId === "Bids") {
          //At shipper
          paginationOptionsAtShipper.totalSize =
            dataObj?.BidsAccepted?.Pagination?.TotalItemCount;
          paginationOptionsAtShipper.sizePerPageList = [
            { text: "4", value: 4 },
            { text: "10", value: 10 },
            { text: "25", value: 25 },
            {
              text: String(dataObj?.BidsAccepted?.Pagination?.TotalItemCount),
              value: dataObj?.BidsAccepted?.Pagination?.TotalItemCount,
            },
          ];
          setAtShipperData(dataObj?.BidsAccepted?.Shipments);
        }
        let values = { ...PaginationProperty };
        values.pageSize = dataObj?.BidsAccepted?.TotalItemCount;
        setPaginationProperty(values);
        switch (StatusId) {
          case "New":
            setloadingDataNew(false);
            break;
          case "Order":
            setloadingDataBooked(false);
            break;
          case "Bids":
            setloadingDataAtShipper(false);
            break;
          case "NotConfirmed":
            setloadingDataNotConfirmed(false);
            break;
          default:
            break;
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function GetDataLoadBoardFunction() {
    setloadingDataAtShipper(true);
    setloadingDataBooked(true);
    setloadingDataNew(true);
    let filter = customersUIProps.queryParams.filter;
    var Customers = filter.CustomerId;
    var search =
      filter.SearchText !== null && filter.SearchText !== undefined
        ? filter.SearchText
        : "";

    if (Customers !== "" && Customers !== null && Customers !== undefined) {
      var listCustomers = Customers.map((a) => a.value);
      Customers = "&CustomerId=" + listCustomers.join("&CustomerId=");
    }
    var Modes = filter.ModeId;

    if (Modes !== "" && Modes !== null && Modes !== undefined) {
      var listModes = Modes.map((a) => a.value);
      Modes = "&ModeId=" + listModes.join("&ModeId=");
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${window.$apiurl
      }/tms/CapacityLoadBoard?search=${search}${Customers}${Modes}&userAssignee=${filter.userAssignee
      }&shipDateFilter=${filter.shipDateFilter ?? null}&pageNumber=${1}&pageSize=${4}`,
      requestOptions
    )
      .then(async (response) => {
        if (response.status === 401) {
          history.push("/error/401");
        }
        return response.text();
      })
      .then((result) => {
        var dataObj = JSON.parse(result);
        let _paginationOptionsNew = { ...paginationOptionsNew };
        _paginationOptionsNew.fromLoading = true;
        _paginationOptionsNew.totalSize =
          dataObj?.New?.Pagination?.TotalItemCount;
        _paginationOptionsNew.sizePerPageList = [
          { text: "4", value: 4 },
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          {
            text: String(dataObj?.New?.Pagination?.TotalItemCount),
            value: dataObj?.New?.Pagination?.TotalItemCount,
          },
        ];
        _paginationOptionsNew.page = 1;
        setpaginationOptionsNew(_paginationOptionsNew);
        //booked
        var _paginationOptionsBooked = { ...paginationOptionsBooked };

        _paginationOptionsBooked.totalSize =
          dataObj?.OrderBids?.Pagination?.TotalItemCount;
        _paginationOptionsBooked.sizePerPageList = [
          { text: "4", value: 4 },
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          {
            text: String(dataObj?.OrderBids?.Pagination?.TotalItemCount),
            value: dataObj?.OrderBids?.Pagination?.TotalItemCount,
          },
        ];
        _paginationOptionsBooked.page = 1;
        setpaginationOptionsBooked(_paginationOptionsBooked);

        //NotConfirmed
        var _paginationOptionsNotConfirmed = {
          ...paginationOptionsNotConfirmed,
        };

        _paginationOptionsNotConfirmed.totalSize =
          dataObj?.NotConfirmed?.Pagination?.TotalItemCount;
        _paginationOptionsNotConfirmed.sizePerPageList = [
          { text: "4", value: 4 },
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          {
            text: String(dataObj?.NotConfirmed?.Pagination?.TotalItemCount),
            value: dataObj?.NotConfirmed?.Pagination?.TotalItemCount,
          },
        ];
        _paginationOptionsNotConfirmed.page = 1;
        setpaginationOptionsNotConfirmed(_paginationOptionsNotConfirmed);
        //At shipper
        var _paginationOptionsAtShipper = { ...paginationOptionsAtShipper };
        _paginationOptionsAtShipper.totalSize =
          dataObj?.BidsAccepted?.Pagination?.TotalItemCount;
        _paginationOptionsAtShipper.sizePerPageList = [
          { text: "4", value: 4 },
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          {
            text: String(dataObj?.BidsAccepted?.Pagination?.TotalItemCount),
            value: dataObj?.BidsAccepted?.Pagination?.TotalItemCount,
          },
        ];
        _paginationOptionsAtShipper.page = 1;
        setpaginationOptionsAtShipper(_paginationOptionsAtShipper);

        setNewOrderData(dataObj?.New?.Shipments);
        setBookedData(dataObj?.OrderBids?.Shipments);
        setAtShipperData(dataObj?.BidsAccepted?.Shipments);
        setNotConfirmedData(dataObj?.NotConfirmed?.Shipments);

        setloadingDataAtShipper(false);
        setloadingDataBooked(false);
        setloadingDataNew(false);
        setloadingDataNotConfirmed(false);
      })
      .catch((error) => console.log("error", error));
  }
  const [BidsInfo, setBidsInfo] = useState({
    ShipmentId: "",
    VendorId: "",
    VendorName: "",
    SpecialReq: "",
    Amount: null,
    PickupAppointmentDate: null,
    DeliveryDate: null,
    IsTendered: false,
  });
  async function GetEmailNotification(SO, VendorId, VendorName, Amount) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/tms/getemail/${SO}?vendorId=${VendorId}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dataemail = JSON.parse(result);
        setEmailAddressList(dataemail?.Emails || []);
        setSubjectData(
          `Mitco Carrier Offer: SO # ${SO} – ${dataemail?.Shipment?.FromCity}/${dataemail?.Shipment?.FromState} to ${dataemail?.Shipment?.ToCity}/${dataemail?.Shipment?.ToState}`
        );
        setBodyData(
          `<p>Hello ${VendorName},</p><p>Please see below load request and reply back confirming acceptance or rejection, thank you!</p><p>&nbsp;</p><p><strong>Shipper</strong>: ${dataemail?.Shipment?.FromCity !== null
            ? dataemail?.Shipment?.FromCity
            : ""
          }/${dataemail?.Shipment?.FromState !== null
            ? dataemail?.Shipment?.FromState
            : ""
          }</p><p><strong>Receiver</strong>: ${dataemail?.Shipment?.ToCity !== null
            ? dataemail?.Shipment?.ToCity
            : ""
          }/${dataemail?.Shipment?.ToState !== null
            ? dataemail?.Shipment?.ToState
            : ""
          }</p><p><strong>Ship Date Range</strong>: ${dataemail?.Shipment?.ShipmentDate !== null
            ? moment(dataemail?.Shipment?.ShipmentDate).format("YYYY-MM-DD")
            : ""
          } to ${dataemail?.Shipment?.ReqShipDate !== null
            ? moment(dataemail?.Shipment?.ReqShipDate).format("YYYY-MM-DD")
            : ""
          }</p><p><strong>MABD</strong>: ${dataemail?.Shipment?.MustArriveByDate !== null
            ? moment(dataemail?.Shipment?.MustArriveByDate).format(
              "YYYY-MM-DD"
            )
            : ""
          }</p><p><strong>Commodity</strong>: ${dataemail?.Shipment?.Commodities !== undefined &&
            dataemail?.Shipment?.Commodities !== null
            ? dataemail?.Shipment?.Commodities?.join(", ")
            : ""
          }</p><p><strong>Pallets</strong>: ${dataemail?.Shipment?.Pallets !== null
            ? dataemail?.Shipment?.Pallets
            : ""
          }</p><p><strong>Pieces</strong>: ${dataemail?.Shipment?.Pieces !== null
            ? dataemail?.Shipment?.Pieces
            : ""
          }</p><p><strong>Weight</strong>: ${dataemail?.Shipment?.Weight !== null
            ? dataemail?.Shipment?.Weight
            : ""
          }</p><p><strong>Special Requirements</strong>: [${dataemail?.Shipment?.SpecialReq !== null
            ? dataemail?.Shipment?.SpecialReq
            : ""
          }]</p><p>&nbsp;</p><p><strong>Requested Rate</strong>: ${Amount}</p><p>&nbsp;</p><p>Thank you!</p>`
        );
        setshowModalSend(true);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    GetDataLoadBoardFunction();
  }, [customersUIProps.queryParams]);
  //eXPEND Row
  useEffect(() => {
    getDataViewModel();
  }, []);
  const [LoadBoardsList, setLoadBoardsList] = useState([]);

  function getDataViewModel() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/CapacityLoadBoard/GetViewModel`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var obj = JSON.parse(result);
        setLoadBoardsList(obj);
        window.VendorListCapasityLB = obj?.Vendors || [];
      })
      .catch((error) => console.log("error", error));
  }

  async function SendBidsNotification() {
    if (ToData === null) {
      Notification("error", "Please fill email Info");
      return;
    }
    // server request for deleting product by id
    setisLoadingSend(true);
    var data = {};
    if (window.SendDispatchShipmentsList !== undefined) {
      data = {
        Ids: window.SendDispatchShipmentsList?.map(function (item) {
          return item.id;
        }),
        Emails: ToData,
        Subject: SubjectData,
        Body: BodyData,
        IncludeCc: includCCData,
        Attachments: AttachmentData,
        Bol: false,
        OutboundDispatch: false,
        ShipmentManifest: false,
      };
    } else {
      data = {
        Emails: ToData,
        Subject: SubjectData,
        Body: BodyData,
        IncludeCc: includCCData,
        Attachments: AttachmentData,
        Bol: false,
        OutboundDispatch: false,
        ShipmentManifest: false,
      };
    }

    var raw = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    };
    var url = "";
    if (window.SendDispatchShipmentsList !== undefined) {
      url = "/shipmentsmanagement/sendMultiEmail";
    } else {
      url = `/shipments/sendEmail/` + BidsInfo.ShipmentId;
    }
    let response = await fetch(`${window.$apiurl}${url}`, requestOptions);
    if (response.status === 200) {
      Notification(
        "success",
        "Email sent successfuly for Shipment ID " + BidsInfo.ShipmentId
      );
      setToData(null);
      setToDataDefeault(null);
      setSubjectData(null);
      setBodyData("");
      setincludCCData(false);
      setAttachmentData([]);
    } else {
      Notification("error", "invalid data");
    }
    setBidsInfo({
      ShipmentId: "",
      VendorId: "",
      VendorName: "",
      SpecialReq: "",
      Amount: null,
      PickupAppointmentDate: null,
      DeliveryDate: null,
      IsTendered: false,
    });
    setisLoadingSend(false);
    setshowModalSend(false);
  }

  const expandRow = {
    renderer: (row) => {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Carrier</TableCell>
              <TableCell>Bid Amount</TableCell>
              <TableCell>Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row?.OrderBids?.map((item, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="customers-delete-tooltip">
                        Accept Bid
                      </Tooltip>
                    }
                  >
                    <a
                      href={() => false}
                      className="btn btn-icon btn-light btn-hover-success btn-sm"
                      onClick={async () => {
                        setLoadingPost(true);

                        var myHeaders = new Headers();
                        myHeaders.append(
                          "Authorization",
                          `Bearer ${db.read().getState().Token}`
                        );
                        myHeaders.append("Content-Type", "application/json");
                        var raw = JSON.stringify({
                          Id: item.Id,
                          Value: true,
                          Type: 1,
                        });
                        var requestOptions = {
                          method: "POST",
                          headers: myHeaders,
                          body: raw,
                          redirect: "follow",
                        };

                        fetch(`${window.$apiurl}/tms/BidStatus`, requestOptions)
                          .then(async (response) => {
                            const statusCode = await response.status;
                            const contentType = response.headers.get(
                              "content-type"
                            );
                            if (
                              contentType &&
                              contentType.indexOf("application/json") !== -1
                            ) {
                              return response
                                .json()
                                .then((data) => [statusCode, data]);
                            } else {
                              return response
                                .text()
                                .then((text) => [statusCode, { Message: "" }]);
                            }
                          })
                          .then((result) => {
                            if (result[0] === 200) {
                              Notification("success", `Bid Add successfully`);
                              GetDataLoadBoardFunction();
                              setLoadingPost(false);
                            } else {
                              if (
                                result[1].ModelState !== null &&
                                result[1].ModelState !== undefined &&
                                result[1].ModelState !== ""
                              ) {
                                let modelState = result[1].ModelState;
                                if (modelState)
                                  Object.keys(modelState).forEach(function (k) {
                                    modelState[k].forEach((element) => {
                                      Notification("errorClick", element);
                                    });
                                  });
                              } else if (
                                result[1].Message !== null &&
                                result[1].Message !== undefined &&
                                result[1].Message !== ""
                              ) {
                                Notification("errorClick", result[1].Message);
                              } else {
                                Notification("errorClick", "invalid request");
                              }
                              setLoadingPost(false);
                            }
                          })
                          .catch((error) => {
                            console.log("error", error);
                            Notification("errorClick", "invalid request");
                            setLoadingPost(false);
                          });
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-success">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Done-circle.svg"
                          )}
                        />
                      </span>
                    </a>
                  </OverlayTrigger>
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.Carrier}
                </TableCell>
                <TableCell component="th" scope="row">
                  {currencyFormat(item.BidAmount)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.Note}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    },
    expanded: expendedDefault,
    expandByColumnOnly: true,
  };

  return (
    <>
      <Modal
        size="xl"
        show={showModalSend}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Carrier Offer Email
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              setBidsInfo({
                ShipmentId: "",
                VendorId: "",
                VendorName: "",

                SpecialReq: "",
                Amount: null,
                PickupAppointmentDate: null,
                DeliveryDate: null,
                IsTendered: false,
              });
              setshowModalSend(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <p className="mb-3">To: </p>
                {EmailAddressList && (
                  <CreatableSelect
                    isMulti
                    name="To"
                    id="To"
                    value={ToDataDefeault}
                    options={EmailAddressList.map((a) => {
                      return { value: a.Email, label: a.Email };
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                      var obj = [];
                      var objDefeault = [];
                      if (e) {
                        e.map((item) => {
                          obj.push({
                            Email: item.value,
                            Name: item.value,
                          });
                          objDefeault.push({
                            value: item.value,
                            label: item.value,
                          });
                          return null;
                        });
                      }
                      setToDataDefeault(objDefeault);
                      setToData(obj);
                    }}
                  />
                )}

                <CheckboxLarge
                  Size="12px"
                  name="includCC"
                  text="Include me on CC"
                  onChange={(event) => {
                    setincludCCData(event.target.checked);
                  }}
                />
              </div>
              <div className="col-lg-12 mt-5">
                <p className="mb-3">Subject: </p>
                <input
                  name="Subject"
                  className="form-control"
                  placeholder="Subject"
                  value={SubjectData}
                  onChange={(event) => {
                    setSubjectData(event.target.value);
                  }}
                />
              </div>
              <div className="col-lg-12 mt-5">
                <p className="mb-3">Body: </p>

                <CKEditor
                  name="Body"
                  Id="Body"
                  label="Body"
                  editor={ClassicEditor}
                  config={{
                    removePlugins: [
                      "Image",
                      "ImageCaption",
                      "ImageStyle",
                      "ImageToolbar",
                      "ImageUpload",
                      "MediaEmbed",
                    ],
                  }}
                  data={BodyData}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    setBodyData(data);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                setBidsInfo({
                  ShipmentId: "",
                  VendorId: "",
                  VendorName: "",

                  SpecialReq: "",
                  Amount: null,
                  PickupAppointmentDate: null,
                  DeliveryDate: null,
                  IsTendered: false,
                });
                setshowModalSend(false);
              }}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={SendBidsNotification}
              disabled={isLoadingSend}
              className="btn btn-delete btn-primary"
            >
              Send Email
              {isLoadingSend && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={ShowAddOfferModal}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Add Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <div className="col-md-12">
              <label for="exampleTextarea">Carrier</label>

              <Select
                isClearable
                name="VendorId"
                id="VendorId"
                label="Carrier Name"
                inputId="react-select-single"
                options={LoadBoardsList?.Vendors?.map((a) => {
                  return { value: a.Id, label: a.Code + "-" + a.Name };
                })}
                onChange={(e) => {
                  if (e !== null) {
                    let value = { ...BidsInfo };
                    value.VendorId = e?.value;
                    value.VendorName = e?.label;
                    setBidsInfo(value);
                  }
                }}
              />
            </div>

            <div className="col-md-6 mt-5">
              <label for="exampleTextarea">Special Req</label>
              <input
                type="text"
                className="form-control"
                name="SpecialReq"
                id="SpecialReq"
                onChange={(e) => {
                  let value = { ...BidsInfo };
                  value.SpecialReq = e?.target.value;
                  setBidsInfo(value);
                }}
              />
            </div>
            <div className="col-md-6 mt-5">
              <label for="exampleTextarea">Rate</label>
              <input
                type="number"
                className="form-control"
                name="Amount"
                id="Amount"
                onChange={(e) => {
                  let value = { ...BidsInfo };
                  value.Amount = e?.target.value;
                  setBidsInfo(value);
                }}
              />
            </div>
            <div className="col-md-6 mt-5">
              <label for="exampleTextarea">PU Date</label>

              <input
                className="form-control"
                id="PickupAppointmentDate"
                name="PickupAppointmentDate"
                type="date"
                onChange={(e) => {
                  let value = { ...BidsInfo };
                  value.PickupAppointmentDate = e?.target.value;
                  setBidsInfo(value);
                }}
              />
            </div>
            <div className="col-md-6 mt-5">
              <label for="exampleTextarea">DEL Date</label>

              <input
                className="form-control"
                id="DeliveryDate"
                name="DeliveryDate"
                type="date"
                onChange={(e) => {
                  let value = { ...BidsInfo };
                  value.DeliveryDate = e?.target.value;
                  setBidsInfo(value);
                }}
              />
            </div>

            <div className="col-md-6 mt-5">
              <CheckboxLargeWB
                className="font-size-md"
                text="Offer to Carrier"
                color="success"
                checked={BidsInfo.IsTendered}
                onChange={(e) => {
                  let value = { ...BidsInfo };
                  value.IsTendered = e?.target.checked;
                  setBidsInfo(value);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setShowAddOfferModal(false);
              setBidsInfo({
                ShipmentId: "",
                VendorId: "",
                VendorName: "",

                SpecialReq: "",
                Amount: null,
                PickupAppointmentDate: null,
                DeliveryDate: null,
                IsTendered: false,
              });
            }}
            className="btn btn-outline btn-elevate"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-elevate"
            onClick={() => {
              if (
                BidsInfo.VendorId === null ||
                BidsInfo.VendorId === "" ||
                BidsInfo.VendorId === undefined
              ) {
                Notification("errorClick", "please select carrier");
                return;
              }
              if (
                BidsInfo.PickupAppointmentDate === null ||
                BidsInfo.PickupAppointmentDate === "" ||
                BidsInfo.PickupAppointmentDate === undefined
              ) {
                Notification(
                  "errorClick",
                  "please Fill Pickup Appointment Date"
                );
                return;
              }
              if (
                BidsInfo.DeliveryDate === null ||
                BidsInfo.DeliveryDate === "" ||
                BidsInfo.DeliveryDate === undefined
              ) {
                Notification("errorClick", "please Fill Delivery Date");
                return;
              }
              //return;

              setLoadingPost(true);
              var myHeaders = new Headers();
              myHeaders.append(
                "Authorization",
                `Bearer ${db.read().getState().Token}`
              );
              myHeaders.append("Content-Type", "application/json");

              var raw = JSON.stringify(BidsInfo);

              var requestOptions = {
                method: "Post",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
              };

              fetch(`${window.$apiurl}/tms/PostBidNew`, requestOptions)
                .then(async (response) => {
                  const statusCode = await response.status;
                  const contentType = response.headers.get("content-type");
                  if (
                    contentType &&
                    contentType.indexOf("application/json") !== -1
                  ) {
                    return response.json().then((data) => [statusCode, data]);
                  } else {
                    return response
                      .text()
                      .then((text) => [statusCode, { Message: "" }]);
                  }
                })
                .then((result) => {
                  if (result[0] === 200) {
                    Notification(
                      "success",
                      "Bid has been Created successfully"
                    );
                    if (BidsInfo.IsTendered === true) {
                      GetEmailNotification(
                        BidsInfo.ShipmentId,
                        BidsInfo.VendorId,
                        BidsInfo.VendorName,
                        BidsInfo.Amount
                      );
                    } else {
                      setBidsInfo({
                        ShipmentId: "",
                        VendorId: "",
                        VendorName: "",

                        SpecialReq: "",
                        Amount: null,
                        PickupAppointmentDate: null,
                        DeliveryDate: null,
                        IsTendered: false,
                      });
                    }
                    GetDataLoadBoardFunction();
                    setLoadingPost(false);
                    setShowAddOfferModal(false);
                  } else {
                    if (
                      result[1].ModelState !== null &&
                      result[1].ModelState !== undefined &&
                      result[1].ModelState !== ""
                    ) {
                      let modelState = result[1].ModelState;
                      if (modelState)
                        Object.keys(modelState).forEach(function (k) {
                          modelState[k].forEach((element) => {
                            Notification("errorClick", element);
                          });
                        });
                    } else if (
                      result[1].Message !== null &&
                      result[1].Message !== undefined &&
                      result[1].Message !== ""
                    ) {
                      Notification("errorClick", result[1].Message);
                    } else {
                      Notification("errorClick", "Bad Request");
                    }
                  }
                  setLoadingPost(false);
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }}
          >
            Save
            {LoadingPost && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
      <Helmet>
        <title>eMPower | Capacity Board</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <Card>
        <CardHeader title="Capacity Board">
          <button
            type="button"
            disabled={
              loadingDataNew || loadingDataBooked || loadingDataAtShipper
            }
            onClick={GetDataLoadBoardFunction}
            className="btn btn-success mt-5"
            style={{ height: "37px" }}
          >
            Refresh
          </button>
        </CardHeader>
        <CardBody>
          <div className="d-flex flex-column">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="d-flex flex-wrap">
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-info btn-sm mb-4"
                  onClick={() => {
                    setShowData("");
                  }}
                >
                  <span
                    className={`svg-icon svg-icon-md svg-icon-info
                 `}
                  >
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")}
                    />
                  </span>
                </a>

                <div className="col-xl-11 col-lg-11 col-md-11 col-11 ml-auto">
                  <div className="row mb-5">

                    {FilterTable.map(({ name, color }) => (
                      <div className="col-xl-3 col-lg-3 col-md-4 mb-4 pr-0">
                        <p
                          className={`mb-0 py-2 px-4 text-center font-size-sm  bg-${color} ${name === "Dispatched" ? "text-dark" : "text-white"
                            }`}
                          onClick={() => {
                            setShowData(name);
                          }}
                        >
                          {name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12">
              <CustomersFilter
                GetDataLoadBoardFunction={GetDataLoadBoardFunction}
                loadingDataAtShipper={loadingDataAtShipper}
                loadingDataBooked={loadingDataBooked}
                loadingDataNew={loadingDataNew}
                LoadBoardsList={LoadBoardsList}
              />
            </div>
          </div>
          {customersUIProps.ids.length > 0 && <CustomersGrouping />}

          <Row>
            {(ShowData === "New Assignments" || ShowData === "") && (
              <>
                <Col
                  className={`mb-4 border-bottom ${ShowData === "New Assignments" ? "col-12" : "col-xl-4"
                    }`}
                >
                  <p
                    className={`mb-0 py-2 px-4 text-center font-size-h6 bg-primary text-white font-weight-bolder`}
                  >
                    New Assignments
                  </p>
                  {loadingDataNew === true ? (
                    <ProgressBar
                      variant="success"
                      animated
                      now={100}
                      style={{ height: "6px", width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                  <PaginationProvider
                    pagination={paginationFactory(paginationOptionsNew)}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <Pagination paginationProps={paginationProps}>
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center"
                            bootstrap4
                            remote
                            id="New"
                            keyField="Id"
                            noDataIndication={
                              <h4 style={{ textAlign: "center" }}>
                                There is no data available at the moment.
                              </h4>
                            }
                            data={NewOrderData}
                            columns={columnsNew}
                            defaultSorted={[{ dataField: "Id", order: "desc" }]}
                            onTableChange={GetHandlerTableChangeNew}
                            {...paginationTableProps}
                          >
                            <PleaseWaitMessage entities={NewOrderData} />
                            <NoRecordsFoundMessage entities={NewOrderData} />
                          </BootstrapTable>
                        </Pagination>
                      );
                    }}
                  </PaginationProvider>
                </Col>
              </>
            )}
            {(ShowData === "Order Bids" || ShowData === "") && (
              <>
                <Col
                  className={`mb-4 border-bottom ${ShowData === "Order Bids" ? "col-12" : "col-xl-4"
                    }`}
                >
                  <p
                    className={`mb-0 py-2 px-4 text-center font-size-h6 bg-info text-white font-weight-bolder`}
                  >
                    Order Bids
                  </p>
                  {loadingDataBooked === true ? (
                    <ProgressBar
                      variant="success"
                      animated
                      now={100}
                      style={{ height: "6px", width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                  <PaginationProvider
                    pagination={paginationFactory(paginationOptionsBooked)}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <Pagination paginationProps={paginationProps}>
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center"
                            bootstrap4
                            remote
                            id="Booked"
                            keyField="Id"
                            noDataIndication={
                              <h4 style={{ textAlign: "center" }}>
                                There is no data available at the moment.
                              </h4>
                            }
                            data={BookedData}
                            expandRow={expandRow}
                            columns={columnsOrder}
                            defaultSorted={[{ dataField: "Id", order: "desc" }]}
                            onTableChange={GetHandlerTableChangeBooked}
                            {...paginationTableProps}
                          >
                            <PleaseWaitMessage entities={BookedData} />
                            <NoRecordsFoundMessage entities={BookedData} />
                          </BootstrapTable>
                        </Pagination>
                      );
                    }}
                  </PaginationProvider>
                </Col>
              </>
            )}
            {(ShowData === "Bids Accepted" || ShowData === "") && (
              <>
                <Col
                  className={`mb-4 border-bottom ${ShowData === "Bids Accepted" ? "col-12" : "col-xl-4"
                    }`}
                >
                  <p
                    className={`mb-0 py-2 px-4 text-center font-size-h6 bg-success text-white font-weight-bolder`}
                  >
                    Bids Accepted
                  </p>
                  {loadingDataAtShipper === true ? (
                    <ProgressBar
                      variant="success"
                      animated
                      now={100}
                      style={{ height: "6px", width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                  <PaginationProvider
                    pagination={paginationFactory(paginationOptionsAtShipper)}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <Pagination paginationProps={paginationProps}>
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center"
                            bootstrap4
                            remote
                            id="AtShipper"
                            keyField="Id"
                            noDataIndication={
                              <h4 style={{ textAlign: "center" }}>
                                There is no data available at the moment.
                              </h4>
                            }
                            data={AtShipperData}
                            columns={columnsBids}
                            defaultSorted={[{ dataField: "Id", order: "desc" }]}
                            onTableChange={GetHandlerTableChangeAtShipper}
                            {...paginationTableProps}
                          >
                            <PleaseWaitMessage entities={AtShipperData} />
                            <NoRecordsFoundMessage entities={AtShipperData} />
                          </BootstrapTable>
                        </Pagination>
                      );
                    }}
                  </PaginationProvider>
                </Col>
              </>
            )}
            {(ShowData === "Booked - Not Confirmed" || ShowData === "") && (
              <>
                <Col
                  className={`mb-4 border-bottom ${ShowData === "Booked - Not Confirmed"
                    ? "mx-auto"
                    : "mx-auto"
                    }`}
                  xl="4"
                >
                  <p
                    className={`mb-0 py-2 px-4 text-center font-size-h6 bg-warning text-white font-weight-bolder`}
                  >
                    Booked - Not Confirmed
                  </p>
                  {loadingDataNotConfirmed === true ? (
                    <ProgressBar
                      variant="success"
                      animated
                      now={100}
                      style={{ height: "6px", width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                  <PaginationProvider
                    pagination={paginationFactory(
                      paginationOptionsNotConfirmed
                    )}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <Pagination paginationProps={paginationProps}>
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center"
                            bootstrap4
                            remote
                            id="NotConfirmed"
                            keyField="Id"
                            noDataIndication={
                              <h4 style={{ textAlign: "center" }}>
                                There is no data available at the moment.
                              </h4>
                            }
                            data={NotConfirmedData}
                            columns={columnsNoteCofirmed}
                            defaultSorted={[{ dataField: "Id", order: "desc" }]}
                            onTableChange={GetHandlerTableChangeNotConfirmed}
                            {...paginationTableProps}
                          >
                            <PleaseWaitMessage entities={NotConfirmedData} />
                            <NoRecordsFoundMessage
                              entities={NotConfirmedData}
                            />
                          </BootstrapTable>
                        </Pagination>
                      );
                    }}
                  </PaginationProvider>
                </Col>
              </>
            )}
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
