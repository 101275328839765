import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { DashboardScentsy } from "../_metronic/_partials/dashboards/DashboardScentsy";
import { ManageAdresses, ManageContacts } from "./pages/Address/index";
import {
  AddCarrierServices, CustomLabelPage, EditCarrierServices, FreezeTransactionsPage, LocationBillingPage, ManageAttachedFiles, ManageCarrierAccountsPage, ManageCarriersPage, ManageCompanyInfo, ManageEquipmentsTypePage, ManageLSPCarrierPage, ManageModesPage, ManageServiceLevelsPage, ManageServicesPage, ManageSmartParcelCarriersPage, ManageTPLScalesPage, MovableUnitsPage, RecurringStorageChargesPage, ServiceManageCarriersPage
} from "./pages/Admin/index";
/* import { ManageUsersPage } from "./pages/ManageUsers/index"; */
import {
  BillingRatesExport, billingWizard, ClassicBOLMessages,
  ClassicBOLTemplates,
  CustomerNotifs,
  CustomerNotifsDetails, ErrorLog, ImportContacts,
  ImportZoneBilling,
  ManageBarcodes, ManageConnections, ManageCustomersPage, ManageRatesPage,
  ManageRatesSelect, PackingSlipTemplates, SmartParcelSettings, SystemCalculated, TransactionBilling, TransactionBillingSelect, TransactionBillingShippingPage, ZoneBillingPage
} from "./pages/Customers/index";
import { ManageEquipments } from "./pages/Equipments/index";
import { ImportOrderItems } from "./pages/ImportOrderItems/ImportOrderItems";
import { ImportReceiptItems } from "./pages/ImportReceiptItems/ImportReceiptItems";
import { ImportUsersPage } from "./pages/ImportUsers/ImportUsers";
import {
  BatchItemUpdateItems,
  CancelAssemblyItems,
  ImportAssembliesItems,
  ImportItems,
  ItemAliasItems,
  ManageAssembliesItems,
  ManageClassificationsItems,
  ManageCommoditiesItems,
  ManageItemsPage,
  ManageStorageRatesItems
} from "./pages/Items/Index";
import {
  /* FindOrders, */
  ImportOrders, OrderImportPreferences, PackagingOptions, ReprintUPSLabels, ShippingSetups
} from "./pages/Orders/Index";
import {
  CreatePurchaseOrder,
  EditPurchaseOrder,
  ImportPurchaseOrder,
  ManagePurchaseOrders, PurchaseOrderProgressReport, PurchaseOrderReport, PurchaseOrderReports
} from "./pages/PurchaseOrders/index";
import { Quoting } from "./pages/Rating/Quoting";
import { Rating } from "./pages/Rating/Rating";
import {
  AdjustReceiptChargesPage,
  // FindReceipts,
  ImportReceiptsPage,
  ReceiptImportPreferencesPage
} from "./pages/Receipts/index";
import { TransloadShipmentManifest } from "./pages/shipment/GlobalTransload/AssigntoOutbound2/TransloadShipmentManifest";
import { TransloadPalletManifest } from "./pages/shipment/GlobalTransload/AssignToWareHouse/TransloadPalletManifest";
import {
  AssignPallettoLoad, AssigntoOutbound, AssigntoOutboundV2,
  AssigntoOutboundV3, AssigntoPallet, GlobalTransload,
  PoToLocation,
  PoToLocationV2, UpdateStatus
} from "./pages/shipment/index";
import { ManageUOM } from "./pages/Unitofmeasures/index";
import {
  Importlocations, Managelocations, ManageWarehousePages, MassUpdateLocations
} from "./pages/Warehouse/index";

const adapter = new LocalStorage("db");
const db = low(adapter);
const ECommercePage = lazy(() =>
  import("./modules/eMPower/pages/eCommercePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {db.read().getState().User.IsCustomer === true &&
        db.read().getState().User.Customers[0]?.Name === "Lulus" ? (
          <Redirect exact from="/" to="/Dashboards/LulusDashboard" />
        ) : db.read().getState().User.IsCustomer ? (
          <Redirect exact from="/" to="/HomePage" />
        ) : (
          <Redirect exact from="/" to="/CustomerDashboard/GlobalDashboard" />
        )
        /* Redirect from root URL to /dashboard. */
        }
        <ContentRoute
          path="/transload/AssigntoOutbound"
          component={AssigntoOutbound}
        />
        <ContentRoute
          path="/transload/AssignPallettoLoad"
          component={AssignPallettoLoad}
        />
        <ContentRoute
          path="/transload/AssignToOutboundv2"
          component={AssigntoOutboundV2}
        />
        <ContentRoute
          path="/transload/AssigntoOutboundV3"
          component={AssigntoOutboundV3}
        />
        <ContentRoute path="/GlobalTransload" component={GlobalTransload} />
        <ContentRoute path="/PoToLocation" component={PoToLocation} />
        <ContentRoute path="/PoToLocationScan" component={PoToLocationV2} />
        <ContentRoute
          path="/transload/AssigntoPallet"
          component={AssigntoPallet}
        />
        <ContentRoute
          path="/ManageWarehouseP"
          component={ManageWarehousePages}
        />
        <ContentRoute path="/Managelocations" component={Managelocations} />
        <ContentRoute path="/Importlocations" component={Importlocations} />
        <ContentRoute
          path="/TransloadPalletManifest"
          component={TransloadPalletManifest}
        />
        <ContentRoute
          path="/TransloadShipmentManifest"
          component={TransloadShipmentManifest}
        />
        <ContentRoute
          path="/MassUpdateLocations"
          component={MassUpdateLocations}
        />
        {/* shipment Pages */}
        <ContentRoute path="/UpdateStatus" component={UpdateStatus} />
        {/* Unit of measures Pages */}
        <ContentRoute path="/ManageUOMPage" component={ManageUOM} />
        {/* Adresse Pages */}
        <ContentRoute path="/ManageAddressPage" component={ManageAdresses} />
        <ContentRoute path="/ManageContactsPage" component={ManageContacts} />
        <ContentRoute
          path="/ManageEquipmentsPage"
          component={ManageEquipments}
        />
        {/* Receipts Pages */}
        <ContentRoute
          path="/AdjustReceiptCharges"
          component={AdjustReceiptChargesPage}
        />
        {/* <ContentRoute path="/CreateReceipts" component={CreateReceiptsPage} /> */}
        {/* <ContentRoute path="/FindReceipts" component={FindReceipts} /> */}
        <ContentRoute path="/Receipts/Import" component={ImportReceiptsPage} />
        <ContentRoute
          path="/ReceiptImportPreferences"
          component={ReceiptImportPreferencesPage}
        />
        <ContentRoute
          path="/transload/ReceiptsManagement/ImportItems"
          component={ImportReceiptItems}
        />
        <ContentRoute
          path="/transload/FindOrders/ImportItems"
          component={ImportOrderItems}
        />
        <ContentRoute
          path="/ReceiptsManagement/ImportItems/:id/:cs"
          component={ImportReceiptItems}
        />
        <ContentRoute
          path="/ReceiptsManagement/ImportItems"
          component={ImportReceiptItems}
        />
        <ContentRoute path="/users/Importusers" component={ImportUsersPage} />
        {/* <ContentRoute path="/Receipts/Import" component={ImportReceiptsPage} /> */}
        {/* Purchase Orders Pages */}
        <ContentRoute
          path="/PurchaseOrders/Import"
          component={ImportPurchaseOrder}
        />
        <ContentRoute
          path="/PurchaseOrders/Add"
          component={CreatePurchaseOrder}
        />
        <ContentRoute
          path="/PurchaseOrders/Manage"
          component={ManagePurchaseOrders}
        />
        <ContentRoute
          path="/PurchaseOrders/:id/Edit"
          component={EditPurchaseOrder}
        />
        <ContentRoute
          path="/PurchaseOrderReports"
          component={PurchaseOrderReports}
        />
        <ContentRoute
          path="/PurchaseOrderReport"
          component={PurchaseOrderReport}
        />
        <ContentRoute
          path="/PurchaseOrderProgressReport"
          component={PurchaseOrderProgressReport}
        />
        <ContentRoute
          path="/MiffDashboard/ScentsyDashboard"
          component={DashboardScentsy}
        />
        {/* Admin Pages */}
        <ContentRoute
          path="/Admin/ManageCarrierPage"
          component={ManageLSPCarrierPage}
        />
        <ContentRoute path="/Admin/CompanyInfo" component={ManageCompanyInfo} />
        <ContentRoute
          path="/LocationBillingPage"
          component={LocationBillingPage}
        />
        <ContentRoute
          path="/RecurringStorageChargesPage"
          component={RecurringStorageChargesPage}
        />
        <ContentRoute path="/MovableUnitsPage" component={MovableUnitsPage} />
        <ContentRoute
          path="/ManageServicesPage"
          component={ManageServicesPage}
        />
        <ContentRoute path="/ManageModesPage" component={ManageModesPage} />
        <ContentRoute
          path="/ManageEquipmentsTypePage"
          component={ManageEquipmentsTypePage}
        />
        <ContentRoute
          path="/ManageServiceLevelsPage"
          component={ManageServiceLevelsPage}
        />
        <ContentRoute
          path="/ManageAttachedFiles"
          component={ManageAttachedFiles}
        />
        <ContentRoute
          path="/ManageSmartParcelCarriersPage"
          component={ManageSmartParcelCarriersPage}
        />
        <ContentRoute
          path="/ManageCarriersPage"
          component={ManageCarriersPage}
        />
        <ContentRoute
          path="/FreezeTransactionsPage"
          component={FreezeTransactionsPage}
        />
        <ContentRoute
          path="/ManageCarrierAccountsPage"
          component={ManageCarrierAccountsPage}
        />
        <ContentRoute path="/CustomLabelPage" component={CustomLabelPage} />
        <ContentRoute
          path="/ManageTPLScalesPage"
          component={ManageTPLScalesPage}
        />
        <ContentRoute
          path="/AddCarrierServices"
          component={AddCarrierServices}
        />
        <ContentRoute
          path="/EditCarrierServices"
          component={EditCarrierServices}
        />
        <ContentRoute
          path="/ServiceManageCarriers"
          component={ServiceManageCarriersPage}
        />
        {/* Customers Pages */}
        <ContentRoute
          path="/ManageCustomersP"
          component={ManageCustomersPage}
        />
        <ContentRoute path="/ManageRatesSelect" component={ManageRatesSelect} />
        <ContentRoute path="/ManageRates" component={ManageRatesPage} />
        <ContentRoute path="/SystemCalculated" component={SystemCalculated} />
        <ContentRoute path="/billingWizard" component={billingWizard} />
        <ContentRoute path="/Rating" component={Rating} />
        <ContentRoute path="/QuotingMenu" component={Quoting} />
        <ContentRoute path="/ImportContacts" component={ImportContacts} />
        <ContentRoute path="/CustomerNotifs" component={CustomerNotifs} />
        <ContentRoute
          path="/CustomerNotifsDetails"
          component={CustomerNotifsDetails}
        />
        <ContentRoute
          path="/TransactionBillingSelect"
          component={TransactionBillingSelect}
        />
        <ContentRoute
          path="/TransactionBilling/:id"
          component={TransactionBilling}
        />
        <ContentRoute path="/ImportZoneBilling" component={ImportZoneBilling} />
        <ContentRoute
          path="/TransactionBillingShippingPage"
          component={TransactionBillingShippingPage}
        />
        <ContentRoute path="/ManageBarcodes" component={ManageBarcodes} />
        <ContentRoute
          path="/BillingRatesExport"
          component={BillingRatesExport}
        />
        <ContentRoute path="/ZoneBillingPage" component={ZoneBillingPage} />
        <ContentRoute
          path="/SmartParcelSettings"
          component={SmartParcelSettings}
        />
        <ContentRoute path="/ManageConnections" component={ManageConnections} />
        <ContentRoute
          path="/ClassicBOLMessages"
          component={ClassicBOLMessages}
        />
        <ContentRoute
          path="/PackingSlipTemplates"
          component={PackingSlipTemplates}
        />
        <ContentRoute path="/ErrorLog" component={ErrorLog} />
        <ContentRoute
          path="/ClassicBOLTemplates"
          component={ClassicBOLTemplates}
        />
        {/* items Pages */}
        <ContentRoute
          path="/BatchItemUpdateItems"
          component={BatchItemUpdateItems}
        />
        <ContentRoute
          path="/CancelAssemblyItems"
          component={CancelAssemblyItems}
        />
        <ContentRoute
          path="/ImportAssembliesItems"
          component={ImportAssembliesItems}
        />
        <ContentRoute path="/ImportItems" component={ImportItems} />
        <ContentRoute path="/ManageItemsPage" component={ManageItemsPage} />
        <ContentRoute path="/ItemAliasItems" component={ItemAliasItems} />
        <ContentRoute
          path="/ManageClassificationsItems"
          component={ManageClassificationsItems}
        />
        {/* Tracking */}
        <ContentRoute
          path="/ManageCommoditiesItems"
          component={ManageCommoditiesItems}
        />
        <ContentRoute
          path="/ManageAssembliesItems"
          component={ManageAssembliesItems}
        />
        <ContentRoute path="/ShippingSetups" component={ShippingSetups} />
        {/*  <ContentRoute
          path="/FindOrders"
          component={FindOrders}
        /> */}
        <ContentRoute path="/ImportOrders" component={ImportOrders} />
        <ContentRoute path="/PackagingOptions" component={PackagingOptions} />
        <ContentRoute
          path="/OrderImportPreferences"
          component={OrderImportPreferences}
        />
        <ContentRoute path="/ReprintUPSLabels" component={ReprintUPSLabels} />
        <ContentRoute
          path="/ManageStorageRatesItems"
          component={ManageStorageRatesItems}
        />
        <Route path="/" component={ECommercePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
