import MockUtils from "../mock.utilsDynamic";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import $ from "jquery";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

const weight = 0.45359237;
const cube = 0.0283168;

let remarkTableMock = [];

export default function mockTransloadReceiving(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetModelsFirst(id) {
    return await fetch(`${window.$apiurl}/transload/receipts/${id}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        let a = 0;
        let b = 0;
        res[1]["LineItems"].forEach((x) => {
          if (x.Cube) {
            if (x.WeightType === "KGS") a += x.Weight;
            if (x.WeightType === "LBS") a += x.Weight * weight;
          }
          if (x.Weight) {
            if (x.CubeType === "CBM") b += x.Cube;
            if (x.CubeType === "CFT") b += x.Cube * cube;
          }
        });
        window.Cube = a.toFixed(2);
        window.Weight = b.toFixed(2);
        window.Cube2 = (a * cube).toFixed(2);
        window.Weight2 = (b * weight).toFixed(2);
        return ChangeId(res[1]["LineItems"]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetModels(id) {
    return await fetch(`${window.$apiurl}/transload/receipts/${id}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        let a = 0;
        let b = 0;
        let u = 0;
        let p = 0;
        let c = res[1]?.ViewModel?.LineItems || [];
        c.forEach((x) => {
          if (x.Weight) {
            if (x.WeightType === "KGS") a += x.Weight;
            if (x.WeightType === "LBS") a += x.Weight * weight;
          }
          if (x.Cube) {
            if (x.CubeType === "CBM") b += x.Cube;
            if (x.CubeType === "CFT") b += x.Cube * cube;
          }
          if (x.UnitCount)
            u += x.UnitCount + (x?.OverQuantity ?? 0) - (x?.ShortQuantity ?? 0);
          if (x.Pallets) p += x.Pallets;
        });
        window.$reciept_LineItemList = c;
        $(`[name="TotalCube1"]`).val(b.toFixed(2) + " CBM");
        $(`[name="TotalWeight1"]`).val(a.toFixed(2) + " KGS");
        $(`[name="TotalCube2"]`).val((b * weight).toFixed(2));
        $(`[name="TotalWeight2"]`).val((a * cube).toFixed(2));
        $(`[name="Cartons2"]`).val(u.toFixed(2));
        $(`[name="Pallets"]`).val(p);

        window.ARCartons = +u;
        window.ARTotalWeight1 = +a;
        window.ARTotalWeight2 = +(a * 2.20462);
        window.ARTotalCube1 = +b;
        return ChangeId(c);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetViewmodel() {
    return await fetch(window.$apiurl + "/transload/receipts/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(window.$apiurl + "/transload/receipts/lineitem", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        if (!res.ok) throw res;
        Notification("success", "success");
      })
      .catch((err) => {
        err.json().then((body) => {
          Notification("error", body?.Message);
        });
      });
  }

  async function DeleteModel(id) {
    return fetch(window.$apiurl + "/transload/receipts/lineitem/" + id, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    return fetch(window.$apiurl + "/transload/receipts/lineitem?id=" + id, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetModel(id) {
    return await fetch(
      window.$apiurl + "/transload/receipts/getlineitems?itemId=" + id,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1]["LineItems"][0];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //------------------------------------------------------------------------------------

  mock.onGet("api/TransloadReceiving/GetAllApi").reply(async () => {
    const obj = await GetViewmodel();
    return [200, obj];
  });

  mock.onPost("api/TransloadReceiving").reply(async ({ data }) => {
    const { remark } = JSON.parse(data);
    const {
      id,
      ReceiptId,
      PurchaseOrder,
      Sku,
      Pieces,
      UnitOfMeasureId,
      UnitCount,
      Description,
      Weight,
      WeightType,
      Cube,
      CubeType,
      OverQuantity,
      Pallets,
      Location,
      ShortQuantity,
      DamagedQuantity,
      IsHot,
      IsHazardousMateriel,
      UnNumber,
      PackagingGroup,
      HazardClass,
      PieceDescription,
      EmergencyContactNumber,
      Color,
      Size,
      ProductType,
      ReceivedQuantity,
      // DestinationId
      // LBSWeight = (window.$LBSWeight || ""),
      // CFTCube = (window.$CFTCube || "")
    } = remark;
    const newRemark = {
      id,
      ReceiptId,
      Pallets,
      Location,
      PurchaseOrder,
      Sku,
      Pieces,
      UnitOfMeasureId,
      UnitCount,
      Description,
      Weight,
      WeightType,
      Cube,
      CubeType,
      OverQuantity,
      ShortQuantity,
      DamagedQuantity,
      IsHot,
      IsHazardousMateriel,
      UnNumber,
      PackagingGroup,
      HazardClass,
      PieceDescription,
      EmergencyContactNumber,
      Color,
      Size,
      ProductType,
      ReceivedQuantity,
      // DestinationId
      // LBSWeight,
      // CFTCube
    };
    /* await PostModel(newRemark);
    remarkTableMock.push(newRemark);
    return [200, { remark: newRemark }]; */

    await PostModel(remark);
    remarkTableMock.push(remark);
    return [200, { remark: remark }];
  });

  mock.onPost(/api\/TransloadReceivingfind\/\d+/).reply(async (config) => {
    const urls = config.url.split("/");
    const id = urls[2];
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    let productRemarks = await GetModels(id);
    // window.VesselVoyageData = result;
    if (queryParams.filter.search && queryParams.filter.search !== "") {
      productRemarks = productRemarks.filter(
        (word) =>
          String(word.Sku)
            .toLowerCase()
            .trim()
            .includes(
              String(queryParams.filter.search)
                .toLowerCase()
                .trim()
            ) ||
          String(word.Color)
            .toLowerCase()
            .trim()
            .includes(
              String(queryParams.filter.search)
                .toLowerCase()
                .trim()
            ) ||
          String(word.Size)
            .toLowerCase()
            .trim()
            .includes(
              String(queryParams.filter.search)
                .toLowerCase()
                .trim()
            ) ||
          String(word.Sku)
            .toLowerCase()
            .trim()
            .includes(
              String(queryParams.filter.search)
                .toLowerCase()
                .trim()
            ) ||
          String(word.Upc)
            .toLowerCase()
            .trim()
            .includes(
              String(queryParams.filter.search)
                .toLowerCase()
                .trim()
            ) ||
          String(word.Destination)
            .toLowerCase()
            .trim()
            .includes(
              String(queryParams.filter.search)
                .toLowerCase()
                .trim()
            )
      );
    }
    delete queryParams.filter.ReLoadTableCustomTrRm;
    delete queryParams.filter.search;
    const filterdRemarks = mockUtils.baseFilter(productRemarks, queryParams);
    return [200, filterdRemarks];
  });

  mock.onPost("api/TransloadReceiving/deleteRemarks").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = remarkTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        remarkTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onGet(/api\/TransloadReceiving\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TransloadReceiving\/(\d+)/)[1];
    const remark = await GetModel(id);
    remark.id = id;
    if (!remark) {
      return [400];
    }
    return [200, remark];
  });

  mock.onPut(/api\/TransloadReceiving\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TransloadReceiving\/(\d+)/)[1];
    const { remark } = JSON.parse(config.data);
    var putObj = await PutModel(remark, id);
    if (!putObj) {
      return [400];
    }
    const index = remarkTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }
    remarkTableMock[index] = { ...remark };
    return [200];
  });

  mock.onDelete(/api\/TransloadReceiving\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TransloadReceiving\/(\d+)/)[1];
    const index = remarkTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    remarkTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
