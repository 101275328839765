import { MenuItem, MenuList, Typography } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Card } from "react-bootstrap";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function Status({
  DataWareHouse,
  filterOptions,
  setfilterOptions,
  paginationOptions,
  setpaginationOptions,
}) {
  var Spinner = require("react-spinkit");
  const [ShipmentsException, setShipmentsException] = useState([]);
  async function getExceptionAscync() {
    var _customers = filterOptions.customers.length > 0 ? "" : "?customerId=";
    filterOptions.customers.map((item, index) => {
      _customers +=
        (index === 0 ? "?customerId=" : "&customerId=") + item.value;
    });
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${window.$apiurl}/warehouseManagement/getexceptions${_customers}&statusId=${filterOptions.statusId}&ServiceId=${filterOptions.service}&modeId=${filterOptions.mode}&serviceLevelId=${filterOptions.serviceLevelId}&equipmentTypeId=${filterOptions.eqTypeId}&equipmentSizeId=${filterOptions.eqSizeId}&shipFromId=${filterOptions.ShipFrom}&VendorId=${filterOptions.VendorId}&filterDate=${filterOptions.DateRangeType}&startDate=${filterOptions.startDate}&endDate=${filterOptions.endDate}&isHot=${filterOptions.hot}&isHazardousMaterial=${filterOptions.hasMat}&exception=&IsCancel=${filterOptions.canceled}&IsClosed=${filterOptions.closed}&pageNumber=${paginationOptions.page}&pageSize=${paginationOptions.sizePerPage}&sortField=${paginationOptions.sortField}&sortOrder=${paginationOptions.sortOrder}&search=${filterOptions.search}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        setShipmentsException(res[1] || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    getExceptionAscync();
  }, [filterOptions]);
  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-6">
          <>
            <Card className="card-custom gutter-b card-stretch mb-5">
              <h6 className="font-weight-bold pl-5 pt-3 mt-1">Statuses</h6>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    var datapagination = { ...paginationOptions };
                    datapagination.sortField = "StatusNewDate";
                    setpaginationOptions(datapagination);
                    var data = { ...filterOptions };
                    data.statusId = "";
                    data.StatusName = "";
                    data.exceptionsId = "";
                    setfilterOptions(data);
                  }}
                >
                  <span className="svg-icon svg-icon-primary pr-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x="4"
                          y="4"
                          width="4"
                          height="4"
                          rx="2"
                        />
                        <rect
                          fill="#000000"
                          x="4"
                          y="10"
                          width="4"
                          height="4"
                          rx="2"
                        />
                        <rect
                          fill="#000000"
                          x="10"
                          y="4"
                          width="4"
                          height="4"
                          rx="2"
                        />
                        <rect
                          fill="#000000"
                          x="10"
                          y="10"
                          width="4"
                          height="4"
                          rx="2"
                        />
                        <rect
                          fill="#000000"
                          x="16"
                          y="4"
                          width="4"
                          height="4"
                          rx="2"
                        />
                        <rect
                          fill="#000000"
                          x="16"
                          y="10"
                          width="4"
                          height="4"
                          rx="2"
                        />
                        <rect
                          fill="#000000"
                          x="4"
                          y="16"
                          width="4"
                          height="4"
                          rx="2"
                        />
                        <rect
                          fill="#000000"
                          x="10"
                          y="16"
                          width="4"
                          height="4"
                          rx="2"
                        />
                        <rect
                          fill="#000000"
                          x="16"
                          y="16"
                          width="4"
                          height="4"
                          rx="2"
                        />
                      </g>
                    </svg>
                  </span>
                  <Typography
                    className={`font-weight-bolder ${
                      filterOptions.statusId === "" ? "text-info-warehouse" : ""
                    }`}
                    variant="body2"
                  >
                    ALL Shipments
                  </Typography>
                  <span className="navi-label ml-auto">
                    <span className="label label-light-info label-rounded font-weight-bold">
                      {DataWareHouse?.ShipmentsCount?.reduce(
                        (a, b) => a + (b["CountItems"] || 0),
                        0
                      )}
                    </span>
                  </span>
                </MenuItem>
                {DataWareHouse?.ShipmentsCount?.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        var datapagination = { ...paginationOptions };
                        switch (item.StatusId) {
                          case 2:
                            datapagination.sortField = "StatusNewDate";
                            datapagination.sortOrder = "desc";
                            break;
                          case 3:
                            datapagination.sortField = "StatusBookedDate";
                            datapagination.sortOrder = "desc";
                            break;
                          case 5:
                            datapagination.sortField =
                              "StatusLoadingFromOurWarehouseDate";
                            datapagination.sortOrder = "desc";
                            break;
                          case 6:
                            datapagination.sortField =
                              "StatusLoadedFromOurWarehouseDate";
                            datapagination.sortOrder = "desc";
                            break;
                          case 102:
                            datapagination.sortField = "StatusNotifyTrucker";
                            datapagination.sortOrder = "desc";
                            break;
                          case 7:
                            datapagination.sortField = "StatusPickedUpDate";
                            datapagination.sortOrder = "desc";
                            break;
                          case -1:
                            datapagination.sortField = "StatusNewDate";
                            datapagination.sortOrder = "desc";
                            break;
                          default:
                            datapagination.sortField = "StatusNewDate";
                            datapagination.sortOrder = "desc";
                            break;
                        }
                        setpaginationOptions(datapagination);
                        var data = { ...filterOptions };
                        data.statusId = item.StatusId;
                        data.StatusName = item.StatusName;
                        data.exceptionsId = "";
                        setfilterOptions(data);
                      }}
                    >
                      <span className="svg-icon svg-icon-primary pr-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              x="4"
                              y="4"
                              width="4"
                              height="4"
                              rx="2"
                            />
                            <rect
                              fill="#000000"
                              x="4"
                              y="10"
                              width="4"
                              height="4"
                              rx="2"
                            />
                            <rect
                              fill="#000000"
                              x="10"
                              y="4"
                              width="4"
                              height="4"
                              rx="2"
                            />
                            <rect
                              fill="#000000"
                              x="10"
                              y="10"
                              width="4"
                              height="4"
                              rx="2"
                            />
                            <rect
                              fill="#000000"
                              x="16"
                              y="4"
                              width="4"
                              height="4"
                              rx="2"
                            />
                            <rect
                              fill="#000000"
                              x="16"
                              y="10"
                              width="4"
                              height="4"
                              rx="2"
                            />
                            <rect
                              fill="#000000"
                              x="4"
                              y="16"
                              width="4"
                              height="4"
                              rx="2"
                            />
                            <rect
                              fill="#000000"
                              x="10"
                              y="16"
                              width="4"
                              height="4"
                              rx="2"
                            />
                            <rect
                              fill="#000000"
                              x="16"
                              y="16"
                              width="4"
                              height="4"
                              rx="2"
                            />
                          </g>
                        </svg>
                      </span>
                      <Typography
                        className={`font-weight-bolder ${
                          item.StatusId === filterOptions.statusId
                            ? "text-info-warehouse"
                            : ""
                        }`}
                        variant="body2"
                      >
                        {item.StatusName}
                      </Typography>
                      <span className="navi-label ml-auto">
                        <span className="label label-light-info label-rounded font-weight-bold">
                          {item.CountItems}
                        </span>
                      </span>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Card>
          </>
        </div>
        <div className="col-xl-12 col-lg-12 col-md-6">
          <>
            {ShipmentsException.length > 0 && (
              <Card className="card-custom gutter-b card-stretch mb-5">
                <Fragment>
                  <h6 className="font-weight-bold pl-5 pt-3 mt-1">
                    Exceptions
                  </h6>
                  {ShipmentsException?.length > 0 ? (
                    <MenuList>
                      {ShipmentsException?.map((a, i) => (
                        <MenuItem
                          key={i}
                          onClick={() => {
                            var datapagination = { ...paginationOptions };
                            datapagination.sortField = "StatusNewDate";
                            setpaginationOptions(datapagination);

                            var data = { ...filterOptions };
                            data.exceptionsId = a.Id;
                            data.statusId = "";
                            data.StatusName = "";
                            setfilterOptions(data);
                          }}
                        >
                          <span className="svg-icon menu-icon pr-3 text-primary">
                            <i className="fas fa-exclamation-triangle text-primary"></i>
                          </span>
                          <Typography
                            className={`font-weight-bolder text-wrap ${
                              filterOptions.exceptionsId === a.Id
                                ? "text-info-warehouse"
                                : ""
                            }`}
                            variant="body2"
                          >
                            {a.Name.substring(0, 30)}
                          </Typography>
                          <span className="navi-label ml-auto">
                            <span className="label label-light-info label-rounded font-weight-bold">
                              {a.Count}
                            </span>
                          </span>
                        </MenuItem>
                      ))}
                    </MenuList>
                  ) : (
                    <div
                      className={`col-lg-12 col-xxl-12 row mt-2 ${
                        ShipmentsException?.length === 0
                          ? "mb-md-10 mt-md-8"
                          : ""
                      }`}
                      style={{ left: "44%" }}
                    >
                      <Spinner name="folding-cube" color="blue" />
                    </div>
                  )}
                </Fragment>
              </Card>
            )}
          </>
        </div>
      </div>
    </Fragment>
  );
}
