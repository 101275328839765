export default [
    {
        
        sku :"",
        qualifier: " ",
        qty:"",
        shiptoFacility:"",
        exceptedDate:"",
        canceledDate:"",
        action:"",
        
    },
  ];
  