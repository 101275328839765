import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { SearchResult } from "./SearchResult";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../../_partials/dropdowns";
import low from "lowdb";
import { useHistory } from "react-router-dom";

import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function SearchDropdown() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueSubmited, setsearchValueSubmited] = useState("");
  let timeoutId;

  const clearTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
  };
  const history = useHistory();
  const handelSubmit = (e) => {
    window.ExistUrl = "/General/Search?key=" + searchValue;

    history.push("/General/Search?key=" + searchValue);
    document.getElementById("ShowGlobalTab").click();
    e.preventDefault();
  };
  const handleSearchChange = (event) => {
    setData(null);
    setSearchValue(event.target.value);

    if (event.target.value.length > 2) {
      clearTimeout();
      setsearchValueSubmited(event.target.value);
      setLoading(true);

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-FP-API-KEY", "iphone");
      var requestOptions = {
        method: "GET",
        withCredentials: true,
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        window.$apiurl + `/Search/SmallSearch?query=` + event.target.value,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          var obj = JSON.parse(result);
          setData(obj.Results);
          setLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const clear = () => {
    setData(null);
    setSearchValue("");
  };

  useEffect(() => {
    return () => {
      clearTimeout();
    };
  }, [clearTimeout]);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.search.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1"
            id="kt_quick_search_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
            </span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown
          alignRight
          drop="down"
          size="xxl"
          onToggle={() => {
            setData(null);
            setLoading(false);
            setSearchValue("");
          }}
          id="kt_quick_search_toggle"
        >
          <Dropdown.Toggle as={DropdownTopbarItemToggler}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="search-panel-tooltip">Quick search</Tooltip>
              }
            >
              <div className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1 searchClickEvent">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                  />
                </span>
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-xxl">
            <div
              id="kt_quick_search_dropdown"
              className={clsx("quick-search quick-search-dropdown", {
                "quick-search-has-result": data && data.length,
              })}
            >
              <form
                onSubmit={handelSubmit}
                className="quick-search-form"
                autoComplete={false}
              >
                <div className="input-group">
                  <div
                    className={`input-group-prepend`}
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className="input-group-text"
                      onClick={() => {
                        window.ExistUrl = "/General/Search?key=" + searchValue;
                        document.getElementById("ShowGlobalTab").click();
                        history.push("/General/Search?key=" + searchValue);
                      }}
                    >
                      <span className="svg-icon svg-icon-xxl">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Search.svg"
                          )}
                        />
                      </span>
                    </span>
                  </div>
                  <input
                    style={{ fontSize: "large" }}
                    type="text"
                    id="globalSearchInput"
                    autoFocus={true}
                    placeholder="Search Anything"
                    value={searchValue}
                    onChange={handleSearchChange}
                    className="form-control"
                  />
                  <div
                    className={`input-group-append ${
                      loading ? "spinner spinner-xxl spinner-primary" : ""
                    }")}`}
                  >
                    <span className="input-group-text">
                      <i
                        style={{
                          display:
                            loading && searchValue && searchValue.length > 0
                              ? "none"
                              : "flex",
                        }}
                        onClick={clear}
                        className="quick-search-close ki ki-close icon-md text-muted"
                      />
                    </span>
                  </div>
                </div>
              </form>
              <SearchResult data={data} dataSearch={searchValueSubmited} />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
