import React, { useEffect, useState } from "react";
import { Modal, Form, Card, Alert, Accordion, Button } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  RadioButton,
} from "../../../../../../_metronic/_partials/controls";
import { Icon } from "@material-ui/core";
import clsx from "clsx";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";

export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  let CustomerEditSchema;
  if (customer.id === undefined) {
    CustomerEditSchema = Yup.object().shape({
      Name: Yup.string()
        .min(3, "Minimum 8 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Warehouse Name is required"),
      Code: Yup.string()
        .min(3, "Minimum 8 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Warehouse Name is required"),
      NumberPerLocation: Yup.string().required("Warehouse Name is required"),
      Email: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
    });
  } else {
    CustomerEditSchema = Yup.object().shape({
      Email: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
    });
  }

  const [timeZoneInfoList, setTimeZoneInfoList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    fetchUsersAsync();
  }, []);

  useEffect(() => {
    if (customer.IsActive !== undefined) {
      var a = customer.IsActive ? "true" : "false";
      setisActive(a);
    }
  }, [customer]);

  const fetchUsersAsync = async () => {
    try {
      const result = await axios.get("api/Warehouses/GetAllApi");
      setTimeZoneInfoList(result.data["TimeZoneInfo"]);
      setCountriesList(result.data["Countries"]);
    } catch (e) {}
  };

  return (
    <>
      <Accordion defaultActiveKey="0">
        <Formik
          enableReinitialize={true}
          initialValues={customer}
          validationSchema={CustomerEditSchema}
          onSubmit={(values) => {
            saveCustomer(values);
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <>
              <Modal.Body>
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}

                <Form className="form form-label-right">
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="0"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">CONTACT INFORMATION</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className=" p-2">
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Field
                            name="Name"
                            component={Input}
                            placeholder="Warehouse Name"
                            label="Warehouse Name"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Field
                            name="Code"
                            component={Input}
                            placeholder="Warehouse Code"
                            label="Warehouse Code"
                          />
                        </div>
                        <div className="col-lg-4">
                          <RadioButton
                            name="IsActive2"
                            label="Status"
                            row
                            value={isActive}
                            onChange={(e) => {
                              setisActive(e.target.value);
                              setFieldValue("IsActive", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio color="primary" />}
                              label="Activate"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio color="primary" />}
                              label="Deactivate"
                            />
                          </RadioButton>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Field
                            name="Address1"
                            component={Input}
                            placeholder="Address1"
                            label="Address1"
                          />
                        </div>

                        <div className="col-lg-4">
                          <Field
                            name="Address2"
                            component={Input}
                            placeholder="Address2"
                            label="Address2"
                          />
                        </div>

                        <div className="col-lg-4">
                          <Select name="Country" label="Country">
                            <option>Select</option>
                            {countriesList.map((item, index) => (
                              <option value={item.Id}>{item.Name}</option>
                            ))}
                          </Select>
                          {countriesList.length === 0 && <LinearProgress />}
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Field
                            name="State"
                            component={Input}
                            placeholder="State/Province/Region"
                            label="State/Province/Region"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Field
                            name="City"
                            component={Input}
                            placeholder="City"
                            label="City"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Field
                            name="ZipCode"
                            component={Input}
                            placeholder="Zip/Postal"
                            label="Zip/Postal"
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Select name="TimeZone" label="Time Zone">
                            <option>Select</option>
                            {timeZoneInfoList.length > 0 &&
                              timeZoneInfoList
                                .filter(
                                  (x) =>
                                    x ===
                                      "(UTC-06:00) Central Time (US & Canada)" ||
                                    x === "(UTC-06:00) Central America"
                                )
                                .map((item, index) => (
                                  <option value={item}>{item}</option>
                                ))}
                          </Select>
                          {timeZoneInfoList.length === 0 && <LinearProgress />}
                        </div>
                        <div className="col-lg-4">
                          <Field
                            type="number"
                            name="PhoneNumber"
                            component={Input}
                            placeholder="Phone Number"
                            label="Phone Number"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Field
                            type="number"
                            name="Fax"
                            component={Input}
                            placeholder="Fax Number"
                            label="Fax Number"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Field
                            type="email"
                            name="Email"
                            placeholder="Email"
                            component={Input}
                            label="Email"
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="1"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">ACCOUNTS</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="1">
                    <Card.Body className=" p-2">
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Field
                            name="UpsNumber"
                            component={Input}
                            placeholder="UPS Account Number"
                            label="UPS Account Number"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Field
                            name="FedExNumber"
                            component={Input}
                            placeholder="Warehouse FedExAccount"
                            label="Warehouse FedExAccount"
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="2"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">CUSTOMIZATIONS</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="2">
                    <Card.Body className="p-2">
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Field
                            name="FuelSurcharge"
                            component={Input}
                            placeholder="Fuel Surcharge"
                            label="Fuel Surcharge"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Field
                            name="FreightRate"
                            component={Input}
                            placeholder="Freight Rate Location Code"
                            label="Freight Rate Location Code"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Select
                            name="NumberPerLocation"
                            label="Number of Fields Per Location"
                          >
                            <option>Select</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </Select>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Select name="IsMetric" label="IsMetric">
                            <option value="">Select</option>
                            <option value="false">Imperial</option>
                            <option value="true">Metric</option>
                          </Select>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Accordion>
    </>
  );
}
