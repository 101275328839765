import MockUtils from "../mock.utils";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default function mockVessels(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  //Get all Vessels
  async function GetAllVessels() {
    return await fetch(window.$apiurl + "/vessel", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetModelId(id) {
    return await fetch(`${window.$apiurl}/vesselvoyage/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1]["Voyage"];
        a.id = a.Id;
        return a;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostVessel(someData) {
    return fetch(window.$apiurl + "/vessel", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        window.$statusPostContact = statusCode;
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  //Put
  async function PutVessel(someData, id) {
    return fetch(window.$apiurl + "/vessel/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        //window.$statusCodePut = statusCode;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  //Delete Contact
  async function DeleteVessel(id) {
    return await fetch(`${window.$apiurl}/vessel/${id}`, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  mock.onPost("api/Vessels").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const { Name = "", Imo = "", Mmsi = "", ShipId = "" } = customer;

    const newVessel = {
      Name,
      Imo,
      Mmsi,
      ShipId,
    };
    console.log(newVessel);
    var postModel = await PostVessel(newVessel);
    if (postModel.Id !== undefined) {
      Notification("success", "Vessel has been added successfully");
      postModel.id = postModel.Id;
      customerTableMock.push(newVessel);
      return [
        200,
        { customer: newVessel, message: postModel.Message, success: true },
      ];
    } else {
      if (postModel.Message) {
        Notification("error", postModel.Message);
      } else {
        Notification("error", "Error occured");
      }
      return [400, { message: postModel.Message, success: false }];
    }
  });

  mock.onPost("api/Vessels/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetAllVessels();
    //Filter
    if (
      queryParams.filter.Name !== "" &&
      queryParams.filter.Name !== undefined
    ) {
      customerTableMock = customerTableMock.filter(
        (word) =>
          String(word.Name).includes(String(queryParams.filter.Name)) ||
          String(word.VoyagesCount).includes(String(queryParams.filter.Name)) ||
          String(word.id).includes(String(queryParams.filter.Name))
      );
    }
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.Name;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/Vessels/filter").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetAllVessels();
    delete queryParams.filter.ReLoadTable;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/Vessels/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/Vessels/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/Vessels\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/Vessels\/(\d+)/)[1];
    const customer = customerTableMock.find((el) => el.id === +id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/Vessels\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/Vessels\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    await PutVessel(customer, id);
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (!index === -1) {
      Notification("error", "Error Occured");
      return [400];
    }
    Notification("success", "success");
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/Vessels\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/Vessels\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteVessel(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
