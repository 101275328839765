// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import Axios from "axios";
const adapter = new LocalStorage("db");
const db = low(adapter);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
export function ProductEditForm({ product, btnRef, saveProduct }) {

  const classes = useStyles();
  const [CustomersList, setCustomersList] = useState([]);
  const [ShowDeleteLBModal, setShowDeleteLBModal] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    Customers: [],
    Carriers: [],
  });
  const [CarriersList, setCarriersList] = useState([]);
  const [ModeList, setModeList] = useState([]);
  const [UpdatorsList, setUpdatorsList] = useState([]);
  const deleteProduct = async (id) => {
    await Axios.delete(`api/TMSLoadBoard/${id}`);
    document.getElementById("LoadBoardGrid").click();
  };
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/loadboard/GetLBViewModel`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var obj = JSON.parse(result);
        setCustomersList(obj?.Customers || []);
        setDataFilter({
          Customers: obj?.Customers || [],
          Carriers: obj?.Carriers || [],
        });
        setCarriersList(obj?.Carriers || []);
        setModeList(obj.Modes || []);
        setUpdatorsList(obj?.Updators || []);
      })
      .catch((error) => console.log("error", error));
  }, []);
  var typingTimer;
  function filterCustomerCheckbox(isCustomer, value) {
    try {
      let result = isCustomer ? dataFilter.Customers : dataFilter.Carriers;
      if (value === "") {
        if (isCustomer) {
          setCustomersList(result);
        } else {
          setCarriersList(result);
        }
      } else {
        result = result.filter((word) =>
          String(word.Name)
            .toLowerCase()
            .trim()
            .includes(String(value).toLowerCase().trim())
        );
        if (result.length === 0) {
          if (isCustomer) {
            setCustomersList([]);
          } else {
            setCarriersList([]);
          }
        } else {
          if (isCustomer) {
            setCustomersList(result);
          } else {
            setCarriersList(result);
          }
        }
      }
    } catch (err) {}
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={product}
        onSubmit={(values) => {
          saveProduct(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Modal
              show={ShowDeleteLBModal}
              //onHide={onHide}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Load Board Delete
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <span>Are you sure to permanently delete this Load Board?</span>
              </Modal.Body>
              <Modal.Footer>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setShowDeleteLBModal(false);
                    }}
                    className="btn btn-light btn-elevate mr-3"
                  >
                    Cancel
                  </button>
                  <> </>
                  <button
                    type="button"
                    onClick={() => {
                      deleteProduct(product.Id);
                      setShowDeleteLBModal(false);
                    }}
                    className="btn btn-delete btn-danger"
                  >
                    Delete
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            <Form className="form form-label-right">
              <div class="form-group row">
                <div className="col-md-4">
                  <label> Load Board Name </label>
                  <input
                    type="text"
                    className="form-control"
                    name="Name"
                    value={values.Name}
                    onChange={(e) => {
                      setFieldValue("Name", e.target.value);
                    }}
                    id="Name"
                    placeholder=""
                  />
                </div>
                <div className="col-md-4">
                  <label> User Modification Rights </label>
                  <Select
                    isMulti
                    value={UpdatorsList.filter((item) =>
                      values.Updators.includes(item.Id)
                    ).map((a) => {
                      return { value: a.Id, label: a.Name };
                    })}
                    name="LoadBoard"
                    id="LoadBoard"
                    options={UpdatorsList.map((a) => {
                      return { value: a.Id, label: a.Name };
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                      if (e !== null) {
                        setFieldValue(
                          "Updators",
                          e.map((a) => a.value)
                        );
                      } else {
                        setFieldValue("Updators", []);
                      }
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <div class="radio-inline mt-10">
                    <label class="radio radio-success">
                      <input
                        type="radio"
                        value={true}
                        checked={values.Public === true}
                        name="Public"
                        onChange={() => {
                          setFieldValue("Public", true);
                        }}
                      />
                      <span></span>
                      Public Board
                    </label>
                    <label class="radio radio-success">
                      <input
                        type="radio"
                        checked={values.Public === false}
                        value={false}
                        name="Public"
                        onChange={() => {
                          setFieldValue("Public", false);
                        }}
                      />
                      <span></span>
                      Private Board
                    </label>
                    {product.Id !== undefined ? (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="products-delete-tooltip">Delete</Tooltip>
                        }
                      >
                        <a
                          onClick={() => {
                            setShowDeleteLBModal(true);
                          }}
                          href={() => false}
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/General/Trash.svg"
                              )}
                            />
                          </span>
                        </a>
                      </OverlayTrigger>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-4">
                  <h5 className="mb-md-7 mt-3"> Mode Filter </h5>
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        <FormControlLabel
                          control={
                            <div className="col">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name="Modes"
                                  checked={
                                    values?.Modes?.length === ModeList?.length
                                  }
                                  value="Select All"
                                  onClick={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        "Modes",
                                        ModeList.map((a) => a.Id)
                                      );
                                    } else {
                                      setFieldValue("Modes", []);
                                    }
                                  }}
                                />
                                <span></span>
                              </label>
                            </div>
                          }
                          label="Select All"
                        />
                      </Typography>
                    </ExpansionPanelSummary>
                    <div
                      className={`${
                        ModeList.length >= 5 ? "scrollCustomerCheckbox" : ""
                      } ${
                        ModeList.length === 0 ? "noDataCheckList" : "modeHeight"
                      }`}
                    >
                      {ModeList.length > 0 ? (
                        ModeList.map((Item, Index) => (
                          <ExpansionPanelDetails key={Index} className="pb-0">
                            <Typography>
                              <div className="col-lg-12">
                                <FormControlLabel
                                  control={
                                    <div className="col">
                                      <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                      <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                        <input
                                          type="checkbox"
                                          name={Item.Name}
                                          checked={
                                            values?.Modes?.filter(
                                              (a) => a === Item.Id
                                            ).length > 0
                                          }
                                          onChange={(e) => {
                                            var find = values?.Modes.filter(
                                              (item) => item === Item.Id
                                            ).length;
                                            var list = JSON.parse(
                                              JSON.stringify(values?.Modes)
                                            );
                                            if (find === 0) {
                                              list.push(Item.Id);
                                              setFieldValue("Modes", list);
                                            } else {
                                              const index = list.indexOf(
                                                Item.Id
                                              );
                                              if (index > -1) {
                                                list.splice(index, 1);
                                                setFieldValue("Modes", list);
                                              }
                                            }
                                          }}
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                  }
                                  label={Item.Name}
                                />
                              </div>
                            </Typography>
                          </ExpansionPanelDetails>
                        ))
                      ) : (
                        <h5 className="op-6">No Data founded on those modes</h5>
                      )}
                    </div>
                  </ExpansionPanel>
                </div>
                <div className="col-md-4">
                  <div className="row mb-4">
                    <div className="col-lg-6">
                      <h5 className="mb-3 mt-3"> Customer Filter </h5>
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        placeholder="Filter by Customer Name"
                        className="form-control w-100"
                        onClick={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onChange={async (e) => {
                          try {
                            const value = e.target.value;
                            clearTimeout(typingTimer);
                            typingTimer = setTimeout(async function() {
                              filterCustomerCheckbox(true, value);
                            }, 500);
                          } catch (err) {}
                        }}
                      />
                    </div>
                  </div>

                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        <FormControlLabel
                          control={
                            <div className="col">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  checked={
                                    values?.Customers?.length ===
                                    CustomersList?.length
                                  }
                                  onClick={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        "Customers",
                                        CustomersList.map((a) => a.Id)
                                      );
                                    } else {
                                      setFieldValue("Customers", []);
                                    }
                                  }}
                                  value="Select All"
                                />
                                <span></span>
                              </label>
                            </div>
                          }
                          label="Select All"
                        />
                      </Typography>
                    </ExpansionPanelSummary>
                    <div
                      className={`scrollCustomerCheckbox ${CustomersList.length ===
                        0 && "noDataCheckList"}`}
                    >
                      {CustomersList.length > 0 ? (
                        CustomersList.map((Item, Index) => (
                          <ExpansionPanelDetails className="pb-0">
                            <Typography>
                              <div className="col-lg-12">
                                <FormControlLabel
                                  control={
                                    <div className="col">
                                      <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                      <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                        <input
                                          checked={
                                            values?.Customers?.filter(
                                              (a) => a === Item.Id
                                            ).length > 0
                                          }
                                          onChange={(e) => {
                                            var find = values?.Customers.filter(
                                              (item) => item === Item.Id
                                            ).length;
                                            var list = JSON.parse(
                                              JSON.stringify(values?.Customers)
                                            );
                                            if (find === 0) {
                                              list.push(Item.Id);
                                              setFieldValue("Customers", list);
                                            } else {
                                              const index = list.indexOf(
                                                Item.Id
                                              );
                                              if (index > -1) {
                                                list.splice(index, 1);
                                                setFieldValue(
                                                  "Customers",
                                                  list
                                                );
                                              }
                                            }
                                          }}
                                          type="checkbox"
                                          name={Item.Name}
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                  }
                                  label={Item.Name}
                                />
                              </div>
                            </Typography>
                          </ExpansionPanelDetails>
                        ))
                      ) : (
                        <h5 className="op-6">
                          No Data founded on those customers
                        </h5>
                      )}
                    </div>
                  </ExpansionPanel>
                </div>
                <div className="col-md-4">
                  <div className="row mb-4">
                    <div className="col-lg-6">
                      <h5 className="mb-3 mt-3"> Carrier Filter </h5>
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        placeholder="Filter by Carrier Name"
                        className="form-control w-100"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        onChange={async (e) => {
                          try {
                            const value = e.target.value;
                            clearTimeout(typingTimer);
                            typingTimer = setTimeout(async function() {
                              filterCustomerCheckbox(false, value);
                            }, 500);
                          } catch (err) {}
                        }}
                      />
                    </div>
                  </div>
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        <FormControlLabel
                          control={
                            <div className="col">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name="CarrierAll"
                                  checked={
                                    values?.Carriers?.length ===
                                    CarriersList?.length
                                  }
                                  onClick={(e) => {
                                    if (e.target.checked) {
                                      setFieldValue(
                                        "Carriers",
                                        CarriersList.map((a) => a.Id)
                                      );
                                    } else {
                                      setFieldValue("Carriers", []);
                                    }
                                  }}
                                  value="Select All"
                                />
                                <span></span>
                              </label>
                            </div>
                          }
                          label="Select All"
                        />
                      </Typography>
                    </ExpansionPanelSummary>
                    <div
                      className={`scrollCustomerCheckbox ${CarriersList.length ===
                        0 && "noDataCheckList"}`}
                    >
                      {CarriersList.length > 0 ? (
                        CarriersList.map((Item, Index) => (
                          <ExpansionPanelDetails key={Index} className="pb-0">
                            <Typography>
                              <div className="col-lg-12">
                                <FormControlLabel
                                  control={
                                    <div className="col">
                                      <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                      <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                        <input
                                          checked={
                                            values?.Carriers?.filter(
                                              (a) => a === Item.Id
                                            ).length > 0
                                          }
                                          onChange={(e) => {
                                            var find = values?.Carriers.filter(
                                              (item) => item === Item.Id
                                            ).length;
                                            var list = JSON.parse(
                                              JSON.stringify(values?.Carriers)
                                            );
                                            if (find === 0) {
                                              list.push(Item.Id);
                                              setFieldValue("Carriers", list);
                                            } else {
                                              const index = list.indexOf(
                                                Item.Id
                                              );
                                              if (index > -1) {
                                                list.splice(index, 1);
                                                setFieldValue("Carriers", list);
                                              }
                                            }
                                          }}
                                          type="checkbox"
                                          name={Item.Name}
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                  }
                                  label={Item.Name}
                                />
                              </div>
                            </Typography>
                          </ExpansionPanelDetails>
                        ))
                      ) : (
                        <h5 className="op-6">
                          No Data founded on those carriers
                        </h5>
                      )}
                    </div>
                  </ExpansionPanel>
                </div>
              </div>
              <button
                type="submit"
                style={{ display: "none" }}
                ref={btnRef}
                onSubmit={() => handleSubmit()}
              ></button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
