import moment from "moment";
import React, { useState, useEffect } from "react";
import { IconButton, LinearProgress } from "@material-ui/core";
import LocalStorage from "lowdb/adapters/LocalStorage";
import low from "lowdb";
import CloseIcon from "@material-ui/icons/Close";
import SVG from "react-inlinesvg";
import {
  Modal,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Popover,
} from "react-bootstrap";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { ChargesFunction } from "./RemarkEditForm";
import { ChargesOnly } from "./ChargesOnly";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { DateTime30GL } from "../../../../../../../../_metronic/_partials/controls/forms/DateTime30GL";
import { Notification } from "../../../../../../Notification";

const adapter = new LocalStorage("db");
const db = low(adapter);

export function ARCheckZeroAndSame(Calcul, Quantity, AutoRating, IsHot) {
  Quantity = Quantity ?? 0;
  var a = AutoRatingFunction(Calcul, Quantity, AutoRating, IsHot);
  return (a !== 0 && a !== Quantity && Quantity !== 0) || typeof a === "string";
}

export function RoundToFixed(Quantity, round = 2) {
  try {
    return +Quantity.toFixed(round) ?? 0;
  } catch (error) {
    return 0;
  }
}

export function GetLengthRound(e, Round = 2) {
  try {
    var str = e.target.value.toString();
    var patt = /\d+/g;
    var r = str.match(patt)[1]?.length ?? 0;
    if (r > Round) {
      var qnty = str.substring(0, str.length - 1);
      if (qnty.slice(-1) === ".") qnty = qnty.substring(0, str.length - 1);
      e.target.value = +qnty;
      Notification(
        "warning",
        "You're exceeding the rounding limit which is " + Round
      );
    }
  } catch (error) {
    e.target.value = 0;
  }
}

export const isVariableOpenDefault = true;

export function RateClassName(ChargesReadOnly, x, isVariableOpen) {
  var isVariable = x?.IsVariable ?? false;
  var rateChanged = x?.RateChanged ?? false;
  var b = !(ChargesReadOnly ? true : x?.IsReadOnly);
  var bg = isVariableOpen && isVariable ? "bg-white" : "bg-light";
  return `form-control ${bg} ${isVariable &&
    b &&
    !rateChanged &&
    "border border-warning"}  ${rateChanged && b && "border border-success"}`;
}

export function RateReadOnly(isVariableOpen, isVariable) {
  return isVariableOpen && isVariable ? false : "readonly";
}

export function AutoRatingCharges({
  setAutoRatingModal,
  Charges,
  AutoRating,
  IsHot,
  setCharges,
  bool,
  AutoRatingCheck = [],
  IndexId = "",
}) {
  setAutoRatingModal(false);

  var list = Charges;
  list.forEach((x) => {
    var index = AutoRatingCheck.indexOf(x?.Id);
    if (index === -1) return;

    if (bool || (!bool && x.Quantity === 0)) {
      var a = AutoRatingFunction(x?.Calcul, x.Quantity, AutoRating, IsHot);
      if (isNumeric(a)) x.Quantity = a === 0 ? x.Quantity : a;
      else {
        var ele = document.getElementById("autoCharge" + x.Id + IndexId);
        if (ele) {
          x.Quantity = +ele.getAttribute("data-time").split(" ")[0];
          x.DateStart = ele.getAttribute("data-Start");
          x.DateEnd = ele.getAttribute("data-End");
        }
      }
    }
  });

  setCharges([]);
  setTimeout(() => {
    setCharges(list);
  }, 1);
}

export function checkIs0(Quantity, iiii) {
  Quantity = Quantity;
  if (Quantity === 0)
    Quantity = document.getElementById(`${iiii}Id`)?.value ?? 0;
  return +Quantity;
}

export function CalculateCharge({
  Rate,
  Quantity,
  rateType,
  MinCharge,
  MaxCharge,
  FreeTime,
  OriAddSup,
}) {
  var amount = 0;
  Rate = Rate ?? 0;
  Quantity = Quantity ?? 0;
  MinCharge = MinCharge ?? 0;
  MaxCharge = MaxCharge ?? 0;
  FreeTime = FreeTime ?? 0;
  switch (rateType) {
    case "Days":
    case "Hours":
      if (FreeTime > 0)
        amount = Quantity < FreeTime ? 0 : (Quantity - FreeTime) * Rate;
      else amount = Rate * Quantity;
      break;
    case "Weight":
      amount = Rate * Quantity;
      break;
    case "Volume":
      amount = Rate * Quantity;
      break;
    case "Quantity":
      amount = Rate * Quantity;
      break;
    case "Distance":
      amount = Rate * Quantity;
      break;
    case "Revenue":
      amount = Rate * Quantity;
      break;
    case "Cost":
      amount = Rate * Quantity;
      break;
    case " Dsp Time":
      amount = Rate * Quantity;
      break;
    default:
      amount = Rate * Quantity;
      break;
  }
  if (MinCharge > 0 && amount < MinCharge && Quantity > 0 && OriAddSup == 0)
    amount = MinCharge;
  if (MaxCharge > 0 && amount > MaxCharge) amount = MaxCharge;
  return amount;
}

export function RemoveDuplicate(chargesVar, objCalc) {
  return objCalc?.filter(
    (rate) => chargesVar.findIndex((x) => x?.ChargeId === rate?.Id) === -1
  );
}

export function ChargesColor(oriAddSup, quantity, invoice) {
  if (oriAddSup === 0) return "text-dark";
  else if (oriAddSup === 1) return "text-primary";
  else if (oriAddSup === 2 && quantity < 0 && invoice !== "Empty")
    return "text-danger";
  else if (oriAddSup === 2) return "text-info";
}

export function ChargesColorCategory(Category) {
  if (Category === "Additional Services") return "primary";
  else if (Category === "Outbound Rates") return "info";
  else if (Category === "Port Drayage Rates") return "danger";
  else if (Category === "Inbound Rates") return "success";
  else return "warning";
}

export function ChargesModel({ id, path = "", isDropdown = false }) {
  const [ChargesId, setChargesId] = useState(false);
  return (
    <>
      <Modal
        size="xl"
        show={ChargesId}
        onHide={() => setChargesId(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <ChargesOnly
          onHide={() => setChargesId(false)}
          isId={ChargesId}
          path={path}
        />
      </Modal>

      {isDropdown ? (
        <a
          href={() => false}
          className="navi-link"
          onClick={() => {
            setChargesId(id);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary pr-2">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
          </span>
          <span className="navi-text">Charges</span>
        </a>
      ) : (
        <OverlayTrigger
          overlay={<Tooltip id="products-edit-tooltip">Charges</Tooltip>}
        >
          <a
            href={() => false}
            className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1"
            role="button"
            onClick={() => {
              setChargesId(id);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}
              />
            </span>
          </a>
        </OverlayTrigger>
      )}
    </>
  );
}

export function SetSubTotal(number, str) {
  setTimeout(() => {
    var ele = document.querySelector(str);
    if (ele) ele.innerText = AddSpace3(number);
  }, 500);
}

export function SortAndRename(Charges) {
  var charges = Charges;
  charges.forEach((x) => {
    if (x.Invoice === "" || x?.Invoice === undefined) {
      x.Invoice = "Empty";
      x.InvoiceId = 9999999;
    }
  });

  charges = charges.sort(function(a, b) {
    var x = a["OriAddSup"];
    var y = b["OriAddSup"];
    return x < y ? -1 : x > y ? 1 : 0;
  });
  charges = charges.sort(function(a, b) {
    var x = a["Position"];
    var y = b["Position"];
    return x < y ? -1 : x > y ? 1 : 0;
  });
  charges = charges.sort(function(a, b) {
    var x = a["CategoryPosition"];
    var y = b["CategoryPosition"];
    return x < y ? -1 : x > y ? 1 : 0;
  });
  charges = charges.sort(function(a, b) {
    var x = a["Category"];
    var y = b["Category"];
    return x < y ? -1 : x > y ? 1 : 0;
  });
  charges = charges.sort(function(a, b) {
    var x = a["InvoiceId"];
    var y = b["InvoiceId"];
    return x < y ? -1 : x > y ? 1 : 0;
  });

  charges.forEach((x) => {
    if (x.SubTotal) delete x.SubTotal;
  });

  var applicatInvoice = {};
  var applicatCategory = {};

  charges.forEach((element, index) => {
    // Invoice
    var inv = element.Invoice;
    var a = applicatInvoice[inv];
    if (a) element.InvoiceAppend = "";
    else {
      element.InvoiceAppend = inv;
      applicatInvoice[inv] = inv;
      applicatCategory = {};
    }
    // Category

    if (charges?.length - 1 === index) charges[index].SubTotal = true;

    var cat = element.Category;
    var a = applicatCategory[cat];
    if (a) {
      element.CategoryAppend = "";
    } else {
      var check = charges.filter((x) => x.Category === element.Category);
      if (index !== 0) charges[index - 1].SubTotal = true;
      if (check?.length === 1) charges[index].SubTotal = true;
      element.CategoryAppend = cat;
      applicatCategory[cat] = cat;
    }
  });
  charges.forEach((x) => {
    if (x.InvoiceId === 9999999) x.InvoiceId = null;
  });
  return charges || [];
}

export function RemoveSpace(txt) {
  try {
    return txt.replace(/\s/g, "");
  } catch (error) {
    return txt;
  }
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function AutoRatingClick(txt) {
  var btnList = document.querySelectorAll(".BtnAutoRating");
  btnList.forEach((ele) => {
    ele.setAttribute("data-type", txt);
    ele.click();
  });
}

export function AutoRateComponent({
  title = "",
  setAutoRatingModal,
  AutoRatingModal,
  Charges,
  AutoRating,
  IsHot,
  setCharges,
  setAutoRatingCheck,
  AutoRatingCheck,
  IndexId = "",
}) {
  return (
    <Modal
      size="xl"
      show={AutoRatingModal}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title
          id="example-modal-sizes-title-lg"
          className="w-100 row px-0"
        >
          <div className="col-lg-12">Auto Rate for {title}</div>
        </Modal.Title>
        <IconButton
          className="MuiButtonBase-root MuiIconButton-root jss179"
          aria-label="Close"
          onClick={() => setAutoRatingModal(false)}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>
      {AutoRatingModal && (
        <Modal.Body className="row">
          <div className="col-12 my-5">
            <h3>Do you want to override the existing Quantities ?</h3>
            <h5>Please check the charges you want to override</h5>
          </div>
          <div className="col-12 mt-5" />
          {SortOnlyCharge(Charges, AutoRatingCheck, AutoRating, IsHot)?.map(
            (x, index) => (
              <AutoRatingItem
                key={index}
                x={x}
                AutoRating={AutoRating}
                IsHot={IsHot}
                setAutoRatingCheck={setAutoRatingCheck}
                IndexId={IndexId}
              />
            )
          )}
        </Modal.Body>
      )}
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setAutoRatingModal(false)}
            className="btn btn-light btn-elevate mr-5"
          >
            Cancel
          </button>

          <button
            type="button"
            onClick={() =>
              AutoRatingCharges({
                setAutoRatingModal: setAutoRatingModal,
                setCharges: setCharges,
                Charges: Charges,
                AutoRating: AutoRating,
                IsHot: IsHot,
                bool: false,
                AutoRatingCheck: AutoRatingCheck,
                IndexId: IndexId,
              })
            }
            className="btn btn-light btn-elevate mr-5"
          >
            No
          </button>

          <button
            type="button"
            onClick={() =>
              AutoRatingCharges({
                setAutoRatingModal: setAutoRatingModal,
                setCharges: setCharges,
                Charges: Charges,
                AutoRating: AutoRating,
                IsHot: IsHot,
                bool: true,
                AutoRatingCheck: AutoRatingCheck,
                IndexId: IndexId,
              })
            }
            className="btn btn-primary btn-elevate"
          >
            OK
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export function SortOnlyCharge(
  Charges,
  autoRatingCheck = false,
  AutoRating,
  IsHot
) {
  if (autoRatingCheck)
    Charges = Charges.filter((y) => autoRatingCheck.indexOf(y.Id) > -1);

  Charges = Charges.sort(function(a, b) {
    var x = AutoRatingFunction(a?.Calcul, a.Quantity, AutoRating, IsHot);
    var y = AutoRatingFunction(b?.Calcul, b.Quantity, AutoRating, IsHot);
    return x < y ? -1 : x > y ? 1 : 0;
  });

  return Charges.filter((x) =>
    ARCheckZeroAndSame(x?.Calcul, x.Quantity, AutoRating, IsHot)
  );
}

export function AutoRatingFunction(rateType, Quantity, autoRating, IsHot) {
  var number = 0;
  var autoType = autoRating ?? window.AutoRating;
  var isHot = IsHot ?? window.ContainerIsHot === true;

  switch (rateType) {
    case "perorder":
      number = 1;
      break;
    case "percarton":
      number = window.ARCartons ?? +autoType?.Cartons ?? 0;
      break;
    case "perorderhot":
      number = isHot ? 1 : 0;
      break;
    case "percube":
      number = window.ARTotalCube1 ?? +autoType?.CubeCbm ?? 0;
      break;
    case "perpallet":
      number = +autoType?.Pallets ?? 0;
      break;
    case "percubecft":
      number = +autoType?.CubeCft ?? 0;
      break;
    case "perdaychassis":
      number = "perdaychassis";
      break;
    case "perdayyard":
      number = "perdayyard";
      break;
    case "perhour":
      number = "perhour";
      break;
    case "peritem":
      number = +autoType?.Items ?? 0;
      break;
    case "perload":
      number = 1;
      break;
    case "permile":
      number = "permile";
      break;
    case "pershipment":
      number = 1;
      break;
    case "perweight":
      number = window.ARTotalWeight1 ?? +autoType?.WeightKgs ?? 0;
      break;
    case "perweightlbs":
      number = window.ARTotalWeight2 ?? +autoType?.WeightLbs ?? 0;
      break;
    case "perday": //NO
      number = 0;
      break;
    default:
      number = Quantity;
      break;
  }
  return number;
}

export function Spinner2s() {
  const [show, setshow] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setshow(false);
    }, 1100);
  }, []);
  return (
    <div class="col-lg-12 mt-2 text-center">
      {show && (
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
          style={{ fontSize: "35px", width: "150px", height: "150px" }}
        />
      )}
    </div>
  );
}

function CheckAutoRate(check) {
  var eles = document.querySelectorAll(".CheckCharges") ?? [];
  var txt = "";
  eles.forEach((ele) => {
    txt += ele.innerText;
  });

  return txt.includes(check);
}
export function EventChangeAutoRate({}) {
  const [ShowChassis, setShowChassis] = useState(false);
  const [ShowMail, setShowMail] = useState(false);
  return (
    <div>
      <input
        type="hidden"
        id="setShowChassis"
        onClick={() => {
          if (CheckAutoRate("perdaychassis")) setShowChassis(true);
        }}
      />
      <ConfirmationDateAutoRate
        Show={ShowChassis}
        setShow={setShowChassis}
        Title={<b>Override chassis rates</b>}
        Body={
          <b className="ml-5">
            Do you want to override the dates for chassis rates ?
          </b>
        }
        type={"perdaychassis"}
      />

      <input
        type="hidden"
        id="setShowMail"
        onClick={() => {
          if (CheckAutoRate("permile")) setShowMail(true);
        }}
      />
      <ConfirmationDateAutoRate
        Show={ShowMail}
        setShow={setShowMail}
        Title={<b>Override per mile rates</b>}
        Body={
          <b className="ml-5">
            Do you want to override the addresses for per mile rates ?
          </b>
        }
        type={"permile"}
      />
    </div>
  );
}

export function ConfirmationDateAutoRate({
  Show,
  setShow,
  Title = "",
  Body = "",
  type = "",
}) {
  return (
    <>
      <Modal show={Show} aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">{Title}</Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => setShow(false)}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body className="row">{Body}</Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setShow(false)}
              className="btn btn-light btn-elevate"
            >
              No
            </button>

            <button
              type="button"
              onClick={() => {
                AutoRatingClick(type);
                setShow(false);
              }}
              className="btn btn-primary btn-elevate ml-2"
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export async function StartAutoRate(resArr, ChargesId = "", AutoRating, IsHot) {
  await resArr.reduce(async (promise, x) => {
    await promise;
    x.Quantity = x.Quantity ?? 0;
    if (x?.Quantity === 0) {
      var clc = AutoRatingFunction(x?.Calcul, x?.Quantity, AutoRating, IsHot);
      // not string
      if (typeof clc !== "string") x.Quantity = clc;
      // perdaychassis
      else if (x?.Calcul === "perdaychassis") {
        if (!x?.DateStart) x.DateStart = FieldID("C_PickedUpDate" + ChargesId);
        if (!x?.DateEnd) x.DateEnd = FieldID("C_TerminatedDate" + ChargesId);
        if (!x?.DateStart || !x?.DateEnd) return;
        var obj = CalcTime(
          x?.DateStart,
          x?.DateEnd,
          "perdaychassis",
          x?.Rounding
        );
        x.Quantity = obj?.number ?? 0;
      } else if (x?.Calcul === "permile") {
        var a = await GetEmilCharge(false, false, ChargesId);
        x.Quantity = +a ?? 0;
      } else if (x?.Calcul === "perdayyard" || x?.Calcul === "perhour") {
        var obj = await CalcTime(
          x?.DateStart,
          x?.DateEnd,
          x?.Calcul,
          x?.Rounding
        );
        x.Quantity = obj?.number ?? 0;
      }
    }
  }, Promise.resolve());
  return resArr;
}

export function CheckRateInCharges(
  list1 = [],
  list2 = [],
  ChargesReadOnly = false,
  setChargesRemove,
  IsHot
) {
  if (ChargesReadOnly) return list1;

  const list = [];
  const listRemove = [];

  var isHot = IsHot ?? window.ContainerIsHot === true;
  if (isHot)
    list2
      .filter((x) => x?.Calcul === "perorderhot")
      .forEach((rate) => {
        rate.ChargeId = rate?.Id;
        list.push(rate);
      });

  list1.forEach((rate) => {
    var index = list2.findIndex((x) => x?.Id === rate?.ChargeId);
    if (index > -1 || rate?.IsReadOnly || rate?.InvoiceId !== null)
      list.push(rate);
    else {
      var find = list2.find(
        (x) =>
          x?.Code === rate?.Code && rate.InvoiceId === null && rate.quantity > 0
      );
      if (find) {
        find.QuoteIdFist = rate?.QuoteId;
        find.RateFist = rate?.Rate;
        find.Quantity = rate?.QuoteId;
        find.ChargeId = find?.Id;
        find.isChange = true;
        list.push(find);
      }
      listRemove.push(rate);
    }
  });
  setChargesRemove(listRemove);
  return list;
}

export function isChangeFunction(x) {
  return (
    <>
      {x?.isChange && (
        <>
          <br />
          <br />
          <b
            className="btn btn-light-danger py-1 pl-2 pr-5"
            style={{
              position: "absolute",
              top: "18px",
              left: "9px",
              fontWeight: "900",
              fontSize: "12px",
            }}
          >
            <i class="fas fa-exclamation-triangle text-danger mr-5" />
            Removed from quote, Rate : {x?.RateFist}
          </b>
        </>
      )}
    </>
  );
}

function CalcTime(value1, value2, auroRating, rounding = 2) {
  try {
    var date1 = value1;
    var date2 = value2;

    if (date1 && date2) {
      var a = moment(date1);
      var b = moment(date2);

      var type =
        auroRating === "perdayyard" || auroRating === "perdaychassis"
          ? "days"
          : "hours";

      var number = b.diff(a, type);
      if (auroRating === "perdaychassis") number += 1;
      if (auroRating === "perhour") {
        var diff = b.valueOf() - a.valueOf();
        number = diff / 1000 / 60 / 60;
        number = RoundToFixed(number, rounding);
      }

      if (isNaN(number)) number = 0;
      return { number: number, type: type };
    } else {
      auroRating = auroRating > 0 ? auroRating : 0;
      return { number: auroRating, type: "" };
    }
  } catch (error) {}
}

export function AddSpace3(txt) {
  try {
    txt = +txt?.toFixed(2)?.toString();
    txt = txt.toLocaleString().replaceAll(",", " ");
    return txt;
  } catch (error) {
    return +txt?.toFixed(2);
  }
}

export function CalculIdCheck(CalculId) {
  let x = 0.0;
  switch (CalculId) {
    case 1:
      x = 1;
      break;
    case 2:
      x = document.querySelector('[name="Cartons"]')?.value ?? 0;
      break;

    case 3:
      x = document.querySelector('[name="Pallets"]')?.value ?? 0;
      break;

    case 4:
      x = document.querySelector('[name="TotalCube1"]')?.value ?? 0;
      break;

    default:
      x = x ?? 0;
      break;
  }
  return x;
}

export function InputValueCharge(x, ChargesReadOnly) {
  try {
    var a = x?.Quantity ?? 0;
    if (!(ChargesReadOnly ? true : x?.IsReadOnly))
      a =
        RoundToFixed(x?.Quantity ?? 0, x?.Rounding) ||
        RoundToFixed(CalculIdCheck(x?.CalculId), x?.Rounding);

    return a;
  } catch (error) {
    return x?.Quantity ?? 0;
  }
}

export function IsRounding({ x, ChargesReadOnly }) {
  return (
    <div
      style={{
        position: "absolute",
        right: "24px",
      }}
    >
      {RoundToFixed(x?.Quantity ?? 0, x?.Rounding) !== (x?.Quantity ?? 0) &&
        !(ChargesReadOnly ? true : x?.IsReadOnly) && (
          <OverlayTrigger
            overlay={
              <Tooltip id="products-edit-tooltip">
                <p class="w-100 text-center">
                  <span class="d-inline-block w-100 text-warning">
                    <b>Rounding not saved</b>
                  </span>
                  <span class="w-100">
                    <b>Old Quantity : {x?.Quantity ?? "Empty"}</b>
                  </span>
                  <br />
                  <span class="w-100 mt-2">
                    <b>
                      New Quantity :{" "}
                      {RoundToFixed(x?.Quantity ?? 0, x?.Rounding) ?? "Empty"}
                    </b>
                  </span>
                </p>
              </Tooltip>
            }
          >
            <a className="btn btn-icon btn-hover-light btn-sm mr-3">
              <span className="svg-icon svg-icon-md svg-icon-warning">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                />
              </span>
            </a>
          </OverlayTrigger>
        )}
    </div>
  );
}

export function AutoRatingItem({
  x,
  isAddCharges = false,
  index,
  AutoRating,
  IsHot,
  setAutoRatingCheck,
  IndexId = "",
  ChargesId = "",
}) {
  const auroRating = AutoRatingFunction(
    x?.Calcul,
    x?.Quantity,
    AutoRating,
    IsHot
  );
  const [Time, setTime] = useState(0);
  const [OnlyTime, setOnlyTime] = useState(0);

  const [value1, setvalue1] = useState();
  const [value2, setvalue2] = useState();

  useEffect(() => {
    if (auroRating === "permile")
      GetEmilCharge(setTime, setOnlyTime, ChargesId);
    else if (auroRating === "perdaychassis") ChassisSetValue();
    else if (auroRating > 0) setOnlyTime(auroRating);
  }, []);

  function ChassisSetValue() {
    setTimeout(() => {
      setvalue1(
        GetOnlyDateFun(x?.DateStart) ??
          GetOnlyDate("C_PickedUpDate" + ChargesId)
      );
      setvalue2(
        GetOnlyDateFun(x?.DateEnd) ??
          GetOnlyDate("C_TerminatedDate" + ChargesId)
      );
    }, 1000);
  }

  useEffect(() => {
    var obj = CalcTime(value1, value2, auroRating, x?.Rounding);
    setTime(obj?.number + " " + obj?.type);
    setOnlyTime(obj?.number);
  }, [value1, value2]);

  function GetOnlyDate(txt) {
    return GetOnlyDateFun(FieldID(txt));
  }

  function GetOnlyDateFun(txt) {
    if (!txt && txt === null) return undefined;
    try {
      const d = new Date(txt);
      var month = d.getMonth() + 1;
      var y = d.getFullYear();
      var m = month < 10 ? "0" + month : month;

      if (isNaN(d.getDate())) return undefined;

      var t = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      return y + "-" + m + "-" + t;
    } catch (error) {
      return undefined;
    }
  }

  return (
    <>
      {auroRating !== 0 && (
        <div className="w-100 row px-4 m-auto mt-5">
          {isAddCharges &&
            (auroRating === "perhour" ||
              auroRating === "perdayyard" ||
              auroRating === "perdaychassis" ||
              auroRating === "permile") && <div className="col-12 m-5" />}
          <div className={`mt-4 ${isAddCharges ? "col-4" : "col-3"}`}>
            <div
              className={`${
                isAddCharges ? "d-none" : "d-flex"
              } align-items-center `}
            >
              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                <input
                  defaultChecked={true}
                  type="checkbox"
                  onChange={(e) => {
                    setAutoRatingCheck((item) => {
                      var index = item.indexOf(x?.Id);
                      if (e.target.value && index === -1) {
                        item.push(x?.Id);
                      } else item.splice(index, 1);
                      return item;
                    });
                  }}
                />
                <span></span>
              </label>
              <div className="d-flex flex-column flex-grow-1">
                <b>{x?.Code}</b>
              </div>
            </div>
          </div>
          <div
            className={`pl-2 pr-3 mt-2 ${auroRating === "permile" && "pt-2"} ${
              isAddCharges ? "col-4" : "col-4"
            }`}
          >
            {auroRating > 0 && !isAddCharges && (
              <b className="btn btn-light-danger w-100 p-2 font-weight-bold">
                {RoundToFixed(x?.Quantity, x?.Rounding)}
              </b>
            )}
            {(auroRating === "perhour" || auroRating === "perdayyard") && (
              <DateTime30GL
                defaultvalue={x?.DateStart}
                onChange={(newValue) => {
                  setvalue1(newValue);
                }}
              />
            )}

            {auroRating === "perdaychassis" && (
              <input
                defaultValue={value1}
                type="date"
                className="form-control border"
                onChange={(e) => {
                  setvalue1(e.target.value);
                }}
              />
            )}

            {auroRating === "permile" && (
              <b
                ref={(element) => {
                  var city =
                    FieldID("C_fromCity" + ChargesId) !== ""
                      ? FieldID("C_fromCity" + ChargesId)
                      : "City is Empty";

                  var state =
                    FieldID("C_fromState" + ChargesId) !== ""
                      ? FieldID("C_fromState" + ChargesId)
                      : "State is Empty";

                  if (element) element.innerText = city + ", " + state;
                }}
              />
            )}
          </div>
          <div className={`col-1 mt-5 pl-3 ${isAddCharges && "d-none"}`}>
            {auroRating > 0 && <i class="fas fa-arrow-right"></i>}
          </div>
          <div
            className={`pl-2 pr-3 mt-2 ${auroRating === "permile" && "pt-2"} ${
              isAddCharges ? "col-4" : "col-4"
            }`}
          >
            {auroRating > 0 && !isAddCharges && (
              <b className="btn btn-light-primary w-100 p-2 font-weight-bold">
                {auroRating > 0
                  ? RoundToFixed(auroRating, x?.Rounding)
                  : auroRating}
              </b>
            )}
            {(auroRating === "perhour" || auroRating === "perdayyard") && (
              <DateTime30GL
                defaultvalue={x?.DateEnd}
                onChange={(newValue) => {
                  setvalue2(newValue);
                }}
              />
            )}
            {auroRating === "perdaychassis" && (
              <input
                defaultValue={value2}
                type="date"
                className="form-control border"
                onChange={(e) => {
                  setvalue2(e.target.value);
                }}
              />
            )}
            {auroRating === "permile" && (
              <b
                ref={(element) => {
                  var city =
                    FieldID("C_toCity" + ChargesId) !== ""
                      ? FieldID("C_toCity" + ChargesId)
                      : "City is Empty";

                  var state =
                    FieldID("C_toState" + ChargesId) !== ""
                      ? FieldID("C_toState" + ChargesId)
                      : "State is Empty";

                  if (element) element.innerText = city + ", " + state;
                }}
              />
            )}
          </div>
          {isAddCharges &&
            OnlyTime !== 0 &&
            JSChargeN(index, OnlyTime, value1, value2, x?.Rounding)}

          {!isAddCharges &&
            (auroRating === "perhour" ||
              auroRating === "perdayyard" ||
              auroRating === "perdaychassis" ||
              auroRating === "permile") && (
              <>
                <div className="col-3 mt-4" />
                <div className="col-4 pl-2 pr-3 mt-2">
                  <b className="btn btn-light-danger w-100 p-2 font-weight-bold">
                    {RoundToFixed(x?.Quantity, x?.Rounding)}
                  </b>
                </div>
                <div className="col-1 mt-5 pl-3">
                  <i class="fas fa-arrow-right"></i>
                </div>
                <div className="col-4 pl-2 pr-3 mt-2">
                  <b
                    className="btn btn-light-primary w-100 p-2 font-weight-bold"
                    id={"autoCharge" + x.Id + IndexId}
                    data-time={Time}
                    data-Start={value1}
                    data-End={value2}
                  >
                    {Time}
                  </b>
                </div>
                <div className="col-12 mt-4" />
              </>
            )}
        </div>
      )}
    </>
  );
}

function JSChargeN(index, val, value1, value2, Rounding) {
  const ele = document.getElementById("ChargeN" + index);
  if (ele) ele.value = RoundToFixed(val, Rounding);

  const ele1 = document.getElementById("DateStart" + index);
  if (ele1 && value1) ele1.value = value1;

  const ele2 = document.getElementById("DateEnd" + index);
  if (ele2 && value2) ele2.value = value2;
}

export async function GetEmilCharge(setTime, setOnlyTime, ChargesId = "") {
  const fromCity = FieldID("C_fromCity" + ChargesId);
  const fromState = FieldID("C_fromState" + ChargesId);
  const toCity = FieldID("C_toCity" + ChargesId);
  const toState = FieldID("C_toState" + ChargesId);

  return await fetch(
    window.$apiurl +
      `/tracking/getdistance?fromcity=${fromCity}&fromstate=${fromState}&destCity=${toCity}&destState=${toState}&fromCountry=&destCountry=`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      if (!!setTime) setTime(res[1] ?? 0 + " ");
      if (!!setOnlyTime) setOnlyTime(res[1] ?? 0);
      return res[1];
    })
    .catch((error) => {
      console.error(error);
    });
}

export const LockPayblesButton = ({
  isPrimary = false,
  Id,
  IsReceipt = false,
  FreightChargeId,
  ArePayablesReadOnly,
  setArePayablesReadOnly = false,
}) => {
  const [freightChargeId, setfreightChargeId] = useState();
  const [isLock, setisLock] = useState(ArePayablesReadOnly);
  useEffect(() => {
    setfreightChargeId(FreightChargeId);
  }, [FreightChargeId]);

  return (
    <>
      {+freightChargeId === 99 && (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            LockPaybles(Id, IsReceipt, !isLock).then(() => {
              if (setArePayablesReadOnly) setArePayablesReadOnly(!isLock);
              setisLock((x) => !x);
            });
            e.stopPropagation();
          }}
          className={`font-weight-bold btn btn-elevate ml-3  ${
            isPrimary ? "btn-light-primary py-1" : "btn-light text-dark py-0"
          }`}
        >
          <b>{isLock ? "UnLock" : "Lock"} </b>
          {isLock ? (
            <i
              className={`fas fa-lock-open text-${
                isPrimary ? "" : "primary"
              } ml-1`}
              style={{ fontSize: "12px" }}
            />
          ) : (
            <i
              className={`fas fa-lock text-${isPrimary ? "" : "primary"} ml-1`}
              style={{ fontSize: "12px" }}
            />
          )}
        </button>
      )}
      {/* <LinearProgress /> */}
    </>
  );
};

export async function LockPaybles(Id, IsReceipt, Lock) {
  return await fetch(window.$apiurl + `/Charges/LockCharges`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Id: Id,
      Lock: Lock,
      IsReceipt: IsReceipt,
    }),
  })
    .then(async (response) => {
      const statusCode = await response.status;
      if (statusCode === 200)
        Notification("success", Lock ? "Lock success" : "UnLock success");
      const data = (await response?.json()) ?? "";
      return Promise.all([statusCode, data]);
    })
    .then((res) => {})
    .catch((error) => {
      console.error(error);
    });
}

export function FieldID(name) {
  return document.getElementById(name)?.value ?? "";
}

export function MenuListComposition({ Child1, Child2, Child3 }) {
  let ref = React.useRef(null);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>
        <MenuItem onClick={() => ref.handleHide()}>{Child1}</MenuItem>
        <MenuItem onClick={() => ref.handleHide()}>{Child2}</MenuItem>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      ref={(r) => (ref = r)}
      container={ref.current}
      trigger="click"
      placement="auto"
      overlay={popover}
      rootClose
    >
      <Button variant="dark">
        <MoreVertIcon />
      </Button>
    </OverlayTrigger>
  );
}

export const ChargesFunctionCompo = ({
  VandorSelectedData,
  customers,
  optionsVandorList,
  isInbound,
  linq = "",
}) => {
  const [VendorsId, setVendorsId] = useState();
  const [VendorsList, setVendorsList] = useState([]);
  const [CarrierId, setCarrierId] = useState();
  const [values, setValues] = useState();

  useEffect(() => {
    //CheckVendor
    var vendorId = VandorSelectedData;
    if (!VandorSelectedData && customers?.length > 0)
      vendorId = customers.find((x) => x?.VendorId !== null || "")?.VendorId;

    if (vendorId) setVendorsId(vendorId);

    var vendorItem = optionsVandorList.find((x) => x?.value === vendorId);
    if (vendorItem) setCarrierId(vendorItem?.CarrierId);
  }, [VandorSelectedData, optionsVandorList]);

  useEffect(() => {
    if (VendorsId && customers?.length > 0)
      GetList(customers[0]?.Id, VendorsId, setValues);
  }, [VendorsId]);

  function setChergesFun(a) {
    const idsShipments = [];
    customers !== null &&
      customers !== undefined &&
      customers.length > 0 &&
      customers.forEach((item) => {
        idsShipments.push(item.Id);
      });
    window.$payablesRates = JSON.stringify({
      Ids: idsShipments,
      VendorId: VendorsId,
      Payables: a,
    });
  }

  async function GetList(id, vendorId, setValues) {
    return await fetch(
      `${window.$apiurl}/${linq}/payables/${id}?vendorId=${vendorId}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        window.AutoRating = a?.AutoRating;
        window.ContainerIsHot = a?.IsHot ?? false;
        setValues(a);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div>
      <input type="hidden" id="C_CustomerId" value={values?.CustomerId ?? ""} />
      <input type="hidden" id="C_ServiceId" value={values?.ServiceId ?? ""} />
      <input type="hidden" id="C_ModeId" value={values?.ModeId ?? ""} />
      <input
        type="hidden"
        id="C_ServiceLevelId"
        value={values?.ServiceLevelId ?? ""}
      />
      <input
        type="hidden"
        id="C_EquipmentTypeId"
        value={values?.EquipmentTypeId ?? ""}
      />
      <input
        type="hidden"
        id="C_EquipmentSizeId"
        value={values?.EquipmentSizeId ?? ""}
      />
      <input type="hidden" id="C_ReceivedDate" value="" />
      <input type="hidden" id="C_PickedUpDate" value="" />
      <input type="hidden" id="C_TerminatedDate" value="" />

      <input type="hidden" id="C_fromCity" value={values?.FromCity ?? ""} />
      <input
        type="hidden"
        id="C_fromAddress"
        value={values?.FromAddress ?? ""}
      />
      <input type="hidden" id="C_fromState" value={values?.FromState ?? ""} />
      <input
        type="hidden"
        id="C_fromZipCode"
        value={values?.FromZipcode ?? ""}
      />

      <input type="hidden" id="C_toCity" value={values?.ToCity ?? ""} />
      <input type="hidden" id="C_toAddress" value={values?.ToAddress ?? ""} />
      <input type="hidden" id="C_toState" value={values?.ToState ?? ""} />
      <input type="hidden" id="C_toZipCode" value={values?.ToZipcode ?? ""} />

      <input type="hidden" id="C_CarrierId" value={CarrierId} />

      {CarrierId && values && (
        <ChargesFunction
          isInbound={isInbound}
          isPayable={true}
          type="Payables"
          list={values?.Payables}
          setChergesFun={setChergesFun}
        />
      )}
    </div>
  );
};

export function ChargesRemoveComponent({ ChargesRemove = [] }) {
  const Bg = "bg-light-warning";
  return (
    <>
      <b className="text-warning ml-5 pl-3 mb-3">
        Removed Charges : Count {ChargesRemove.length}
      </b>
      <table
        className="table table-head-custom table-head-bg table-vertical-center rounded"
        style={{ width: "93%", margin: "auto", overflow: "hidden" }}
      >
        <thead>
          <tr className={`text-left text-uppercase ${Bg} rounded-0`}>
            <th className={`border-0 ${Bg} rounded-0`}>
              <b className="text-warning">Code</b>
            </th>
            <th className={`border-0 ${Bg}`}>
              <b className="text-warning">Name</b>
            </th>
            <th className={`border-0 ${Bg}`}>
              <b className="text-warning">Rate</b>
            </th>
            <th className={`border-0 ${Bg} rounded-0`}>
              <b className="text-warning">Quantity</b>
            </th>
          </tr>
        </thead>

        {ChargesRemove.map((x, index) => {
          return (
            <>
              <tbody
                className="border-0"
                key={x?.Code + "" + x?.Id + "" + index + (x?.Invoice ?? "")}
              >
                <tr className={`${Bg}`}>
                  <td className="border-0">
                    {isChangeFunction(x)}
                    <b className={`title mr-2`}>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="products-edit-tooltip">
                            <p class="w-100 text-center">
                              <span class="d-inline-block w-100 text-primary">
                                <b>Quote # </b>
                              </span>
                              <span class="w-100">{x?.QuoteId ?? "Empty"}</span>
                            </p>
                          </Tooltip>
                        }
                      >
                        <a className="btn btn-icon btn-hover-light btn-sm mr-3">
                          <span className="svg-icon svg-icon-md svg-icon-info">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Code/Info-circle.svg"
                              )}
                            />
                          </span>
                        </a>
                      </OverlayTrigger>

                      {x?.Code}
                      <span class="ml-2 text-primary">
                        <b>{x?.CalculName}</b>
                      </span>
                    </b>
                  </td>
                  <td className="border-0">{x?.Name}</td>
                  <td className="border-0">{x?.Rate}</td>
                  <td className="border-0">{x?.Quantity}</td>
                </tr>
              </tbody>
            </>
          );
        })}
      </table>
    </>
  );
}

export const ChargesValues = ({ values, ChargesId, index = 0 }) => {
  function IdAddCharges(id) {
    return id + "" + ChargesId;
  }
  return (
    <div>
      <input
        type="hidden"
        id={IdAddCharges("C_CustomerId")}
        value={values?.CustomerId ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_ServiceId")}
        value={values?.ServiceId ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_ModeId")}
        value={values?.ModeId ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_ServiceLevelId")}
        value={values?.ServiceLevelId ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_EquipmentTypeId")}
        value={values?.EquipmentTypeId ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_EquipmentSizeId")}
        value={values?.EquipmentSizeId ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_PuAppointment")}
        value={values?.PuAppointment ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_ReceivedDate")}
        value={values?.ReceivedDate ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_PickedUpDate")}
        value={values?.PickedUpDate ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_fromCity")}
        value={values?.ShipperCity ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_fromAddress")}
        value={values?.ShipperAddress ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_fromState")}
        value={values?.ShipperState ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_fromZipCode")}
        value={values?.ShipperZipCode ?? ""}
      />

      <input
        type="hidden"
        id={IdAddCharges("C_toCity")}
        value={values?.ConsigneeCity ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_toAddress")}
        value={values?.ConsigneeAddress ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_toState")}
        value={values?.ConsigneeState ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_toZipCode")}
        value={values?.ConsigneeZipCode ?? ""}
      />
      <input
        type="hidden"
        id={IdAddCharges("C_CarrierId")}
        value={values?.Vendors[index]?.CarrierId ?? ""}
      />
    </div>
  );
};
