import React, { useEffect, useState } from "react";
import Index from "./AssigntoPallet/Index.js";
import { default as JsNative } from "jquery";
import { Modal } from "react-bootstrap";
import { Notification } from "../../modules/Notification";
import Button from "@material-ui/core/Button";
import low from "lowdb";
import Select from "react-select";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Helmet } from "react-helmet";

const adapter = new LocalStorage("db");
const db = low(adapter);

const mystyleTabs = {
  fontSize: "13px",
};

export function AssigntoPallet() {
  const [PalletsIDStat, setPalletsStat] = useState([]);
  const [Pallets, setPallets] = useState([]);
  const [ShipmentNewModel, setShipmentNewModelt] = useState(false);

  useEffect(() => {
    GetPallets();
    window.GetAllApi = undefined;
  }, []);

  async function GetPallets() {
    return await fetch(`${window.$apiurl}/Pallets?IsShipped=false`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        return setPallets(res[1]?.pallets);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function setPallet() {
    var obj = PalletsIDStat[0];
    obj.CustomerId = JsNative('[name="CustomerId"]').val();
    obj.ConsigneeId = JsNative('[name="ConsigneeId"]').val();
    obj.ShipperId = JsNative('[name="ShipperId"]').val();
    obj.WarehouseId = JsNative('[name="WarehouseId"]').val();
    obj.LocationId = JsNative('[name="LocationId"]').val();

    setPalletsStat([]);
    setTimeout(() => {
      setPalletsStat([obj]);
    }, 1);
  }

  return (
    <>
      <Helmet>
        <title>eMPower | Assign to Pallet</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>

      <Modal show={ShipmentNewModel}>
        <Modal.Header closeButton>
          <Modal.Title>New Pallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="ModelShipment" className="text-center">
            <div className="form-group row">
              <div className="col-lg-12 mt-5">
                <input
                  type="text"
                  className="form-control ScrollingMenuApp "
                  id="LabelForPalletID"
                  placeholder="Label For Pallet"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setShipmentNewModelt(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-success btn-elevate"
            onClick={() => {
              setShipmentNewModelt(false);
              PostNewPallet().then((e) => {
                if (e[0] === 200) {
                  Notification(
                    "success",
                    "Create New Pallet ID : " + e[1]?.palletVm?.Id
                  );
                  setShipmentNewModelt(false);
                  GetPallets();
                  setPalletsStat([]);
                  setTimeout(() => {
                    setPalletsStat([e[1]?.palletVm]);
                  }, 1);
                }
              });
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card">
        <div className="card-header font-weight-bold py-0 pt-5 pb-3">
          <div className="form-group row mb-0">
            <div className="col-xl-2 col-lg-4 col-md-6 text-left mt-2 pt-1 text-right ml-auto">
              <button
                type="button"
                className="btn btn-light-success font-weight-bolder font-size-sm w-100  pb-3 mb-3"
                style={mystyleTabs}
                onClick={() => {
                  setShipmentNewModelt(true);
                }}
              >
                <i className="fas fa-plus-circle"></i> New Pallet
              </button>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-6 text-left mt-2 pt-1 text-right">
              <button
                type="button"
                className="btn btn-light-warning font-weight-bolder font-size-sm w-100  pb-3 mb-3"
                style={mystyleTabs}
                disabled={PalletsIDStat.length === 0}
                onClick={() => {
                  PostClonePallet(PalletsIDStat[0].Id).then((e) => {
                    if (e[0] === 200) {
                      Notification(
                        "success",
                        "Clone New Pallet ID : " + e[1]?.palletVm?.Id
                      );
                      setShipmentNewModelt(false);
                      GetPallets();
                      setPalletsStat([]);
                      setTimeout(() => {
                        setPalletsStat([e[1]?.palletVm]);
                      }, 1);
                    }
                  });
                }}
              >
                <i className="fas fa-clone"></i> Clone
              </button>
            </div>
            {PalletsIDStat[0]?.Status !== undefined &&
              PalletsIDStat[0]?.Status !== "Closed" && (
                <div className="col-xl-2 col-lg-4 col-md-6 text-left mt-2 pt-1 text-right">
                  <button
                    type="button"
                    className="btn btn-light-danger font-weight-bolder font-size-sm w-100  pb-3 mb-3"
                    style={mystyleTabs}
                    disabled={PalletsIDStat?.length === 0}
                    onClick={() => {
                      PosttoClosed(
                        PalletsIDStat[0],
                        setPalletsStat,
                        Pallets,
                        setPallets
                      );
                    }}
                  >
                    <i className="fas fa-times-circle"></i> Close & GO Manifest
                  </button>
                </div>
              )}

            {PalletsIDStat[0]?.Status !== undefined &&
              PalletsIDStat[0]?.Status === "Closed" && (
                <div className="col-xl-2 col-lg-4 col-md-6 text-left mt-2 pt-1 text-right">
                  <button
                    type="button"
                    className="btn btn-light-danger font-weight-bolder font-size-sm w-100  pb-3 mb-3"
                    style={mystyleTabs}
                    disabled={PalletsIDStat?.length === 0}
                    onClick={() => {
                      window
                        .open(
                          `/PalletManifest/` + PalletsIDStat[0]?.Id,
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    <i className="fas fa-print"></i> Manifest
                  </button>
                </div>
              )}

            <div className="col-xl-3 col-lg-4 col-md-12 text-left">
              <Select
                className="w-100 mt-3"
                isSearchable={true}
                isClearable
                value={{
                  value: PalletsIDStat[0]?.Id,
                  label: PalletsIDStat[0]?.Id ?? "Select ...",
                }}
                options={(Pallets ?? [])
                  .filter((x) => x?.IsClosed === false)
                  .map((x) => {
                    return {
                      value: x?.Id,
                      label: x?.Id,
                    };
                  })}
                onChange={async (newValue) => {
                  var i = newValue?.value;
                  var item = Pallets.find((x) => x?.Id === i);
                  setPalletsStat([]);
                  setTimeout(() => {
                    if (item) setPalletsStat([item]);
                  }, 1);
                }}
              />
            </div>
          </div>
        </div>
        <div className="card-body AssigntoOutboundId">
          {PalletsIDStat?.length > 0 ? (
            <Index PalletsIDStat={PalletsIDStat} setPallet={setPallet} />
          ) : (
            <Nodatafound />
          )}
        </div>
      </div>
    </>
  );
}

function Nodatafound() {
  return (
    <div className="row">
      <div className="col-xl-4 col-lg-6 col-md-6 mt-2 mx-auto">
        <p className="h1 mt-5" style={{ opacity: 0.3, fontSize: "70px" }}>
          <i className="fas fa-quote-left mr-3" style={{ fontSize: "70px" }} />
          No data found
        </p>
      </div>
    </div>
  );
}

async function PostClonePallet(Id) {
  return await fetch(`${window.$apiurl}/pallets/clone/${Id}`, {
    method: "GET",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  }).then(async (response) => {
    const statusCode = response.status;
    const data = statusCode === 200 ? await response.json() : "";
    return Promise.all([statusCode, data]);
  });
}

async function PostNewPallet() {
  return await fetch(`${window.$apiurl}/pallets`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Email: "",
    }),
  }).then(async (response) => {
    const statusCode = response.status;
    const data = statusCode === 200 ? await response.json() : "";
    return Promise.all([statusCode, data]);
  });
}

async function PosttoClosed(
  PalletsIDStat,
  setPalletsStat,
  Pallets,
  setPallets
) {
  return await fetch(`${window.$apiurl}/pallets/UpdateSingleStatus`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Id: PalletsIDStat?.Id,
      StatusId: 84,
    }),
  }).then(async (response) => {
    const statusCode = response.status;
    if (statusCode === 200) {
      Notification("success", "success");
      window.open(`/PalletManifest/` + PalletsIDStat?.Id, "_blank").focus();
      setPalletsStat([]);
      setTimeout(() => {
        PalletsIDStat.Status = "Closed";
        setPalletsStat([PalletsIDStat]);

        var a = Pallets.filter((x) => x.Id !== PalletsIDStat?.Id);
        setPallets(a ?? []);
      }, 1);
    } else {
      const data = await response.json();
      Notification("error", data.Message);
    }
  });
}
