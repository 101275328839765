import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { string } from "prop-types";
import { Notification } from "../../../modules/Notification";
import MockUtils from "./mock.utilsDynamic";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default async function mockCustomerProfileRating(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetUsersData() {
    return await fetch(window.$apiurl + "/rating/charges?IsCustomer=" + true, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostCustomerProfileRating(someData) {
    return fetch(window.$apiurl + "/rating/charges?IsCustomer=" + true, {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  async function PutUser(someData, id) {
    return fetch(
      window.$apiurl + "/rating/charges/" + id + "?IsCustomer=" + true,
      {
        method: "put",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
        body: JSON.stringify(someData),
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function DeleteUser(id) {
    return await fetch(window.$apiurl + "/rating/charges/" + id, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetCustomers() {
    return await fetch(window.$apiurl + "/rating/customer/viewmodel", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function PostCloneRate(id) {
    return fetch(window.$apiurl + "/rating/clone/" + id, {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }
  //Get View Model
  async function GetViewModelCompanies() {
    return await fetch(window.$apiurl + "/contact/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res.Countries;
      })
      .catch((err) => {
        return false;
      });
  }

  async function GetGlAccounts() {
    return await fetch(window.$apiurl + "/rating/charges/viewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return false;
      });
  }
  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/CustomerProfileRating/GetAllApi").reply(async () => {
    const obj = {};
    obj["viewModel"] = await GetCustomers();
    obj["CountriesList"] = await GetViewModelCompanies();
    obj["GlAccounts"] = await GetGlAccounts();
    return [200, obj];
  });
  mock.onGet("api/CustomerProfileRating/CloneRate").reply(async () => {
    const id = window.$RateId;
    await PostCloneRate(id);
    document.getElementById("ReLoadTableId").click();
    Notification("success", "Your rate has been cloned successfully.");
  });
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const result = [month, day, year].join("/");
    if (result !== "NaN/NaN/NaN") {
      return result;
    } else {
      return "--/--/----";
    }
  }
  mock.onPost("api/CustomerProfileRating").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const start = Date.now();
    const dateNow = formatDate(start);
    const {
      Code = "",
      Name = "",
      Description = "",
      RateTypeId = "",
      IsActive = false,
      IsForTwoTen = false,
      ModeId = "",
      ServiceId = null,
      ServiceLevelId = null,
      EquipmentTypeId = null,
      EquipmentSizeId = null,
      PickUpAddress1 = "",
      PickUpAddress2 = "",
      PickUpZipCode = "",
      PickUpCity = "",
      PickUpState = "",
      PickUpCountry = "",
      DeliveryAddress1 = "",
      DeliveryAddress2 = "",
      DeliveryZipCode = "",
      DeliveryCity = "",
      DeliveryState = "",
      DeliveryCountry = "",
      GlAccounts = [],
      ChargeOptionId = "",
      vendorTypeId = null
    } = customer;
    const id = generateUserId();
    const newCustomer = {
      id,
      Name,
      Code,
      Description,
      RateTypeId,
      IsActive,
      ModeId,
      ServiceId,
      ServiceLevelId,
      EquipmentTypeId,
      EquipmentSizeId,
      PickUpAddress1,
      PickUpAddress2,
      PickUpZipCode,
      PickUpCity,
      PickUpState,
      PickUpCountry,
      DeliveryAddress1,
      DeliveryAddress2,
      DeliveryZipCode,
      DeliveryCity,
      DeliveryState,
      DeliveryCountry,
      GlAccounts,
      IsForTwoTen,
      ChargeOptionId,
      vendorTypeId
    };
    var postCustomerProfile = await PostCustomerProfileRating(newCustomer);
    if (postCustomerProfile.Id !== undefined) {
      Notification(
        "success",
        "Customer Profile Rate has been added successfully"
      );
      postCustomerProfile.id = postCustomerProfile.Id;
      customerTableMock.push(newCustomer);
      document.getElementById("ReLoadTableId").click();
      return [
        200,
        {
          customer: newCustomer,
          message: postCustomerProfile.Message,
          success: true,
        },
      ];
    } else {
      if (postCustomerProfile.Message) {
        document.getElementById("ReLoadTableId").click();
        Notification("error", postCustomerProfile.Message);
      } else {
        Notification(
          "success",
          "Customer Profile Rate has been added successfully"
        );
        document.getElementById("ReLoadTableId").click();
        return [
          200,
          {
            customer: newCustomer,
            message: postCustomerProfile.Message,
            success: true,
          },
        ];
      }
    }
  });
  function filterDataTable(stringValue,filter,data){
    const params = 'filter.'+stringValue;
    console.log(params);
    data = data.filter((filter) =>
    params
    .trim()
    .includes(String(filter).trim()));
    return data; 
  }
  mock.onPost("api/CustomerProfileRating/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    if(queryParams.filter.searchText){
      const oldData = window.$CustomerDataFiltered;
      const searchValue = queryParams.filter.searchText;
      customerTableMock = oldData.filter((word) =>
      String(word.Name)
      .trim()
      .toLowerCase()
      .includes(String(searchValue).trim().toLowerCase()) || 

      String(word.Code)
      .toLowerCase()
      .includes(String(searchValue).trim().toLowerCase()) || 

      String(word.id)
      .includes(String(searchValue).trim()) 
      );
    }
    const dataFiltered = window.$filterRatingCust;
    if (queryParams?.pageNumber === 1 && !queryParams.filter.searchText) {
      customerTableMock = await GetUsersData();
      window.$CustomerDataFiltered = customerTableMock;
      if(dataFiltered){
        if(queryParams.filter.Service !== ""){
          customerTableMock = customerTableMock.filter((word) =>
          String(word.Service)
          .trim()
          .toLowerCase()
          .includes(String(dataFiltered.Service).trim().toLowerCase())
          );
        }
        if(queryParams.filter.ServiceLevel !== ""){
          customerTableMock = customerTableMock.filter((word) =>
          String(word.ServiceLevel)
          .trim()
          .toLowerCase()
          .includes(String(dataFiltered.ServiceLevel).trim().toLowerCase())
          );
        }
        if(queryParams.filter.Mode !== ""){
          customerTableMock = customerTableMock.filter((word) =>
          String(word.Mode)
          .trim()
          .toLowerCase()
          .includes(String(dataFiltered.Mode).trim().toLowerCase())
          );
        }
        if(dataFiltered && queryParams.filter.EquipmentSize !== ""){
          customerTableMock = customerTableMock.filter((word) =>
          String(word.EquipmentSize)
          .trim()
          .toLowerCase()
          .includes(String(dataFiltered.EquipmentSize).trim().toLowerCase())
          );
        }
        if(queryParams.filter.EquipmentType !== ""){
          customerTableMock = customerTableMock.filter((word) =>
          String(word.EquipmentType)
          .trim()
          .toLowerCase()
          .includes(String(dataFiltered.EquipmentType).trim().toLowerCase())
          );
        }
        if(queryParams.filter.RateType !== ""){
          customerTableMock = customerTableMock.filter((word) =>
          String(word.RateType)
          .trim()
          .toLowerCase()
          .includes(String(dataFiltered.RateType).trim().toLowerCase())
          );
        }
      }
    }
    if(!dataFiltered){
      if(queryParams.filter.Service !== undefined){
        customerTableMock = customerTableMock.filter((word) =>
        String(word.Service)
        .trim()
        .toLowerCase()
        .includes(String(queryParams.filter.Service).trim().toLowerCase())
        );
      }
      if(queryParams.filter.ServiceLevel !== undefined){
        customerTableMock = customerTableMock.filter((word) =>
        String(word.ServiceLevel)
        .trim()
        .toLowerCase()
        .includes(String(queryParams.filter.ServiceLevel).trim().toLowerCase())
        );
      }
      if(queryParams.filter.Mode !== undefined){
        customerTableMock = customerTableMock.filter((word) =>
        String(word.Mode)
        .trim()
        .toLowerCase()
        .includes(String(queryParams.filter.Mode).trim().toLowerCase())
        );
      }
      if(queryParams.filter.EquipmentSize !== undefined){
        customerTableMock = customerTableMock.filter((word) =>
        String(word.EquipmentSize)
        .trim()
        .toLowerCase()
        .includes(String(queryParams.filter.EquipmentSize).trim().toLowerCase())
        );
      }
      if(queryParams.filter.EquipmentType !== undefined){
        customerTableMock = customerTableMock.filter((word) =>
        String(word.EquipmentType)
        .trim()
        .toLowerCase()
        .includes(String(queryParams.filter.EquipmentType).trim().toLowerCase())
        );
      }
      if(queryParams.filter.RateType !== undefined){
        customerTableMock = customerTableMock.filter((word) =>
        String(word.RateType)
        .trim()
        .toLowerCase()
        .includes(String(queryParams.filter.RateType).trim().toLowerCase())
        );
      }
    }
    const filterIsActive = queryParams.filter.IsActive;
    if (filterIsActive === undefined) {
      customerTableMock = customerTableMock.filter((word) =>
        String(word.IsActive).includes(String(true))
      );
    } else {
      if (filterIsActive) {
        customerTableMock = customerTableMock.filter((word) =>
          String(word.IsActive).includes(String(filterIsActive))
        );
      } else {
        customerTableMock = customerTableMock.filter((word) =>
          String(word.IsActive).includes(String(false))
        );
      }
    }
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.searchText;

    const filteredCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filteredCustomers];
  });

  mock.onPost("api/CustomerProfileRating/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/CustomerProfileRating/updateStatusForCustomers")
    .reply((config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  async function GetUser(id) {
    return await fetch(window.$apiurl + "/rating/charges/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  mock.onGet(/api\/CustomerProfileRating\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/CustomerProfileRating\/(\d+)/)[1];
    const customer = await GetUser(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/CustomerProfileRating\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/CustomerProfileRating\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    const result = await PutUser(customer, id);
    if(result?.Message){
      Notification(
        "errorClick",
        result?.Message
      );
      return false;
    }else{
      const index = customerTableMock.findIndex((el) => el.id === +id);
    customerTableMock[index] = { ...customer };
    Notification(
      "success",
      "Customer Profile Rate has been updated successfully"
    );
    document.getElementById("ReLoadTableId").click();
    return [200];
    }
  });

  mock.onDelete(/api\/CustomerProfileRating\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/CustomerProfileRating\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteUser(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
