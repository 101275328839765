import React from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
const SelectPagination = (props) => {
  const loadOptions = async (search, prevOptions) => {
    await sleep(2);
    let filteredOptions;
    try {
      if (!search) {
        filteredOptions = props.options;
      } else {
        filteredOptions = props.options.filter(
          (word) =>
            String(word.value)
              .toLowerCase()
              .includes(
                String(search)
                  .toLowerCase()
                  .trim()
              ) ||
            String(word.label)
              .toLowerCase()
              .includes(
                String(search)
                  .toLowerCase()
                  .trim()
              )
        );
      }
      const hasMore = filteredOptions.length > prevOptions.length + 10;
      const slicedOptions = filteredOptions.slice(
        prevOptions.length,
        prevOptions.length + 10
      );

      if (props.searchBy && search) {
        slicedOptions.push({
          value: search,
          label: props.searchBy + " " + search,
        });
      }

      return {
        options: slicedOptions,
        hasMore: hasMore,
      };
    } catch (err) {}
  };

  const onChange = (option) => {
    if (typeof props?.onChange === "function") {
      props.onChange(option);
    }
  };
  const initialOptions = props?.options.slice(0, 10);

  return (
    <>
      {props?.label && <label>{props?.label}</label>}
      <AsyncPaginate
        isOptionDisabled={props?.isOptionDisabled}
        options={initialOptions}
        name={props?.name}
        className={props?.className}
        isMulti={props?.isMulti ?? false}
        key={JSON.stringify(props.name)}
        value={props.value}
        isDisabled={props?.isDisabled}
        loadOptions={loadOptions}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
        onChange={onChange}
        isClearable
        id={props?.id}
        placeholder={props?.placeholder}
        additional={{
          page: 1,
        }}
      />
    </>
  );
};

SelectPagination.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default SelectPagination;
