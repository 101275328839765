// Form is based on Formik
// Data validation is based on Yup
import { LinearProgress } from "@material-ui/core";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import $ from "jquery";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";
import {
  IsValidMessage,
  jqueryChange,
} from "../../../../../../../../_metronic/_helpers/ATHelpers";
import {
  AttachementsComponent,
  CheckboxLargeWB,
  CreatableSelect2,
  CreatableSelect3,
  DateTime30,
  Input,
  Select,
} from "../../../../../../../../_metronic/_partials/controls";
const adapter = new LocalStorage("db");
const db = low(adapter);
// Validation schema
const RemarkEditSchema = Yup.object().shape({});
const catchError = [];
export function RemarkEditForm({
  saveRemark,
  remark,
  actionsLoading,
  onHide,
  shipment = false,
  isScencty = false,
  SKUsList = false,
  GetSKu,
}) {
  const [ReceiptId, setReceiptId] = useState(false);
  const [PackagingGroupsList, setPackagingGroupsList] = useState(catchError);
  const [HazardClassesList, setHazardClassesList] = useState(catchError);
  const [isHasardOfMaterial, setisHasardOfMaterial] = useState(false);
  const [LBSWeight, setLBSWeight] = useState(1);
  const [LBSWeightPut, setLBSWeightPut] = useState("");
  const [putModeWeight, setPutModeWeight] = useState(false);
  const [disabledInput, setdisabledInput] = useState(false);
  const [CFTCube, setCFTCube] = useState(1);
  const [CFTCubePut, setCFTCubePut] = useState("");
  const [putModeCFTCube, setPutModeCFTCube] = useState(false);
  const [isLtlForm, setIsLtl] = useState(false);
  const [UnitOfMeasures, setUnitOfMeasures] = useState(catchError);

  const [consigneList, setConsigneList] = useState([]);

  useEffect(() => {
    remark.ReceiptId = window?.ReceiptId || 0;
    if (window.location.pathname === "/TransportManagementSystem") {
      setIsLtl(true);
    } else {
      setIsLtl(false);
    }
    setReceiptId(window?.ReceiptId);
  }, [window?.ReceiptId]);

  function SetValuefunction(id, value) {
    var element = document.getElementById(id);
    if (element != null)
      setTimeout(() => {
        element.value = value;
      }, 500);
  }

  useEffect(() => {
    var weightType = remark?.WeightType || false;
    if (weightType) {
      SetValuefunction("WeightTypeId", weightType);
    }
    var cubeType = remark?.CubeType || false;
    if (cubeType) {
      SetValuefunction("CubeTypeId", cubeType);
    }
    var WeightMode = remark?.WeightMode || false;
    if (WeightMode) {
      SetValuefunction("WeightMode", WeightMode);
    }
    document.getElementById("IsHazardousMateriel").checked =
      remark.IsHazardousMateriel;

    document.getElementById("IsHot").checked = remark.IsHot;

    setisHasardOfMaterial(remark.IsHazardousMateriel);

    var weight = remark?.Weight || 1;
    setLBSWeight(weight * 2.2);

    var cube = remark?.Cube || 1;
    setCFTCube(cube * 35.3147);
    /* try {
      setConsigneValue({
        value: remark.DestinationId,
        label: `${remark.DestinationCode ?? ""} - ${remark.DestinationName ??
          ""}`,
      });
    } catch (err) {} */

    try {
      if (
        remark.StatusId === 6 &&
        remark.IsLocked !== null &&
        remark.IsLocked
      ) {
        setReadOnly(true);
      } else {
        setReadOnly(false);
      }
    } catch (err) {}
    if (remark.Class) {
      setFreightClassSelect({ label: remark.Class });
    }

    //LTL GET DATA
    try {
      if (remark?.Width) {
        setWidthValue(remark.Width);
      }
      if (remark?.Height) {
        setHeightValue(remark.Height);
      }
      if (remark?.Length) {
        setLengthValue(remark.Length);
      }
      if (remark?.Weight) {
        setWeightValue($("#WeightId").val());
      }
      if (remark?.Class) {
        setFreightClassSelect({ value: remark.Class, label: remark.Class });
      }
      if (remark?.Width && remark?.Weight && remark?.Height && remark?.Length) {
        setIsChangingClass(true);
      }
    } catch (err) {}
  }, [remark]);

  useEffect(() => {
    var UnitOfMeasureId = remark?.UnitOfMeasureId || false;
    jqueryChange("UnitOfMeasureId", UnitOfMeasureId);
    if (remark.isHasardOfMaterial) {
      setisHasardOfMaterial(remark.isHasardOfMaterial);
    }
  }, [remark, UnitOfMeasures]);

  useEffect(() => {
    fetchUsersAsync();
    fetchConsigneList();
    GetViewModelStops();
  }, []);

  useEffect(() => {
    if (consigneList?.length > 0 && remark?.DestinationId) {
      var a = consigneList.find((x) => x.Id === remark?.DestinationId);
      setConsigneValue({
        value: remark.DestinationId,
        label: `${a?.Code ?? ""} - ${a?.CompanyName ?? ""}`,
      });
    }
  }, [remark?.DestinationId, consigneList]);

  async function fetchConsigneList() {
    return await fetch(window.$apiurl + "/transload/orders/getviewmodel", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        setConsigneList(a?.Consignees);
        window.$consigneeList = a?.Consignees;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const [stopsList, setStopsList] = React.useState([]);

  async function GetViewModelStops() {
    return await fetch(
      window.$apiurl + `/TransportationItem/GetViewModel/${remark.ReceiptId}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        setStopsList(a?.Stops);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const fetchUsersAsync = async () => {
    try {
      const result = await axios.get("api/FindOrderContainers/GetAllApi");
      setPackagingGroupsList(
        result.data.ViewModel.PackagingGroups || catchError
      );
      setUnitOfMeasures(result.data.ViewModel.UnitOfMeasures || catchError);
      setHazardClassesList(result.data.ViewModel.HazardClasses || catchError);
    } catch (e) {}
  };

  function CubeTypeCalc() {
    const cubeTypeId = $("#CubeTypeId").val();
    const cubeId = $("#CubeId").val();
    if (cubeTypeId === "CBM") {
      setPutModeCFTCube(false);
      setCFTCube(cubeId * 35.3147);
    } else {
      setPutModeCFTCube(true);
      setCFTCubePut(cubeId);
    }
  }

  function WeightTypeCalc(isChangingClass) {
    const weightType = $("#WeightTypeId").val();
    const cubeId = $("#WeightId").val();
    if (isChangingClass) {
      setIsChangingClass(false);
      setWeightValue(cubeId);
    }
    if (weightType === "KGS") {
      setPutModeWeight(false);
      setLBSWeight(cubeId * 2.20462);
    } else {
      setPutModeWeight(true);
      setLBSWeightPut(cubeId);
    }
  }

  const [consigneValue, setConsigneValue] = useState({
    value: "",
    label: "Select",
  });

  useEffect(() => {
    try {
      if (stopsList.length > 0 && remark?.StopId) {
        var currentStop = stopsList?.find((x) => x.Id === remark?.StopId);
        setStopValue({
          value: currentStop?.Id,
          label: `${currentStop?.Id} - ${currentStop?.CompanyName}`,
        });
      }
    } catch (err) {}
  }, [stopsList, remark?.StopId]);

  const [isReadOnly, setReadOnly] = useState(false);
  const suggestionsFreightClass = [
    { label: "0" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "70" },
    { label: "77.5" },
    { label: "85" },
    { label: "92.5" },
    { label: "100" },
    { label: "110" },
    { label: "125" },
    { label: "150" },
    { label: "175" },
    { label: "200" },
    { label: "250" },
    { label: "300" },
    { label: "400" },
    { label: "500" },
  ].map((suggestion) => ({
    value: suggestion.label,
    label: suggestion.label,
  }));
  const suggestions2 = [{ label: "in./lbs." }, { label: "cm./kg." }].map(
    (suggestion2) => ({
      value: suggestion2.label,
      label: suggestion2.label,
    })
  );
  const [isMeter, setIsMeter] = React.useState(false);
  const [unitValue, setUnitValue] = React.useState("in.");
  const [unitWeight, setUnitWeight] = React.useState({
    value: "in./lbs.",
    label: "in./lbs.",
  });
  const [stopValue, setStopValue] = React.useState({
    value: "",
    label: "Select",
  });

  async function ClassCalculatorFunctionFetch() {
    return await fetch(
      window.$apiurl +
        `/FreightClass/CalculateClass?length=${lengthValue}&width=${widthValue}&height=${heightValue}&weight=${weightValue}
      &isMeter=${isMeter}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        if (!isChangingClass) {
          if (a) {
            setFreightClassSelect({
              label: a?.Result,
              value: a?.result,
            });
          } else {
            setFreightClassSelect({ label: 0, value: 0 });
          }
        }

        return a;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const [freightClassValue, setFreightClassValue] = React.useState(0);

  const [lengthValue, setLengthValue] = React.useState("");
  const [widthValue, setWidthValue] = React.useState("");
  const [heightValue, setHeightValue] = React.useState("");
  const [weightValue, setWeightValue] = React.useState("");
  const [isChangingClass, setIsChangingClass] = React.useState(false);

  const [freightClassSelect, setFreightClassSelect] = React.useState(null);
  useEffect(() => {
    if (
      lengthValue !== "" &&
      heightValue !== "" &&
      widthValue !== "" &&
      weightValue !== ""
    ) {
      ClassCalculatorFunctionFetch();
    }
  }, [lengthValue, widthValue, heightValue, weightValue, isMeter]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={remark}
        validationSchema={RemarkEditSchema}
        onSubmit={(values) => {
          var stringfyJson = JSON.stringify(values);
          var parseJson = JSON.parse(stringfyJson);
          parseJson.FreightClass = freightClassSelect?.label;
          parseJson.StopId = stopValue?.value;
          parseJson.WeightType = parseJson?.WeightType || "KGS";
          parseJson.CubeType = parseJson?.CubeType || "CBM";
          parseJson.ReceiptId = parseJson.ReceiptId || window.ReceiptId;
          parseJson.ShipmentId = parseJson.ShipmentId || window.ReceiptId;
          if (
            parseJson.WeightMode === undefined ||
            parseJson.WeightMode === null ||
            parseJson.WeightMode === ""
          ) {
            parseJson.WeightMode = "each";
          }
          parseJson.Pieces = parseJson?.Pieces || 1;
          parseJson.UnitCount = parseJson?.UnitCount || 0;
          parseJson.Weight = parseJson?.Weight || 1;
          parseJson.Cube = parseJson?.Cube || 1;
          parseJson.DestinationId = consigneValue.value;
          window.IsValid = false;

          // IsValidMessage("Purchase Order", parseJson?.PurchaseOrder);
          IsValidMessage("Unit Of Measure", parseJson?.UnitOfMeasureId);

          var customerId =
            +document.querySelector('[name="CustomerId"]')?.value ?? 0;

          if (customerId === 112) {
            /* IsValidMessage("Cartons", +parseJson?.UnitCount > 0); */
            IsValidMessage("Weight", +parseJson?.Weight > 0);
            IsValidMessage("Cube", +parseJson?.Cube > 0);
          }
          if (window.IsValid) return false;
          saveRemark(parseJson);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Modal.Body>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-6 mt-5">
                    <Field
                      name="ReceiptId2"
                      component={Input}
                      placeholder="ReceiptId"
                      label={!shipment ? "Receipt Id" : "Shipment Id"}
                      required={true}
                      value={ReceiptId}
                      disabled
                    />
                  </div>
                  <div className="col-lg-6 mt-5">
                    <Field
                      name="PurchaseOrder"
                      component={Input}
                      placeholder="Purchase Order"
                      label="Purchase Order"
                      required={true}
                      maxlength="10"
                    />
                  </div>
                  {window.$modeNameLtl !== "LTL" &&
                    (!SKUsList || values?.OverOnly) && (
                      <div className="col-lg-6 mt-5">
                        <Field
                          name="Sku"
                          component={Input}
                          placeholder="Sku"
                          label="Sku"
                        />
                      </div>
                    )}
                  {SKUsList?.length > 0 && !values?.OverOnly && (
                    <div className="col-lg-6 mt-5">
                      <CreatableSelect3
                        name="Sku"
                        label="Sku"
                        options={SKUsList.map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Sku,
                          };
                        })}
                        onChange={(e) => {
                          var id = e?.value;
                          if (id) GetSKu(id);
                        }}
                      />
                    </div>
                  )}
                  {!isScencty && (
                    <div className="col-lg-6 mt-5">
                      <Field
                        disabled={isReadOnly}
                        type="number"
                        min="0"
                        name="Pieces"
                        component={Input}
                        placeholder="Case pack"
                        label="Case pack"
                        required={true}
                      />
                    </div>
                  )}
                  <div className="col-lg-6 mt-5">
                    <CreatableSelect2
                      name="UnitOfMeasureId"
                      label="Unit Of Measure"
                      setFieldValue={setFieldValue}
                      options={UnitOfMeasures.map((x) => {
                        return {
                          value: x.Id,
                          label: x?.Name,
                        };
                      })}
                    />
                    {UnitOfMeasures.length === 0 && <LinearProgress />}
                  </div>
                  <div
                    className={
                      "col-lg-6 mt-5 " +
                      (!isScencty /* || (isScencty && remark?.Id) */
                        ? ""
                        : "d-none")
                    }
                  >
                    <Field
                      disabled={
                        isReadOnly /* || !(!isScencty || (isScencty && remark?.Id)) */
                      }
                      type="number"
                      name="UnitCount"
                      component={Input}
                      placeholder="Unit Count"
                      label="Unit Count"
                      required={true}
                      min="0"
                    />
                  </div>
                  {!isScencty && (
                    <div className="col-lg-6 mt-5">
                      <Select
                        id="WeightTypeId"
                        name="WeightType"
                        label="Weight Type"
                        onChange={(event) => {
                          setFieldValue("WeightType", event.target.value);
                          WeightTypeCalc(false);
                        }}
                      >
                        <option value="KGS">KGS</option>
                        <option value="LBS">LBS</option>
                      </Select>
                    </div>
                  )}
                  {!isScencty && (
                    <div className="col-lg-6 mt-5">
                      <Select
                        id="CubeTypeId"
                        name="CubeType"
                        label="Cube Type"
                        onChange={(event) => {
                          setFieldValue("CubeType", event.target.value);
                          CubeTypeCalc();
                        }}
                      >
                        <option value="CBM">CBM</option>
                        <option value="CFT">CFT</option>
                      </Select>
                    </div>
                  )}
                  {!isScencty && (
                    <div className="col-lg-6 mt-5">
                      <Field
                        type="number"
                        name="Weight"
                        id="WeightId"
                        component={Input}
                        placeholder="Weight"
                        label="Weight"
                        required={true}
                        min="0"
                        onBlur={WeightTypeCalc(true)}
                      />
                      {isLtlForm && (
                        <div className="row">
                          <div
                            className="col-lg-3 mt-5"
                            style={{ paddingRight: "0px" }}
                          >
                            <Select
                              id="WeightMode"
                              name="WeightMode"
                              label="Weight Mode"
                              onChange={(event) => {
                                setFieldValue("WeightMode", event.target.value);
                              }}
                            >
                              <option value="each">each</option>
                              <option value="total">total</option>
                            </Select>
                          </div>
                          <div
                            className="col-lg-9 mt-5"
                            style={{ paddingLeft: "0px" }}
                          >
                            <Field
                              disabled={isReadOnly}
                              type="number"
                              name="Weight"
                              id="WeightId"
                              component={Input}
                              placeholder="Weight"
                              label="Weight"
                              required={true}
                              min="0"
                              onBlur={WeightTypeCalc(true)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {!isScencty && (
                    <div className="col-lg-6 mt-5">
                      <Field
                        type="number"
                        name="Cube"
                        id="CubeId"
                        component={Input}
                        placeholder="Cube"
                        label="Cube"
                        required={true}
                        min="0"
                        onBlur={CubeTypeCalc()}
                      />
                    </div>
                  )}
                  {!isScencty && (
                    <div className="col-lg-6 mt-5">
                      <Field
                        type="number"
                        name="LBSWeight"
                        id="LBSWeightId"
                        value={
                          putModeWeight ? LBSWeightPut : LBSWeight.toFixed(2)
                        }
                        onChange={(event) => {
                          if ($("#WeightTypeId").val() === "KGS") {
                            setPutModeWeight(true);
                            setLBSWeightPut(event.target.value);
                            window.$LBSWeight = event.target.value;
                            setFieldValue("LBSWeight", event.target.value);
                          }
                        }}
                        component={Input}
                        placeholder="Weight (LBS)"
                        label="Weight (LBS)"
                      />
                    </div>
                  )}
                  {!isScencty && (
                    <div className="col-lg-6 mt-5">
                      <Field
                        type="number"
                        name="CFTCube"
                        id="CFTCubeId"
                        value={putModeCFTCube ? CFTCubePut : CFTCube.toFixed(2)}
                        onChange={(event) => {
                          if ($("#CubeTypeId").val() === "CBM") {
                            setPutModeCFTCube(true);
                            setCFTCubePut(event.target.value);
                            window.$CFTCube = event.target.value;
                            setFieldValue("LBSWeight", event.target.value);
                          }
                        }}
                        component={Input}
                        placeholder="Cube (CFT) "
                        label="Cube (CFT)"
                      />
                    </div>
                  )}
                  {isLtlForm && (
                    <>
                      {window.$modeNameLtl === "LTL" && (
                        <>
                          <div className="col-lg-6 mt-5">
                            <Field
                              type="number"
                              name="Length"
                              value={lengthValue}
                              onChange={(event) => {
                                setFieldValue("Length", event.target.value);
                                setLengthValue(event.target.value);
                                setIsChangingClass(false);
                              }}
                              component={Input}
                              placeholder={`Length (${unitValue})`}
                              label={`Length (${unitValue})`}
                            />
                          </div>
                          <div className="col-lg-6 mt-5">
                            <Field
                              type="number"
                              name="Width"
                              value={widthValue}
                              onChange={(event) => {
                                setFieldValue("Width", event.target.value);
                                setWidthValue(event.target.value);
                                setIsChangingClass(false);
                              }}
                              component={Input}
                              placeholder={`Width (${unitValue})`}
                              label={`Width (${unitValue})`}
                            />
                          </div>
                          <div className="col-lg-6 mt-5">
                            <Field
                              type="number"
                              name="Height"
                              value={heightValue}
                              onChange={(event) => {
                                setFieldValue("Height", event.target.value);
                                setHeightValue(event.target.value);
                                setIsChangingClass(false);
                              }}
                              component={Input}
                              placeholder={`Height (${unitValue})`}
                              label={`Height (${unitValue})`}
                            />
                          </div>
                          <div className="col-lg-6 mt-5">
                            <label>Units</label>
                            <CreatableSelect
                              name="FreightClass"
                              setFieldValue={setFieldValue}
                              name="Units"
                              onChange={async (e) => {
                                try {
                                  if (e?.value === "in./lbs.") {
                                    setIsMeter(false);
                                    setUnitValue("in.");
                                  } else {
                                    setIsMeter(true);
                                    setUnitValue("cm.");
                                  }
                                  setIsChangingClass(false);
                                  setUnitWeight(
                                    e || {
                                      value: "in./lbs.",
                                      label: "in./lbs.",
                                    }
                                  );
                                } catch (err) {}
                              }}
                              options={suggestions2}
                              value={unitWeight}
                            />
                          </div>
                        </>
                      )}
                      <div className="col-lg-6 mt-5">
                        <Field
                          type="text"
                          name="Nmfc"
                          onChange={(event) => {
                            setFieldValue("Nmfc", event.target.value);
                          }}
                          component={Input}
                          placeholder="NMFC"
                          label="NMFC"
                        />
                      </div>
                      {window.$modeNameLtl === "LTL" && (
                        <div className="col-lg-6 mt-5">
                          <label>Estimated Freight Class</label>
                          <CreatableSelect
                            name="FreightClass"
                            setFieldValue={setFieldValue}
                            options={suggestionsFreightClass}
                            value={freightClassSelect}
                            onChange={(newValue) => {
                              try {
                                if (newValue) {
                                  setFreightClassSelect(newValue);
                                } else {
                                  setFreightClassSelect(null);
                                }
                              } catch (err) {}
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {isLtlForm && window.$modeNameLtl === "FTL" && (
                    <div className="col-lg-6 mt-5">
                      <label>Stop</label>
                      <CreatableSelect
                        name="Stops"
                        setFieldValue={setFieldValue}
                        options={stopsList.map((x) => {
                          return {
                            value: x.Id,
                            label: `${x?.Id} - ${x?.CompanyName}`,
                          };
                        })}
                        value={stopValue}
                        onChange={(newValue) => {
                          try {
                            if (newValue) {
                              setStopValue(newValue);
                            } else {
                              setStopValue(null);
                            }
                          } catch (err) {}
                        }}
                      />
                    </div>
                  )}

                  {!isLtlForm && (
                    <>
                      {!isScencty && (
                        <ShortOverChange
                          OverQuantity={values?.OverQuantity}
                          ShortQuantity={values?.ShortQuantity}
                          setdisabledInput={setdisabledInput}
                        />
                      )}
                      {!values?.DamagedOnly && !values?.ShortOnly && (
                        <div className="col-lg-6 mt-5">
                          <Field
                            disabled={isReadOnly || disabledInput === "Short"}
                            type="number"
                            name="OverQuantity"
                            component={Input}
                            min="0"
                            placeholder="Over Quantity"
                            label="Over Quantity"
                          />
                        </div>
                      )}{" "}
                      {!values?.OverOnly && !values?.DamagedOnly && (
                        <div className="col-lg-6 mt-5">
                          <Field
                            disabled={isReadOnly || disabledInput === "Over"}
                            type="number"
                            name="ShortQuantity"
                            min="0"
                            component={Input}
                            placeholder="Short Quantity"
                            label="Short Quantity"
                          />
                        </div>
                      )}
                      {!values?.OverOnly && !values?.ShortOnly && (
                        <div className="col-lg-6 mt-5">
                          <Field
                            disabled={isReadOnly}
                            type="number"
                            min="0"
                            name="DamagedQuantity"
                            component={Input}
                            placeholder="Damaged Quantity"
                            label="Damaged Quantity"
                          />
                        </div>
                      )}
                      <div className="col-lg-6 mt-5">
                        <label>DC Location</label>
                        <CreatableSelect
                          name="ConsigneNumber"
                          className={isReadOnly && `disabledSelect`}
                          value={consigneValue}
                          options={consigneList.map((x) => {
                            return {
                              value: x?.Id,
                              label: `${x?.Code} - ${x?.CompanyName}`,
                            };
                          })}
                          onChange={(e) => {
                            setConsigneValue(e);
                          }}
                        />
                      </div>
                      {!isScencty && (
                        <div className="col-lg-6 mt-5">
                          <Field
                            name="ReferenceNumber"
                            component={Input}
                            placeholder="Reference Number"
                            label="Reference Number"
                          />
                        </div>
                      )}
                      {!isScencty && (
                        <div className="col-lg-6 mt-5">
                          <Field
                            disabled={isReadOnly}
                            type="number"
                            min="0"
                            name="AstrayQuantity"
                            component={Input}
                            placeholder="Astray Quantity"
                            label="Astray Quantity"
                          />
                        </div>
                      )}
                      {!isScencty && (
                        <div className="col-lg-6 mt-5">
                          <Field
                            type="number"
                            name="Pallets"
                            min="0"
                            component={Input}
                            placeholder="Pallets"
                            label="Pallets"
                          />
                        </div>
                      )}
                      {!isScencty && (
                        <div className="col-lg-6 mt-5">
                          <Field
                            type="number"
                            name="Upc"
                            component={Input}
                            placeholder="Upc"
                            label="Upc"
                          />
                        </div>
                      )}
                      {!isScencty && (
                        <div className="col-lg-6 mt-5">
                          <Field
                            name="BuyerId"
                            component={Input}
                            placeholder="Buyer Id"
                            label="Buyer Id"
                          />
                        </div>
                      )}
                      {!isScencty && (
                        <div className="col-lg-6 mt-5">
                          <Field
                            name="Department"
                            component={Input}
                            placeholder="Department"
                            label="Department"
                          />
                        </div>
                      )}
                      {!isScencty && (
                        <div className="col-lg-6 mt-5">
                          <DateTime30
                            disabled={true}
                            name="PoExpectedDate"
                            label="PoExpected Date"
                            defaultvalue={values?.PoExpectedDate}
                            setFieldValue={setFieldValue}
                            Time={false}
                          />
                        </div>
                      )}
                      {!isScencty && (
                        <div className="col-lg-6 mt-5">
                          <Field
                            type="number"
                            name="ThirdPartyQuantity"
                            component={Input}
                            placeholder="DropShip Quantity"
                            label="DropShip Quantity"
                          />
                        </div>
                      )}
                      {!isScencty && (
                        <div className="col-lg-6 mt-5">
                          <Field
                            name="ThirdPartyName"
                            component={Input}
                            placeholder="DropShip Name"
                            label="DropShip Name"
                          />
                        </div>
                      )}
                      {/* Additional Fields */}
                      {window.$ItemsCusId === 2271 && (
                        <>
                          <div className="col-lg-6 mt-5">
                            <Field
                              name="Color"
                              component={Input}
                              placeholder="Color"
                              label="Color"
                            />
                          </div>
                          <div className="col-lg-6 mt-5">
                            <Field
                              name="Size"
                              component={Input}
                              placeholder="Size"
                              label="Size"
                            />
                          </div>

                          <div className="col-lg-6 mt-5">
                            <Field
                              name="ProductType"
                              component={Input}
                              placeholder="Product Type"
                              label="Product Type"
                            />
                          </div>
                          <div className="col-lg-6 mt-5">
                            <Field
                              name="ReceivedQuantity"
                              component={Input}
                              type="number"
                              min="0"
                              placeholder="Cartons Count"
                              label="Cartons Count"
                            />
                          </div>
                        </>
                      )}
                      {/* ************************ */}
                    </>
                  )}
                  <div className="col-lg-6 mt-md-5">
                    <Field
                      name="Description"
                      component={Input}
                      placeholder="Description"
                      label="Description"
                    />
                  </div>
                  <div className="col-lg-6 mt-md-5">
                    <Field
                      name="Commodity"
                      component={Input}
                      placeholder="Commodity"
                      label="Commodity"
                    />
                  </div>
                  <div
                    className={
                      "col-lg-3 mt-md-8 pt-5 " + (isScencty ? "d-none" : "")
                    }
                  >
                    <CheckboxLargeWB
                      className="font-size-md"
                      text="Is Hot"
                      name="IsHot"
                      id="IsHot"
                      onChange={(event) => {
                        if (!isReadOnly) {
                          setFieldValue("IsHot", event.target.checked);
                        }
                      }}
                    />
                  </div>
                  <div
                    className={
                      "col-lg-3 mt-md-8 pt-5 " + (isScencty ? "d-none" : "")
                    }
                  >
                    <CheckboxLargeWB
                      className="font-size-md"
                      text="Is HazMat"
                      name="IsHazardousMateriel"
                      id="IsHazardousMateriel"
                      checked={isHasardOfMaterial}
                      onChange={(event) => {
                        if (!isReadOnly) {
                          setFieldValue(
                            "IsHazardousMateriel",
                            event.target.checked
                          );
                          setisHasardOfMaterial(event.target.checked);
                        }
                      }}
                    />
                  </div>
                  {isHasardOfMaterial && (
                    <div className="row col-lg-12">
                      <div className="col-lg-6 mt-5">
                        <Field
                          type="text"
                          label="UN Number"
                          name={`UnNumber`}
                          component={Input}
                        />
                      </div>
                      <div className="col-lg-6 mt-5">
                        <Select
                          label="Packing Group"
                          name={`PackagingGroup`}
                          id={`PackagingGroup`}
                        >
                          <option value="0">Select</option>
                          {PackagingGroupsList.map((item, index) => (
                            <option key={index} value={item.Id}>
                              {item.Name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-lg-6 mt-5">
                        <Select
                          label="Hazard Class"
                          name={`HazardClass`}
                          id={`HazardClass`}
                        >
                          <option value="0">Select</option>
                          {HazardClassesList.map((item, index) => (
                            <option key={index} value={item.Id}>
                              {item.Name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div className="col-lg-6 mt-5">
                        <Field
                          type="text"
                          label="Piece Description"
                          name={`PieceDescription`}
                          component={Input}
                        />
                      </div>
                      <div className="col-lg-6 mt-5">
                        <Field
                          type="text"
                          label="Emergency Contact Number"
                          name={`EmergencyContactNumber`}
                          component={Input}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {values?.DamagedOnly && values?.Id && (
                  <AttachementsComponent
                    id={window?.ReceiptId}
                    post={
                      "/transload/receipts/addattachment?receiptId=" +
                      window?.ReceiptId
                    }
                    get={
                      "/receiptsmanagement/getreceiptattachments/" +
                      window?.ReceiptId
                    }
                  />
                )}
              </Form>
            </Modal.Body>
            <>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                {!isScencty && (
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate"
                  >
                    Save
                  </button>
                )}
                {isScencty && (
                  <button
                    disabled={SKUsList?.length > 0}
                    type="submit"
                    onClick={() => {
                      window.$NewItemSaveandClose = true;
                      handleSubmit();
                    }}
                    className="btn btn-primary btn-elevate"
                  >
                    Save & Close
                  </button>
                )}
                {!isScencty && (
                  <button
                    type="submit"
                    onClick={() => {
                      window.$NewItemSaveClose = true;
                      handleSubmit();
                    }}
                    className="btn btn-primary btn-elevate"
                  >
                    Save & New
                  </button>
                )}
              </Modal.Footer>
            </>
          </>
        )}
      </Formik>
    </>
  );
}

const ShortOverChange = ({ OverQuantity, ShortQuantity, setdisabledInput }) => {
  useEffect(() => {
    if (OverQuantity > 0) setdisabledInput("Over");
    else if (ShortQuantity > 0) setdisabledInput("Short");
    else setdisabledInput(false);

    console.log(OverQuantity + " / " + ShortQuantity + " / ");
  }, [OverQuantity, ShortQuantity]);
  return <></>;
};
