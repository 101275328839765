import React, { useEffect, useState } from "react";
import { Modal, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import {
  Input,
  TextArea,
  CreatableSelect3,
} from "../../../../../../../../_metronic/_partials/controls";
import { Field } from "formik";
import { LinearProgress, IconButton } from "@material-ui/core";
import { ReturnEmptyOrString } from "../../../../../../../../_metronic/_helpers/ATHelpers";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import $ from "jquery";
import { default as JsNative } from "jquery";
import CloseIcon from "@material-ui/icons/Close";
import { Notification } from "../../../../../../Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import {
  AddSpace3,
  AutoRateComponent,
  AutoRatingCharges,
  AutoRatingItem,
  CalculateCharge,
  ChargesColor,
  ChargesColorCategory,
  ChargesRemoveComponent,
  checkIs0,
  CheckRateInCharges,
  GetLengthRound,
  InputValueCharge,
  isChangeFunction,
  IsRounding,
  isVariableOpenDefault,
  MenuListComposition,
  RateClassName,
  RateReadOnly,
  RemoveDuplicate,
  RemoveSpace,
  RoundToFixed,
  SetSubTotal,
  SortAndRename,
  SortOnlyCharge,
  Spinner2s,
  StartAutoRate,
} from "./ChargesFunction";
const adapter = new LocalStorage("db");
const db = low(adapter);

const catchError = [];
export const OneMoveMasterVendors = ({
  item,
  countId,
  Vendorsf = [],
  isInboundV = false,
  isPayableV = true,
  S_ChargesReadOnly = false,
  isShipment = false,
  ZipCode = false,
  ChargesId = "",
  TotalPallets = 0,
  GetChargesLink = "/ordersRating/GetCharges",
  GetCalculatedLink = "/ordersRating/GetCalculatedCharges",
  ParmPickUp = "",
  FreightChargeId,
  setFieldValue = false,
}) => {
  const [VendorContact, setVendorContact] = useState([]);
  const [VendorDetail, setVendorDetail] = useState(null);
  const [VendorDelete, setVendorDelete] = useState(false);
  const [Vendors, setVendors] = useState([]);
  const [CarrierIdS, setCarrierIdS] = useState(0);
  const [ChargesFunctionOne, setChargesFunctionOne] = useState(false);
  const [FreightChargeList, setFreightChargeList] = useState([]);
  const [BillToOptionsList, setBillToOptionsList] = useState([]);
  const [VendorTypeOptionsList, setVendorTypeOptionsList] = useState([]);
  const [FreightChargeIdSelected, setFreightChargeIdSelected] = useState(null);
  const [BillToIdSelected, setBillToIdSelected] = useState(null);
  const [defaultFreightChargeId, setdefaultFreightChargeId] = useState(
    FreightChargeId
  );
  const [VendorTypeId, setVendorTypeId] = useState(item?.VendorTypeId);

  function setFieldValueF(a, b) {
    if (setFieldValue) setFieldValue(a, b);
    else window.setFieldValue(a, b);
  }

  const [ChargesReadOnly, setChargesReadOnly] = useState(S_ChargesReadOnly);
  useEffect(() => {
    setChargesReadOnly(S_ChargesReadOnly);
  }, [S_ChargesReadOnly]);

  useEffect(() => {
    setTimeout(() => {
      setChargesFunctionOne(true);
    }, 1000);
    window.OneMoveMasterVendors = Object.keys(item).length !== 0;
    setBillToIdSelected(item?.BillToId || null);

    var freightChargeId = +(
      document.querySelector('input[name="FreightChargeId"]:checked')?.value ??
      0
    );

    setdefaultFreightChargeId(freightChargeId);
    setFreightChargeIdSelected(
      item?.FreightChargeId || freightChargeId || null
    );
  }, []);

  useEffect(() => {
    Vendorsf.forEach((x) => {
      let c = "#000000";
      if (x?.AlertType === "Danger") c = "#ff5555";
      else if (x?.AlertType === "Warning") c = "#bbb000";
      x.Color = c;
    });

    setVendors(Vendorsf);
    setFreightChargeList(window.FreightCharges ?? []);
    setVendorTypeOptionsList(window.VendorTypeListOption ?? []);
    setBillToOptionsList(window.BillToOptionsList ?? []);
  }, [Vendorsf]);

  useEffect(() => {
    setCarrierIdS(item?.CarrierId || 0);
  }, [item?.CarrierId]);

  useEffect(() => {
    item.IdOrg = item?.Id;
  }, [item?.Id]);

  var listChargesSelect = [];
  var listChargesModel = VendorDetail?.Charges;
  if (listChargesModel) {
    {
      listChargesModel.map((item, countId) => {
        listChargesSelect.push(item);
      });
    }
  }

  //Charges Rates Modal
  return (
    <>
      {!VendorDelete && (
        <div className="col-lg-12 mb-5 VendorListAll">
          <input
            type="hidden"
            className={"setChargesReadOnly" + ChargesId}
            onClick={() => setChargesReadOnly(window.ChargesReadOnly)}
          />

          <div
            className="row bg-light pt-2 pb-3 mx-1"
            style={{
              borderRadius: "5px",
              border: "1px solid #ddd",
            }}
          >
            <div style={{ position: "absolute", top: -12, right: 3 }}>
              {(item?.Payables || []).filter((x) => x.IsReadOnly === true)
                ?.length === 0 && (
                <i
                  class="fas fa-times-circle"
                  style={{
                    color: "black",
                    fontSize: "27px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setFieldValueF(`Vendors[${countId}]`, undefined);
                    setVendorDelete(true);
                  }}
                ></i>
              )}
            </div>
            <div className="col-lg-4">
              <label class="text-danger font-weight-bold ml-1 mb-3">
                Vendor *
              </label>
              <CreatableSelect3
                name={"VendorName" + countId}
                className="dangerlist"
                options={Vendors.map((x) => {
                  return {
                    value: x?.Id,
                    label: x?.Code + " - " + x?.Name,
                    color: x?.Color,
                  };
                })}
                valueSelect={item?.VendorId || 0}
                ColorBool={FreightChargeIdSelected === 98}
                onChange={async (newValue) => {
                  var a = newValue?.value;
                  var b = Vendors.find((x) => x.Id == a);
                  if (window.OneMoveMasterVendors === true) {
                    if (
                      b?.AlertType === "Danger" &&
                      FreightChargeIdSelected === 98
                    ) {
                      const alertMessage = b?.AlertMessage || [];
                      alertMessage.map((x) => {
                        const c =
                          b?.AlertType === "Danger" ? "error" : "warning";
                        Notification(c, newValue?.label + " : " + x);
                      });
                    }
                    window.OneMoveMasterVendors = false;
                  }
                  setTimeout(() => {
                    window.OneMoveMasterVendors = true;
                  }, 3000);
                  setFieldValueF(`Vendors[${countId}].VendorId`, a);
                  setFieldValueF(`Vendors[${countId}].Id`, a);
                  setVendorContact(b?.Contacts || []);
                  setVendorDetail(b);
                  setCarrierIdS(b?.CarrierId ?? 0);
                }}
              />
              {VendorDetail?.AlertType === "Danger" &&
                FreightChargeIdSelected === 98 && (
                  <b className="text-danger w-100 pt-1">
                    * This Vendor is not Certified
                  </b>
                )}
              <div>
                <div className="form-control h-50 mt-2">
                  {ReturnEmptyOrString(VendorDetail?.CompanyName)}
                  <br />
                  {ReturnEmptyOrString(VendorDetail?.Address)} <br />
                  {ReturnEmptyOrString(VendorDetail?.City)}.{" "}
                  {ReturnEmptyOrString(VendorDetail?.State)}{" "}
                  {ReturnEmptyOrString(VendorDetail?.ZipCode)}
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-1">
              <CreatableSelect3
                name={"VendorsContactId" + countId}
                className="dangerlist"
                label="Vendor Contact"
                options={VendorContact.map((x) => {
                  return {
                    value: x?.Id,
                    label: x?.Name,
                  };
                })}
                valueSelect={item?.ContactId || 0}
                onChange={async (newValue) => {
                  setFieldValueF(
                    `Vendors[${countId}].ContactId`,
                    newValue?.value
                  );
                }}
              />
              <div className="mt-5 pt-1" />
              <div className="row">
                <div className="col-lg-12">
                  <Field
                    defaultValue={item?.VendorNumber}
                    name={`Vendors[${countId}].VendorNumber`}
                    component={Input}
                    placeholder="Vendor Ref No."
                    label="Vendor Ref No."
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-1">
              <Field
                rows="5"
                defaultValue={item?.Description}
                name={`Vendors[${countId}].Description`}
                component={TextArea}
                placeholder="instructions"
                label="instructions"
                className="form-control p-4"
              />
            </div>
            <div className="col-lg-8 mt-1">
              <div className="row">
                <div className="col-lg-4 mt-4">
                  <Field
                    defaultValue={item?.DriverName}
                    name={`Vendors[${countId}].DriverName`}
                    component={Input}
                    placeholder="Driver Name"
                    label="Driver Name"
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <Field
                    defaultValue={item?.DriverPhone}
                    name={`Vendors[${countId}].DriverPhone`}
                    component={Input}
                    placeholder="Driver Phone"
                    label="Driver Phone"
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <div className="d-none">{item?.VendorTypeId} </div>
                  <CreatableSelect3
                    required={true}
                    name={"VendorTypeId" + countId}
                    className="dangerlist"
                    label="Vendor Type"
                    options={VendorTypeOptionsList.map((x) => {
                      return {
                        value: x?.Id,
                        label: x?.Name,
                      };
                    })}
                    valueSelect={
                      item?.VendorTypeId || VendorTypeOptionsList[0]?.Id
                    }
                    onChange={async (newValue) => {
                      setVendorTypeId(newValue?.value);
                      setFieldValueF(
                        `Vendors[${countId}].VendorTypeId`,
                        newValue?.value
                      );
                    }}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <Field
                    defaultValue={item?.TruckNumber}
                    name={`Vendors[${countId}].TruckNumber`}
                    component={Input}
                    placeholder="Truck Number"
                    label="Truck Number"
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <div className="d-none">{item?.FreightChargeId} </div>
                  <CreatableSelect3
                    name={"FreightChargeId" + countId}
                    className="dangerlist"
                    label="Freight Charge"
                    options={FreightChargeList.map((x) => {
                      return {
                        value: x?.Id,
                        label: x?.Name,
                      };
                    })}
                    valueSelect={
                      item?.FreightChargeId ?? defaultFreightChargeId
                    }
                    onChange={async (newValue) => {
                      setFreightChargeIdSelected(newValue?.value);
                      setFieldValueF(
                        `Vendors[${countId}].FreightChargeId`,
                        newValue?.value
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              {FreightChargeIdSelected === 100 && (
                <>
                  <div className="d-none">{item?.VendorTypeId} </div>
                  <CreatableSelect3
                    name={"BillToId" + countId}
                    valueSelect={item?.BillToId}
                    label="Third Party Bill To"
                    options={BillToOptionsList.map((x) => {
                      return {
                        value: x.Id,
                        label:
                          x?.Code +
                          "-" +
                          (x?.Name !== undefined ? x?.Name : x?.CompanyName),
                      };
                    })}
                    onChange={async (newValue) => {
                      setBillToIdSelected(newValue?.value);
                      setFieldValueF(
                        `Vendors[${countId}].BillToId`,
                        newValue?.value
                      );
                    }}
                  />
                  <div class="form-control h-50 mt-2">
                    {ReturnEmptyOrString(
                      BillToOptionsList.filter(
                        (a) => a.Id === BillToIdSelected
                      )[0]?.Name !== undefined
                        ? BillToOptionsList.filter(
                            (a) => a.Id === BillToIdSelected
                          )[0]?.Name
                        : BillToOptionsList.filter(
                            (a) => a.Id === BillToIdSelected
                          )[0]?.CompanyName
                    )}
                    <br />
                    {ReturnEmptyOrString(
                      BillToOptionsList.filter(
                        (a) => a.Id === BillToIdSelected
                      )[0]?.Address1 !== undefined
                        ? BillToOptionsList.filter(
                            (a) => a.Id === BillToIdSelected
                          )[0]?.Address1
                        : BillToOptionsList.filter(
                            (a) => a.Id === BillToIdSelected
                          )[0]?.Address
                    )}{" "}
                    <br />
                    {ReturnEmptyOrString(
                      BillToOptionsList.filter(
                        (a) => a.Id === BillToIdSelected
                      )[0]?.City
                    )}
                    .{" "}
                    {ReturnEmptyOrString(
                      BillToOptionsList.filter(
                        (a) => a.Id === BillToIdSelected
                      )[0]?.State
                    )}{" "}
                    {ReturnEmptyOrString(
                      BillToOptionsList.filter(
                        (a) => a.Id === BillToIdSelected
                      )[0]?.ZipCode
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="col-lg-12 mt-5">
              {ChargesFunctionOne && VendorDetail?.Id && (
                <ChargesFunction
                  isInbound={isInboundV}
                  isPayable={isPayableV}
                  type="Payables"
                  list={item?.Payables || []}
                  CarrierId={item?.CarrierId || 0}
                  VendorTypeId={VendorTypeId}
                  CarrierIdS={CarrierIdS}
                  countId={countId}
                  ChargesId={ChargesId}
                  isShipment={isShipment}
                  S_ChargesReadOnly={ChargesReadOnly}
                  GetCalculatedLink={GetCalculatedLink}
                  ZipCode={ZipCode}
                  GetChargesLink={GetChargesLink}
                  VendorDetail={VendorDetail}
                  TotalPallets={TotalPallets}
                  ParmPickUp={ParmPickUp}
                  setFieldValue={setFieldValueF}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function listdistinctFunction(array, Type, Show, propertyName) {
  if (Show === false) return [];
  propertyName = "Id";
  if (Type !== "info") {
    return array.filter(
      (e, i) =>
        array.findIndex((a) => a[propertyName] === e[propertyName]) === i
    );
  } else {
    return array.filter(
      (e, i) =>
        array.findIndex((a) => a[propertyName] === e[propertyName]) === i
    );
  }
}

const ChargesFunction = ({
  isInbound,
  isPayable,
  type,
  list,
  ChargesId = "",
  VendorTypeId,
  CarrierIdS,
  countId,
  isShipment,
  S_ChargesReadOnly,
  GetCalculatedLink,
  ZipCode,
  GetChargesLink,
  VendorDetail,
  TotalPallets,
  ParmPickUp,
  isVariableOpen = isVariableOpenDefault,
  setFieldValue = { setFieldValue },
}) => {
  const [ChargeModel, setChargeModel] = useState({
    Show: false,
    Type: "success",
  });
  const [Charges, setCharges] = useState(list);
  const [ChargesRemove, setChargesRemove] = useState([]);
  const [ChargesN, setChargesN] = useState(catchError);
  const [loadingCharges, setloadingCharges] = useState(true);
  const [FilterCharges, setFilterCharges] = useState("");
  const [AutoRatingModal, setAutoRatingModal] = useState(false);
  const [AutoRatingCheck, setAutoRatingCheck] = useState([]);
  const [NoteModal, setNoteModal] = useState({
    Show: false,
    Id: 0,
    Code: "",
  });
  //vendor
  const [ReloadCalc, setReloadCalc] = useState(0);
  function setReloadCalcF() {
    setReloadCalc((y) => y + 1);
  }

  useEffect(() => {
    if (CarrierIdS) GetPayableForVendor({ button: false });
    else setloadingCharges(false);
    console.log(CarrierIdS);
  }, [ReloadCalc, VendorTypeId]);

  function FieldID(name) {
    return document.getElementById(name)?.value ?? "";
  }

  const GetPayableForVendor = ({ button }) => {
    setloadingCharges(true);

    const customerId = FieldID("C_CustomerId" + ChargesId);
    const serviceId = FieldID("C_ServiceId" + ChargesId);
    const modeId = FieldID("C_ModeId" + ChargesId);
    const serviceLevelId = FieldID("C_ServiceLevelId" + ChargesId);
    const equipmentTypeId = FieldID("C_EquipmentTypeId" + ChargesId);
    const equipmentSizeId = FieldID("C_EquipmentSizeId" + ChargesId);
    const date = isShipment
      ? FieldID("C_PuAppointment" + ChargesId)
      : FieldID("C_ReceivedDate" + ChargesId);
    const CarrierId = CarrierIdS;
    const fromCity = FieldID("C_fromCity" + ChargesId);
    const fromAddress = FieldID("C_fromAddress" + ChargesId);
    const fromState = FieldID("C_fromState" + ChargesId);
    const fromZipCode = FieldID("C_fromZipCode" + ChargesId);
    const toCity = FieldID("C_toCity" + ChargesId);
    const toAddress = FieldID("C_toAddress" + ChargesId);
    const toState = FieldID("C_toState" + ChargesId);
    const toZipCode = FieldID("C_toZipCode" + ChargesId);

    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-FP-API-KEY", "iphone");
      var requestOptions = {
        method: "GET",
        withCredentials: true,
        headers: myHeaders,
        redirect: "follow",
      };
      let thisLink = GetChargesLink;
      if (
        GetChargesLink === "/ordersRating/GetCharges" ||
        GetChargesLink === "/ordersrating/GetChargesForPickup"
      )
        thisLink += `?customerId=${customerId}&serviceId=${serviceId}&serviceLevelId=${serviceLevelId}&ModeId=${modeId}&isInbound=${isInbound}&isPayable=${isPayable}&carrierId=${CarrierId}&equipmentTypeId=${equipmentTypeId}&equipmentSizeId=${equipmentSizeId}&fromCity=${fromCity}&fromAddress=${fromAddress}&fromState=${fromState}&fromZipCode=${fromZipCode}&toCity=${toCity}&toAddress=${toAddress}&toState=${toState}&toZipCode=${toZipCode}&date=${date}${ParmPickUp}&VendorTypeId=${VendorTypeId}`;

      fetch(window.$apiurl + thisLink, requestOptions)
        .then((response) => response.json())
        .then(async (result) => {
          try {
            setChargesN([]);

            //vendor only
            var thisList = window["List" + type + countId + ChargesId];
            thisList = thisList.filter(
              (x) => x?.VendorId === +VendorDetail?.Id
            );
            //vendor only

            let chargesVar = thisList;

            chargesVar = CheckRateInCharges(
              chargesVar,
              result,
              S_ChargesReadOnly,
              setChargesRemove
            );

            let objCalc = [];
            if (button) objCalc = await GetPayableForVendorCalc();
            else
              objCalc =
                chargesVar?.length === 0 ? await GetPayableForVendorCalc() : [];

            objCalc = RemoveDuplicate(chargesVar, objCalc);

            chargesVar = JSON.parse(JSON.stringify(chargesVar));
            chargesVar.forEach((x) => {
              x.Id2 = x?.Id;
              x.Id = x?.ChargeId;
            });
            let allObjs = chargesVar.concat(objCalc);

            let resArr = [];
            allObjs.filter(function(item) {
              resArr.push({ ...item, ChargeId: item?.Id });
            });

            var a = [];
            (result || []).forEach((x) => {
              var i = allObjs.find((y) => y?.Id === x?.Id);
              if (i === undefined) a.push(x);
            });

            //get auto rate
            resArr = (await StartAutoRate(resArr, ChargesId)) ?? [];

            setCharges(resArr || []);
            setChargesN(a || []);
            setloadingCharges(false);

            if (button)
              setTimeout(() => {
                document.getElementById("AutoRatingBtn" + countId).click();
              }, 1000);
          } catch (err) {
            console.error(err);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) {}
  };

  async function GetPayableForVendorCalc() {
    if (S_ChargesReadOnly === true) return [];
    const customerId = FieldID("C_CustomerId" + ChargesId);
    const serviceId = FieldID("C_ServiceId" + ChargesId);
    const modeId = FieldID("C_ModeId" + ChargesId);
    const serviceLevelId = FieldID("C_ServiceLevelId" + ChargesId);
    const equipmentTypeId = FieldID("C_EquipmentTypeId" + ChargesId);
    const equipmentSizeId = FieldID("C_EquipmentSizeId" + ChargesId);
    const date = isShipment
      ? FieldID("C_PuAppointment" + ChargesId)
      : FieldID("C_ReceivedDate" + ChargesId);
    const CarrierId = CarrierIdS;
    const fromCity = FieldID("C_fromCity" + ChargesId);
    const fromAddress = FieldID("C_fromAddress" + ChargesId);
    const fromState = FieldID("C_fromState" + ChargesId);
    const fromZipCode = FieldID("C_fromZipCode" + ChargesId);
    const toCity = FieldID("C_toCity" + ChargesId);
    const toAddress = FieldID("C_toAddress" + ChargesId);
    const toState = FieldID("C_toState" + ChargesId);
    const toZipCode = FieldID("C_toZipCode" + ChargesId);

    let thisLink = GetCalculatedLink;
    if (
      GetCalculatedLink === "/ordersRating/GetCalculatedCharges" ||
      GetCalculatedLink === "/ordersrating/GetCalculatedChargesForPickup"
    )
      thisLink += `?customerId=${customerId}&serviceId=${serviceId}&serviceLevelId=${serviceLevelId}&ModeId=${modeId}&isInbound=${isInbound}&isPayable=${isPayable}&carrierId=${CarrierId}&equipmentTypeId=${equipmentTypeId}&equipmentSizeId=${equipmentSizeId}&fromCity=${fromCity}&fromAddress=${fromAddress}&fromState=${fromState}&fromZipCode=${fromZipCode}&toCity=${toCity}&toAddress=${toAddress}&toState=${toState}&toZipCode=${toZipCode}&date=${date}${ParmPickUp}&VendorTypeId=${VendorTypeId}`;

    return await fetch(window.$apiurl + thisLink, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = [];
        res[1].map((x) => {
          var quantity = TotalPallets;
          if (x.MinCharge > 0 && x.MaxCharge > 0) quantity = 1;
          a.push({ ...x, Calc: true, Quantity: quantity });
        });
        return a;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function CalcTotal() {
    var sum = 0;
    $(`.RatesTotal-${type + countId + ChargesId}`).each(function() {
      var a = Number(
        $(this)
          .find("input")
          .val()
      );
      $(this)
        .find("input")
        .val(a.toFixed(2));
      sum += a;
    });
    $(`.RateAllTotal-${type + countId}-${ChargesId} span`).text(AddSpace3(sum));

    document
      .querySelectorAll(`.Charges2-${type + countId + ChargesId} span`)
      .forEach((element) => {
        var category = element.getAttribute("data-Name");
        var invoice = element.getAttribute("data-Invoice");
        var filter = Charges.filter(
          (x) => x?.Category === category && x?.Invoice === invoice
        );
        var a = filter.reduce(
          (total, x) =>
            CalculateCharge({
              Rate: x?.Rate,
              Quantity: RoundToFixed(x?.Quantity ?? 0, x?.Rounding),
              rateType: x?.RateType,
              MinCharge: x?.MinCharge,
              MaxCharge: x?.MaxCharge,
              FreeTime: x?.FreeTime,
              OriAddSup: x?.OriAddSup,
            }) + total,
          0
        );
        element.innerText = "Sub Total : " + AddSpace3(a);

        var str = `.Charges-${RemoveSpace(category)}-${RemoveSpace(
          invoice
        )}-${type}-${countId}-${ChargesId}`;
        SetSubTotal(a, str);
      });
  }

  useEffect(() => {
    let a = [];
    Charges.map((x) =>
      a.push({
        ...x,
        ChargeId: x?.ChargeId || x?.Id,
        Code: x?.Code,
        Name: x?.Name,
        Quantity: x?.Quantity || 0,
        Rate: x?.Rate,
        Position: x?.Position,
        CategoryPosition: x?.CategoryPosition,
        OriAddSup: x?.OriAddSup,
        Invoice: x?.Invoice || "",
        InvoiceId: +x?.InvoiceId || null,
        VendorId: VendorDetail?.Id || null, //vendor only
        IsReadOnly: x?.IsReadOnly || false,
        Note: x?.Note,
        MinCharge: x?.MinCharge,
        MaxCharge: x?.MaxCharge,
        FreeTime: x?.FreeTime,
        RateType: x?.RateType,
        Calcul: x?.Calcul,
        Category: x?.Category,
        DateEnd: x?.DateEnd,
        DateStart: x?.DateStart,
        QuoteId: x?.QuoteId,
        Rounding: x?.Rounding,
        CalculName: x?.CalculName,
      })
    );

    window["List" + type + countId + ChargesId] = a;
    if (type === "Charges") {
      window.OnemoveMasterCharges = a;
    }
    if (type === "Payables") {
      setFieldValue(`Vendors[${countId}].Payables`, a);
    }
    CalcTotal();
  }, [Charges, ChargesN]);

  function CheckHidden(param1, param2) {
    return param1?.toLowerCase()?.includes(param2?.toLowerCase())
      ? "row"
      : "d-none";
  }

  return (
    <div className="row RatesParent">
      {/* Only Vendors */}
      <input
        type="hidden"
        className="setReloadCalcF"
        onClick={() => setReloadCalcF()}
      />

      <Modal
        size="lg"
        show={ChargeModel?.Show}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            className="w-100 row px-5"
          >
            <div className="col-lg-4">Customer Charges</div>
            <div className="col-lg-8">
              <input
                className="form-control"
                placeholder="Filter by Name and Code"
                onChange={(e) => setFilterCharges(e.target.value?.trim() ?? "")}
              />
            </div>
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => setChargeModel({ Show: false, Type: "success" })}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          {listdistinctFunction(
            ChargeModel?.Type === "info" ? Charges : ChargesN,
            ChargeModel?.Type,
            ChargeModel?.Show
          )?.map((x, index) => (
            <div
              key={x?.Code + "" + x?.Id + "" + index + "" + countId}
              className={CheckHidden(x?.Name + "," + x?.Code, FilterCharges)}
            >
              <AutoRatingItem
                key={index}
                x={x}
                index={index}
                isAddCharges={true}
                AutoRatingCheck={AutoRatingCheck}
                ChargesId={ChargesId}
              />
              <div className={`col-lg-4 mt-5 mb-5`}>
                <b className={`text-black title mr-2`}>
                  {" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="products-edit-tooltip">{x.Code}</Tooltip>
                    }
                  >
                    <a className="btn btn-icon btn-light btn-hover-light btn-sm mr-3">
                      <span className="svg-icon svg-icon-md svg-icon-info">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Info-circle.svg"
                          )}
                        />
                      </span>
                    </a>
                  </OverlayTrigger>
                  {x?.Name}
                </b>
              </div>
              <div className={`col-lg-3 mt-4`}>
                <input
                  className="form-control"
                  disabled={true}
                  defaultValue={x?.Rate}
                />
              </div>
              <div className={`col-lg-3 pl-1 pr-5 mt-4`}>
                <input
                  className="form-control"
                  id={"ChargeN" + index}
                  defaultValue={
                    x.MinCharge > 0 && x.MaxCharge > 0 ? 1 : x?.Quantity ?? 0
                  }
                />
                <input type="hidden" id={"DateStart" + index} />
                <input type="hidden" id={"DateEnd" + index} />
              </div>
              <div className={`col-lg-2 mt-4`}>
                <button
                  type="button"
                  class={`btn btn-light-${ChargeModel?.Type} w-100`}
                  onClick={() => {
                    const isInfo = ChargeModel?.Type === "info";
                    let a = JSON.parse(JSON.stringify(Charges)) || [];
                    var listCharge = isInfo ? Charges : ChargesN;
                    var c = ChargesN?.filter((y) => y?.Id !== x?.Id);
                    var b = listCharge?.find((y) => y?.Id === x?.Id);
                    if (b || isInfo) {
                      var color = 0;
                      if (ChargeModel?.Type === "primary") color = 1;
                      else if (isInfo) color = 2;

                      /* var quantity = TotalPallets ?? 0;
                      if (b.MinCharge > 0 && b.MaxCharge > 0) quantity = 1; */

                      a.push({
                        ...b,
                        Id: b?.Id,
                        ChargeId: b?.Id,
                        Rate: b?.Rate,
                        Name: b?.Name,
                        Code: b?.Code,
                        Quantity: +document.getElementById("ChargeN" + index)
                          .value,
                        RateType: b?.RateType,
                        MinCharge: b?.MinCharge,
                        MaxCharge: b?.MaxCharge,
                        Position: b?.Position,
                        CategoryPosition: b?.CategoryPosition,
                        FreeTime: b?.FreeTime,
                        Calcul: b?.Calcul,
                        Category: b?.Category,
                        IsVariable: x?.IsVariable || false,
                        Invoice: "",
                        VendorId: VendorDetail?.Id || null,
                        OriAddSup: color,
                        IsReadOnly: false,
                        DateEnd: FieldID("DateEnd" + index),
                        DateStart: FieldID("DateStart" + index),
                        QuoteId: b?.QuoteId,
                        Rounding: b?.Rounding,
                        CalculName: b?.CalculName,
                      });
                    }

                    setCharges(a);
                    if (!isInfo) setChargesN(c);

                    setTimeout(() => {
                      if (
                        (c?.length === 0 && !isInfo) ||
                        (a?.length === 0 && isInfo)
                      )
                        setChargeModel({ Show: false, Type: "success" });
                    }, 1);
                  }}
                >
                  <i className={`fas fa-plus fa-sm`}></i>
                </button>
              </div>
            </div>
          ))}
          {(ChargeModel?.Type === "info" ? Charges : ChargesN)?.length ===
            0 && (
            <div class="col-lg-12 mt-2 text-center">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                style={{ fontSize: "25px", width: "100px", height: "100px" }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setChargeModel({ Show: false, Type: "success" })}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Auto Rating ---------------------------------------- */}
      <AutoRateComponent
        title={VendorDetail?.Name}
        setAutoRatingModal={setAutoRatingModal}
        AutoRatingModal={AutoRatingModal}
        Charges={Charges}
        setCharges={setCharges}
        AutoRatingCheck={AutoRatingCheck}
        setAutoRatingCheck={setAutoRatingCheck}
        IndexId={ChargesId}
      />
      {/* Auto Rating ---------------------------------------- */}

      <Modal
        show={NoteModal.Show}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Note : {NoteModal?.Code}
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => setNoteModal({ Show: false })}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body className="row">
          <textarea
            id="NoteCharges"
            class="form-control"
            rows="3"
            defaultValue={NoteModal?.Note}
            disabled={
              S_ChargesReadOnly ? true : Charges[NoteModal?.index]?.IsReadOnly
            }
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setNoteModal({ Show: false })}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            {!(S_ChargesReadOnly
              ? true
              : Charges[NoteModal?.index]?.IsReadOnly) && (
              <button
                type="button"
                onClick={() => {
                  var note = JsNative("#NoteCharges").val();
                  if (NoteModal?.index > -1) {
                    let c = Charges;
                    c[NoteModal?.index].Note = note;
                    setCharges([]);
                    setTimeout(() => {
                      setCharges(c || []);
                      setNoteModal({ Show: false });
                    }, 1);
                  }
                }}
                className="btn btn-primary btn-elevate ml-2"
              >
                OK
              </button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      {loadingCharges && (
        <div className="col-lg-12">
          <LinearProgress />
        </div>
      )}
      <div className="col-md-12">
        <div className="table-responsive">
          <div className="CheckCharges d-none">
            {Charges?.map((x, index) => (
              <p key={x?.ChargeId + "" + index}>{x?.Calcul}</p>
            ))}
          </div>
          <table
            className="table table-head-custom table-head-bg table-vertical-center"
            style={{ width: "95%", margin: "auto", overflow: "hidden" }}
          >
            <thead>
              <tr className="text-left text-uppercase">
                <th>Code</th>
                <th>Name</th>
                <th>Rate</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Remove</th>
                <th>Action</th>
              </tr>
            </thead>
            {SortAndRename(Charges)?.map((x, index) => {
              const iiii = x?.ChargeId + "" + index + "" + countId + ChargesId;
              return (
                <>
                  <tbody
                    className="border-0"
                    key={
                      x?.Code +
                      "" +
                      x?.Id +
                      "" +
                      index +
                      "" +
                      countId +
                      (x?.Invoice ?? "")
                    }
                  >
                    {x?.InvoiceAppend !== "" && (
                      <>
                        <tr className="mt-5 d-block" style={{ height: "35px" }}>
                          <td
                            ref={(element) => {
                              if (element)
                                element.style.setProperty(
                                  "font-size",
                                  "15px",
                                  "important"
                                );
                            }}
                            className={`border-0`}
                            style={{
                              position: "absolute",
                              left: "9px",
                            }}
                          >
                            <b>Invoice</b>{" "}
                            <b>
                              <span className="rounded-pill bg-dark text-white px-2 py-1 ml-1 Charges">
                                {x?.Invoice}
                              </span>
                            </b>
                          </td>
                        </tr>
                      </>
                    )}

                    {x?.CategoryAppend !== "" && (
                      <>
                        <tr className="mt-5 d-block CategoryClass">
                          <td
                            className={`border-0  bg-${ChargesColorCategory(
                              x?.Category
                            )} rounded-top`}
                          >
                            <b className={`text-light title mr-2`}>
                              <span
                                className={`rounded-pill px-2 py-1 ml-1 Charges2-${type +
                                  countId +
                                  ChargesId}`}
                                style={{ fontSize: " 13px" }}
                              >
                                {x?.Category}
                                <span
                                  id={
                                    x?.Category?.replace(/ /g, "") + type + "Id"
                                  }
                                  className={`ml-2 btn btn-light py-0 text-${ChargesColorCategory(
                                    x?.Category
                                  )} pt-1`}
                                  data-Name={x?.Category}
                                  data-Invoice={x?.Invoice}
                                  style={{
                                    fontWeight: "600",
                                    borderRadius: "3px",
                                  }}
                                >
                                  Sub Total : 0.00
                                </span>
                              </span>
                            </b>
                          </td>
                        </tr>
                      </>
                    )}

                    <tr className="bg-white">
                      <td className={`border-0 position-relative`}>
                        {isChangeFunction(x)}
                        <b
                          className={`${
                            x?.Calc
                              ? ""
                              : ChargesColor(
                                  x.OriAddSup ?? 0,
                                  x?.Quantity ?? 0,
                                  x?.Invoice ?? "Empty"
                                )
                          } title mr-2`}
                        >
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                <p class="w-100 text-center">
                                  <span class="d-inline-block w-100 text-primary">
                                    <b>Quote # </b>
                                  </span>
                                  <span class="w-100">
                                    {x?.QuoteId ?? "Empty"}
                                  </span>
                                  {/* {x?.RatingTable && x?.RatingTable !== null && (
                                    <>
                                      <br />
                                      <span class="d-inline-block w-100  mt-5 text-primary">
                                        <b>Rating Table</b>
                                      </span>
                                      <span class="w-100 mt-5">
                                        {x?.RatingTable ?? "Empty"}
                                      </span>
                                    </>
                                  )} */}
                                </p>
                              </Tooltip>
                            }
                          >
                            <a className="btn btn-icon btn-hover-light btn-sm mr-3">
                              <span className="svg-icon svg-icon-md svg-icon-info">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Code/Info-circle.svg"
                                  )}
                                />
                              </span>
                            </a>
                          </OverlayTrigger>
                          {x?.Code}
                          <span class="ml-2 text-primary">
                            <b>{x?.CalculName}</b>
                          </span>
                        </b>
                      </td>
                      <td className={`border-0`}>{x?.Name}</td>
                      <td className={`border-0 Rate`}>
                        {x?.IsVariable}
                        <input
                          className={RateClassName(
                            S_ChargesReadOnly,
                            x,
                            isVariableOpen
                          )}
                          readonly={RateReadOnly(isVariableOpen, x?.IsVariable)}
                          defaultValue={x?.Rate}
                          disabled={S_ChargesReadOnly ? true : x?.IsReadOnly}
                          onClick={(e) => {
                            if (e.detail === 2 && x?.IsVariable === true) {
                              e.target.readOnly = false;
                              $(e.target).removeClass("bg-light");
                            }
                          }}
                          onBlur={(e) => {
                            var b = JSON.parse(JSON.stringify(Charges));
                            var a = b.findIndex(
                              (y) =>
                                y.ChargeId === x?.ChargeId &&
                                y.OriAddSup === x?.OriAddSup &&
                                y?.InvoiceId === x?.InvoiceId
                            );
                            if (a > -1) {
                              b[a].Rate = +e.target.value;
                              setCharges(b);
                            }
                            const c = CalculateCharge({
                              Rate: +e.target.value,
                              Quantity: RoundToFixed(
                                x?.Quantity ?? 0,
                                x?.Rounding
                              ),
                              rateType: x?.RateType,
                              MinCharge: x?.MinCharge,
                              MaxCharge: x?.MaxCharge,
                              FreeTime: x?.FreeTime,
                              OriAddSup: x?.OriAddSup,
                            });
                            $(`.RatesTotal${iiii} input`).val(c.toFixed(2));
                          }}
                        />
                      </td>
                      <td className={`border-0 position-relative`}>
                        <IsRounding x={x} ChargesReadOnly={S_ChargesReadOnly} />
                        <input
                          type="number"
                          className={`form-control ${(x?.Quantity || 0) === 0 &&
                            "border border-danger"}`}
                          disabled={S_ChargesReadOnly ? true : x?.IsReadOnly}
                          min="1"
                          id={`${iiii}Id`}
                          onBlur={(e) => {
                            var b = JSON.parse(JSON.stringify(Charges));
                            var a = b.findIndex(
                              (y) =>
                                y.ChargeId === x?.ChargeId &&
                                y.OriAddSup === x?.OriAddSup &&
                                y?.InvoiceId === x?.InvoiceId
                            );
                            if (a > -1) {
                              b[a].Quantity = +e.target.value;
                              setCharges(b);
                            }
                            const c = CalculateCharge({
                              Rate: x?.Rate,
                              Quantity: e.target.value,
                              rateType: x?.RateType,
                              MinCharge: x?.MinCharge,
                              MaxCharge: x?.MaxCharge,
                              FreeTime: x?.FreeTime,
                              OriAddSup: x?.OriAddSup,
                            });
                            $(`.RatesTotal${iiii} input`).val(c.toFixed(2));
                          }}
                          onChange={(e) => {
                            GetLengthRound(e, x?.Rounding);
                          }}
                          defaultValue={InputValueCharge(x, S_ChargesReadOnly)}
                        />
                      </td>
                      <td
                        className={`border-0 RatesTotal-${type +
                          countId +
                          ChargesId} RatesTotal${iiii} `}
                      >
                        <input
                          className="form-control"
                          disabled={true}
                          value={
                            CalculateCharge({
                              Rate: x?.Rate,
                              Quantity: RoundToFixed(
                                checkIs0(x?.Quantity, iiii),
                                x?.Rounding
                              ),
                              rateType: x?.RateType,
                              MinCharge: x?.MinCharge,
                              MaxCharge: x?.MaxCharge,
                              FreeTime: x?.FreeTime,
                              OriAddSup: x?.OriAddSup,
                            })?.toFixed(2) ?? 0
                          }
                        />
                      </td>
                      <td className={`border-0`}>
                        <button
                          type="button"
                          class="btn btn-light-danger w-100"
                          disabled={S_ChargesReadOnly ? true : x?.IsReadOnly}
                          onClick={() => {
                            let a = Charges || [];
                            let b = ChargesN || [];
                            a = a?.filter((y) => y !== x);
                            if (x?.OriAddSup !== 2)
                              b.push({
                                ...x,
                                Id: x?.ChargeId,
                                Rate: x?.Rate,
                                Name: x?.Name,
                                Code: x?.Code,
                                Quantity: 1,
                                RateType: x?.RateType,
                                MinCharge: x?.MinCharge,
                                MaxCharge: x?.MaxCharge,
                                FreeTime: x?.FreeTime,
                                Position: x?.Position,
                                CategoryPosition: x?.CategoryPosition,
                                Calcul: x?.Calcul,
                                Category: x?.Category,
                                IsVariable: x?.IsVariable || false,
                                Invoice: x?.Invoice,
                                InvoiceId: x?.InvoiceId,
                                OriAddSup: x?.OriAddSup,
                                IsReadOnly: x?.IsReadOnly || false,
                                DateEnd: x?.DateEnd,
                                DateStart: x?.DateStart,
                                QuoteId: x?.QuoteId,
                                Rounding: x?.Rounding,
                                CalculName: x?.CalculName,
                              });

                            setCharges(a);
                            setChargesN(b);
                          }}
                        >
                          <i className={`fas fa-times fa-sm`}></i>
                        </button>
                      </td>
                      <td className={`border-0`}>
                        <MenuListComposition
                          Child1={
                            <button
                              type="button"
                              className="btn btn-light-warning w-100"
                              onClick={() => {
                                var autoRatingCheck = [x?.Id];
                                setAutoRatingCheck(autoRatingCheck);

                                var find = SortOnlyCharge(
                                  Charges,
                                  autoRatingCheck
                                ).length;

                                if (find > 0) setAutoRatingModal(true);
                                else
                                  AutoRatingCharges({
                                    setAutoRatingModal: setAutoRatingModal,
                                    setCharges: setCharges,
                                    Charges: Charges,
                                    bool: true,
                                    AutoRatingCheck: AutoRatingCheck,
                                    IndexId: ChargesId,
                                  });
                              }}
                            >
                              <i className={`fas fa-magic fa-sm`} />
                            </button>
                          }
                          Child2={
                            <button
                              type="button"
                              class="btn btn-light-primary w-100"
                              onClick={() => {
                                setNoteModal({
                                  Show: true,
                                  Id: x?.ChargeId,
                                  Code: x?.Code,
                                  Note: x?.Note,
                                  index: index,
                                });
                              }}
                            >
                              <i
                                className={`${
                                  x?.Note ? "fas" : "far"
                                } fa-sticky-note fa-sm`}
                              ></i>
                            </button>
                          }
                        />
                      </td>
                    </tr>
                    <tr className="bg-white">
                      <td className={`border-0`}></td>
                      <td className={`border-0`}></td>
                      <td className={`border-0`}></td>
                      <td className="row mb-0 border-0 place-content-center py-0">
                        {(x?.FreeTime || null) !== null && (
                          <button
                            type="button"
                            className="btn btn-primary text-center btn-sm p-2"
                          >
                            FT : {ReturnEmptyOrString(x?.FreeTime)}
                          </button>
                        )}
                        {(x?.MinCharge || null) !== null && (
                          <button
                            type="button"
                            className="btn btn-info text-center mx-2 btn-sm p-2"
                          >
                            Min : {ReturnEmptyOrString(x?.MinCharge)}
                          </button>
                        )}
                        {(x?.MaxCharge || null) !== null && (
                          <button
                            type="button"
                            className="btn btn-warning text-center btn-sm p-2"
                          >
                            Max : {ReturnEmptyOrString(x?.MaxCharge)}
                          </button>
                        )}
                      </td>
                      <td className={`border-0`}></td>
                      <td className={`border-0`}></td>
                      <td className={`border-0`}></td>
                    </tr>
                    {x?.SubTotal && (
                      <tr
                        className={`bg-white border-bottom border-${ChargesColorCategory(
                          x?.Category
                        )}`}
                      >
                        <td className={`border-0`} />
                        <td className={`border-0`} />
                        <td className={`border-0`} />
                        <td className={`border-0 text-right`}>
                          <p
                            className={`btn py-1 w-100 text-white bg-${ChargesColorCategory(
                              x?.Category
                            )}`}
                          >
                            <b>Sub Total</b>
                          </p>
                        </td>
                        <td className={`border-0`}>
                          <p
                            className={`btn py-1 w-100 Charges-${RemoveSpace(
                              x?.Category
                            )}-${RemoveSpace(
                              x?.Invoice
                            )}-${type}-${countId}-${ChargesId} text-${ChargesColorCategory(
                              x?.Category
                            )} bg-light-${ChargesColorCategory(x?.Category)}`}
                            style={{ height: "29px" }}
                          />
                        </td>
                        <td className={`border-0`} />
                        <td className={`border-0`} />
                      </tr>
                    )}
                  </tbody>
                </>
              );
            })}
            <tbody className="border-0">
              <tr>
                <td colSpan="3" className="border-0"></td>
                <td className="text-right border-0">
                  <p className={`btn py-1 mt-4 w-100 text-dark bg-secondary`}>
                    <b>Total</b>
                  </p>
                </td>
                <td
                  className={`border-0 RateAllTotal-${type +
                    countId}-${ChargesId}`}
                >
                  <span className="btn py-1 w-100 bg-light-secondary"></span>
                </td>
                <td className="border-0"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {ChargesRemove?.length > 0 && (
        <ChargesRemoveComponent ChargesRemove={ChargesRemove} />
      )}
      {Charges?.length === 0 && <Spinner2s />}
      <div className="col-lg-8 text-black p-5 font-weight-bold">
        {Charges.findIndex((x) => x?.IsReadOnly === true) === -1 && (
          <button
            type="button"
            class="btn btn-light-success p-2"
            onClick={() => {
              setChargeModel({ Show: true, Type: "success" });
            }}
            disabled={
              S_ChargesReadOnly || ChargesN?.length === 0 || loadingCharges
            }
          >
            <i className={`fas fa-plus fa-sm`}></i> Add Charge
          </button>
        )}
        {Charges.findIndex((x) => x?.IsReadOnly === true) > -1 && (
          <>
            <button
              type="button"
              class={"btn btn-light-primary p-2 ml-2"}
              onClick={() => {
                setChargeModel({ Show: true, Type: "primary" });
              }}
              disabled={
                S_ChargesReadOnly ||
                ChargesN?.length === 0 ||
                Charges.findIndex(
                  (x) => x?.OriAddSup === 2 && x?.Invoice === "Empty"
                ) > -1
              }
            >
              <i className={`fas fa-plus fa-sm`}></i> Add Additional
            </button>
            <button
              type="button"
              class="btn btn-light-info p-2 ml-2"
              onClick={() => {
                setChargeModel({ Show: true, Type: "info" });
              }}
              disabled={
                S_ChargesReadOnly ||
                Charges?.length === 0 ||
                Charges.findIndex(
                  (x) => x?.OriAddSup === 1 && x?.Invoice === "Empty"
                ) > -1
              }
            >
              <i className={`fas fa-plus fa-sm`}></i> Add Supplemental
            </button>
          </>
        )}
        {ZipCode && <b className="mx-5">PickUp Zip Code : {ZipCode}</b>}
      </div>
      <div className="col-lg-2 text-black p-5 font-weight-bold text-right">
        <button
          id={"AutoRatingBtn" + countId}
          type="button"
          disabled={S_ChargesReadOnly || loadingCharges}
          className="btn btn-light-warning p-2 w-100"
          onClick={() => {
            var list = [];
            Charges.forEach((x) => list.push(x?.Id));
            setAutoRatingCheck(list);

            var find = SortOnlyCharge(Charges).length;
            if (find > 0) setAutoRatingModal(true);
            else
              AutoRatingCharges({
                setAutoRatingModal: setAutoRatingModal,
                setCharges: setCharges,
                Charges: Charges,
                bool: true,
                AutoRatingCheck: AutoRatingCheck,
                IndexId: ChargesId,
              });
          }}
        >
          <i className={`fas fa-magic fa-sm`}></i> Auto Rate
        </button>

        <input
          type="hidden"
          data-type=""
          className="BtnAutoRating"
          onClick={(e) => {
            //get type --------------------------------------------------
            var type = e.target.getAttribute("data-type");

            var list = [];
            Charges.filter((x) => type.includes(x.Calcul)).forEach((x) => {
              if (type.includes("perdaychassis")) {
                x.DateStart = FieldID("C_PickedUpDate" + ChargesId);
                x.DateEnd = FieldID("C_TerminatedDate" + ChargesId);
              }
              list.push(x?.Id);
            });
            var autoRatingCheck = list;
            setAutoRatingCheck(list);

            //get type --------------------------------------------------

            var find = SortOnlyCharge(Charges, autoRatingCheck).length;
            if (find > 0) setAutoRatingModal(true);
            else
              AutoRatingCharges({
                setAutoRatingModal: setAutoRatingModal,
                setCharges: setCharges,
                Charges: Charges,
                bool: true,
                AutoRatingCheck: AutoRatingCheck,
                IndexId: ChargesId,
              });
          }}
        />
      </div>
      <div className="col-lg-2 text-black p-5 font-weight-bold text-right">
        <button
          type="button"
          disabled={S_ChargesReadOnly || loadingCharges}
          className="btn btn-light-primary p-2"
          onClick={() => {
            GetPayableForVendor({ button: true });
          }}
        >
          {loadingCharges && (
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              style={{
                fontSize: "10px",
                width: "20px",
                height: "20px",
                marginRight: "3px",
              }}
            />
          )}
          <i className={`fas fa-square-root-alt fa-sm`}></i> Calculated
        </button>
      </div>
    </div>
  );
};
