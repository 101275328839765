import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../_redux/Transload/FindOrderContainers/remarksActions";
import { RemarkEditForm } from "./RemarkEditForm";
import { RemarkEditDialogHeader } from "./RemarkEditDialogHeader";
import { AssignToOutbound } from "./AssignToOutbound";
import { useRemarksUIContext } from "../RemarksUIContext";
import axios from "axios";

export function RemarkEditDialog() {
  // Remarks UI Context
  const remarksUIContext = useRemarksUIContext();
  const remarksUIProps = useMemo(() => {
    return {
      id: remarksUIContext.selectedId,
      setIds: remarksUIContext.setIds,
      productId: remarksUIContext.productId,
      queryParams: remarksUIContext.queryParams,
      showEditRemarkDialog: remarksUIContext.showEditRemarkDialog,
      showAssigntoOutbound: remarksUIContext.showAssigntoOutbound,
      closeEditRemarkDialog: remarksUIContext.closeEditRemarkDialog,
      openEditRemarkDialog: remarksUIContext.openEditRemarkDialog,
      initRemark: remarksUIContext.initRemark,
    };
  }, [remarksUIContext]);

  // Remarks Redux state
  const dispatch = useDispatch();
  const { actionsLoading, remarkForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.remarks.actionsLoading,
      remarkForEdit: state.remarks.remarkForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server request for getting remark by seleted id
    if (remarksUIProps.id) dispatch(actions.fetchRemark(remarksUIProps.id));
  }, [remarksUIProps.id, dispatch]);

  const saveRemark = async (remark) => {
    if (!remarksUIProps.id) {
      // server request for creating remarks
      dispatch(actions.createRemark(remark)).then(() => {
        // refresh list after deletion
        dispatch(
          actions.fetchRemarks(
            remarksUIProps.queryParams,
            remarksUIProps.productId
          )
        ).then(() => {
          if (window.$NewReceiptsID && window.ContainerSaveClose === false) {
            remarksUIProps.openEditRemarkDialog(window.$NewReceiptsID);
            window.$NewReceiptsID = undefined;
          }

          if (window.ContainerSaveClose === true)
            remarksUIProps.closeEditRemarkDialog();
        });
      });
    } else {
      if (window.ReceiptManagmetShow) {
        await axios
          .put("api/FindOrderContainers/" + window.ReceiptId, {
            remark: remark,
          })
          .then(() => {
            if (window.ContainerSaveClose === true) {
              remarksUIProps.closeEditRemarkDialog();
              setTimeout(() => {
                if (window.receiptEditCommingFrom.indexOf("Billing") > -1) {
                  document
                    .getElementsByClassName("text-info")[0]
                    .parentElement.click();
                }
                if (window.receiptEditCommingFrom.indexOf("ReceiptsManagement") > -1) {
                  document.getElementById("ReLoadTableId").click();
                  document.getElementById("refreshReceiptException").click();
                }
              }, 500);
            }
          });
      } else {
        // server request for updating remarks
        dispatch(actions.updateRemark(remark)).then(() => {
          // refresh list after deletion
          dispatch(
            // refresh list after deletion
            actions.fetchRemarks(
              remarksUIProps.queryParams,
              remarksUIProps.productId
            )
          ).then(() => {
            // clear selections list
            remarksUIProps.setIds([]);
            if (window.ContainerSaveClose === true)
              remarksUIProps.closeEditRemarkDialog();
          });
        });
      }
    }
  };
  return (
    <div>
      <Modal
        size="xl"
        show={remarksUIProps.showEditRemarkDialog}
        /* onHide={remarksUIProps.closeEditRemarkDialog} */
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <RemarkEditDialogHeader
          id={remarksUIProps.id}
          onHide={remarksUIProps.closeEditRemarkDialog}
        />
        <RemarkEditForm
          saveRemark={saveRemark}
          actionsLoading={actionsLoading}
          remark={
            remarksUIProps?.id && remarksUIProps?.id === remarkForEdit?.Id
              ? remarkForEdit
              : remarksUIProps.initRemark
          }
          onHide={remarksUIProps.closeEditRemarkDialog}
          isId={remarksUIProps.id}
        />
      </Modal>
      <Modal
        size="xl"
        show={remarksUIProps.showAssigntoOutbound}
        onHide={remarksUIProps.closeEditRemarkDialog}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <AssignToOutbound onHide={remarksUIProps.closeEditRemarkDialog} />
      </Modal>
    </div>
  );
}
