import React, { StrictMode, useEffect, useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import SVG from "react-inlinesvg";
import { Notification } from "../../app/modules/Notification";
import {
  sortCaret,
  toAbsoluteUrl,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import $ from "jquery";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { TextField ,IconButton, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Table } from "react-bootstrap";
import { JsonToTable } from "react-json-to-table";
import CloseIcon from "@material-ui/icons/Close";
import { FileViewer } from "react-file-viewer";
import PDFViewer from "pdf-viewer-reactjs";
const fedHolidays = require("@18f/us-federal-holidays");

const options = { shiftSaturdayHolidays: true, shiftSundayHolidays: true };
const _holidays = fedHolidays.allForYear(2021, options);
const adapter = new LocalStorage("db");
const db = low(adapter);

export function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}
export const containsHTML = (str) => /<[a-z][\s\S]*>/i.test(str);

export function Export(List, Type, Entity) {
  try {
    var dataExportList = JSON.parse(JSON.stringify(List));
    dataExportList.forEach((o) => {
      Object.keys(o).forEach((k) => {
        if (containsHTML(o[k])) {
          o[k] = stripHtml(o[k]);
        }
        if (
          moment(
            o[k] !== null ? String(o[k]).slice(0, 10) : "",
            "YYYY-MM-DD",
            true
          ).isValid()
        ) {
          o[k] = new Date(o[k]);
        }
      });
    });
    var date = new Date(Date.now());
    var dateformat =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "" +
      date.getDate() +
      "" +
      date.getFullYear() +
      "" +
      date.getHours() +
      "" +
      date.getMinutes() +
      "" +
      date.getSeconds();
    if (Type === "excel") {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(dataExportList, {
        cellDates: true,
        dateNF: "mm/dd/yyyy",
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    } else if (Type === "csv") {
      const data2 = returncsv(dataExportList);
      const fileType = "text/csv;charset=utf-8";
      const fileExtension = ".csv";
      const data = new Blob([data2], { type: fileType });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    } else if (Type === "json") {
      const fileType = "application/json";
      const fileExtension = ".json";
      const data = new Blob([JSON.stringify(dataExportList)], {
        type: fileType,
      });
      FileSaver.saveAs(data, Entity + "_" + dateformat + fileExtension);
    }
  } catch (err) {
    console.log(err);
  }
}
export const ComparisonGlobalSearchOptions = [
  { value: "Equals", label: "Equals" },
  { value: "Contains", label: "Contains" },
  { value: "Starts With", label: "Starts With" },
  { value: "Ends With", label: "Ends With" },
];
export const PickTypeList = [
  { value: "PICKUP", label: "PICKUP" },
  { value: "DROP OFF", label: "DROP OFF" },
];
export const timeList = [
  { value: "00:00:00", label: "12.00 AM" },
  { value: "00:30:00", label: "12.30 AM" },
  { value: "01:00:00", label: "01.00 AM" },
  { value: "01:30:00", label: "01.30 AM" },
  { value: "02:00:00", label: "02.00 AM" },
  { value: "02:30:00", label: "02.30 AM" },
  { value: "03:00:00", label: "03.00 AM" },
  { value: "03:30:00", label: "03.30 AM" },
  { value: "04:00:00", label: "04.00 AM" },
  { value: "04:30:00", label: "04.30 AM" },
  { value: "05:00:00", label: "05.00 AM" },
  { value: "05:30:00", label: "05.30 AM" },
  { value: "06:00:00", label: "06.00 AM" },
  { value: "06:30:00", label: "06.30 AM" },
  { value: "07:00:00", label: "07.00 AM" },
  { value: "07:30:00", label: "07.30 AM" },
  { value: "08:00:00", label: "08.00 AM" },
  { value: "08:30:00", label: "08.30 AM" },
  { value: "09:00:00", label: "09.00 AM" },
  { value: "09:30:00", label: "09.30 AM" },
  { value: "10:00:00", label: "10.00 AM" },
  { value: "10:30:00", label: "10.30 AM" },
  { value: "11:00:00", label: "11.00 AM" },
  { value: "11:30:00", label: "11.30 AM" },
  { value: "12:00:00", label: "12.00 PM" },
  { value: "12:30:00", label: "12.30 PM" },
  { value: "13:00:00", label: "01.00 PM" },
  { value: "13:30:00", label: "01.30 PM" },
  { value: "14:00:00", label: "02.00 PM" },
  { value: "14:30:00", label: "02.30 PM" },
  { value: "15:00:00", label: "03.00 PM" },
  { value: "15:30:00", label: "03.30 PM" },
  { value: "16:00:00", label: "04.00 PM" },
  { value: "16:30:00", label: "04.30 PM" },
  { value: "17:00:00", label: "05.00 PM" },
  { value: "17:30:00", label: "05.30 PM" },
  { value: "18:00:00", label: "06.00 PM" },
  { value: "18:30:00", label: "06.30 PM" },
  { value: "19:00:00", label: "07.00 PM" },
  { value: "19:30:00", label: "07.30 PM" },
  { value: "20:00:00", label: "08.00 PM" },
  { value: "20:30:00", label: "08.30 PM" },
  { value: "21:00:00", label: "09.00 PM" },
  { value: "21:30:00", label: "09.30 PM" },
  { value: "22:00:00", label: "10.00 PM" },
  { value: "22:30:00", label: "10.30 PM" },
  { value: "23:00:00", label: "11.00 PM" },
  { value: "23:30:00", label: "11.30 PM" },
];
export const TMStimeList = [
  { value: "00:00:00", label: "00:00" },
  { value: "00:30:00", label: "00:30" },
  { value: "01:00:00", label: "01:00" },
  { value: "01:30:00", label: "01:30" },
  { value: "02:00:00", label: "02:00" },
  { value: "02:30:00", label: "02:30" },
  { value: "03:00:00", label: "03:00" },
  { value: "03:30:00", label: "03:30" },
  { value: "04:00:00", label: "04:00" },
  { value: "04:30:00", label: "04:30" },
  { value: "05:00:00", label: "05:00" },
  { value: "05:30:00", label: "05:30" },
  { value: "06:00:00", label: "06:00" },
  { value: "06:30:00", label: "06:30" },
  { value: "07:00:00", label: "07:00" },
  { value: "07:30:00", label: "07:30" },
  { value: "08:00:00", label: "08:00" },
  { value: "08:30:00", label: "08:30" },
  { value: "09:00:00", label: "09:00" },
  { value: "09:30:00", label: "09:30" },
  { value: "10:00:00", label: "10:00" },
  { value: "10:30:00", label: "10:30" },
  { value: "11:00:00", label: "11:00" },
  { value: "11:30:00", label: "11:30" },
  { value: "12:00:00", label: "12:00" },
  { value: "12:30:00", label: "12:30" },
  { value: "13:00:00", label: "13:00" },
  { value: "13:30:00", label: "13:30" },
  { value: "14:00:00", label: "14:00" },
  { value: "14:30:00", label: "14:30" },
  { value: "15:00:00", label: "15:00" },
  { value: "15:30:00", label: "15:30" },
  { value: "16:00:00", label: "16:00" },
  { value: "16:30:00", label: "16:30" },
  { value: "17:00:00", label: "17:00" },
  { value: "17:30:00", label: "17:30" },
  { value: "18:00:00", label: "18:00" },
  { value: "18:30:00", label: "18:30" },
  { value: "19:00:00", label: "19:00" },
  { value: "19:30:00", label: "19:30" },
  { value: "20:00:00", label: "20:00" },
  { value: "20:30:00", label: "20:30" },
  { value: "21:00:00", label: "21:00" },
  { value: "21:30:00", label: "21:30" },
  { value: "22:00:00", label: "22:00" },
  { value: "22:30:00", label: "22:30" },
  { value: "23:00:00", label: "23:00" },
  { value: "23:30:00", label: "23:30" },
];
export const StatesUs = [
  {
    Id: 7,
    Name: "AL",
  },
  {
    Id: 8,
    Name: "AK",
  },
  {
    Id: 9,
    Name: "AZ",
  },
  {
    Id: 10,
    Name: "AR",
  },
  {
    Id: 11,
    Name: "CA",
  },
  {
    Id: 12,
    Name: "CO",
  },
  {
    Id: 13,
    Name: "CT",
  },
  {
    Id: 14,
    Name: "DE",
  },
  {
    Id: 15,
    Name: "DC",
  },
  {
    Id: 16,
    Name: "FL",
  },
  {
    Id: 17,
    Name: "GA",
  },
  {
    Id: 18,
    Name: "HI",
  },
  {
    Id: 19,
    Name: "ID",
  },
  {
    Id: 20,
    Name: "IL",
  },
  {
    Id: 21,
    Name: "IN",
  },
  {
    Id: 22,
    Name: "IA",
  },
  {
    Id: 23,
    Name: "KS",
  },
  {
    Id: 24,
    Name: "KY",
  },
  {
    Id: 25,
    Name: "LA",
  },
  {
    Id: 26,
    Name: "ME",
  },
  {
    Id: 27,
    Name: "MD",
  },
  {
    Id: 28,
    Name: "MA",
  },
  {
    Id: 29,
    Name: "MI",
  },
  {
    Id: 30,
    Name: "MN",
  },
  {
    Id: 31,
    Name: "MS",
  },
  {
    Id: 32,
    Name: "MO",
  },
  {
    Id: 33,
    Name: "MT",
  },
  {
    Id: 34,
    Name: "NE",
  },
  {
    Id: 35,
    Name: "NV",
  },
  {
    Id: 36,
    Name: "NH",
  },
  {
    Id: 37,
    Name: "NJ",
  },
  {
    Id: 38,
    Name: "NM",
  },
  {
    Id: 39,
    Name: "NY",
  },
  {
    Id: 40,
    Name: "NC",
  },
  {
    Id: 41,
    Name: "ND",
  },
  {
    Id: 42,
    Name: "OH",
  },
  {
    Id: 43,
    Name: "OK",
  },
  {
    Id: 44,
    Name: "OR",
  },
  {
    Id: 45,
    Name: "PA",
  },
  {
    Id: 46,
    Name: "RI",
  },
  {
    Id: 47,
    Name: "SC",
  },
  {
    Id: 48,
    Name: "SD",
  },
  {
    Id: 49,
    Name: "TN",
  },
  {
    Id: 50,
    Name: "TX",
  },
  {
    Id: 51,
    Name: "UT",
  },
  {
    Id: 52,
    Name: "VT",
  },
  {
    Id: 53,
    Name: "VA",
  },
  {
    Id: 54,
    Name: "WA",
  },
  {
    Id: 55,
    Name: "WV",
  },
  {
    Id: 56,
    Name: "WI",
  },
  {
    Id: 57,
    Name: "WY",
  },
];
export const DateTime30minInterval = () => {
  return (
    <>
      <option value="0">Select</option>
      {timeList.map((item, index) => (
        <option value={`${item.value}`}>{item.label}</option>
      ))}
    </>
  );
};
export const RequiredHtml = () => {
  return (
    <i
      class="fa fa-star-of-life text-danger icon-l ml-2"
      style={{ fontSize: "8px", position: "absolute" }}
    ></i>
  );
};

export function TimeSpanColumnFormatter(dateTime) {
  var _dateTime = new Date(dateTime);
  var currentDate = new Date();
  var utcTime = new Date(
    _dateTime.getTime() + -60 * (60000 * window.$timezone)
  );
  var convertedDate = new Date(
    utcTime.getTime() + currentDate.getTimezoneOffset() * -60000
  );
  var b = moment(new Date());
  var diffYear = b.diff(convertedDate, "year");
  var diffMonths = b.diff(convertedDate, "months");
  var diffDays = b.diff(convertedDate, "days");
  var diffHours = b.diff(convertedDate, "hours");
  var diffMinutes = b.diff(convertedDate, "minutes");
  var text = "";
  if (diffYear > 0) text = diffYear + ` year${diffYear > 1 ? "s" : ""} ago`;
  else if (diffMonths > 0)
    text = diffMonths + ` month${diffMonths > 1 ? "s" : ""} ago`;
  else if (diffDays > 0) text = diffDays + ` day${diffDays > 1 ? "s" : ""} ago`;
  else if (diffHours > 0)
    text = diffHours + ` hour${diffHours > 1 ? "s" : ""} ago`;
  else if (diffMinutes > 0)
    text = diffMinutes + ` minute${diffMinutes > 1 ? "s" : ""} ago`;
  else text = "now";
  return text;
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function JqueryName(name) {
  return $(`[name="${name}"]`).val() || "0";
}

export function Close_aside() {
  var aside = $("#kt_body");
  if (!aside.hasClass("aside-minimize")) {
    aside.addClass("aside-minimize");
  }
}

export function CheckDetail(value) {
  if (value === undefined || value === "" || value === null) return "";
  return (
    <>
      {value}
      <br />
    </>
  );
}

export function jqueryChange(name, value) {
  $(`[name="${name}"]`).ready(function() {
    setTimeout(() => {
      if (value && value !== "" && value !== undefined && value !== null) {
        window.$CreatableSelect2 = value;
        $(`[name="${name}"]`)
          .val(value)
          .trigger("change");
      }
    }, 1000);
  });
}

export function is(str, point = true) {
  if (!str || str === null || str === undefined) return "";
  return str.toString() + (point ? " - " : "");
}

export function is2({ str, append = "" }) {
  if (!str || str === null || str === undefined) return "";
  return append + str.toString();
}

export function ReturnEmptyOrString(str) {
  if (!str || str === null || str === "" || str === undefined)
    return <small className="text-danger">Empty</small>;
  return <>{str}</>;
}

export function IsValidMessage(name, value) {
  if (!value) {
    Notification("error", name + " is required");
    window.IsValid = true;
  }
}

export function Try(fun) {
  try {
    return fun;
  } catch (err) {
    console.log(err.message);
  }
}

export function strip(html) {
  var tmp = document.implementation.createHTMLDocument("New").body;
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function returncsv(values) {
  var json = values;
  var fields = Object.keys(json[0]);
  var replacer = function(key, value) {
    return value === null ? "" : value;
  };
  var csv = json.map(function(row) {
    return fields
      .map(function(fieldName) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(",");
  });
  csv.unshift(fields.join(",")); // add header column
  csv = csv.join("\r\n");
  return csv;
}
function returnColorAlert(value, x) {
  if (x === "elapsed_time") {
    if (value !== null) {
      if (value / 1000 > 180) {
        return "danger";
      } else if (value / 1000 >= 90) {
        return "warning";
      } else {
        return "success";
      }
    }
  } else if (x === "cpu") {
    if (value !== null) {
      if (value > 90000) {
        return "danger";
      } else if (value >= 50000) {
        return "warning";
      } else {
        return "success";
      }
    }
  } else if (x === "used_memory") {
    if (value !== null) {
      if (value > 200000) {
        return "danger";
      } else if (value >= 100000) {
        return "warning";
      } else {
        return "success";
      }
    }
  }
}
export function TryList(list) {
  return list || [];
}

export function currencyFormat(no) {
  if (no === null) {
    no = 0;
  }
  var ar = (+no).toFixed(2).split(".");
  return [numberFormat(ar[0] | 0), ".", ar[1]].join("");
}
export function numberWithCommas(x, type) {
  if (x !== null && x !== "") {
    return (
      <>
        <div className="row" style={{ placeContent: "center" }}>
          {returnColorAlert(x, type) !== "success" ? (
            <span
              className={`svg-icon svg-icon-md mt-4 mr-1 svg-icon-${returnColorAlert(
                x,
                type
              )}`}
            >
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
            </span>
          ) : (
            ""
          )}
          <a
            href={() => false}
            class={`alert px-2 py-1 mt-3 text-${returnColorAlert(
              x,
              type
            )} bg-light-${returnColorAlert(x, type)}`}
          >
            {String(x)
              .toString()
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </a>
        </div>
      </>
    );
  } else {
    return "";
  }
}
function numberFormat(no) {
  var str = no + "";
  var ar = [];
  var i = str.length - 1;

  while (i >= 0) {
    ar.push((str[i - 2] || "") + (str[i - 1] || "") + (str[i] || ""));
    i = i - 3;
  }
  return ar.reverse().join(",");
}

//Convert time to user timeZone
export function ConvertDate(dateTime, timezone) {
  if (!dateTime || !Date.parse(dateTime)) return "";
  var ISODate = new Date(`${dateTime}-${window.$timezone}`).toISOString();
  return moment(ISODate).utcOffset(timezone);
}

//Convert User time to GMT timeZone
function DeConvertDate(dateTime, timezone) {
  if (!dateTime || !Date.parse(dateTime)) return "";
  var ISODate = new Date(`${dateTime}+${window.$timezone}`).toISOString();
  return moment(ISODate).utcOffset(timezone);
}

// Get
export function momentDateTimeByDay(a, format = "MM-DD-YYYY, h:mm A") {
  if (!a || !Date.parse(a)) return "";
  var c = moment(new Date(a), "M/D/YYYY");
  var b = moment(new Date(), "M/D/YYYY");
  return ConvertDate(a, -GetTimeZone()).format(
    b.diff(c, "days") === 0 ? format : "MM-DD-YYYY"
  );
}

// Get
export function momentDate(a, format = "MM-DD-YYYY, h:mm A") {
  if (!a || !Date.parse(a)) return "";
  return ConvertDate(a, -GetTimeZone()).format(format);
  //return moment(a).format(format);
}
export function momentTime(a, format = "h:mm A") {
  if (!a || !Date.parse(a)) return "";
  return ConvertDate(a, -GetTimeZone()).format(format);
  //return moment(a).format(format);
}
// Get
export function momentDateOnly(a) {
  if (!a || !Date.parse(a)) return "";
  return ConvertDate(a, -GetTimeZone()).format("MM-DD-YYYY");
  //return moment(a).format("MM-DD-YYYY");
}

// Post
export function PostMomentDate(dateTime) {
  if (!dateTime || !Date.parse(dateTime)) return "";
  var dateTime = DeConvertDate(dateTime, +GetTimeZone());
  return moment(dateTime).format("MM-DD-YYYY, h:mm A");
}

export function PostMomentTimeOnly(dateTime) {
  dateTime = "1997-1-1 " + dateTime;
  if (!dateTime || !Date.parse(dateTime)) return "";
  var dateTime = DeConvertDate(dateTime, +GetTimeZone());
  return moment(dateTime).format("HH:mm:ss");
}

function GetTimeZone() {
  try {
    var uct = db.read().getState()?.user?.TimeZone;
    if (uct === undefined) return 8;
    var subStr = uct?.split("(UTC -")[1]?.replace(")", "");
    return parseInt(subStr ?? "8");
  } catch (error) {
    return 8;
  }
}

export function workingDaysBetweenDates(d0) {
  /* Two working days and an sunday (not working day) */
  if (d0 === null || d0 === "" || d0 === undefined) {
    return 0;
  }

  var holidays = _holidays.map((a) => a.dateString);

  const dateOneObj = new Date(getDate(d0));
  const dateTwoObj = new Date();
  const milliseconds = Math.abs(dateTwoObj - dateOneObj);
  var hours = milliseconds / 36e5;
  var startDate = parseDate(moment(d0).format("YYYY-MM-DD"));
  var endDate = parseDate(moment().format("YYYY-MM-DD"));
  // Validate input
  if (endDate < startDate) {
    return 0;
  }
  if (endDate === startDate) {
    return hours;
  }
  // Validate input

  // Calculate days between dates
  var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1); // Start just after midnight
  endDate.setHours(23, 59, 59, 999); // End just before midnight
  var diff = endDate - startDate; // Milliseconds between datetime objects
  var days = Math.ceil(diff / millisecondsPerDay);

  // Subtract two weekend days for every week in between
  var weeks = Math.floor(days / 7);
  hours -= weeks * 2 * 24;

  // Handle special cases
  var startDay = startDate.getDay();
  var endDay = endDate.getDay();

  // Remove weekend not previously removed.
  if (startDay - endDay > 1) {
    hours -= 2 * 24;
  }
  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay == 0 && endDay != 6) {
    hours -= 24;
  }
  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay == 6 && startDay != 0) {
    hours -= 24;
  }
  /* Here is the code */
  holidays.forEach((day) => {
    if (day >= d0 && day <= moment().format("YYYY-MM-DD")) {
      /* If it is not saturday (6) or sunday (0), substract it */
      if (parseDate(day).getDay() % 6 != 0) {
        hours -= 24;
      }
    }
  });
  return hours;
}

function parseDate(input) {
  // Transform date from text to date
  var parts = input.match(/(\d+)/g);
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
}
var getDate = function(d) {
  if (d === null || d === "" || d === undefined) return "";
  var format = "MM/DD/YYYY, hh:mm A";
  if (
    moment(d)
      .startOf("day")
      .valueOf() === moment(d).valueOf()
  ) {
    format = "MM/DD/YYYY";
  }
  return momentDate(moment(d).format(format));
};
//ZB 08/16 Activities

const columns = [
  {
    dataField: "Icon",
    text: "",
    classes: "text-center",
    formatter: (cell, row) => {
      if (row.Fields.length !== 0) {
        return (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="customers-delete-tooltip">
                  Activity Details
                </Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-warning btn-sm"
                onClick={() => {}}
              >
                <span className="svg-icon svg-icon-md svg-icon-warning">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </>
        );
      }
    },
  },
  {
    dataField: "User",
    text: "User",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "Created",
    text: "Date",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    formatter: (cell, row) => {
      return (
        <>
          <div className="align-items-center">
            <span className="label text-primary p-1 m-0 updated">
              {momentDate(row.Created)}
            </span>
          </div>
        </>
      );
    },
  },
  {
    dataField: "Action",
    text: "Event",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
];
export function ActivityFunction({ Activitys = [] }) {
  const [Activities, setActivities] = useState(NewActivites( Activitys.map((a, Index) => {
    a.Id = Index;
    return a;
  })));

  function NewActivites(activitys = []) {
    try {
      var list = JSON.parse(JSON.stringify(activitys));
      (list ?? []).forEach((a, Index) => {
        a.Id = Index;
      });
      return list;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  const [jsonValue, setJsonValue] = useState("");
  const [showModalJson, setShowJsonModal] = useState(false);
  const [rowItem, setRowItem] = useState(null);
  const [modalShowAttachment, setModalShowAttachment] = useState(false);
  const [modalShowHtml, setModalShowHtml] = useState(false);
  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };
  function createMarkup(dataString) {
    return { __html: dataString };
  }
  function getExtension(path) {
    try {
      var basename = path?.split(/[\\/]/).pop(),
        pos = basename?.lastIndexOf(".");
      if (basename === "" || pos < 1) return "";
      return basename?.slice(pos + 1);
    } catch (err) {
      console.log(err);
    }
  }
  const expandRow = {
    renderer: (row) => {
      if (row.Fields.length !== 0) {
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Old Value</TableCell>
                <TableCell>New Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.Fields.map((x) => {
                if (x.Field === "Total") {
                  return (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <strong>{x.Field}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>
                          {x?.Type === "json" && x?.OldValue !== "Empty" ? (
                            <button
                              className="btn btn-light-primary"
                              onClick={() => {
                                try {
                                  setShowJsonModal(true);
                                  const jsonData = JSON.parse(x?.OldValue);
                                  setJsonValue(jsonData);
                                } catch (err) {
                                  console.log(err);
                                }
                              }}
                            >
                              Show Data Json
                            </button>
                          ) : (
                            x?.OldValue
                          )}
                        </strong>
                      </TableCell>
                      <TableCell>
                        <strong>
                          {x?.Type === "json" ? (
                            <button
                              className="btn btn-light-primary"
                              onClick={() => {
                                try {
                                  setShowJsonModal(true);
                                  const jsonData = JSON.parse(x?.NewValue);
                                  setJsonValue(jsonData);
                                } catch (err) {
                                  console.log(err);
                                }
                              }}
                            >
                              Show Data Json
                            </button>
                          ) : x?.Type === "attachment" ? (
                            <button
                              className="btn btn-light-primary"
                              onClick={() => {
                                setModalShowAttachment(true);
                                setRowItem(x);
                              }}
                            >
                              Show Attachment
                            </button>
                          ) : x?.Type === "html" ? (
                            <button
                              className="btn btn-light-primary"
                              onClick={() => {
                                setModalShowHtml(true);
                                setRowItem(x);
                              }}
                            >
                              Show Html
                            </button>
                          ) : (
                            x?.NewValue
                          )}
                        </strong>
                      </TableCell>
                    </TableRow>
                  );
                } else if (x.NewValue === "Removed") {
                  return (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {x.Field}
                      </TableCell>
                      <TableCell>{x.OldValue}</TableCell>
                      <TableCell style={{ color: "red" }}>
                        {x.NewValue}
                      </TableCell>
                    </TableRow>
                  );
                } else if (x.OldValue === "Empty") {
                  return (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {x.Field}
                      </TableCell>
                      <TableCell style={{ color: "blue" }}>
                      {x?.Type === "json" && x?.OldValue !== "Empty" ? (
                          <button
                            className="btn btn-light-primary"
                            onClick={() => {
                              try {
                                setShowJsonModal(true);
                                const jsonData = JSON.parse(x?.OldValue);
                                setJsonValue(jsonData);
                              } catch (err) {
                                console.log(err);
                              }
                            }}
                          >
                            Show Data Json
                          </button>
                        ) : (
                          x?.OldValue
                        )}
                      </TableCell>
                      <TableCell>
                        {x?.Type === "json" ? (
                          <button
                            className="btn btn-light-primary"
                            onClick={() => {
                              try {
                                setShowJsonModal(true);
                                const jsonData = JSON.parse(x?.NewValue);
                                setJsonValue(jsonData);
                              } catch (err) {
                                console.log(err);
                              }
                            }}
                          >
                            Show Data Json
                          </button>
                        ) : x?.Type === "attachment" ? (
                          <button
                            className="btn btn-light-primary"
                            onClick={() => {
                              setModalShowAttachment(true);
                              setRowItem(x);
                            }}
                          >
                            Show Attachment
                          </button>
                        ) : x?.Type === "html" ? (
                          <button
                            className="btn btn-light-primary"
                            onClick={() => {
                              setModalShowHtml(true);
                              setRowItem(x);
                            }}
                          >
                            Show Html
                          </button>
                        ) : (
                          x?.NewValue
                        )}
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {x.Field}
                      </TableCell>
                      <TableCell>
                      {x?.Type === "json" && x?.OldValue !== "Empty" ? (
                          <button
                            className="btn btn-light-primary"
                            onClick={() => {
                              try {
                                setShowJsonModal(true);
                                const jsonData = JSON.parse(x?.OldValue);
                                setJsonValue(jsonData);
                              } catch (err) {
                                console.log(err);
                              }
                            }}
                          >
                            Show Data Json
                          </button>
                        ) : (
                          x?.OldValue
                        )}
                      </TableCell>
                      <TableCell>
                        {x?.Type === "json" ? (
                          <button
                            className="btn btn-light-primary"
                            onClick={() => {
                              try {
                                setShowJsonModal(true);
                                const jsonData = JSON.parse(x?.NewValue);
                                setJsonValue(jsonData);
                              } catch (err) {
                                console.log(err);
                              }
                            }}
                          >
                            Show Data Json
                          </button>
                        ) : x?.Type === "attachment" ? (
                          <button
                            className="btn btn-light-primary"
                            onClick={() => {
                              setModalShowAttachment(true);
                              setRowItem(x);
                            }}
                          >
                            Show Attachment
                          </button>
                        ) : x?.Type === "html" ? (
                          <button
                            className="btn btn-light-primary"
                            onClick={() => {
                              setModalShowHtml(true);
                              setRowItem(x);
                            }}
                          >
                            Show Html
                          </button>
                        ) : (
                          x?.NewValue
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        );
      }
    },
  };

  function fetchTypeImages(path){
    try {
      const imgPath = getExtension(path)?.toLowerCase();
      let result = false;
      switch (imgPath) {
        case "jpg": {
          result = true;
          break;
        }
        case "png": {
          result = true;
          break;
        }
        case "webp": {
          result = true;
          break;
        }
        case "jpeg": {
          result = true;
          break;
        }
        default: {
          result = false;
        }
      }
      return result;
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <div className={`h-60 card card-custom`}>
        <div className="card-body pt-5 row now-rapTable">
          <div className="col-xl-12">
            <div className="form-group row">
              <div className="col-lg-5">
                <input
                  type="text"
                  id="SearchActivitiesInput"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onChange={(e) => {
                    setActivities(
                      Activitys.filter(
                        (a) =>
                          String(a.Action)
                            .toLocaleLowerCase()
                            .trim()
                            .includes(
                              String(e.target.value)
                                .toLocaleLowerCase()
                                .trim()
                            ) ||
                          String(a.User)
                            .toLocaleLowerCase()
                            .trim()
                            .includes(
                              String(e.target.value)
                                .toLocaleLowerCase()
                                .trim()
                            )
                      )
                    );
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="customers-delete-tooltip">
                        All Activities
                      </Tooltip>
                    }
                  >
                    <Link
                      onClick={() => {
                        document.getElementById("SearchActivitiesInput").value =
                          "";
                        setActivities(Activitys);
                      }}
                      to="#"
                      className="nav-link align-self-center btn btn-icon  btn-hover-primary mr-2"
                    >
                      <i class="fas fa-list-ul icon-lg "></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="customers-delete-tooltip">Updates</Tooltip>
                    }
                  >
                    <Link
                      onClick={() => {
                        document.getElementById("SearchActivitiesInput").value =
                          "";
                        setActivities(
                          Activitys.filter(
                            (a) =>
                              String(a.Action)
                                .toLocaleLowerCase()
                                .trim()
                                .includes(
                                  String("Update")
                                    .toLocaleLowerCase()
                                    .trim()
                                ) &&
                              !String(a.Action)
                                .toLocaleLowerCase()
                                .trim()
                                .includes(
                                  String("status")
                                    .toLocaleLowerCase()
                                    .trim()
                                ) &&
                              !String(a.Action)
                                .toLocaleLowerCase()
                                .trim()
                                .includes(
                                  String("receivables")
                                    .toLocaleLowerCase()
                                    .trim()
                                ) &&
                              !String(a.Action)
                                .toLocaleLowerCase()
                                .trim()
                                .includes(
                                  String("payables")
                                    .toLocaleLowerCase()
                                    .trim()
                                )
                          )
                        );
                      }}
                      to="#"
                      className="nav-link align-self-center btn btn-icon btn-hover-primary mr-2"
                    >
                      <i class="fas fa-edit icon-lg "></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="customers-delete-tooltip">Creation</Tooltip>
                    }
                  >
                    <Link
                      onClick={() => {
                        document.getElementById("SearchActivitiesInput").value =
                          "";

                        setActivities(
                          Activitys.filter((a) =>
                            String(a.Action)
                              .toLocaleLowerCase()
                              .trim()
                              .includes(
                                String("Create")
                                  .toLocaleLowerCase()
                                  .trim()
                              )
                          )
                        );
                      }}
                      to="#"
                      className="nav-link align-self-center btn btn-icon btn-hover-primary mr-2"
                    >
                      <i class="far fa-plus-square icon-lg "></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="customers-delete-tooltip">
                        Status changes
                      </Tooltip>
                    }
                  >
                    <Link
                      onClick={() => {
                        document.getElementById("SearchActivitiesInput").value =
                          "";

                        setActivities(
                          Activitys.filter((a) =>
                            String(a.Action)
                              .toLocaleLowerCase()
                              .trim()
                              .includes(
                                String("status")
                                  .toLocaleLowerCase()
                                  .trim()
                              )
                          )
                        );
                      }}
                      to="#"
                      className="nav-link btn btn-icon btn-hover-primary mr-2"
                    >
                      <i class="ki ki-reload icon-lg"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="customers-delete-tooltip">
                        Charge Changes
                      </Tooltip>
                    }
                  >
                    <Link
                      onClick={() => {
                        document.getElementById("SearchActivitiesInput").value =
                          "";

                        setActivities(
                          Activitys.filter(
                            (a) =>
                              String(a.Action)
                                .toLocaleLowerCase()
                                .trim()
                                .includes(
                                  String("receivables")
                                    .toLocaleLowerCase()
                                    .trim()
                                ) ||
                              String(a.Action)
                                .toLocaleLowerCase()
                                .trim()
                                .includes(
                                  String("payables")
                                    .toLocaleLowerCase()
                                    .trim()
                                )
                          )
                        );
                      }}
                      to="#"
                      className="nav-link btn btn-icon btn-hover-primary"
                    >
                      <i class="fas fa-dollar-sign icon-lg"></i>
                    </Link>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12" style={{ textAlign: "left" }}>
            {Activities.length > 0 ? (
              <BootstrapTable
                keyField="Id"
                defaultSorted={[{ dataField: "Created", order: "desc" }]}
                data={Activities?.filter(
                  (a) =>
                    !(
                      a.Action.toLocaleLowerCase().includes(
                        "from Trinium".toLocaleLowerCase()
                      ) && a.Fields.length === 0
                    )
                )}
                columns={columns}
                pagination={paginationFactory()}
                expandRow={expandRow}
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                bordered={false}
              />
            ) : (
              <strong>No data to display</strong>
            )}
          </div>
        </div>
      </div>
      <Modal
        size="xl"
        show={showModalJson}
        onHide={() => {
          setShowJsonModal(false);
          setJsonValue("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <div className="row w-100">
            <div className="col-lg-6">
              <h3 className="font-weight-bolder text-dark">Json Data Table</h3>
            </div>
          </div>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setShowJsonModal(false);
              setJsonValue("");
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h4>Data JSON</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <JsonToTable json={jsonValue} />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-light"
            onClick={() => {
              setJsonValue("");
              setShowJsonModal(false);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal Attachment Viewer */}
      <Modal
        size="xl"
        show={modalShowAttachment}
        onHide={() => {
          setModalShowAttachment(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>Attachment Viewer</Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setModalShowAttachment(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <React.StrictMode>
            <div className="form-group row mb-5">
              <div className="col-lg-8"></div>
              <div className="col-lg-4" style={{ textAlignLast: "right" }}>
                <button
                  type="submit"
                  className="btn btn-primary btn-elevate"
                  onClick={() => {
                    const fileName = rowItem?.NewValue?.replace(
                      /^.*[\\\/]/,
                      ""
                    );
                    FileSaver(rowItem?.NewValue, fileName);
                  }}
                >
                  Download
                </button>{" "}
              </div>
            </div>
            {getExtension(rowItem?.NewValue)?.toLowerCase() === "pdf" ? (
              <div className="form-group row" key={Math.random()}>
                <div className="col-lg-12 pdfViewer" key={Math.random()}>
                  <PDFViewer
                    document={{
                      url: rowItem?.NewValue,
                    }}
                  />
                </div>
              </div>
            ) : (
              <StrictMode>
                <div className="form-group row" key={Math.random()}>
                  <div
                    className={`col-lg-12 ${
                      fetchTypeImages(rowItem?.NewValue) ? "CenterColDiv" : ""
                    }`}
                    key={Math.random()}
                  >
                    {fetchTypeImages(rowItem?.NewValue) ? (
                      <img
                        src={rowItem?.NewValue}
                        alt="logo"
                        className="w50Img"
                      />
                    ) : (
                      <FileViewer
                        fileType={getExtension(
                          rowItem?.NewValue
                        )?.toLowerCase()}
                        filePath={rowItem?.NewValue}
                        onError={onError}
                        className="w-100"
                      />
                    )}
                  </div>
                </div>
              </StrictMode>
            )}
          </React.StrictMode>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              setModalShowAttachment(false);
            }}
            className="btn btn-light btn-elevate w-25"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal Html Viewer */}
      <Modal
        size="xl"
        show={modalShowHtml}
        onHide={() => {
          setModalShowHtml(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>Html Viewer</Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setModalShowHtml(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="form-group row mb-5">
              <Col>
                <div
                  className="bodyDetail"
                  dangerouslySetInnerHTML={createMarkup(rowItem?.NewValue)}
                />
              </Col>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              setModalShowHtml(false);
            }}
            className="btn btn-light btn-elevate w-25"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function LoadingHtml(params) {
  return (
    <div class="text-center">
      <span
        role="status"
        aria-hidden="true"
        class="spinner-border spinner-border-sm m-5 text-center"
        style={{ width: "200px", height: "200px", fontSize: "30px" }}
      ></span>
    </div>
  );
}

function minOr30(h) {
  try {
    let a = h?.split(":");
    let b = parseInt(a[1]);
    let c = 15 < b && b < 45 ? "30" : "00";
    return `${a[0]}:${c}:00`;
  } catch (err) {
    return "00:00:00";
  }
}

function twoDigit(number) {
  var twodigit = number >= 10 ? number : "0" + number.toString();
  return twodigit;
}

const filterOption = (option, inputValue) =>
  (
    String(option.label)
      .replace(".", "")
      .match(inputValue) || []
  ).length > 0;
function validateHhMm(inputField) {
  var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
    inputField
  );
  return isValid;
}
export function DateTime30GL({
  field,
  label,
  span,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  IdName = "",
  required,
  ClassLabel,
  setFieldValue,
  defaultvalue,
  disabled = false,
  Time = true,
  ...props
}) {
  const { name, idInput, onChange } = props;

  const [DateValue, setDateValue] = useState("");
  const [TimeValue, setTimeValue] = useState("");
  const [valueDate, setvalueDate] = useState("");
  const [border, setborder] = useState("");

  useEffect(() => {
    if (DateValue === "" || DateValue === null || DateValue === undefined) {
      setvalueDate("");
      setTimeValue("");
      setDateValue("");
    } else if (String(new Date(DateValue).getFullYear()).length > 3) {
      if (DateValue !== "")
        if (TimeValue.length > 4) {
          var kk = validateHhMm(TimeValue);
          if (kk) {
            setvalueDate(DateValue + "T" + TimeValue + ":00");
          } else {
            setTimeValue("00:00")
            setvalueDate(DateValue + "T00:00:00");
          }
        } else {
          setvalueDate(DateValue + "T00:00:00");
        }
    }
  }, [DateValue, TimeValue]);

  useEffect(() => {
    onChange(valueDate);
  }, [valueDate]);

  useEffect(() => {
    if (defaultvalue) {
      var c = new Date(momentDate(defaultvalue));

      var date = `${c.getFullYear()}-${twoDigit(c.getMonth() + 1)}-${twoDigit(
        c.getDate()
      )}`;

      var label = twoDigit(c.getHours()) + ":" + twoDigit(c.getMinutes());
      /* var value = minOr30(label); */

      setDateValue(date);
      setTimeValue(label);
    }
  }, [defaultvalue]);

  const RequiredHtml = () => {
    return (
      <i
        class="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };

  return (
    <>
      <div className="row mt-4">
        <div className={"col-lg-6 mt-3 " + (!Time && " col-lg-12")}>
          {label && (
            <label className={ClassLabel}>
              {label} {required && <RequiredHtml />}
              {span && (
                <small className="alert alert-warning text-dark p-0 pl-2 pr-2">
                  {" "}
                  {span}
                </small>
              )}
            </label>
          )}
          <TextField
            type={"date"}
            className="form-control"
            value={DateValue}
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setDateValue(event.target.value);
            }}
          />
        </div>
        <div className={"col-lg-6 " + (!Time && " d-none")}>
          {label && <label className={ClassLabel}>Time</label>}
          <input
            className={`form-control ${border}`}
            value={TimeValue}
            placeholder="00:00"
            disabled={disabled}
            onKeyUp={(e) => {
              if (e.keyCode === 8) {
                setTimeValue("");
                setborder("");
                return;
              }
            }}
            onChange={(e) => {
              var value = e.target?.value;
              if (!value || value === "") return;
              if (value.length === 2) value += ":";
              if (value.length > 5) value = value[5];
              setTimeValue(value);
              var re = /^0[0-9]|1[0-9]|2[0-3]:[0-5][0-9]$/;
              setborder(re.test(value) ? "" : "border-danger");
            }}
          />
        </div>
      </div>
    </>
  );
}


export async function GetBaseData(id) {
  return await fetch(`${window.$apiurl}/driverCheckedIn/GetBaseData/${id}`, {
    method: "GET",
    withCredentials: true,
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      return res[1];
    })
    .catch((error) => {
      console.error(error);
    });
}

export async function GetCarrierCheckedInData(id) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return await fetch(
    `${window.$apiurl}/driverCheckedIn/${id}`,
    requestOptions
  )
    .then(async (response) => {
      const statusCode = response.status;
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then((data) => [statusCode, data]);
      } else {
        return response.text().then((text) => [statusCode, { Message: "" }]);
      }
    })
    .then(async (result) => {
      if (result[0] === 200) {
        return result[1];
      } else if (
        result[1].Message !== null &&
        result[1].Message !== undefined &&
        result[1].Message !== ""
      ) {
        Notification("infoClick", result[1].Message);
      } else {
        Notification("infoClick", "something wrong!!");
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
}