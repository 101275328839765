import React, { useEffect } from "react";
import Select from "react-select";
import { RangeDatePicker } from "react-google-flight-datepicker";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { CheckboxLargeWB } from "../../../../../../_metronic/_partials/controls";
var typingTimer;
const DateRangeTypeList = [
  { value: "created", label: "Creation date" },
  { value: "booked", label: "Assigned date" },
  { value: "loading", label: "Loading date" },
  { value: "loaded", label: "Loaded date" },
  { value: "pickup", label: "Pickup Date" },
];

function formatDate(date) {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const result = [year, month, day].join("-");
    if (result !== "NaN/NaN/NaN") {
      return result;
    } else {
      return "";
    }
  } else {
    return "";
  }
}

export function WarehouseFilter({
  ViewModel,
  filterOptions,
  setfilterOptions,
  statusesList
}) {
  const [IsCancel, setIsCancel] = React.useState({ value: 1, label: "Active" });
  const listCanceled = [
    { value: 1, label: "Active" },
    { value: 2, label: "Canceled" },
  ];
  const listDriver = [
    { value: "", label: "Both" },
    { value: 1, label: "No" },
    { value: 2, label: "Yes" },
  ];
  const [searchValue, setSearchValue] = React.useState("");
  useEffect(() => {
    var currentData = { ...filterOptions };
    currentData.search = searchValue;
    window.$SmGsearchText = searchValue;  
    const delayDebounceFn = setTimeout(() => {
      setfilterOptions(currentData);
      // Send Axios request here
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchValue]);

  useEffect(() => {
    if (filterOptions.search !== null && filterOptions.search !== undefined && filterOptions.search !== "") {
      setSearchValue(filterOptions.search);
    }
  }, [filterOptions.search]);
  return (
    <form className="form form-label-right">
      <div className="form-group row">
        <input
          type="button"
          className="d-none"
          id="ReLoadTableId"
          onClick={(e) => {
            var filter = { ...filterOptions };
            filter.ReLoadTable = filter.ReLoadTable ? true : false;
            setfilterOptions(filter);
          }}
        />
        <div className="col-xl-3 col-lg-4 col-md-4">
          <p className="mb-2">Search</p>
          <input
            type="search"
            className="form-control"
            name="searchText"
            id="searchTextTable"
            placeholder="in all fields"
            value={searchValue}
            onChange={(e) => {
              const value = e.target.value;
              setSearchValue(value);
            }}
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-md-4">
          <p className="mb-2">Customers</p>
          <Select
            isMulti
            isClearable
            name="CustomerId"
            id="CustomerId"
            label="Customers"
            inputId="react-select-single"
            value={filterOptions.customers}
            options={ViewModel?.Customers?.map((x) => {
              return {
                value: x.Id,
                label: x?.Name,
              };
            })}
            onChange={(e) => {
              var filter = { ...filterOptions };
              if (e?.length === 0) {
                filter.customers = [];
              } else if (e !== null) {
                filter.customers = e;
              } else {
                filter.customers = [];
              }
              setfilterOptions(filter);
            }}
          />
        </div>

        <div className="col-xl-3 col-lg-4 col-md-4">
          <p className="mb-2">Service</p>
          <Select
            isClearable
            name="ServiceId"
            id="ServiceId"
            label="Service"
            inputId="react-select-single"
            placeholder="Service"
            value={ViewModel?.Services?.filter(
              (a) => a.Id === filterOptions.service
            )?.map((x) => {
              return {
                value: x.Id,
                label: x?.Name,
              };
            })}
            options={ViewModel?.Services?.map((x) => {
              return {
                value: x.Id,
                label: x?.Name,
              };
            })}
            onChange={(e) => {
              var filter = { ...filterOptions };
              if (e !== null) {
                filter.service = e?.value;
                filter.mode = "";
                filter.serviceLevelId = "";
                filter.eqTypeId = "";
                filter.eqSizeId = "";
                window.$ShipmentManagementModeId = "";
                window.$ShipmentManagementServiceId = e?.value;
              } else {
                filter.service = "";
                filter.mode = "";
                filter.serviceLevelId = "";
                filter.eqTypeId = "";
                filter.eqSizeId = "";
                window.$ShipmentManagementModeId = "";
                window.$ShipmentManagementServiceId = "";
              }
              setfilterOptions(filter);
            }}
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-md-4">
          <p className="mb-2">Mode</p>
          <Select
            isClearable
            name="ModeId"
            id="ModeId"
            label="Mode"
            inputId="react-select-single"
            placeholder="Mode"
            value={ViewModel?.Services?.filter(
              (a) => a.Id === filterOptions.service
            )[0]
              ?.Modes.filter((a) => a.Id === filterOptions.mode)
              .map((x) => {
                return {
                  value: x.Id,
                  label: x?.Name,
                };
              })}
            options={ViewModel?.Services?.filter(
              (a) => a.Id === filterOptions.service
            )[0]?.Modes.map((x) => {
              return {
                value: x.Id,
                label: x?.Name,
              };
            })}
            onChange={(e) => {
              var filter = { ...filterOptions };
              if (e !== null) {
                window.$ShipmentManagementModeId = e?.value;
                filter.mode = e?.value;
                filter.serviceLevelId = "";
                filter.eqTypeId = "";
                filter.eqSizeId = "";
              } else {
                window.$ShipmentManagementModeId = "";
                filter.mode = "";
                filter.serviceLevelId = "";
                filter.eqTypeId = "";
                filter.eqSizeId = "";
              }
              setfilterOptions(filter);
            }}
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-md-4 mt-4">
          <p className="mb-2">Service Level</p>
          <Select
            isClearable
            name="ServiceLevelId"
            id="ServiceLevelId"
            label="Service Level"
            inputId="react-select-single"
            placeholder="Service Level"
            value={ViewModel?.Services?.filter(
              (a) => a.Id === filterOptions.service
            )[0]
              ?.Modes?.filter((a) => a.Id === filterOptions.mode)[0]
              ?.ServiceLevels?.filter(
                (a) => a.Id === filterOptions.serviceLevelId
              )
              .map((x) => {
                return {
                  value: x.Id,
                  label: x?.Name,
                };
              })}
            options={ViewModel?.Services?.filter(
              (a) => a.Id === filterOptions.service
            )[0]
              ?.Modes?.filter((a) => a.Id === filterOptions.mode)[0]
              ?.ServiceLevels?.map((x) => {
                return {
                  value: x.Id,
                  label: x?.Name,
                };
              })}
            onChange={(e) => {
              var filter = { ...filterOptions };
              if (e !== null) {
                filter.serviceLevelId = e?.value;
                filter.eqTypeId = "";
                filter.eqSizeId = "";
              } else {
                filter.serviceLevelId = "";
                filter.eqTypeId = "";
                filter.eqSizeId = "";
              }
              setfilterOptions(filter);
            }}
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-md-4 mt-4">
          <p className="mb-2">Equipment Type</p>
          <Select
            isClearable
            name="eqTypeId"
            id="eqTypeId"
            label="Equipment Type"
            inputId="react-select-single"
            placeholder="Equipment Type"
            value={ViewModel?.Services?.filter(
              (a) => a.Id === filterOptions.service
            )[0]
              ?.Modes?.filter((a) => a.Id === filterOptions.mode)[0]
              ?.ServiceLevels?.filter(
                (a) => a.Id === filterOptions.serviceLevelId
              )[0]
              ?.EquipmentTypes?.filter((a) => a.Id === filterOptions.eqTypeId)
              ?.map((x) => {
                return {
                  value: x.Id,
                  label: x?.Name,
                };
              })}
            options={ViewModel?.Services?.filter(
              (a) => a.Id === filterOptions.service
            )[0]
              ?.Modes?.filter((a) => a.Id === filterOptions.mode)[0]
              ?.ServiceLevels?.filter(
                (a) => a.Id === filterOptions.serviceLevelId
              )[0]
              ?.EquipmentTypes?.map((x) => {
                return {
                  value: x.Id,
                  label: x?.Name,
                };
              })}
            onChange={(e) => {
              var filter = { ...filterOptions };
              if (e !== null) {
                filter.eqTypeId = e?.value;
                filter.eqSizeId = "";
              } else {
                filter.eqTypeId = "";
                filter.eqSizeId = "";
              }
              setfilterOptions(filter);
            }}
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-md-4 mt-4">
          <p className="mb-2">Equipment Size</p>
          <Select
            isClearable
            name="EqSizeId"
            id="EqSizeId"
            label="Equipment Size"
            inputId="react-select-single"
            placeholder="Equipment Size"
            value={ViewModel?.Services?.filter(
              (a) => a.Id === filterOptions.service
            )[0]
              ?.Modes?.filter((a) => a.Id === filterOptions.mode)[0]
              ?.ServiceLevels?.filter(
                (a) => a.Id === filterOptions.serviceLevelId
              )[0]
              ?.EquipmentTypes?.filter(
                (a) => a.Id === filterOptions.eqTypeId
              )[0]
              ?.EquipmentSizes?.filter((a) => a.Id === filterOptions.eqSizeId)
              ?.map((x) => {
                return {
                  value: x.Id,
                  label: x?.Name,
                };
              })}
            options={ViewModel?.Services?.filter(
              (a) => a.Id === filterOptions.service
            )[0]
              ?.Modes?.filter((a) => a.Id === filterOptions.mode)[0]
              ?.ServiceLevels?.filter(
                (a) => a.Id === filterOptions.serviceLevelId
              )[0]
              ?.EquipmentTypes?.filter(
                (a) => a.Id === filterOptions.eqTypeId
              )[0]
              ?.EquipmentSizes?.map((x) => {
                return {
                  value: x.Id,
                  label: x?.Name,
                };
              })}
            onChange={(e) => {
              var filter = { ...filterOptions };
              if (e !== null) {
                filter.eqSizeId = e?.value;
              } else {
                filter.eqSizeId = "";
              }
              setfilterOptions(filter);
            }}
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-md-4 mt-4">
          <p className="mb-2">Ship From</p>
          <Select
            isClearable
            name="shipFrom"
            id="shipFrom"
            label="Ship From"
            inputId="react-select-single"
            placeholder="ship From"
            options={ViewModel?.Shippers?.map((x) => {
              return {
                value: x.Id,
                label: x?.Code + "-" + x?.Name,
              };
            })}
            onChange={(e) => {
              var filter = { ...filterOptions };
              if (e !== null) {
                filter.ShipFrom = e?.value;
              } else {
                filter.ShipFrom = "";
              }
              setfilterOptions(filter);
            }}
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-md-4 mt-5">
          <p className="mb-2">Date Filter</p>
          <Select
            isClearable
            name="DateRangeType"
            id="DateRangeType"
            label="Date Filter"
            inputId="react-select-single"
            placeholder="Date Filter"
            options={DateRangeTypeList}
            value={DateRangeTypeList.filter(
              (a) => String(a.value) === filterOptions.DateRangeType
            )}
            onChange={(e) => {
              var filter = { ...filterOptions };
              if (e !== null) {
                filter.DateRangeType = e?.value;
              } else {
                filter.DateRangeType = "";
              }
              setfilterOptions(filter);
            }}
          />
        </div>
        <div className={`col-xl-6 col-lg-8 col-md-8 mt-3`}>
          <p className="mb-2">Date Start - End</p>
          <RangeDatePicker
            startDate={filterOptions.startDate}
            endDate={filterOptions.endDate}
            onChange={(startDate, endDate) => {
              var filter = { ...filterOptions };
              if (startDate) {
                filter.startDate = formatDate(startDate) + "T12:00";
              } else {
                filter.startDate = "";
              }
              if (endDate) {
                filter.endDate = formatDate(endDate) + "T12:00";
              } else {
                filter.endDate = "";
              }
              setfilterOptions(filter);
            }}
            dateFormat="MM/DD/YYYY"
            minDate={new Date(1900, 0, 1)}
            maxDate={new Date(2100, 0, 1)}
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            disabled={false}
            className="my-own-class-name"
            startWeekDay="monday"
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-md-4 mt-5">
          <p className="mb-2">Carrier </p>
          <Select
            isClearable
            name="Carrier"
            id="Carrier"
            label="Carrier"
            inputId="react-select-single"
            placeholder="Carrier"
            options={ViewModel?.Vendors?.map((x) => {
              return {
                value: x.Id,
                label: x?.Code + "-" + x?.Name,
              };
            })}
            onChange={(e) => {
              var filter = { ...filterOptions };
              if (e !== null) {
                filter.VendorId = e?.value;
              } else {
                filter.VendorId = "";
              }
              setfilterOptions(filter);
            }}
          />
        </div>
        <div className={`col-xl-3 col-lg-4 col-md-4 mt-5`}>
          <label className="mb-2">Is Canceled</label>
          <Select
            name="Canceled"
            onChange={(e) => {
              let value = e?.value;
              if (value === 1) {
                value = false;
              } else if (value === 2) {
                value = true;
              } else {
                value = "";
              }
              var filter = { ...filterOptions };
              if (e !== null) {
                setIsCancel(e);
                filter.IsCanceled = value;
                window.$ShipmentManagementCanceled = value;
              } else {
                setIsCancel({ value: "", label: "Both" });
                filter.IsCanceled = "";
                window.$ShipmentManagementCanceled = "";
              }
              setfilterOptions(filter);
            }}
            isClearable
            options={listCanceled}
            value={IsCancel}
          />
        </div>
        <div className={`col-xl-3 col-lg-4 col-md-4 mt-5`}>
          <label>Driver Checked In</label>
          <Select
            name="Driver Checked In"
            onChange={(e) => {
              let value = e?.value;
              if (value === 1) {
                value = false;
              } else if (value === 2) {
                value = true;
              } else {
                value = "";
              }
              var filter = { ...filterOptions };
              if (e !== null) {
                filter.driverCheckedIn = value;
              } else {
                filter.driverCheckedIn = "";
              }
              setfilterOptions(filter);
            }}
            isClearable
            options={listDriver}
            value={listDriver.filter(
              (a) =>
                a.value ===
                (filterOptions?.driverCheckedIn === true
                  ? 2
                  : filterOptions?.driverCheckedIn === false
                  ? 1
                  : "")
            )}
          />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 mt-md-12 checkbox-management">
          <div className="row">
            <div className="pr-xl-3 pr-lg-3 pr-md-10 pr-4 d-flex">
              <CheckboxLargeWB
                className="font-size-md pr-4"
                value={filterOptions.hot}
                text={"Hot"}
                checked={filterOptions.hot}
                id="checkboxisHote"
                onChange={(e) => {
                  var filter = { ...filterOptions };
                  filter.hot = e.target.checked;
                  setfilterOptions(filter);
                }}
              />
              <span className="navi-icon" style={{ marginTop: "0.6rem" }}>
                <i className="fab fa-hotjar text-danger"></i>
              </span>
            </div>
            <div className="pr-xl-3 pr-lg-3 pr-md-10 pr-4 d-flex">
              <CheckboxLargeWB
                className="font-size-md"
                text={"HazMat"}
                id="checkboxisHazardousMaterial2"
                value={filterOptions.hasMat}
                checked={filterOptions.hasMat}
                onChange={(e) => {
                  var filter = { ...filterOptions };
                  filter.hasMat = e.target.checked;
                  setfilterOptions(filter);
                }}
              />
              <span className="navi-icon" style={{ marginTop: "0.6rem" }}>
                <i className="fas fa-skull-crossbones text-warning"></i>
              </span>
            </div>
            <div className="pr-xl-3 pr-lg-3 pr-md-10 pr-4 d-flex">
              <CheckboxLargeWB
                className="font-size-md"
                text={"Heavy"}
                id="checkboxIsHeavy"
                value={filterOptions.isHeavy}
                checked={filterOptions.isHeavy}
                onChange={(e) => {
                  var filter = { ...filterOptions };
                  filter.isHeavy = e.target.checked;
                  setfilterOptions(filter);
                }}
              />
              <span className="navi-icon" style={{ marginTop: "0.6rem" }}>
                <i className="fas fa-weight-hanging text-info-warehouse"></i>
              </span>
            </div>
            <div className="d-flex">
              <CheckboxLargeWB
                className="font-size-md"
                text={"Closed"}
                id="checkboxClosed"
                value={filterOptions.closed}
                checked={filterOptions.closed}
                onChange={(e) => {
                  var filter = { ...filterOptions };
                  filter.closed = e.target.checked;
                  window.$ShipmentManagementClosed = e.target.checked;
                  setfilterOptions(filter);
                }}
              />
              <span className="navi-icon" style={{ marginTop: "0.6rem" }}>
                <i className="fas fa-times-circle text-warning"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group row mb-1 mt-3">
        <div className="col-lg-10 bg-light-warning mb-4 mt-3 pl-5">
          <small className="pr-3">
            WORKFLOW:{" "}
            <b>
              {" "}
              {statusesList?.ShipmentsCount?.find(
                (x) => x.StatusId === filterOptions?.statusId
              )?.StatusName ?? "All Statuses"}
            </b>
          </small>
          <small className="pr-3">
            CUSTOMER:{" "}
            <b>
              {filterOptions.customers?.length === 0
                ? "All"
                : filterOptions.customers.map((a) => a.label).join(", ")}
            </b>
          </small>
          <small className="pr-3">
            SERVICE:{" "}
            <b>
              {
                ViewModel?.Services?.filter(
                  (a) => a.Id === filterOptions.service
                )[0]?.Name
              }
            </b>
          </small>
          <small className="pr-3">
            MODE:{" "}
            <b>
              {
                ViewModel?.Services?.filter(
                  (a) => a.Id === filterOptions.service
                )[0]
                  ?.Modes.filter((a) => a.Id === filterOptions.mode)
                  .map((x) => {
                    return x?.Name;
                  })[0]
              }
            </b>
          </small>
          <small className="pr-3">
            HOT: <b>{filterOptions?.hot === false ? "false" : "true"}</b>
          </small>
          <small className="pr-3">
            HAZMAT: <b>{filterOptions?.hasMat === false ? "false" : "true"}</b>
          </small>
          <small className="pr-3">
            Heavy: <b>{filterOptions?.isHeavy === false ? "false" : "true"}</b>
          </small>
          <small className="pr-3">
            Closed: <b>{filterOptions?.closed === false ? "false" : "true"}</b>
          </small>
        </div>
        <div className="col-lg-2" style={{ textAlignLast: "right" }}>
          <a
            href={() => false}
            onClick={(event) => {
              try {
                let filterOptionsReset = { ...filterOptions };
                filterOptionsReset = {
                  customers: [],
                  service: window.$ShipmentManagementServiceId,
                  mode: window.$ShipmentManagementModeId,
                  serviceLevelId: "",
                  IsCanceled: null,
                  search: "",
                  eqTypeId: "",
                  eqSizeId: "",
                  DateRangeType: "created",
                  exceptionsId: "",
                  ShipFrom: "",
                  VendorId: "",
                  startDate: "",
                  endDate: "",
                  statusId: "",
                  StatusName: "",
                  hot: false,
                  hasMat: false,
                  isHeavy: false,
                  canceled: "",
                  driverCheckedIn: "",
                  closed: "",
                };
                setfilterOptions(filterOptionsReset);
              } catch (err) {}
            }}
            className="btn  btn-light-info btn-elevate"
          >
            <i class="fas fa-filter pr-3"></i>
            Clear Filters
          </a>
        </div>
      </div>
    </form>
  );
}
