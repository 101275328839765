import MockAdapter from "axios-mock-adapter";
import mockAuth from "../../app/modules/Auth/__mocks__/mockAuth";
import mockManageZoneBilling from "../../app/modules/eMPower/__mocks__/mockManageZoneBilling";
import mockManageSmartParcelCarriers from "../../app/modules/eMPower/__mocks__/mockManageSmartParcelCarriers";
import mockRecurringStorageCharges from "../../app/modules/eMPower/__mocks__/mockRecurringStorageCharges";
import mockMovableUnits from "../../app/modules/eMPower/__mocks__/mockMovableUnits";
import mockNotes from "../../app/modules/eMPower/__mocks__/mockNotes";
import mockManageServices from "../../app/modules/eMPower/__mocks__/mockManageServices";
import mockManageTrackingDevices from "../../app/modules/eMPower/__mocks__/mockManageTrackingDevices";
import mockManageModes from "../../app/modules/eMPower/__mocks__/mockManageModes";
import mockManageEquipmentsType from "../../app/modules/eMPower/__mocks__/mockManageEquipmentsType";
import mockManageServiceLevels from "../../app/modules/eMPower/__mocks__/mockManageServiceLevels";
import mockCustomers from "../../app/modules/eMPower/__mocks__/mockCustomer";
import mockAssignments from "../../app/modules/eMPower/__mocks__/mockAssignments";
import mockCommodities from "../../app/modules/eMPower/__mocks__/mockCommodities";
import mockClassifications from "../../app/modules/eMPower/__mocks__/mockClassifications";
import mockPurchaseOrders from "../../app/modules/eMPower/__mocks__/mockPurchaseOrders";
import mockManageCustomer from "../../app/modules/eMPower/__mocks__/mockManageCustomer";
import mockManageWarehouse from "../../app/modules/eMPower/__mocks__/mockManageWarehouse";
import mockpackingSlipTemplates from "../../app/modules/eMPower/__mocks__/mockpackingSlipTemplates";
import mockUsers from "../../app/modules/eMPower/__mocks__/mockUsers";
import mockManageRoles from "../../app/modules/eMPower/__mocks__/mockManageRoles";
import mockManageUserGroups from "../../app/modules/eMPower/__mocks__/mockManageUserGroups";
import mockProducts from "../../app/modules/eMPower/__mocks__/mockProduct";
import mockRemarks from "../../app/modules/eMPower/__mocks__/mockRemark";
import mockSpecifications from "../../app/modules/eMPower/__mocks__/mockSpecification";
import mockManageCarrierAccount from "../../app/modules/eMPower/__mocks__/mockManageCarrierAccounts";
import mockLocationBilling from "../../app/modules/eMPower/__mocks__/mockLocationBilling";
import mockCustomLabels from "../../app/modules/eMPower/__mocks__/mockCustomLabels";
import mockEmpowerCMS from "../../app/modules/eMPower/__mocks__/mockEmpowerCMS";
import mockCarriers from "../../app/modules/eMPower/__mocks__/mockCarriers";
import mockServiceManageCarriers from "../../app/modules/eMPower/__mocks__/mockServiceManageCarriers";
import mockFreezeTransactions from "../../app/modules/eMPower/__mocks__/mockFreezeTransactions";
import mockManageTPLScale from "../../app/modules/eMPower/__mocks__/mockManageTPLScale";
import mockFindOrders from "../../app/modules/eMPower/__mocks__/mockFindOrders";
import mockFindReceipts from "../../app/modules/eMPower/__mocks__/mockFindReceipts";
import mockManageLocations from "../../app/modules/eMPower/__mocks__/mockManageLocations";
import mockManageItems from "../../app/modules/eMPower/__mocks__/mockManageItems";
import mockPurchaseLineItems from "../../app/modules/eMPower/__mocks__/mockPurchaseLineItems";
import mockImportContacts from "../../app/modules/eMPower/__mocks__/mockImportContacts";
import mockReceipts from "../../app/modules/eMPower/__mocks__/mockReceipts";
import mockIgnoreSkus from "../../app/modules/eMPower/__mocks__/mockIgnoreSkus";
import mockCompanyInfo from "../../app/modules/eMPower/__mocks__/mockCompanyInfo";
import mockCustomFields from "../../app/modules/eMPower/__mocks__/mockCustomFields";
import mockManageSuppliers from "../../app/modules/eMPower/__mocks__/mockManageSuppliers";
import mockFindOrdersTransload from "../../app/modules/eMPower/__mocks__/Transload/mockFindOrders";
import mockContainers from "../../app/modules/eMPower/__mocks__/Transload/mockContainers";
import mockTransactionBillingShipping from "../../app/modules/eMPower/__mocks__/mockTransactionBillingShipping";
import mockTransactionBilling from "../../app/modules/eMPower/__mocks__/mockTransactionBilling";
import mockTMSLoadBoard from "../../app/modules/eMPower/__mocks__/mockTMSLoadBoard";
import mockZoneBilling from "../../app/modules/eMPower/__mocks__/mockZoneBilling";
import mockOrderReceiver from "../../app/modules/eMPower/__mocks__/mockOrderReceiver";
import mockBillingManual from "../../app/modules/eMPower/__mocks__/mockBillingManual";
import mockLocStorageRates from "../../app/modules/eMPower/__mocks__/mockLocStorageRates";
import mockReceiptItems from "../../app/modules/eMPower/__mocks__/mockReceiptItems";
import mockReceiptCustomFields from "../../app/modules/eMPower/__mocks__/mockReceiptCustomFields";
import mockOrderLineItems from "../../app/modules/eMPower/__mocks__/mockOrderLineItems";
import mockOrdercharges from "../../app/modules/eMPower/__mocks__/mockOrdercharges";
import mockOrderCustomFields from "../../app/modules/eMPower/__mocks__/mockOrderCustomFields";
import mockReceiptcharges from "../../app/modules/eMPower/__mocks__/mockReceiptcharges";
import mockTransloadReceiving from "../../app/modules/eMPower/__mocks__/Transload/mockTransloadReceiving";
import mockManageAddress from "../../app/modules/eMPower/__mocks__/Transload/mockManageAddress";
import mockManageEquipments from "../../app/modules/eMPower/__mocks__/Transload/mockManageEquipments";
import mockVesselVoyage from "../../app/modules/eMPower/__mocks__/Transload/mockVesselVoyage";
import mockManageContacts from "../../app/modules/eMPower/__mocks__/Transload/mockManageContacts";
import mockManageUOM from "../../app/modules/eMPower/__mocks__/Transload/mockManageUOM";
import mockFindShipments from "../../app/modules/eMPower/__mocks__/mockFindShipments";
import mockManageLSPCarrier from "../../app/modules/eMPower/__mocks__/mockManageLSPCarrier";
import mockAttachments from "../../app/modules/eMPower/__mocks__/mockAttachments";
import mockContactInformation from "../../app/modules/eMPower/__mocks__/mockContactInformation";
import mockInsuranceInformation from "../../app/modules/eMPower/__mocks__/mockInsuranceInformation";
import mockVessels from "../../app/modules/eMPower/__mocks__/Transload/mockVessels";
import mockManageRates from "../../app/modules/eMPower/__mocks__/mockManageRates";
import mockAddInventory from "../../app/modules/eMPower/__mocks__/mockAddInventory";
import mockAllOrderBeta from "../../app/modules/eMPower/__mocks__/mockAllOrdersBeta";
import mockAllOrderBetaTMS from "../../app/modules/eMPower/__mocks__/mockAllOrdersBetaTMS";
import mockReceiptsManagmentNewVersion from "../../app/modules/eMPower/__mocks__/mockReceiptsManagmentNewVersion";
import mockCustomerProfileRating from "../../app/modules/eMPower/__mocks__/mockCustomerProfileRating";
import mockCarrierProfileRating from "../../app/modules/eMPower/__mocks__/mockCarrierProfileRating";
import mockManageQuotes from "../../app/modules/eMPower/__mocks__/mockManageQuotes";
import mockManageQuotesCarriers from "../../app/modules/eMPower/__mocks__/mockManageQuotesCarriers";
import mockTransportationItems from "../../app/modules/eMPower/__mocks__/Transload/mockTransportationItems";
import mockManageTripsManagement from "../../app/modules/eMPower/__mocks__/mockManageTripsManagement";
import mockPickUpManagement from "../../app/modules/eMPower/__mocks__/mockPickUpManagement";
import mockPoBillingManagement from "../../app/modules/eMPower/__mocks__/mockPoBillingManagement";
import mockManageGlAccounts from "../../app/modules/eMPower/__mocks__/mockManageGlAccounts";
import mockLulusProcess from "../../app/modules/eMPower/__mocks__/mockLulusProcess";
import mockManageCmsNews from "../../app/modules/eMPower/__mocks__/mockManageCmsNews";
import mockPalletsManagment from "../../app/modules/eMPower/__mocks__/mockPalletsManagment";
import mockPalletsManagmentBeta from "../../app/modules/eMPower/__mocks__/mockPalletsManagmentBeta";
import mockPickUpBillingManagement from "../../app/modules/eMPower/__mocks__/mockPickUpBillingManagement";
import mockReleaseNotes from "../../app/modules/eMPower/__mocks__/mockReleaseNotes";
import mocksynchronizeProcess from "../../app/modules/eMPower/__mocks__/mocksynchronizeProcess";
import mocksynchronizeProcess2 from "../../app/modules/eMPower/__mocks__/mockSynchronizeProcess2";
import mockTriniumCodes from "../../app/modules/eMPower/__mocks__/mockTriniumCodes";
import mockTriniumCodes2 from "../../app/modules/eMPower/__mocks__/mockTriniumCodes2";

export default function mockAxios(axios) {
  const mock = new MockAdapter(axios, { delayResponse: 300 });

  mockContainers(mock);
  mockFindShipments(mock);
  mockFindOrdersTransload(mock);
  mockTransloadReceiving(mock);
  mockTransportationItems(mock);
  mockManageSuppliers(mock);
  mockCustomFields(mock);
  mockIgnoreSkus(mock);
  mockManageItems(mock);
  mockFindOrders(mock);
  mockFindReceipts(mock);
  mockManageLocations(mock);
  mockpackingSlipTemplates(mock);
  mockAuth(mock);
  mockCustomers(mock);
  mockAssignments(mock);
  mockCommodities(mock);
  mockClassifications(mock);
  mockManageWarehouse(mock);
  mockUsers(mock);
  mockManageCustomer(mock);
  mockProducts(mock);
  mockRemarks(mock);
  mockSpecifications(mock);
  mockManageRoles(mock);
  mockManageCarrierAccount(mock);
  mockLocationBilling(mock);
  mockManageZoneBilling(mock);
  mockManageSmartParcelCarriers(mock);
  mockRecurringStorageCharges(mock);
  mockMovableUnits(mock);
  mockManageServices(mock);
  mockManageTrackingDevices(mock);
  mockManageModes(mock);
  mockManageEquipmentsType(mock);
  mockManageServiceLevels(mock);
  mockCustomLabels(mock);
  mockEmpowerCMS(mock);
  mockManageTPLScale(mock);
  mockCarriers(mock);
  mockFreezeTransactions(mock);
  mockPurchaseLineItems(mock);
  mockPurchaseOrders(mock);
  mockImportContacts(mock);
  mockReceipts(mock);
  mockServiceManageCarriers(mock);
  mockCompanyInfo(mock);
  mockTransactionBilling(mock);
  mockTMSLoadBoard(mock);
  mockTransactionBillingShipping(mock);
  mockZoneBilling(mock);
  mockOrderReceiver(mock);
  mockBillingManual(mock);
  mockLocStorageRates(mock);
  mockReceiptItems(mock);
  mockReceiptCustomFields(mock);
  mockOrderLineItems(mock);
  mockManageAddress(mock);
  mockManageEquipments(mock);
  mockOrdercharges(mock);
  mockReceiptcharges(mock);
  mockOrderCustomFields(mock);
  mockVesselVoyage(mock);
  mockManageContacts(mock);
  mockManageUOM(mock);
  mockManageLSPCarrier(mock);
  mockAttachments(mock);
  mockContactInformation(mock);
  mockInsuranceInformation(mock);
  mockVessels(mock);
  mockManageRates(mock);
  mockAddInventory(mock);
  mockAllOrderBeta(mock);
  mockAllOrderBetaTMS(mock);
  mockReceiptsManagmentNewVersion(mock);
  mockCustomerProfileRating(mock);
  mockCarrierProfileRating(mock);
  mockManageQuotes(mock);
  mockManageQuotesCarriers(mock);
  mockManageTripsManagement(mock);
  mockPickUpManagement(mock);
  mockManageGlAccounts(mock);
  mockManageCmsNews(mock);
  mockLulusProcess(mock);
  mockPickUpBillingManagement(mock);
  mockPoBillingManagement(mock);
  mockPalletsManagment(mock);
  mockPalletsManagmentBeta(mock);
  mockReleaseNotes(mock);
  mocksynchronizeProcess(mock);
  mocksynchronizeProcess2(mock);
  mockNotes(mock);
  mockTriniumCodes(mock);
  mockTriniumCodes2(mock);
  mockManageUserGroups(mock);
  return mock;
}
