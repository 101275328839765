/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useSubheader } from "../../../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";
import { Notification } from "../../../../Notification";
import * as actions from "../../../_redux/TMSLoadBoard/productsActions";
import { ProductEditForm } from "./ProductEditForm";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

const initProduct = {
  Id: undefined,
  Name: "",
  IsPublic: false,
  Updators: [],
  Customers: [],
  Carriers: [],
  Modes: [],
};

export function ProductTMSEdit({ id }) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const [LoadingPost, setLoadingPost] = useState(false);
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, productForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.products.actionsLoading,
      productForEdit: state.products.productForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (String(id) !== "undefined") {
      dispatch(actions.fetchProduct(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    let _title = "";
    if (String(id) !== "undefined") {
      _title = "New Load Board Creation";
    } else {
      if (productForEdit !== undefined && String(id) !== "undefined") {
        _title = `Edit Order : "${productForEdit.Id} - ${productForEdit.Name}"`;
      } else {
        _title = `LoadBoard`;
      }
    }
    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productForEdit, id]);

  const saveProduct = (values) => {
    console.log("id", id);
    var obj = JSON.stringify(values);

    if (String(id) === "undefined") {
      setLoadingPost(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: obj,
        redirect: "follow",
      };

      fetch(`${window.$apiurl}/loadboard`, requestOptions)
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            Notification("success", "LoadBoard has been Added successfully");
            backToProductsList();
            setLoadingPost(false);
          } else {
            if (
              result[1].ModelState !== null &&
              result[1].ModelState !== undefined &&
              result[1].ModelState !== ""
            ) {
              let modelState = result[1].ModelState;
              if (modelState)
                Object.keys(modelState).forEach(function(k) {
                  modelState[k].forEach((element) => {
                    Notification("errorClick", element);
                  });
                });
            } else if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("errorClick", result[1].Message);
            } else {
              Notification("errorClick", "Bad Request");
            }
            setLoadingPost(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setLoadingPost(true);

      var myHeaders2 = new Headers();
      myHeaders2.append(
        "Authorization",
        `Bearer ${db.read().getState().Token}`
      );
      myHeaders2.append("Content-Type", "application/json");

      var requestOptions2 = {
        method: "PUT",
        headers: myHeaders2,
        body: obj,
        redirect: "follow",
      };

      fetch(`${window.$apiurl}/loadboard/${id}`, requestOptions2)
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            Notification("success", "LoadBoard has been Updated successfully");
            backToProductsList();
            setLoadingPost(false);
          } else {
            if (
              result[1].ModelState !== null &&
              result[1].ModelState !== undefined &&
              result[1].ModelState !== ""
            ) {
              let modelState = result[1].ModelState;
              if (modelState)
                Object.keys(modelState).forEach(function(k) {
                  modelState[k].forEach((element) => {
                    Notification("errorClick", element);
                  });
                });
            } else if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("errorClick", result[1].Message);
            } else {
              Notification("errorClick", "Bad Request");
            }
            setLoadingPost(false);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const btnRef = useRef();
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProductsList = () => {
    document.getElementById("LoadBoardGrid").click();
  };

  return (
    <Card>
      {LoadingPost && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToProductsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {`  `}
          <button className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveProductClick}
          >
            Save
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          {tab === "basic" && (
            <ProductEditForm
              actionsLoading={actionsLoading}
              product={
                String(id) !== "undefined" && productForEdit !== undefined
                  ? productForEdit
                  : initProduct
              }
              btnRef={btnRef}
              saveProduct={saveProduct}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
}
