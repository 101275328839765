import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CustomersUIHelpers";

const CustomersUIContext = createContext();

export function useCustomersUIContext() {
  return useContext(CustomersUIContext);
}

export const CustomersUIConsumer = CustomersUIContext.Consumer;

export function CustomersUIProvider({ customersUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCustomer = {
    id: undefined,
    Country: "United States",
    CompanyName: "",
    PhoneNumber: "",
    ExternalId: "",
    Fax: "",
    Website: "",
    Email: "",
    State: "",
    Address1: "",
    Address2: "",
    City: "",
    PostalCode: "",
    ContactName: "",
    ContactPhone: "",
    ContactEmail: "",
    LocationOptionId: 38,
    MovableUnitOptionId: 44,
    SupplierOptionId: 49,
    InvoicingOptionId: 54,
    CarrierInfoOptionId: 57,
    CreateInvoicesOptionId: 61,
    FuelSurchargePercentage: 0,
    TurnTimes: 3,
    VicsBillLadingId: "",
    UspNumber: "",
    fedExNumber: "",
    CarrierPostalCode: "",
    AccountingName: "",
    InterchangeId: "",
    IdQualifier: "",
    EwmsInterchangeId: "",
    LowCube:"85",
    EwmsIdQualifier: "",
    SpsTradingPartnerId: "",
    ManufacturingId: "",
    AutoAllocate: false,
    SplitMovableUnit: false,
    SuggestedPutWay: false,
    ReceiveAgaintAsn: false,
    UseBlindReceipt: false,
    DefaultQuantity: false,
    FileImportTracking: false,
    AllocateOrderManually: false,
    AutomaticallyTrackBy: false,
    RequireScac: false,
    ConnectionUpdatesTracking: false,
    RequireOrderClosed: false,
    ScannerApplication: false,
    RequireTrackingNumber: false,
    FullMovableUnits: false,
    LocationIdentifierReport: false,
    EnableCustomFields: false,
    AllowPurchaseOrder: false,
    EnableDashboard: false,
    InvoiceConfirmation: false,
    UseTransactionBillNumber: false,
    DisplayVendorId: false,
    InboundInvoiceOptionId:null,
    InboundInvoiceGrpOptionId:null,
    NetTermId:null,
    IsActive: true,
    RequireCarrier: false,
    RequireLoadNumber: false,
    RequireLadingNumber: false,
    RequirePurchaseOrder: false,
    PackageCount: false,
    OneFileAtLeast: false,
    TotalPackageVolume: false,
    TotalPackageWeight: false,
    WarehousesAccess: [],
    CustomerShipmentModes: [],
    Contacts: [],
    Status: 0,
    WeightLimit: null,
    Sales:"",
    CreditLimit : 0,
    Balance : 0,
    LineHaulDefaultPercentage : 0,
    FuelPercentage : 0,
    AvgDaysToRelease : 0,
    AvgDaysToPickupOn : 0,
    AvgDaysToDevan : 0,
    AvgDaysToPickup : 0,
    AvgDaysToReleaseHot : 0,
    AvgDaysToPickupOnHot : 0,
    AvgDaysToDevanHot : 0,
    AvgDaysToPickupHot : 0,
    IsOnHold:false
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCustomer,
    newCustomerButtonClick: customersUIEvents.newCustomerButtonClick,
    openEditCustomerDialog: customersUIEvents.openEditCustomerDialog,
    openDeleteCustomerDialog: customersUIEvents.openDeleteCustomerDialog,
    openDeleteCustomersDialog: customersUIEvents.openDeleteCustomersDialog,
    openFetchCustomersDialog: customersUIEvents.openFetchCustomersDialog,
    openUpdateCustomersStatusDialog: customersUIEvents.openUpdateCustomersStatusDialog,
    openDetailsCustomerDialog: customersUIEvents.openDetailsCustomerDialog
  };

  return <CustomersUIContext.Provider value={value}>{children}</CustomersUIContext.Provider>;
}