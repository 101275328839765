import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default function mockManageGlAccounts(mock) {
  //Status Message Result
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }
  //Get Movable Units
  async function GetMovableUnitss() {
    return await fetch(window.$apiurl + "/rating/glAccounts", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //Get {id}
  async function GetMovableUnitsById(id) {
    return await fetch(window.$apiurl + "/rating/glAccount/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      const data = await response.json();
      return data;
    });
  }
  //Post Movable Units
  async function PostMovableUnit(someData) {
    return fetch(window.$apiurl + "/rating/glAccount", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //Delete
  async function DeleteMovableUnit(id) {
    return await fetch(window.$apiurl + "/rating/glAccount/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 200) {
          return Promise.all([statusCode, ""]);
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          Notification("success", "success");
          return true;
        } else if (res[0] === 400) {
          Notification("error", res[1].Message);
          return false;
        }
      })
      .catch((err) => {
        Notification("error", "error request");
        return false;
      });
  }
  //Put
  async function PutMovableUnit(someData, id) {
    someData.ServiceType = "shipment";
    return fetch(window.$apiurl + "/rating/glAccount/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function GetViewModel() {
    return await fetch(window.$apiurl + "/rating/glAccount/viewmodel", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  mock.onGet("api/ManageGlAccounts/GetAllApi").reply(async () => {
    var obj = await GetViewModel();
    return [200, obj];
  });
  mock.onPost("api/ManageGlAccounts").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const { Type = "", WarehouseId = "", GlAccount = "", Name = "" } = customer;
    const id = generateUserId();
    const newCustomer = {
      id,
      Type,
      WarehouseId,
      Name,
      GlAccount,
    };
    var postMovableUnit = await PostMovableUnit(newCustomer);
    if (postMovableUnit.Id != undefined) {
      Notification("success", "Gl Account has been added successfully");
      postMovableUnit.id = postMovableUnit.Id;
      customerTableMock.push(newCustomer);
      document.getElementById("ReLoadTableId").click();
      return [
        200,
        {
          customer: newCustomer,
          message: postMovableUnit.Message,
          success: true,
        },
      ];
    } else {
      if (postMovableUnit.Message) {
        window.$error400Gl = 400;
        Notification("error", postMovableUnit.Message);
        return [
          400,
          {
            customer: newCustomer,
            message: postMovableUnit.Message,
            success: false,
          },
        ];
      } else {
        Notification("success", "Gl Account has been added successfully");
        document.getElementById("ReLoadTableId").click();
        return [
          200,
          {
            customer: newCustomer,
            message: postMovableUnit.Message,
            success: true,
          },
        ];
      }
    }
  });

  mock.onPost("api/ManageGlAccounts/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const oldData = window.$dataGlAccountsFiltered;
    if(queryParams.filter.searchText){
      const searchValue = queryParams.filter.searchText;
      customerTableMock = oldData.filter((word) =>
      String(word.GlAccount)
      .trim()
      .toLowerCase()
      .includes(String(searchValue).trim().toLowerCase()) || 

      String(word.Type)
      .toLowerCase()
      .includes(String(searchValue).trim().toLowerCase()) || 

      String(word.WarehouseName)
      .toLowerCase()
      .includes(String(searchValue).trim().toLowerCase()) ||

      String(word.Name)
      .toLowerCase()
      .includes(String(searchValue).trim().toLowerCase()) || 

      String(word.id)
      .includes(String(searchValue).trim()) 
      );
    }  
    if(queryParams.pageNumber === 1 && !queryParams.filter.searchText){
      customerTableMock = await GetMovableUnitss();
      window.$dataGlAccountsFiltered = customerTableMock;
    }


    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.searchText;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageGlAccounts/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/ManageGlAccounts/updateStatusForCustomers")
    .reply((config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });
  mock.onGet(/api\/ManageGlAccounts\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageGlAccounts\/(\d+)/)[1];
    var resultById = await GetMovableUnitsById(id);
    if (!resultById) {
      return [400];
    }
    return [200, resultById];
  });

  mock.onPut(/api\/ManageGlAccounts\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageGlAccounts\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    var putObj = await PutMovableUnit(customer, id);
    if (putObj.Message) {
      Notification("error", putObj.Message);
      return false;
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/ManageGlAccounts\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageGlAccounts\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteMovableUnit(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
