import React, { useEffect, useState } from "react";
import {
  Modal,
  Alert,
  Spinner,
  Accordion,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  RadioButton,
  DateTime30,
  CheckboxLarge,
  CreatableSelect3,
  DenseTableSm,
  Select as SelectDrop,
} from "../../../../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import Select from "react-select";
import { Notification } from "../../../../../../Notification";
import {
  Icon,
  Button,
  IconButton,
  FormControlLabel,
  LinearProgress,
  Radio,
} from "@material-ui/core";
import axios from "axios";
import clsx from "clsx";
import { OrderNotesV2 } from "../../product-edit/OrderNotes";
import $ from "jquery";
import { default as JsNative } from "jquery";
import {
  is,
  IsValidMessage,
  ReturnEmptyOrString,
  momentDate,
  PostMomentTimeOnly,
  currencyFormat,
  DateTime30minInterval,
} from "../../../../../../../../_metronic/_helpers/ATHelpers";
import { Link } from "react-router-dom";
import { OrderAttachementsV2 } from "../../product-edit/OrderAttachements";
import { OneMoveMasterVendors } from "./OneMoveMasterVendors";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { ReceivingUIProvider } from "./TransportationItems/RemarksUIContext";
import { Receiving } from "./TransportationItems/Remarks";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import SelectPagination from "../../../../../../../../_metronic/_partials/widgets/Select/SelectPagination";
import {
  AddSpace3,
  AutoRateComponent,
  AutoRatingCharges,
  AutoRatingItem,
  CalculateCharge,
  ChargesColor,
  ChargesColorCategory,
  ChargesRemoveComponent,
  checkIs0,
  CheckRateInCharges,
  EventChangeAutoRate,
  GetLengthRound,
  InputValueCharge,
  isChangeFunction,
  IsRounding,
  isVariableOpenDefault,
  LockPayblesButton,
  MenuListComposition,
  RateClassName,
  RateReadOnly,
  RemoveDuplicate,
  RemoveSpace,
  RoundToFixed,
  SetSubTotal,
  SortAndRename,
  SortOnlyCharge,
  Spinner2s,
  StartAutoRate,
} from "./ChargesFunction";

var ViewModelSipmentMgt = "";
const adapter = new LocalStorage("db");
const db = low(adapter);

// Validation schema
const CustomerEditSchema = Yup.object().shape({});
const catchError = [];
const obj = {
  Id: 0,
  ExternalId: null,
  Name: "",
  Address: "",
  City: "",
  State: "",
  ZipCode: "",
};
const classRed =
  "btn btn-light-danger font-weight-bolder font-size-sm mt-5 pl-2 w-100";
var eventChange = new Event("change", { bubbles: true });
function eventChangefunction(id) {
  var element = document.getElementById(id);
  if (element != null)
    setTimeout(() => {
      element.dispatchEvent(eventChange);
    }, 500);
}
function SetValuefunction(id, value) {
  var element = document.getElementById(id);
  if (element != null)
    setTimeout(() => {
      element.value = value;
    }, 500);
}
let vendorsOrg = [];
export function OneMoveMaster({ Show, statSHipmentId, isTms }) {
  const [tab, setTab] = useState("1");
  const [idStat, setidStat] = useState(0);
  const [actionsLoading, setactionsLoading] = useState(true);
  const [Customers, setCustomers] = useState(catchError);
  const [Shipment, setShipment] = useState(null);
  const [ShipmentObj, setShipmentObj] = useState(null);
  const [PUContact, setPUContact] = useState(catchError);
  const [Shippers, setShippers] = useState(catchError);
  const [Consignee, setConsignee] = useState(catchError);
  const [GenericAddresses, setGenericAddresses] = useState(catchError);
  const [BillTo, setBillTo] = useState(catchError);
  const [Commodities, setCommodities] = useState(catchError);
  const [Vendors, setVendors] = useState(catchError);
  const [VendorListStat, setVendorListStat] = useState(catchError);
  const [FreightChargeId, setFreightChargeId] = useState("");
  const [EmptyPuLocation, setEmptyPuLocation] = useState();
  const [PuLocationDetail, setPuLocationDetail] = useState();
  const [EquipmentProviderIdDetail, setEquipmentProviderIdDetail] = useState();
  const [NotifyPartyIdDetail, setNotifyPartyIdDetail] = useState();
  const [DestinationIdDetail, setDestinationIdDetail] = useState();
  const [AttachmentTypes, setAttachmentTypes] = useState(catchError);
  const [NoteTypes, setNoteTypes] = useState(catchError);
  const [LastShipDateStat, setLastShipDateStat] = useState();
  const [EstimatedArrivalDateStat, setEstimatedArrivalDateStat] = useState();
  const [ProofOfDeliveryStat, setProofOfDeliveryStat] = useState();
  const [ActualDeliveryStat, setActualDeliveryStat] = useState();
  const [InvoiceDateStat, setInvoiceDateStat] = useState();
  const [ReloadCalc, setReloadCalc] = useState(1);
  const [CustomerId, setCustomerId] = useState();
  const [ChargesFunctionOne, setChargesFunctionOne] = useState(false);
  const [ChargesFunctionModel, setChargesFunctionModel] = useState(false);
  const [Contacts, setContacts] = useState(catchError);
  const [Services, setServices] = useState(catchError);
  const [AccessLevels, setAccessLevels] = useState(catchError);
  const [Status, setStatus] = useState(catchError);
  const [TrackingDevices, setTrackingDevices] = useState(catchError);
  const [ShipmentItems, setShipmentItems] = useState([]);
  //Vendor Charges
  const [VendorChargesRates, setVendorChargesRates] = useState([]);

  const [shortQuantity, setShortQuantity] = useState(0);
  const [damagedQuantity, setDamagedQuantity] = useState(0);
  const [overQuantity, setOverQuantity] = useState(0);
  const [modalOsdEdit, showModalEditOsd] = useState(false);
  const [saveEditOsd, setSaveOsd] = useState(false);
  const [rowOsd, setRowOsd] = useState(null);
  function setFreightChargeIdFun(id) {
    setFreightChargeId(id);
  }
  const [
    EmptyReturnLocationIdDetail,
    setEmptyReturnLocationIdDetail,
  ] = useState();

  function setReloadCalcF() {
    setReloadCalc((y) => y + 1);

    var eles = document.querySelectorAll(".setReloadCalcF");
    eles.forEach((ele) => {
      ele.click();
    });
  }

  useEffect(() => {
    /* window.$S_ChargesFM = false; */
    window.$S_ChargesIs_Ok = false;
    window.C_ChargesReadOnly = false;
    window.$OneMoveMasterSaveClose = false;
    window.$appDateTo = undefined;

    // Auto Rate
    window.ARCartons = undefined;
    window.ARTotalWeight1 = undefined;
    window.ARTotalWeight2 = undefined;
    window.ARTotalCube1 = undefined;
  }, []);

  useEffect(() => {
    var customerId = Shipment?.CustomerId || false;
    if (customerId) {
      window.$S_CustomerId = customerId;
    }
    window.$S_ChargesIs_Ok = Shipment?.Charges?.length > 0;

    window.C_ChargesReadOnly = Shipment?.AreChargesReadOnly || false;
    window.C_PayablesReadOnly = Shipment?.ArePayablesReadOnly || false;

    var notes = Shipment?.Notes || false;
    if (notes) {
      SetValuefunction("NotesId", notes);
    }

    var id = Shipment?.Id || false;
    if (id) {
      setidStat(id);
      window.ReceiptId = id;
    }

    var charges = Shipment?.Charges || false;
    if (charges) {
      window.OneMoveMastercharges = charges;
    }

    var freightChargeId = Shipment?.FreightChargeId || false;
    if (freightChargeId) {
      if (freightChargeId !== 98) SaveDisabledFunction(false);
      setFreightChargeId(freightChargeId + "");
    } else if (Shipment && Shipment !== null) {
      SaveDisabledFunction(false);
    }

    eventChangefunction("ShipmentCustomerId");

    var WillCall = Shipment?.WillCall || false;
    if (WillCall) document.getElementById("WillCallId").checked = WillCall;

    var IsCustomerCheckAcceptable =
      Shipment?.IsCustomerCheckAcceptable || false;
    if (IsCustomerCheckAcceptable)
      document.getElementById(
        "IsCustomerCheckAcceptableId"
      ).checked = IsCustomerCheckAcceptable;

    var vendorId = Shipment?.VendorId || false;
    if (vendorId) {
      eventChangefunction("ShipmentVendorId");
    }
    var lastShipDate = Shipment?.LastShipDate || false;
    if (lastShipDate) {
      setLastShipDateStat(lastShipDate);
    }

    var proofOfDelivery = Shipment?.ProofOfDelivery || false;
    if (proofOfDelivery) {
      setProofOfDeliveryStat(proofOfDelivery);
    }
    var EstimatedArrivalDate = Shipment?.EstimatedArrivalDate || false;
    if (EstimatedArrivalDate) {
      setEstimatedArrivalDateStat(EstimatedArrivalDate);
    }
    var ActualDelivery = Shipment?.ActualDelivery || false;
    if (ActualDelivery) {
      setActualDeliveryStat(ActualDelivery);
    }
    var InvoiceDate = Shipment?.InvoiceDate || false;
    if (InvoiceDate) {
      setInvoiceDateStat(InvoiceDate);
    }

    if (Shipment?.Vendors) vendorsOrg = Shipment?.Vendors;
  }, [Shipment]);

  function SetFieldValueFunction(setFieldValue) {
    window.setFieldValue = setFieldValue;
  }

  useEffect(() => {
    if (statSHipmentId) {
      if (ViewModelSipmentMgt === "") {
        GetViewModel(statSHipmentId);
      } else {
        setServices(ViewModelSipmentMgt["Services"] || []);
        setCustomers(ViewModelSipmentMgt["Customers"] || catchError);
        setShipmentObj(ViewModelSipmentMgt["Shipment"]);
        setShippers(ViewModelSipmentMgt["Shippers"] || catchError);
        setTrackingDevices(
          ViewModelSipmentMgt["TrackingDevices"] || catchError
        );
        setConsignee(ViewModelSipmentMgt["Consignees"] || catchError);
        setGenericAddresses(
          ViewModelSipmentMgt["GenericAddresses"] || catchError
        );
        setBillTo(ViewModelSipmentMgt["BillsTo"] || catchError);
        setVendors(ViewModelSipmentMgt["Vendors"] || catchError);
        setCommodities(ViewModelSipmentMgt["Commodities"] || catchError);
        setAttachmentTypes(
          ViewModelSipmentMgt["AttachmentTypes"] || catchError
        );
        setNoteTypes(ViewModelSipmentMgt["NoteTypes"] || catchError);
        setAccessLevels(ViewModelSipmentMgt["AccessLevels"] || catchError);
        const vendorsList = ViewModelSipmentMgt["Vendors"] || catchError;
        fetchAsync(statSHipmentId, vendorsList);
      }
    }
  }, [statSHipmentId]);

  function GetViewModel(statSHipmentId) {
    fetch(window.$apiurl + `/api/shipmentviewmodel`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        ViewModelSipmentMgt = res[1];

        setServices(res[1]["Services"] || []);
        setCustomers(res[1]["Customers"] || catchError);
        setShipmentObj(res[1]["Shipment"]);
        setShippers(res[1]["Shippers"] || catchError);
        setTrackingDevices(res[1]["TrackingDevices"] || catchError);
        setConsignee(res[1]["Consignees"] || catchError);
        setGenericAddresses(res[1]["GenericAddresses"] || catchError);
        setBillTo(res[1]["BillsTo"] || catchError);
        setVendors(res[1]["Vendors"] || catchError);
        setCommodities(res[1]["Commodities"] || catchError);
        setAttachmentTypes(res[1]["AttachmentTypes"] || catchError);
        setAccessLevels(res[1]["AccessLevels"] || catchError);
        setNoteTypes(res[1]["NoteTypes"] || catchError);

        window.FreightCharges = res[1]["FreightChargeTerms"] || catchError;
        window.VendorTypeListOption = res[1]["VendorTypes"] || catchError;
        window.BillToOptionsList = res[1]["BillsTo"] || catchError;
        const vendorsList = res[1]["Vendors"] || catchError;
        fetchAsync(statSHipmentId, vendorsList);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const fetchAsync = async (statSHipmentId, vendorsList) => {
    try {
      const result = await axios.post("api/FindOrderContainers/OneMoveMaster", {
        statSHipmentId,
      });
      const vendorss = result.data["Shipment"]["Vendors"];
      let shipmentFun = result.data["Shipment"];
      setVendorChargesRates(shipmentFun.Vendors || []);

      shipmentFun.CustomerId = shipmentFun.CustomerId || "0";
      shipmentFun.Weight = shipmentFun?.Weight?.toFixed(2);
      shipmentFun.Cube = shipmentFun?.Cube?.toFixed(2);
      shipmentFun.Pieces = shipmentFun?.Pieces?.toFixed(2);
      shipmentFun.FreightChargeId = shipmentFun?.FreightChargeId || null;

      shipmentFun?.ShipmentItems.length > 0 &&
        shipmentFun.ShipmentItems.map((x) => {
          x.Weight = currencyFormat(x.Weight);
          x.Cube = currencyFormat(x.Cube);
        });
      setShipmentItems(shipmentFun?.ShipmentItems ?? []);
      setShipment(shipmentFun);
      setactionsLoading(false);
      setChargesFunctionOne(true);
      if (vendorss.length === 0 || vendorss === null) {
        $("#VendorDetailID").click();
        setTab("1");
      }
    } catch (e) {}
  };

  useEffect(() => {
    setVendorListStat([]);
    if (VendorChargesRates?.length > 0 && Vendors?.length > 0)
      VendorChargesRates.map((item, index) => {
        setVendorListStat((x) => [
          ...x,
          <OneMoveMasterVendors
            item={item}
            countId={index}
            index={index}
            FreightChargeId={FreightChargeId}
            Vendorsf={Vendors}
            ReloadCalc={ReloadCalc}
            CustomerId={CustomerId}
            isInboundV={false}
            isPayableV={true}
            S_ChargesReadOnly={window.C_PayablesReadOnly}
            isShipment={true}
          />,
        ]);
      });
  }, [VendorChargesRates, Vendors, FreightChargeId]);

  let outboundUrl;
  switch (ShipmentObj?.ModeName) {
    case "LTL": {
      outboundUrl = "/LTL/Outbound/report/" + statSHipmentId;
      break;
    }
    case "Intermodal Rail (COFC)": {
      outboundUrl = "/TRC/Outbound/report/" + statSHipmentId;
      break;
    }
    case "OTR (Over The Road)": {
      outboundUrl = "/TRC/Outbound/report/" + statSHipmentId;
      break;
    }
    default:
      outboundUrl = "/DRAYAGE/Outbound/report/" + statSHipmentId;
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={Shipment}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          if (CheckVandorIsDanger(Vendors, values.Vendors)) return false;

          window.IsValid = false;
          values.IsDomestic = values.IsDomestic || false;
          values.WillCall = values.WillCall || false;

          values.AppointmentTo = window.$appDateTo;
          if (
            values?.AppointmentTo === "Select" ||
            +values?.AppointmentTo === 0
          )
            delete values.AppointmentTo;

          if (
            values?.PuAppointmentTo === "Select" ||
            +values?.PuAppointmentTo === 0
          )
            delete values.PuAppointmentTo;

          var a = $(".VendorsList")
            .eq(0)
            .val();
          if (a) {
            $("#VendorId").val(a);
            eventChangefunction("VendorId");
          }

          values.EstimatedArrivalDate =
            values.EstimatedArrivalDate || EstimatedArrivalDateStat;
          values.ProofOfDelivery =
            values.ProofOfDelivery || ProofOfDeliveryStat;
          values.ActualDelivery = values.ActualDelivery || ActualDeliveryStat;

          values.LastShipDate = values.LastShipDate || LastShipDateStat;

          values.CarrierCodFeeId = values.CarrierCodFeeId || "102";

          var v = [];
          (values.Vendors || []).forEach((x) => {
            if (x?.Id) v.push(x);
          });
          values.Vendors = v;

          delete values.InvoiceNumber;
          delete values.InvoiceDate;

          IsValidMessage("Customer", values?.CustomerId);
          IsValidMessage("BillTo", values?.BillToId);
          IsValidMessage("Last Ship Date", values?.LastShipDate);
          IsValidMessage("Freight Charge", values?.FreightChargeId);

          if (values?.CustomerId === 112) {
            IsValidMessage("Equipment ID", values?.Equipment);
            IsValidMessage("E.T.A", values?.EstimatedArrivalDate);
            IsValidMessage("Seal No.", values?.SealNumber);
            IsValidMessage("Equipment Sizes", values?.EquipmentSizeId);
          }
          if (window.IsValid) return false;

          values.Charges = values?.Charges || [];
          values.Pieces = parseInt(values?.Pieces);
          values.Cube = parseInt(values?.Cube);
          values.Weight = parseInt(values?.Weight);

          (values?.Vendors ?? []).forEach((x, i) => {
            if (x === null) values.Vendors.splice(i, 1);
          });

          SaveDisabledFunction(true);
          axios
            .post("api/FindOrderContainers/PutOneMoveMaster", {
              values,
              statSHipmentId,
            })
            .then((e) => {
              SaveDisabledFunction(false);
              if (e?.data === 200) {
                if (window.$OneMoveMasterSaveClose === true) {
                  Show(false);
                  setTimeout(() => {
                    if (
                      window.shipmentEditCommingFrom.indexOf(
                        "ShipmentsManagement"
                      ) > -1
                    ) {
                      var ReLoadTableId = document
                        .getElementById("ReLoadTableId")
                        .click();
                      if (ReLoadTableId) {
                        ReLoadTableId.click();
                      }
                      var refreshShipmentsException = document.getElementById(
                        "refreshShipmentsException"
                      );
                      if (refreshShipmentsException) {
                        refreshShipmentsException.click();
                      }
                    } else if (
                      window.shipmentEditCommingFrom.indexOf(
                        "AssigntoOutbound"
                      ) > -1
                    ) {
                      document
                        .getElementById("refreshOneMoveMasterButton")
                        .click();
                    }
                  }, 500);
                  setTimeout(() => {
                    if (
                      window.shipmentEditCommingFrom.indexOf("Billing") > -1
                    ) {
                      document
                        .getElementsByClassName("text-info")[0]
                        .parentElement.click();
                    }
                  }, 500);
                }
              }
            })
            .catch(() => {});
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            {SetFieldValueFunction(setFieldValue)}
            <Modal
              closeOnOverlayClick={false}
              show={ChargesFunctionModel}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Charges
                </Modal.Title>
                <IconButton
                  aria-label="Close"
                  onClick={() => {
                    setChargesFunctionModel(false);
                    setReloadCalcF();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Modal.Header>
              <Modal.Body className="row ml-1">
                Do you want to remove the rates?
              </Modal.Body>
              <Modal.Footer>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setChargesFunctionModel(false);
                      document.getElementById("setShowMail").click();
                    }}
                    className="btn btn-light btn-elevate mr-2"
                  >
                    No
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setChargesFunctionModel(false);
                      setFieldValue("Charges", []);
                      setReloadCalcF();

                      setTimeout(() => {
                        document.getElementById("setShowMail").click();
                      }, 2000);
                    }}
                    className="btn btn-success btn-elevate"
                  >
                    Yes
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            <Accordion defaultActiveKey="100">
              <Form className="form form-label-right">
                <Modal.Header closeButton>
                  <div className="row w-100">
                    <div className="col-lg-6">
                      <Field
                        name="Id"
                        Id="shipmentId"
                        component={Input}
                        placeholder="Shipment No."
                        label="Shipment No."
                        disabled="true"
                      />
                    </div>
                    <div className="col-lg-5">
                      <DateTime30
                        label="Creation date"
                        name="CreatedAt"
                        disabled
                        defaultvalue={values?.CreatedAt}
                        setFieldValue={setFieldValue}
                        className="w-100 pt-2"
                      />
                    </div>
                    <div className="col-lg-6 mt-5">
                      <Field
                        name="InvoiceNumber"
                        component={Input}
                        placeholder="Invoice No"
                        label="Invoice No"
                        disabled
                      />
                    </div>
                    <div className="col-lg-5 mt-5">
                      <DateTime30
                        label="Invoice Date"
                        name="InvoiceDate"
                        disabled
                        defaultvalue={values?.InvoiceDate}
                        setFieldValue={setFieldValue}
                        className="w-100 pt-2"
                      />
                    </div>
                  </div>
                  <IconButton
                    aria-label="Close"
                    onClick={() => {
                      Show(false);
                      if (
                        window.shipmentEditCommingFrom.indexOf("Billing") > -1
                      ) {
                        document
                          .getElementsByClassName("text-info text-info")[0]
                          .parentElement.click();
                      }
                      if (
                        window.shipmentEditCommingFrom.indexOf(
                          "AssigntoOutbound"
                        ) > -1
                      ) {
                        document
                          .getElementById("refreshOneMoveMasterButton")
                          .click();
                      }
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Modal.Header>

                <Modal.Body>
                  {actionsLoading && (
                    <div className="overlay-layer bg-transparent mb-5">
                      <div className="spinner spinner-lg spinner-success">
                        .
                      </div>
                    </div>
                  )}
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="0"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                      <h5 className="font-weight-bold">Header</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <ShipmentHeader
                      values={values}
                      setFieldValue={setFieldValue}
                      Customers={Customers}
                      BillTo={BillTo}
                      setChargesFunctionModel={setChargesFunctionModel}
                      Shippers={Shippers}
                      TrackingDevices={TrackingDevices}
                      Consignee={Consignee}
                      Commodities={Commodities}
                      outboundUrl={outboundUrl}
                      setReloadCalcF={setReloadCalcF.bind()}
                      ShipmentObj={ShipmentObj}
                      setStatus={setStatus.bind()}
                      statSHipmentId={statSHipmentId}
                      Services={Services}
                    />
                  </Accordion.Collapse>
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="01"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                      <h5 className="font-weight-bold">Dates</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="01">
                    <DatesComponent
                      values={values}
                      setFieldValue={setFieldValue}
                      Status={Status}
                      isTms={isTms}
                    />
                  </Accordion.Collapse>
                  {(values?.FreightChargeId === 98 ||
                    values?.FreightChargeId === undefined) && (
                    <>
                      <Accordion.Toggle
                        as={Button}
                        className="w-100 text-left p-0 position-relative text-decoration-none ChargesBtn"
                        variant="link"
                        eventKey="10"
                      >
                        <Alert
                          variant="primary"
                          className="pt-3 pl-3 pb-0 w-100"
                        >
                          <h5 className="font-weight-bold">
                            Charges
                            {!ChargesFunctionOne && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="p-2 ml-5"
                              />
                            )}
                          </h5>
                        </Alert>
                        <Icon
                          className={clsx(
                            "fa fa-chevron-down position-absolute text-white"
                          )}
                          style={{ fontSize: 17, right: "1rem", top: "20%" }}
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse
                        eventKey="10"
                        className="ChargesBtnDiv"
                      >
                        <>
                          <div className="form-group row">
                            <div className="col-lg-12 mt-5">
                              {ChargesFunctionOne &&
                              values?.Charges?.length > -1 ? (
                                <ChargesFunction
                                  isInbound={false}
                                  isPayable={false}
                                  type="Charges"
                                  list={values?.Charges}
                                  ReloadCalc={ReloadCalc}
                                  setFieldValue={setFieldValue}
                                />
                              ) : (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  role="status"
                                  aria-hidden="true"
                                  className="p-2 ml-5"
                                />
                              )}
                            </div>
                          </div>
                        </>
                      </Accordion.Collapse>
                    </>
                  )}
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="1"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                      <h5 className="font-weight-bold">Detail</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <div className="form-group row">
                      <div className="col-lg-6 mt-5">
                        <Field
                          name="InvoiceNumber"
                          component={Input}
                          placeholder="Invoice No"
                          label="Invoice No"
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 mt-5">
                        <DateTime30
                          label="Invoice Date"
                          required={false}
                          name="InvoiceDate"
                          defaultvalue={InvoiceDateStat}
                          setFieldValue={setFieldValue}
                          className="w-100 pt-2"
                          disabled
                        />
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-lg-6 mt-5">
                            <Field
                              name="Equipment"
                              component={Input}
                              placeholder="Equipment ID"
                              label="Equipment ID"
                            />
                          </div>
                          <div className="col-lg-6 mt-5">
                            <Field
                              name="SealNumber"
                              component={Input}
                              placeholder="Seal No."
                              label="Seal No."
                            />
                          </div>
                          <div className="col-lg-12">
                            <CheckboxLarge
                              label=" "
                              text="Will Call"
                              name="WillCall"
                              id="WillCallId"
                              onChange={(event) => {
                                setFieldValue("WillCall", event.target.checked);
                              }}
                            />
                          </div>

                          <div className="col-lg-12 mt-5">
                            <SelectPagination
                              name="PuLocationId"
                              label="PU Location"
                              value={{
                                value: values?.PuLocationId,
                                label: GenericAddresses.find(
                                  (x) => x?.Id === values?.PuLocationId
                                )
                                  ? is(
                                      GenericAddresses.find(
                                        (x) => x?.Id === values?.PuLocationId
                                      )?.Code
                                    ) +
                                    GenericAddresses.find(
                                      (x) => x?.Id === values?.PuLocationId
                                    )?.CompanyName
                                  : "Select...",
                              }}
                              options={GenericAddresses.map((x) => {
                                return {
                                  value: x.Id,
                                  label: is(x?.Code) + x?.CompanyName,
                                };
                              })}
                              onChange={(newValue) => {
                                var a = newValue?.value;
                                setFieldValue("PuLocationId", a);
                                var b = GenericAddresses.find(
                                  (x) => x.Id === a
                                );
                                setPuLocationDetail(b);
                                setPUContact(b?.Contacts || []);
                              }}
                            />
                            <div>
                              <div class="form-control h-50 mt-2">
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.PuLocationId
                                  )?.CompanyName
                                }
                                <br />
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.PuLocationId
                                  )?.Address
                                }
                                <br />
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.PuLocationId
                                  )?.City
                                }
                                ,
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.PuLocationId
                                  )?.State
                                }
                                ,
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.PuLocationId
                                  )?.ZipCode
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-5">
                            <CreatableSelect3
                              name="PuContactId"
                              label="PU Contact"
                              valueSelect={values?.PuContactId}
                              setFieldValue={setFieldValue}
                              options={PUContact.map((x) => {
                                return {
                                  value: x.Id,
                                  label: is(x?.Code) + x?.CompanyName,
                                };
                              })}
                            />
                          </div>
                          <div className="col-lg-12 mt-5">
                            <SelectPagination
                              name="NotifyPartyId"
                              label="Notify Party"
                              value={{
                                value: values?.NotifyPartyId,
                                label: GenericAddresses.find(
                                  (x) => x?.Id === values?.NotifyPartyId
                                )
                                  ? is(
                                      GenericAddresses.find(
                                        (x) => x?.Id === values?.NotifyPartyId
                                      )?.Code
                                    ) +
                                    GenericAddresses.find(
                                      (x) => x?.Id === values?.NotifyPartyId
                                    )?.CompanyName
                                  : "Select...",
                              }}
                              options={GenericAddresses.map((x) => {
                                return {
                                  value: x.Id,
                                  label: is(x?.Code) + x?.CompanyName,
                                };
                              })}
                              onChange={(newValue) => {
                                var a = newValue?.value;
                                setFieldValue("NotifyPartyId", a);
                                var b = GenericAddresses.find(
                                  (x) => x.Id === a
                                );
                                setNotifyPartyIdDetail(b);
                              }}
                            />
                            <div>
                              <div class="form-control h-50 mt-2">
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.NotifyPartyId
                                  )?.CompanyName
                                }
                                <br />
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.NotifyPartyId
                                  )?.Address
                                }
                                <br />
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.NotifyPartyId
                                  )?.City
                                }
                                ,
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.NotifyPartyId
                                  )?.State
                                }
                                ,
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.NotifyPartyId
                                  )?.ZipCode
                                }
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-5">
                            <SelectPagination
                              name="DestinationId"
                              label="Destination"
                              value={{
                                value: values?.DestinationId,
                                label: GenericAddresses.find(
                                  (x) => x?.Id === values?.DestinationId
                                )
                                  ? is(
                                      GenericAddresses.find(
                                        (x) => x?.Id === values?.DestinationId
                                      )?.Code
                                    ) +
                                    GenericAddresses.find(
                                      (x) => x?.Id === values?.DestinationId
                                    )?.CompanyName
                                  : "Select...",
                              }}
                              options={GenericAddresses.map((x) => {
                                return {
                                  value: x.Id,
                                  label: is(x?.Code) + x?.CompanyName,
                                };
                              })}
                              onChange={(newValue) => {
                                var a = newValue?.value;
                                setFieldValue("DestinationId", a);
                                var b = GenericAddresses.find(
                                  (x) => x.Id === a
                                );
                                setDestinationIdDetail(b);
                                setContacts(b?.Contacts || []);
                              }}
                            />
                            <div>
                              <div class="form-control h-50 mt-2 mb-2">
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.DestinationId
                                  )?.CompanyName
                                }
                                <br />
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.DestinationId
                                  )?.Address
                                }
                                <br />
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.DestinationId
                                  )?.City
                                }
                                ,
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.DestinationId
                                  )?.State
                                }
                                ,
                                {
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.DestinationId
                                  )?.ZipCode
                                }
                              </div>
                            </div>

                            <div className="mt-5" />
                            <CreatableSelect3
                              name="DestinationContactId"
                              label="Delivery Contact"
                              setFieldValue={setFieldValue}
                              valueSelect={values?.DestinationContactId}
                              options={Contacts.map((x) => {
                                return {
                                  value: x.Id,
                                  label: x?.Name,
                                };
                              })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 row">
                        <div className="col-lg-12 mt-5">
                          <SelectPagination
                            name="EmptyPuLocationId"
                            label="Empty P/U Location"
                            value={{
                              value: values?.EmptyPuLocationId,
                              label: GenericAddresses.find(
                                (x) => x?.Id === values?.EmptyPuLocationId
                              )
                                ? is(
                                    GenericAddresses.find(
                                      (x) => x?.Id === values?.EmptyPuLocationId
                                    )?.Code
                                  ) +
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.EmptyPuLocationId
                                  )?.CompanyName
                                : "Select...",
                            }}
                            options={GenericAddresses.map((x) => {
                              return {
                                value: x.Id,
                                label: is(x?.Code) + x?.CompanyName,
                              };
                            })}
                            onChange={(newValue) => {
                              var a = newValue?.value;
                              setFieldValue("EmptyPuLocationId", a);
                              var b = GenericAddresses.find((x) => x.Id === a);
                              setEmptyPuLocation(b);
                            }}
                          />
                          <div>
                            <div class="form-control h-50 mt-2 h-50">
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EmptyPuLocationId
                                )?.CompanyName
                              }
                              <br />
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EmptyPuLocationId
                                )?.Address
                              }
                              <br />
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EmptyPuLocationId
                                )?.City
                              }
                              ,
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EmptyPuLocationId
                                )?.State
                              }
                              ,
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EmptyPuLocationId
                                )?.ZipCode
                              }
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 mt-5">
                          <Field
                            name="ReleaseBookingNumber"
                            component={Input}
                            placeholder="Release/Booking #"
                            label="Release/Booking #"
                          />
                        </div>

                        <div className="col-lg-12 mt-5">
                          <SelectPagination
                            name="EquipmentProviderId"
                            label="Equipment Provider"
                            value={{
                              value: values?.EquipmentProviderId,
                              label: GenericAddresses.find(
                                (x) => x?.Id === values?.EquipmentProviderId
                              )
                                ? is(
                                    GenericAddresses.find(
                                      (x) =>
                                        x?.Id === values?.EquipmentProviderId
                                    )?.Code
                                  ) +
                                  GenericAddresses.find(
                                    (x) => x?.Id === values?.EquipmentProviderId
                                  )?.CompanyName
                                : "Select...",
                            }}
                            options={GenericAddresses.map((x) => {
                              return {
                                value: x.Id,
                                label: is(x?.Code) + x?.CompanyName,
                              };
                            })}
                            onChange={(newValue) => {
                              var a = newValue?.value;
                              setFieldValue("EquipmentProviderId", a);
                              // var b = GenericAddresses.find((x) => x.Id === a);
                              // setEquipmentProviderIdDetail(b);
                            }}
                          />
                          <div>
                            <div class="form-control h-50 mt-2">
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EquipmentProviderId
                                )?.CompanyName
                              }
                              <br />
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EquipmentProviderId
                                )?.Address
                              }
                              <br />
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EquipmentProviderId
                                )?.City
                              }
                              ,
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EquipmentProviderId
                                )?.State
                              }
                              ,
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EquipmentProviderId
                                )?.ZipCode
                              }
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 mt-5">
                          <SelectPagination
                            name="EmptyReturnLocationId"
                            label="Empty return Location"
                            value={{
                              value: values?.EmptyReturnLocationId,
                              label: GenericAddresses.find(
                                (x) => x?.Id === values?.EmptyReturnLocationId
                              )
                                ? is(
                                    GenericAddresses.find(
                                      (x) =>
                                        x?.Id === values?.EmptyReturnLocationId
                                    )?.Code
                                  ) +
                                  GenericAddresses.find(
                                    (x) =>
                                      x?.Id === values?.EmptyReturnLocationId
                                  )?.CompanyName
                                : "Select...",
                            }}
                            options={GenericAddresses.map((x) => {
                              return {
                                value: x.Id,
                                label: is(x?.Code) + x?.CompanyName,
                              };
                            })}
                            onChange={(newValue) => {
                              var a = newValue?.value;
                              setFieldValue("EmptyReturnLocationId", a);
                              var b = GenericAddresses.find((x) => x.Id === a);
                              setEmptyReturnLocationIdDetail(b);
                            }}
                          />
                          <div>
                            <div class="form-control h-50 mt-2">
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EmptyReturnLocationId
                                )?.CompanyName
                              }
                              <br />
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EmptyReturnLocationId
                                )?.Address
                              }
                              <br />
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EmptyReturnLocationId
                                )?.City
                              }
                              ,
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EmptyReturnLocationId
                                )?.State
                              }
                              ,
                              {
                                GenericAddresses.find(
                                  (x) => x?.Id === values?.EmptyReturnLocationId
                                )?.ZipCode
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mt-5">
                        <label className={classRed}>
                          Bill of Lading Payment / Collection Terms
                        </label>
                      </div>
                      <div className="col-lg-6 mt-5">
                        <Field
                          name="CodAmount"
                          component={Input}
                          placeholder="COD Amount"
                          label="COD Amount"
                        />
                      </div>
                      <div className="col-lg-6 mt-5">
                        <RadioButton
                          name="FreightChargeId"
                          required={true}
                          label="Freight Charge Terms"
                          row
                          value={FreightChargeId}
                          onChange={(e) => {
                            var value = e.target.value;
                            setFreightChargeIdFun(value);
                            setFieldValue("FreightChargeId", value);
                          }}
                        >
                          <FormControlLabel
                            value="98"
                            control={<Radio color="primary" />}
                            label="Prepaid"
                          />
                          <FormControlLabel
                            value="99"
                            control={<Radio color="primary" />}
                            label="Collect"
                          />
                          <FormControlLabel
                            value="100"
                            control={<Radio color="primary" />}
                            label="Third Party Billing"
                          />
                        </RadioButton>
                      </div>
                      <div className="col-lg-6">
                        <CheckboxLarge
                          label=" "
                          text="Customer check acceptable"
                          name="IsCustomerCheckAcceptable"
                          id="IsCustomerCheckAcceptableId"
                          onChange={(event) => {
                            setFieldValue(
                              "IsCustomerCheckAcceptable",
                              event.target.checked
                            );
                          }}
                        />
                        <div className="mt-5 pt-5" />
                      </div>
                      <div className="col-lg-6 mt-5" />
                      <div className="m-auto pb-5 col-lg-11 MuiInputBase-root MuiInput-root MuiInput-underline Mui-error Mui-error MuiInputBase-formControl MuiInput-formControl" />
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="4"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                      <h5 className="font-weight-bold">
                        Vendors List{" "}
                        {values?.Id && (
                          <LockPayblesButton
                            Id={values?.Id}
                            IsReceipt={false}
                            FreightChargeId={values?.FreightChargeId}
                            setReloadCalcF={setReloadCalcF}
                            ArePayablesReadOnly={values?.ArePayablesReadOnly}
                            setArePayablesReadOnly={(e) => {
                              window.C_PayablesReadOnly = e;
                              window.ChargesReadOnly = e;
                              setFieldValue("ArePayablesReadOnly", e);
                              var eles = document.querySelectorAll(
                                ".setChargesReadOnly"
                              );
                              eles.forEach((ele) => {
                                ele.click();
                              });
                            }}
                          />
                        )}
                        {!ChargesFunctionOne && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="p-2 ml-5"
                          />
                        )}
                      </h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <button
                          type="button"
                          class="btn btn-light-success mr-2 w-100 mb-5"
                          onClick={() => {
                            var a = VendorListStat.length;
                            setReloadCalcF();
                            /* window.$S_ChargesFM = false;
                            setTimeout(() => {
                              window.$S_ChargesFM = true;
                            }, 3000); */
                            setVendorListStat((x, index) => [
                              ...x,
                              <OneMoveMasterVendors
                                countId={a}
                                item={{}}
                                index={index}
                                FreightChargeId={FreightChargeId}
                                Vendorsf={Vendors}
                                ReloadCalc={ReloadCalc}
                                CustomerId={CustomerId}
                                isInboundV={false}
                                isPayableV={true}
                                S_ChargesReadOnly={window.C_PayablesReadOnly}
                                isShipment={true}
                              />,
                            ]);
                          }}
                        >
                          <i class="fas fa-plus mr-2"></i> Add New Vendor
                        </button>
                      </div>
                      {ChargesFunctionOne &&
                        values?.Charges?.length > -1 &&
                        VendorListStat.map((x) => x)}
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="2"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                      <h5 className="font-weight-bold">Notes</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <>
                      {values !== null ? (
                        <OrderNotesV2
                          id={idStat}
                          notesList={values.Notes}
                          AccessLevels={AccessLevels}
                          CategorysList={NoteTypes}
                          Type="shipments"
                        />
                      ) : (
                        ""
                      )}
                    </>
                  </Accordion.Collapse>
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="3"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                      <h5 className="font-weight-bold">Attachments</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3">
                    <>
                      {values !== null ? (
                        <OrderAttachementsV2
                          id={idStat}
                          AttachmentList={values.Attachments || []}
                          AttachmentTypes={AttachmentTypes}
                          AccessLevels={AccessLevels}
                          Type="shipment"
                        />
                      ) : (
                        ""
                      )}
                    </>
                  </Accordion.Collapse>
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="9"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                      <h5 className="font-weight-bold">Transportation Items</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="9">
                    <ReceivingUIProvider
                      currentProductId={Shipment?.Id}
                      shipmentObject={Shipment !== null ? Shipment : null}
                    >
                      <Receiving />
                    </ReceivingUIProvider>
                  </Accordion.Collapse>
                  {ShipmentItems.length > 0 && (
                    <Accordion.Toggle
                      as={Button}
                      className="w-100 text-left p-0 position-relative text-decoration-none"
                      variant="link"
                      eventKey="25"
                    >
                      <Alert
                        variant="primary "
                        className="pt-3 pl-3 pb-0 w-100"
                      >
                        <h5 className="font-weight-bold">Shipment Items</h5>
                      </Alert>
                      <Icon
                        className={clsx(
                          "fa fa-chevron-down position-absolute text-white"
                        )}
                        style={{ fontSize: 17, right: "1rem", top: "20%" }}
                      />
                    </Accordion.Toggle>
                  )}
                  <Modal
                    size="xl"
                    show={modalOsdEdit}
                    aria-labelledby="example-modal-sizes-title-lg"
                  >
                    <Modal.Header>
                      <Modal.Title>
                        Edit OSD Item / PO# : {rowOsd?.PurchaseOrder}
                      </Modal.Title>
                      <IconButton
                        aria-label="Close"
                        onClick={() => {
                          showModalEditOsd(false);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="form-group row">
                        <div className="col-lg-6">
                          <label>Over Quantity</label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Over Quantity"
                            min="0"
                            value={overQuantity}
                            onChange={(e) => {
                              setOverQuantity(e.target.value);
                            }}
                          />
                        </div>

                        <div className="col-lg-6">
                          <label>Short Quantity</label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Short Quantity"
                            min="0"
                            value={shortQuantity}
                            onChange={(e) => {
                              setShortQuantity(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-6">
                          <label>Damaged Quantity</label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Damaged Quantity"
                            min="0"
                            value={damagedQuantity}
                            onChange={(e) => {
                              setDamagedQuantity(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btn-light btn-elevate"
                        onClick={(e) => {
                          showModalEditOsd(false);
                        }}
                      >
                        Close
                      </button>
                      <button
                        disabled={saveEditOsd}
                        className={`btn btn-primary btn-elevate ${saveEditOsd &&
                          `spinner spinner-right spinner-white pr-15`}`}
                        onClick={async (e) => {
                          try {
                            setSaveOsd(true);
                            PutOsd({
                              Id: rowOsd.Id,
                              overQuantity: overQuantity,
                              shortQuantity: shortQuantity,
                              damagedQuantity: damagedQuantity,
                            }).then((e) => {
                              if (e[0] === 200) {
                                Notification("success", "success");
                                setShipmentItems((e) => {
                                  var a = e.findIndex(
                                    (x) => x.Id === rowOsd.Id
                                  );
                                  e[a].ShipmentOverQuantity =
                                    overQuantity === null || overQuantity === ""
                                      ? 0
                                      : overQuantity;
                                  e[a].ShipmentShortQuantity =
                                    shortQuantity === null ||
                                    shortQuantity === ""
                                      ? 0
                                      : shortQuantity;
                                  e[a].ShipmentDamagedQuantity =
                                    damagedQuantity === null ||
                                    damagedQuantity === ""
                                      ? 0
                                      : damagedQuantity;
                                  return e;
                                });
                                setSaveOsd(false);
                                showModalEditOsd(false);
                              }
                            });
                          } catch (err) {}
                        }}
                      >
                        Save
                      </button>
                    </Modal.Footer>
                  </Modal>
                  {ShipmentItems.length > 0 && (
                    <Accordion.Collapse eventKey="25">
                      <div className="form-group row">
                        <div className="col-lg-12 mt-5">
                          <DenseTableSm
                            rowsName={[
                              {
                                Name: "PurchaseOrder",
                                Title: "Purchase Order",
                              },
                              {
                                Name: "UnitOfMeasure",
                                Title: "Unit Of Measure",
                              },
                              { Name: "Location", Title: "Location" },
                              { Name: "Sku", Title: "Sku" },
                              { Name: "Upc", Title: "UPC" },
                              {
                                Name: "TransloadOrderId",
                                Title: "Transload Order Id",
                              },
                              { Name: "Container", Title: "Container" },
                              { Name: "ReceiptId", Title: "Receipt Id" },
                              { Name: "Description", Title: "Description" },
                              { Name: "Quantity", Title: "Unit Count" },
                              { Name: "Weight", Title: "Weight" },
                              { Name: "Cube", Title: "Cube" },
                              {
                                Name: "IsHazmat",
                                Title: "Is HazMat",
                              },
                              {
                                Name: "IsHot",
                                Title: "Is Hot",
                              },
                              {
                                Name: "OverQuantity",
                                Title: "Over Qty",
                              },
                              {
                                Name: "ShortQuantity",
                                Title: "Short Qty",
                              },
                              {
                                Name: "DamangedQuantity",
                                Title: "Damaged Qty",
                              },
                              {
                                Name: "ShipmentOverQuantity",
                                Title: "Ship Over Qty",
                              },
                              {
                                Name: "ShipmentShortQuantity",
                                Title: "Ship Short Qty",
                              },
                              {
                                Name: "ShipmentDamagedQuantity",
                                Title: "Ship Damaged Qty",
                              },
                              {
                                Name: "Actions",
                                Title: "Actions",
                                format: ({ row }) => {
                                  return (
                                    <a
                                      href={() => false}
                                      title="Edit OSD"
                                      className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                      onClick={() => {
                                        showModalEditOsd(true);
                                        setRowOsd(row);
                                        setOverQuantity(
                                          row.ShipmentOverQuantity === null
                                            ? 0
                                            : row.ShipmentOverQuantity
                                        );
                                        setShortQuantity(
                                          row.ShipmentShortQuantity === null
                                            ? 0
                                            : row.ShipmentShortQuantity
                                        );
                                        setDamagedQuantity(
                                          row.ShipmentDamagedQuantity === null
                                            ? 0
                                            : row.ShipmentDamagedQuantity
                                        );
                                      }}
                                    >
                                      <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG
                                          src={toAbsoluteUrl(
                                            "/media/svg/icons/Communication/Write.svg"
                                          )}
                                        />
                                      </span>
                                    </a>
                                  );
                                },
                              },
                            ]}
                            rows={ShipmentItems || []}
                          />
                        </div>
                      </div>
                    </Accordion.Collapse>
                  )}
                </Modal.Body>
              </Form>
            </Accordion>{" "}
            <Modal.Footer>
              <button
                type="button"
                onClick={() => {
                  Show(false);
                  if (window.shipmentEditCommingFrom.indexOf("Billing") > -1) {
                    document
                      .getElementsByClassName("text-info")[0]
                      .parentElement.click();
                  }
                  if (
                    window.shipmentEditCommingFrom.indexOf("AssigntoOutbound") >
                    -1
                  ) {
                    document
                      .getElementById("refreshOneMoveMasterButton")
                      .click();
                  }
                  if (document.getElementById("ReLoadTableId"))
                    document.getElementById("ReLoadTableId").click();
                }}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <></>
              {(tab === "1" || tab === "2") && (
                <button
                  type="submit"
                  disabled={!ChargesFunctionOne}
                  onClick={(e) => {
                    window.$OneMoveMasterSaveClose = false;
                    if (!e.target.classList.contains("disabled"))
                      handleSubmit();
                  }}
                  className="btn btn-primary btn-elevate SaveDisabled disabled"
                >
                  Save
                </button>
              )}
              {(tab === "1" || tab === "2") && (
                <button
                  type="submit"
                  disabled={!ChargesFunctionOne}
                  onClick={(e) => {
                    window.$OneMoveMasterSaveClose = true;

                    if (!e.target.classList.contains("disabled"))
                      handleSubmit();
                  }}
                  className="btn btn-primary btn-elevate SaveDisabled disabled"
                >
                  Save & Close
                  {!ChargesFunctionOne && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              )}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}

function ShipmentHeader({
  values,
  setFieldValue,
  Customers = [],
  BillTo = [],
  TrackingDevices = [],
  Shippers = [],
  Consignee = [],
  Commodities = [],
  outboundUrl,
  ShipmentObj,
  setReloadCalcF,
  setChargesFunctionModel,
  statSHipmentId,
  Services,
  setStatus,
}) {
  const [CustomersDetail, setCustomersDetail] = useState(obj);
  const [BillToIdDetail, setBillToIdDetail] = useState(obj);
  const [ShipperDetail, setShipperDetail] = useState(obj);
  const [ConsigneeIdDetail, setConsigneeIdDetail] = useState(obj);
  const [Modes, setModes] = useState(catchError);
  const [ServiceLevels, setServiceLevels] = useState(catchError);

  const [EquipmentTypes, setEquipmentTypes] = useState(catchError);
  const [EquipmentSizes, setEquipmentSizes] = useState(catchError);
  const [appointmentToDate, setAppointmentDateTo] = useState(null);
  const [puAppointmentToDate, setPuAppointmentDateTo] = useState(null);

  const [isPalletized, setIsPalletized] = useState(false);

  function OpenChargesModel() {
    var sum = 0;
    (values?.Charges || []).forEach((x) => {
      sum += x?.Quantity;
    });

    if (values?.Charges?.length > 0 && sum > 0) setChargesFunctionModel(true);
    else setReloadCalcF();
  }

  if (values?.AppointmentTo && appointmentToDate === null) {
    const appToDate = moment(
      momentDate(values?.AppointmentTo),
      "MM-DD-YYYY, hh:mm A"
    ).format("HH:mm:ss");
    window.$appDateTo = appToDate;
    setAppointmentDateTo(appToDate);
  }

  if (values?.PuAppointmentTo && puAppointmentToDate === null) {
    const appToDate = moment(
      momentDate(values?.PuAppointmentTo),
      "MM-DD-YYYY, hh:mm A"
    ).format("HH:mm:ss");
    window.$puAppDateTo = appToDate;
    setPuAppointmentDateTo(appToDate);
  }

  useEffect(() => {
    if (values?.CustomerId) {
      var b = Customers.find((x) => x.Id == values?.CustomerId);
      setCustomersDetail(b);
    }
  }, [values?.CustomerId, Customers]);

  useEffect(() => {
    if (values?.BillToId) {
      var b = BillTo.find((x) => x.Id == values?.BillToId);
      setBillToIdDetail(b);
    }
  }, [values?.BillToId, BillTo]);

  useEffect(() => {
    if (values?.ShipperId) {
      var b = Shippers.find((x) => x.Id == values?.ShipperId);
      setShipperDetail(b);
    }
  }, [values?.ShipperId, Shippers]);

  useEffect(() => {
    if (values?.ConsigneeId) {
      var b = Consignee.find((x) => x.Id == values?.ConsigneeId);
      setConsigneeIdDetail(b);
    }
  }, [values?.ConsigneeId, Consignee]);

  useEffect(() => {
    if (values?.ServiceId) {
      var b = Services.find((x) => x.Id === values?.ServiceId);
      setModes(b?.Modes || []);
      setStatus([]);
    }
  }, [values?.ServiceId, Services]);

  useEffect(() => {
    if (values?.ModeId) {
      var b = Modes.find((x) => x.Id == values?.ModeId);
      setServiceLevels(b?.ServiceLevels || []);
      setStatus(b?.Status || []);

      var el = document.querySelector(`[name="ModeId"]`);
      if (el) el.value = values?.ModeId;
    }
  }, [values?.ModeId, Modes]);

  useEffect(() => {
    if (values?.ServiceLevelId) {
      var b = ServiceLevels.find((x) => x.Id == values?.ServiceLevelId);
      setEquipmentTypes(b?.EquipmentTypes || []);
    }
  }, [values?.ServiceLevelId, ServiceLevels]);

  useEffect(() => {
    if (values?.EquipmentTypeId) {
      var b = EquipmentTypes.find((x) => x.Id == values?.EquipmentTypeId);
      setEquipmentSizes(b?.EquipmentSizes || []);
    }
  }, [values?.EquipmentTypeId, EquipmentTypes]);

  useEffect(() => {
    if (values?.PalletType === "Palletized") {
      setIsPalletized(true);
    }
  }, [values?.PalletType]);
  return (
    <div className="form-group row">
      <input type="hidden" id="C_CustomerId" value={values?.CustomerId ?? ""} />
      <input type="hidden" id="C_ServiceId" value={values?.ServiceId ?? ""} />
      <input type="hidden" id="C_ModeId" value={values?.ModeId ?? ""} />
      <input
        type="hidden"
        id="C_ServiceLevelId"
        value={values?.ServiceLevelId ?? ""}
      />
      <input
        type="hidden"
        id="C_EquipmentTypeId"
        value={values?.EquipmentTypeId ?? ""}
      />
      <input
        type="hidden"
        id="C_EquipmentSizeId"
        value={values?.EquipmentSizeId ?? ""}
      />
      <input type="hidden" id="C_PuAppointment" value={values?.PuAppointment} />
      <input type="hidden" id="C_ReceivedDate" value={values?.ReceivedDate} />
      <input type="hidden" id="C_PickedUpDate" value={values?.PickedUpDate} />

      <input type="hidden" id="C_fromCity" value={ShipperDetail?.City ?? ""} />
      <input
        type="hidden"
        id="C_fromAddress"
        value={ShipperDetail?.Address ?? ""}
      />
      <input
        type="hidden"
        id="C_fromState"
        value={ShipperDetail?.State ?? ""}
      />
      <input
        type="hidden"
        id="C_fromZipCode"
        value={ShipperDetail?.ZipCode ?? ""}
      />

      <input
        type="hidden"
        id="C_toCity"
        value={ConsigneeIdDetail?.City ?? ""}
      />
      <input
        type="hidden"
        id="C_toAddress"
        value={ConsigneeIdDetail?.Address ?? ""}
      />
      <input
        type="hidden"
        id="C_toState"
        value={ConsigneeIdDetail?.State ?? ""}
      />
      <input
        type="hidden"
        id="C_toZipCode"
        value={ConsigneeIdDetail?.ZipCode ?? ""}
      />

      <div className="col-lg-6">
        <SelectPagination
          label="Customer"
          name="CustomerId"
          required={true}
          value={{
            value: values?.CustomerId,
            label: Customers.find((x) => x?.Id === values?.CustomerId)
              ? is(Customers.find((x) => x?.Id === values?.CustomerId)?.Code) +
                Customers.find((x) => x?.Id === values?.CustomerId)?.Name
              : "Select...",
          }}
          options={Customers.map((x) => {
            return {
              value: x.Id,
              label: is(x?.Code) + x?.Name,
            };
          })}
          onChangefirst={false}
          onChange={(newValue) => {
            var a = newValue?.value;
            setFieldValue("CustomerId", a);
            if (a) OpenChargesModel();
          }}
        />
        <div>
          {CustomersDetail != null && (
            <div class="form-control h-50 mt-2">
              {CustomersDetail?.Name}
              <br />
              {CustomersDetail?.Address}
              <br />
              {CustomersDetail?.City}, {CustomersDetail?.State},
              {CustomersDetail?.ZipCode}
            </div>
          )}
        </div>
      </div>
      <div className="col-lg-6">
        <SelectPagination
          label="Bill To"
          required={true}
          name="BillToId"
          value={{
            value: values?.BillToId,
            label: BillTo.find((x) => x?.Id === values?.BillToId)
              ? is(BillTo.find((x) => x?.Id === values?.BillToId)?.Code) +
                BillTo.find((x) => x?.Id === values?.BillToId)?.CompanyName
              : "Select...",
          }}
          onChange={(e) => setFieldValue("BillToId", e?.value)}
          options={BillTo.map((x) => {
            return {
              value: x.Id,
              label: is(x?.Code) + x?.CompanyName,
            };
          })}
        />
        <div>
          <div class="form-control h-50 mt-2">
            {BillToIdDetail?.CompanyName}
            <br />
            {BillToIdDetail?.Address}
            <br />
            {BillToIdDetail?.City}, {BillToIdDetail?.State},
            {BillToIdDetail?.ZipCode}
          </div>
        </div>
      </div>
      <div className="col-lg-6 mt-5">
        <Field
          name="CustomerNumber"
          component={Input}
          placeholder="Customer Ref No"
          label="Customer Ref No"
          maxlength="10"
        />
      </div>
      <div className="col-lg-4 mt-5">
        <Field
          name="BillingNumber"
          component={Input}
          placeholder="Billing Ref No"
          label="Billing Ref No"
        />
      </div>
      <div className="col-lg-2 mt-5">
        <Field
          name="BillingRateCalculated"
          component={Input}
          placeholder="Total"
          label="Total"
          disabled
        />
      </div>
      <div className="col-lg-6 mt-5">
        <CreatableSelect3
          label="Service"
          name="ServiceId"
          valueSelect={values?.ServiceId}
          setFieldValue={setFieldValue}
          options={Services.map((x) => {
            return {
              value: x.Id,
              label: is(x?.Code) + x?.Name,
            };
          })}
          onChangefirst={false}
          onChange={(newValue) => {
            var a = newValue?.value;
            setFieldValue("ModeId", undefined);
            setFieldValue("ServiceLevelId", undefined);
            setFieldValue("EquipmentTypeId", undefined);
            setFieldValue("EquipmentSizeId", undefined);
            if (a) OpenChargesModel();
          }}
        />
      </div>
      {Modes.length > 0 && (
        <div className="col-lg-6 mt-5">
          <label>Mode</label>
          <Select
            value={{
              value: values?.ModeId,
              label:
                Modes.find((x) => x.Id === values?.ModeId)?.Name || "Select...",
            }}
            name="ModeId"
            className="w-100 mr-2"
            onChange={(newValue) => {
              var a = newValue?.value;
              setFieldValue("ModeId", a);
              setFieldValue("ServiceLevelId", undefined);
              setFieldValue("EquipmentTypeId", undefined);
              setFieldValue("EquipmentSizeId", undefined);

              if (a) OpenChargesModel();
            }}
            options={(Modes ?? []).map((x) => {
              return { value: x.Id, label: x.Name };
            })}
          />
        </div>
      )}
      {Modes.length > 0 && ServiceLevels.length > 0 && (
        <div className="col-lg-6 mt-5">
          <label>Service Level</label>
          <Select
            value={{
              value: values?.ServiceLevelId,
              label:
                ServiceLevels.find((x) => x.Id === values?.ServiceLevelId)
                  ?.Name || "Select...",
            }}
            name="ServiceLevelId"
            className="w-100 mr-2"
            onChange={(newValue) => {
              var a = newValue?.value;
              setFieldValue("ServiceLevelId", a);
              setFieldValue("EquipmentTypeId", undefined);
              setFieldValue("EquipmentSizeId", undefined);
              if (a) OpenChargesModel();
            }}
            options={(ServiceLevels ?? []).map((x) => {
              return { value: x.Id, label: x.Name };
            })}
          />
        </div>
      )}
      {Modes.length > 0 &&
        ServiceLevels.length > 0 &&
        EquipmentTypes.length > 0 && (
          <div className="col-lg-6 mt-5">
            <label>Equipment Types</label>
            <Select
              value={{
                value: values?.EquipmentTypeId,
                label:
                  EquipmentTypes.find((x) => x.Id === values?.EquipmentTypeId)
                    ?.Name || "Select...",
              }}
              className="w-100 mr-2"
              name="EquipmentTypeId"
              onChange={(newValue) => {
                var a = newValue?.value;
                setFieldValue("EquipmentTypeId", a);
                setFieldValue("EquipmentSizeId", undefined);
                if (a) OpenChargesModel();
              }}
              options={(EquipmentTypes ?? []).map((x) => {
                return { value: x.Id, label: x.Name };
              })}
            />
          </div>
        )}
      {Modes.length > 0 &&
        ServiceLevels.length > 0 &&
        EquipmentTypes.length > 0 &&
        EquipmentSizes.length > 0 && (
          <div className="col-lg-6 mt-5">
            <label>Equipment Sizes</label>
            <Select
              value={{
                value: values?.EquipmentSizeId,
                label:
                  EquipmentSizes.find((x) => x.Id === values?.EquipmentSizeId)
                    ?.Name || "Select...",
              }}
              name="EquipmentSizeId"
              className="w-100 mr-2"
              onChange={(newValue) => {
                var a = newValue?.value;
                setFieldValue("EquipmentSizeId", a);
                if (a) OpenChargesModel();
              }}
              options={(EquipmentSizes ?? []).map((x) => {
                return { value: x.Id, label: x.Name };
              })}
            />
          </div>
        )}
      <div className="col-lg-6 mt-5">
        <SelectDrop
          name="PalletType"
          label="Load type"
          className="form-control"
          onChange={(e) => {
            if (e.target.value === "Palletized") {
              setIsPalletized(true);
            } else {
              setIsPalletized(false);
            }
            setFieldValue("PalletType", e.target.value);
          }}
        >
          <option value=""></option>
          <option value="Floor loaded">Floor loaded</option>
          <option value="Palletized">Palletized</option>
        </SelectDrop>
      </div>

      {/* Pallet Type Section */}
      {isPalletized && (
        <>
          <div className="col-lg-6 mt-5">
            <SelectDrop
              name="PalletLoadType"
              label="Pallet Type"
              className="form-control"
            >
              <option value="">Select</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="Misc">Misc</option>
            </SelectDrop>
          </div>
          <div className="col-lg-6 mt-5">
            <Field
              id="PalletCount"
              name="PalletCount"
              type="number"
              min="0"
              component={Input}
              placeholder="Pallet Count"
              label="PalletCount"
              onChange={(e) => {
                setFieldValue("PalletCount", e.target.value);
              }}
            />
          </div>
        </>
      )}

      <div className="col-lg-6 mt-5"></div>
      <div className="m-auto pt-5 pb-5 col-lg-11 MuiInputBase-root MuiInput-root MuiInput-underline Mui-error Mui-error MuiInputBase-formControl MuiInput-formControl" />
      <div className="col-lg-6 mt-5 pt-5">
        <SelectPagination
          label="Pickup Location"
          name="ShipperId"
          value={{
            value: values?.ShipperId,
            label: Shippers.find((x) => x?.Id === values?.ShipperId)
              ? is(Shippers.find((x) => x?.Id === values?.ShipperId)?.Code) +
                Shippers.find((x) => x?.Id === values?.ShipperId)?.CompanyName
              : "Select...",
          }}
          options={Shippers.map((x) => {
            return {
              value: x.Id,
              label: is(x?.Code) + x?.CompanyName,
            };
          })}
          onChangefirst={false}
          onChange={(newValue) => {
            var a = newValue?.value;
            setFieldValue("ShipperId", a);
            setTimeout(() => {
              if (a) OpenChargesModel();
            }, 500);
          }}
        />
        <div>
          {ShipperDetail != null && (
            <div class="form-control h-50 mt-2">
              {ShipperDetail?.CompanyName}
              <br />
              {ShipperDetail?.Address}
              <br />
              {ShipperDetail?.City}, {ShipperDetail?.State},
              {ShipperDetail?.ZipCode}
            </div>
          )}
        </div>
      </div>

      <div className="col-lg-6 mt-5 pt-5">
        <SelectPagination
          label="Ship to Location"
          name="ConsigneeId"
          value={{
            value: values?.ConsigneeId,
            label: Consignee.find((x) => x?.Id === values?.ConsigneeId)
              ? is(Consignee.find((x) => x?.Id === values?.ConsigneeId)?.Code) +
                Consignee.find((x) => x?.Id === values?.ConsigneeId)
                  ?.CompanyName
              : "Select...",
          }}
          options={Consignee.map((x) => {
            return {
              value: x.Id,
              label: is(x?.Code) + x?.CompanyName,
            };
          })}
          onChangefirst={false}
          onChange={(newValue) => {
            var a = newValue?.value;
            setFieldValue("ConsigneeId", a);
            setTimeout(() => {
              if (a) OpenChargesModel();
            }, 500);
          }}
        />
        <div>
          <div class="form-control h-50 mt-2">
            {ConsigneeIdDetail?.CompanyName}
            <br />
            {ConsigneeIdDetail?.Address}
            <br />
            {ConsigneeIdDetail?.City}, {ConsigneeIdDetail?.State},
            {ConsigneeIdDetail?.ZipCode}
          </div>
        </div>
      </div>
      <div className="col-lg-6 mt-5">
        <Field
          name="ShipperNumber"
          component={Input}
          placeholder="Shipper Ref No"
          label="Shipper Ref No"
        />
      </div>
      <div className="col-lg-6 mt-5">
        <Field
          name="ConsigneeNumber"
          component={Input}
          placeholder="Consignee Ref No"
          label="Consignee Ref No"
        />
      </div>
      <div className="col-lg-6 mt-5">
        <DateTime30
          label="Requested Pick Up Date"
          name="RequestedPickUpDate"
          IdName="OneMoveMaster"
          defaultvalue={values?.RequestedPickUpDate}
          setFieldValue={setFieldValue}
          className="w-100 pt-2"
        />
      </div>
      <div className="col-lg-4 mt-5">
        <DateTime30
          label="Appointment Date"
          required={false}
          name="AppointmentDate"
          defaultvalue={values?.AppointmentDate}
          setFieldValue={setFieldValue}
          className="w-100 pt-2"
        />
      </div>
      <div className="col-lg-2 mt-5">
        <SelectDrop
          value={appointmentToDate || "0"}
          name="AppointmentTo"
          label="To Time"
          id="AppointmentToValue"
          className="form-control"
          onChange={(e) => {
            window.$appDateTo = PostMomentTimeOnly(e.target.value);
            setAppointmentDateTo(e.target.value);
          }}
        >
          <DateTime30minInterval />
        </SelectDrop>
      </div>
      <div className="col-lg-6 mt-5">
        <DateTime30
          label="Last Ship Date"
          required={true}
          name="LastShipDate"
          IdName="OneMoveMaster"
          defaultvalue={values?.LastShipDate}
          setFieldValue={setFieldValue}
          className="w-100 pt-2"
          onChange={(e) => {
            $("#LastShipDateDateId").val(e.target.value);
          }}
        />
      </div>
      <div className="col-lg-6 mt-5">
        <DateTime30
          label="E.T.A"
          required={CustomersDetail?.Id === 112}
          name="EstimatedArrivalDate"
          defaultvalue={values?.EstimatedArrivalDate}
          setFieldValue={setFieldValue}
          className="w-100 pt-2"
        />
      </div>
      <div className="col-lg-4 mt-5">
        <DateTime30
          label="P/U Appointment"
          name="PuAppointment"
          IdName="OneMoveMaster"
          defaultvalue={values?.PuAppointment}
          setFieldValue={setFieldValue}
          className="w-100 pt-2"
          onChange={(e) => {
            $("#PickupAppointmentDateDateId").val(e.target.value);
          }}
        />
      </div>
      <div className="col-lg-2 mt-5">
        <SelectDrop
          value={puAppointmentToDate}
          name="PuAppointmentTo"
          label="To Time"
          className="form-control"
          onChange={(e) => {
            window.$puAppDateTo = e.target.value;
            setFieldValue(
              "PuAppointmentTo",
              PostMomentTimeOnly(e.target.value)
            );
            setPuAppointmentDateTo(e.target.value);
          }}
        >
          <DateTime30minInterval />
        </SelectDrop>
      </div>
      <div className="col-lg-6 mt-5">
        <DateTime30
          label="Actual Delivery"
          required={false}
          name="ActualDelivery"
          defaultvalue={values?.ActualDelivery}
          setFieldValue={setFieldValue}
          className="w-100 pt-2"
        />
      </div>
      <div className="col-lg-6 mt-5" />
      <div className="col-lg-6 mt-5">
        <DateTime30
          label="Proof of Delivery"
          required={false}
          name="ProofOfDelivery"
          defaultvalue={values?.ProofOfDelivery}
          setFieldValue={setFieldValue}
          className="w-100 pt-2"
        />
      </div>
      <div className="m-auto pt-5 pb-5 col-lg-11 MuiInputBase-root MuiInput-root MuiInput-underline Mui-error Mui-error MuiInputBase-formControl MuiInput-formControl" />
      <div className="col-lg-6 mt-5">
        <CreatableSelect3
          label="Commodity"
          name="CommodityId"
          valueSelect={values?.CommodityId}
          setFieldValue={setFieldValue}
          options={Commodities.map((x) => {
            return {
              value: x.Id,
              label: is(x?.Code) + x?.Description,
            };
          })}
          onChange={(newValue) => {
            var i = newValue?.value;
            var b = Commodities.find((x) => x.Id == i);
            SetValuefunction("LTLClassId", b?.Class);
            SetValuefunction("NMFCId", b?.NMFC);
          }}
        />
      </div>
      <div
        className={
          "col-lg-3 mt-5 " +
          (window.location.pathname.split("/")[2] !== "FindOrders" &&
            "col-lg-6")
        }
      >
        <Field
          name="EnteredBy"
          component={Input}
          placeholder="Entered By"
          label="Entered By"
          disabled
        />
      </div>
      {window.location.pathname.split("/")[2] === "FindOrders" && (
        <div className="col-lg-3 mt-5">
          <Field
            name="OrderNo"
            component={Input}
            placeholder="Order No."
            label="Order No."
            disabled
            value={window.location.pathname.split("/")[3]}
          />
        </div>
      )}
      <div className="col-lg-3 mt-5">
        <Field
          id="LTLClassId"
          name="LTLClass"
          component={Input}
          placeholder="LTL Class"
          label="LTL Class"
          disabled
        />
      </div>
      <div className="col-lg-3 mt-5">
        <Field
          id="NMFCId"
          disabled
          name="NMFC"
          component={Input}
          placeholder="NMFC#"
          label="NMFC#"
        />
      </div>
      <div className="col-lg-6 mt-5">
        <CreatableSelect3
          label="Tracking Device"
          name="DeviceId"
          valueSelect={values?.DeviceId}
          setFieldValue={setFieldValue}
          options={TrackingDevices.map((x) => {
            return {
              value: x.Id,
              label: x?.TrackiId + " - " + x?.Name,
            };
          })}
        />
      </div>
      <div className="col-lg-6 mt-1" />
      <div className="col-lg-12 mt-1" />
      <div className="col-lg-6 mt-5">
        <Field
          name="Weight"
          component={Input}
          placeholder="Weight"
          label="Weight"
          disabled
        />
      </div>
      <div className="col-lg-3 mt-5">
        <Field
          name="Cube"
          component={Input}
          placeholder="Cube"
          label="Cube"
          disabled
        />
      </div>
      <div className="col-lg-3 mt-5">
        <Field
          name="Pieces"
          component={Input}
          placeholder="Unit Counts"
          label="Unit Counts"
          disabled
        />
      </div>
      <div className="col-lg-6 mt-1"></div>
      <div className="m-auto pt-5 pb-5 col-lg-11 MuiInputBase-root MuiInput-root MuiInput-underline Mui-error Mui-error MuiInputBase-formControl MuiInput-formControl" />
      <div className="col-lg-12 mt-5">
        <Link
          target="_blank"
          className="btn btn-info btn-elevate mr-5"
          to={`/report/shipment/bol/${statSHipmentId}`}
          style={{ color: "white" }}
        >
          B/L
        </Link>
        <Link
          target="_blank"
          className="btn btn-info btn-elevate mr-5"
          to={`/report/shipment/profitloss/${statSHipmentId}`}
          style={{ color: "white" }}
        >
          P & L
        </Link>
        {ShipmentObj !== null && (
          <Link
            target="_blank"
            className="btn btn-info btn-elevate "
            to={outboundUrl}
            style={{ color: "white" }}
          >
            Outbound
          </Link>
        )}
      </div>
    </div>
  );
}

function CheckStatus(Status, Name) {
  try {
    if (Status?.length === 0) return false;
    return Status.filter((x) => x?.Name === Name).length > 0;
  } catch (error) {
    return false;
  }
}
function DatesComponent({ values, setFieldValue, Status = [], isTms }) {
  const [StatusLock, setStatusLock] = useState([]);

  useEffect(() => {
    setStatusLock(Status || []);
  }, [Status]);

  return (
    <div className="form-group row">
      {StatusLock?.length === 0 && (
        <div className="col-lg-12">
          <div class="row mt-5 pt-5">
            <div class="col-lg-3"></div>
            <div class="col-lg-1 text-right">
              <i
                class="fas fa-quote-left"
                style={{
                  fontAize: "70px",
                  opacity: "0.3",
                  fontSize: "70px",
                  marginTop: "17px",
                }}
              ></i>
            </div>
            <div class="col-lg-5 mt-2">
              <p class="h1 mt-5 text-center" style={{ opacity: "0.3" }}>
                Please change the mode to display dates.
              </p>
            </div>
            <div class="col-lg-3"></div>
          </div>
        </div>
      )}
      {CheckStatus(StatusLock, "Planned") && isTms === true ? (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusPlannedDate"
            label="Planned"
            defaultvalue={values?.StatusPlannedDate}
            disabled={values?.IsManager ? false : !values?.StatusPlannedDate}
            setFieldValue={setFieldValue}
          />
        </div>
      ) : (
        ""
      )}
      {CheckStatus(StatusLock, "Loading") && isTms === true ? (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="TraillerDropDate"
            label="Trailler Drop Date"
            defaultvalue={values?.TraillerDropDate}
            disabled={values?.IsManager ? false : !values?.TraillerDropDate}
            setFieldValue={setFieldValue}
          />
        </div>
      ) : (
        ""
      )}
      {CheckStatus(StatusLock, "Loading") && isTms === true ? (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="Shipping Arrival Date"
            label="Shipping Arrival Date"
            defaultvalue={values?.ShippingArrivalDate}
            disabled={values?.IsManager ? false : !values?.ShippingArrivalDate}
            setFieldValue={setFieldValue}
          />
        </div>
      ) : (
        ""
      )}
      {CheckStatus(StatusLock, "Notify Trucker") && isTms === true ? (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusNotifyTrucker"
            label="Status Notify Trucker"
            defaultvalue={values?.StatusNotifyTrucker}
            disabled={values?.IsManager ? false : !values?.StatusNotifyTrucker}
            setFieldValue={setFieldValue}
          />
        </div>
      ) : (
        ""
      )}
      {CheckStatus(StatusLock, "Notify Trucker") && isTms === true ? (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusCheckedInDate"
            label="Status Checked In Date"
            defaultvalue={values?.StatusCheckedInDate}
            disabled={values?.IsManager ? false : !values?.StatusCheckedInDate}
            setFieldValue={setFieldValue}
          />
        </div>
      ) : (
        ""
      )}
      {CheckStatus(StatusLock, "Quoted") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusQuotedDate"
            label="Quoted"
            defaultvalue={values?.StatusQuotedDate}
            disabled={values?.IsManager ? false : !values?.StatusQuotedDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "New") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusNewDate"
            label="New"
            defaultvalue={values?.StatusNewDate}
            disabled={values?.IsManager ? false : !values?.StatusNewDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {(CheckStatus(StatusLock, "Booked") ||
        CheckStatus(StatusLock, "Assigned")) && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusBookedDate"
            label="Booked"
            defaultvalue={values?.StatusBookedDate}
            disabled={values?.IsManager ? false : !values?.StatusBookedDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Sent") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusSentDate"
            label="Sent"
            defaultvalue={values?.StatusSentDate}
            disabled={values?.IsManager ? false : !values?.StatusSentDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Dispatched") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusDispatchedDate"
            label="Dispatched"
            defaultvalue={values?.StatusDispatchedDate}
            disabled={values?.IsManager ? false : !values?.StatusDispatchedDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Loading") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusLoadingFromOurWarehouseDate"
            label="Loading From Our Warehouse Date"
            defaultvalue={values?.StatusLoadingFromOurWarehouseDate}
            disabled={
              values?.IsManager
                ? false
                : !values?.StatusLoadingFromOurWarehouseDate
            }
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Loaded") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusLoadedFromOurWarehouseDate"
            label="Loaded From Our Warehouse Date"
            defaultvalue={values?.StatusLoadedFromOurWarehouseDate}
            disabled={
              values?.IsManager
                ? false
                : !values?.StatusLoadedFromOurWarehouseDate
            }
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Released") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusReleasedDate"
            label="Released"
            defaultvalue={values?.StatusReleasedDate}
            disabled={values?.IsManager ? false : !values?.StatusReleasedDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Appointed") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusAppointedDate"
            label="Appointed"
            defaultvalue={values?.StatusAppointedDate}
            disabled={values?.IsManager ? false : !values?.StatusAppointedDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Loading From Our Warehouse") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusLoadingFromOurWarehouseDate"
            label="Loading From Our Warehouse"
            defaultvalue={values?.StatusLoadingFromOurWarehouseDate}
            disabled={
              values?.IsManager
                ? false
                : !values?.StatusLoadingFromOurWarehouseDate
            }
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Loaded From Our Warehouse") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusLoadedFromOurWarehouseDate"
            label="Loaded From Our Warehouse"
            defaultvalue={values?.StatusLoadedFromOurWarehouseDate}
            disabled={
              values?.IsManager
                ? false
                : !values?.StatusLoadedFromOurWarehouseDate
            }
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Picked Up") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusPickedUpDate"
            label="Picked Up"
            defaultvalue={values?.StatusPickedUpDate}
            disabled={values?.IsManager ? false : !values?.StatusPickedUpDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}

      {CheckStatus(StatusLock, "In Gate At Origin Hub") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusInGateAtOriginHubDate"
            label="In Gate At Origin Hub"
            defaultvalue={values?.StatusInGateAtOriginHubDate}
            disabled={
              values?.IsManager ? false : !values?.StatusInGateAtOriginHubDate
            }
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "In Transit") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusInTransitDate"
            label="In Transit"
            defaultvalue={values?.StatusInTransitDate}
            disabled={values?.IsManager ? false : !values?.StatusInTransitDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Arrived Destination Hub") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusArrivedDestinationHubDate"
            label="Arrived Destination Hub"
            defaultvalue={values?.StatusArrivedDestinationHubDate}
            disabled={
              values?.IsManager
                ? false
                : !values?.StatusArrivedDestinationHubDate
            }
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Delivered To Consignee") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusDeliveredToConsigneeDate"
            label="Delivered To Consignee"
            defaultvalue={values?.StatusDeliveredToConsigneeDate}
            disabled={
              values?.IsManager
                ? false
                : !values?.StatusDeliveredToConsigneeDate
            }
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Delivered") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusDeliveredDate"
            label="Delivered"
            defaultvalue={values?.StatusDeliveredDate}
            disabled={values?.IsManager ? false : !values?.StatusDeliveredDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}

      {CheckStatus(StatusLock, "Empty") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusEmptyDate"
            label="Empty"
            defaultvalue={values?.StatusEmptyDate}
            disabled={values?.IsManager ? false : !values?.StatusEmptyDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Terminated") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusTerminatedDate"
            label="Terminated"
            defaultvalue={values?.StatusTerminatedDate}
            disabled={values?.IsManager ? false : !values?.StatusTerminatedDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {CheckStatus(StatusLock, "Closed") && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusClosedDate"
            label="Closed"
            defaultvalue={values?.StatusClosedDate}
            disabled={values?.IsManager ? false : !values?.StatusClosedDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {(CheckStatus(StatusLock, "Delivered Live Unload - closed once billed") ||
        CheckStatus(StatusLock, "Delivered Drop")) && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusDeliveredDate"
            label="Delivered Date"
            defaultvalue={values?.StatusDeliveredDate}
            disabled={values?.IsManager ? false : !values?.StatusDeliveredDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
      {(CheckStatus(StatusLock, "Empty Picked UP - closed once billed") ||
        CheckStatus(StatusLock, "Empty at Delivery")) && (
        <div className="col-lg-6 my-5">
          <DateTime30
            name="StatusEmptyDate"
            label="Empty Date "
            defaultvalue={values?.StatusEmptyDate}
            disabled={values?.IsManager ? false : !values?.StatusEmptyDate}
            setFieldValue={setFieldValue}
          />
        </div>
      )}
    </div>
  );
}

function listdistinctFunction(array, Type, Show, propertyName) {
  if (Show === false) return [];
  propertyName = "Id";
  if (Type !== "info") {
    return array.filter(
      (e, i) =>
        array.findIndex((a) => a[propertyName] === e[propertyName]) === i
    );
  } else {
    return array.filter(
      (e, i) =>
        array.findIndex((a) => a[propertyName] === e[propertyName]) === i
    );
  }
}

const ChargesFunction = ({
  isInbound,
  isPayable,
  type,
  list = [],
  ReloadCalc,
  setFieldValue,
  isVariableOpen = isVariableOpenDefault,
}) => {
  const [ChargeModel, setChargeModel] = useState({
    Show: false,
    Type: "success",
  });
  const [Charges, setCharges] = useState(list);
  const [ChargesRemove, setChargesRemove] = useState([]);
  const [ChargesN, setChargesN] = useState(catchError);
  const [loadingCharges, setloadingCharges] = useState(true);
  const [FilterCharges, setFilterCharges] = useState("");
  const [AutoRatingModal, setAutoRatingModal] = useState(false);
  const [OnlyRate, setOnlyRate] = useState([]);
  const [AutoRatingCheck, setAutoRatingCheck] = useState([]);
  const [NoteModal, setNoteModal] = useState({
    Show: false,
    Id: 0,
    Code: "",
  });

  useEffect(() => {
    GetPayableForVendor({ button: false });
  }, [ReloadCalc]);

  function FieldID(name) {
    return document.getElementById(name)?.value ?? "";
  }

  const GetPayableForVendor = ({ button }) => {
    setloadingCharges(true);

    const customerId = FieldID("C_CustomerId");
    const serviceId = FieldID("C_ServiceId");
    const modeId = FieldID("C_ModeId");
    const serviceLevelId = FieldID("C_ServiceLevelId");
    const equipmentTypeId = FieldID("C_EquipmentTypeId");
    const equipmentSizeId = FieldID("C_EquipmentSizeId");
    const date = FieldID("C_PuAppointment");
    const CarrierId = FieldID("C_CarrierId");

    const fromCity = FieldID("C_fromCity");
    const fromAddress = FieldID("C_fromAddress");
    const fromState = FieldID("C_fromState");
    const fromZipCode = FieldID("C_fromZipCode");

    const toCity = FieldID("C_toCity");
    const toAddress = FieldID("C_toAddress");
    const toState = FieldID("C_toState");
    const toZipCode = FieldID("C_toZipCode");

    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-FP-API-KEY", "iphone");
      var requestOptions = {
        method: "GET",
        withCredentials: true,
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        window.$apiurl +
          `/ordersRating/GetCharges?customerId=${customerId}&serviceId=${serviceId}&serviceLevelId=${serviceLevelId}&ModeId=${modeId}&isInbound=${isInbound}&isPayable=${isPayable}&carrierId=${CarrierId}&equipmentTypeId=${equipmentTypeId}&equipmentSizeId=${equipmentSizeId}&fromCity=${fromCity}&fromAddress=${fromAddress}&fromState=${fromState}&fromZipCode=${fromZipCode}&toCity=${toCity}&toAddress=${toAddress}&toState=${toState}&toZipCode=${toZipCode}&date=${date}`,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (result) => {
          try {
            setChargesN([]);

            var thisList = window["List" + type];
            let chargesVar = thisList;

            chargesVar = CheckRateInCharges(
              chargesVar,
              result,
              window.C_ChargesReadOnly,
              setChargesRemove
            );

            let objCalc = [];
            if (button) objCalc = await GetPayableForVendorCalc();
            else
              objCalc =
                chargesVar?.length === 0 ? await GetPayableForVendorCalc() : [];

            objCalc = RemoveDuplicate(chargesVar, objCalc);

            chargesVar = JSON.parse(JSON.stringify(chargesVar));
            chargesVar.forEach((x) => {
              x.Id2 = x?.Id;
              x.Id = x?.ChargeId;
            });
            let allObjs = chargesVar.concat(objCalc);

            let resArr = [];
            allObjs.filter(function(item) {
              resArr.push({ ...item, ChargeId: item?.Id });
            });

            var a = [];
            (result || []).forEach((x) => {
              var i = allObjs.find((y) => y?.Id === x?.Id);
              if (i === undefined) a.push(x);
            });

            //get auto rate
            resArr = (await StartAutoRate(resArr, "")) ?? [];

            setCharges(resArr || []);
            setChargesN(a || []);
            setloadingCharges(false);
            SaveDisabledFunction(false);

            if (button)
              setTimeout(() => {
                document.getElementById("AutoRatingBtn").click();
              }, 1000);
          } catch (err) {
            console.error(err);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {}
  };

  async function GetPayableForVendorCalc() {
    if (window.C_ChargesReadOnly === true) return [];
    const customerId = FieldID("C_CustomerId");
    const serviceId = FieldID("C_ServiceId");
    const modeId = FieldID("C_ModeId");
    const serviceLevelId = FieldID("C_ServiceLevelId");
    const equipmentTypeId = FieldID("C_EquipmentTypeId");
    const equipmentSizeId = FieldID("C_EquipmentSizeId");
    const date = FieldID("C_PuAppointment");
    const CarrierId = FieldID("C_CarrierId");

    const fromCity = FieldID("C_fromCity");
    const fromAddress = FieldID("C_fromAddress");
    const fromState = FieldID("C_fromState");
    const fromZipCode = FieldID("C_fromZipCode");

    const toCity = FieldID("C_toCity");
    const toAddress = FieldID("C_toAddress");
    const toState = FieldID("C_toState");
    const toZipCode = FieldID("C_toZipCode");

    return await fetch(
      window.$apiurl +
        `/ordersRating/GetCalculatedCharges?customerId=${customerId}&serviceId=${serviceId}&serviceLevelId=${serviceLevelId}&ModeId=${modeId}&isInbound=${isInbound}&isPayable=${isPayable}&carrierId=${CarrierId}&equipmentTypeId=${equipmentTypeId}&equipmentSizeId=${equipmentSizeId}&fromCity=${fromCity}&fromAddress=${fromAddress}&fromState=${fromState}&fromZipCode=${fromZipCode}&toCity=${toCity}&toAddress=${toAddress}&toState=${toState}&toZipCode=${toZipCode}&date=${date}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = [];
        res[1].map((x) => a.push({ ...x, Calc: true }));
        return a;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // function SaveDisabledFun(msg) {
  //   alert(msg);
  // }

  function CalcTotal() {
    var sum = 0;
    $(`.RatesTotal-${type}`).each(function() {
      var a = Number(
        $(this)
          .find("input")
          .val()
      );
      $(this)
        .find("input")
        .val(a.toFixed(2));
      sum += a;
    });
    $(`.RateAllTotal-${type} span`).text(AddSpace3(sum));

    document.querySelectorAll(`.Charges2 span`).forEach((element) => {
      var category = element.getAttribute("data-Name");
      var invoice = element.getAttribute("data-Invoice");
      var filter = Charges.filter(
        (x) => x?.Category === category && x?.Invoice === invoice
      );
      var a = filter.reduce(
        (total, x) =>
          CalculateCharge({
            Rate: x?.Rate,
            Quantity: RoundToFixed(x?.Quantity ?? 0, x?.Rounding),
            rateType: x?.RateType,
            MinCharge: x?.MinCharge,
            MaxCharge: x?.MaxCharge,
            FreeTime: x?.FreeTime,
          }) + total,
        0
      );
      element.innerText = "Sub Total : " + AddSpace3(a);

      var str = `.Charges-${RemoveSpace(category)}-${RemoveSpace(invoice)}`;
      SetSubTotal(a, str);
    });
  }

  useEffect(() => {
    let a = [];
    Charges.map((x) =>
      a.push({
        ...x,
        ChargeId: x?.ChargeId || x?.Id,
        Code: x?.Code,
        Name: x?.Name,
        Quantity: x?.Quantity || 0,
        Category: x?.Category,
        Rate: x?.Rate,
        Position: x?.Position,
        CategoryPosition: x?.CategoryPosition,
        OriAddSup: x?.OriAddSup,
        Invoice: x?.Invoice || "",
        InvoiceId: +x?.InvoiceId || null,
        IsReadOnly: x?.IsReadOnly || false,
        Note: x?.Note,
        MinCharge: x?.MinCharge,
        MaxCharge: x?.MaxCharge,
        FreeTime: x?.FreeTime,
        RateType: x?.RateType,
        Calcul: x?.Calcul,
        DateEnd: x?.DateEnd,
        DateStart: x?.DateStart,
        QuoteId: x?.QuoteId,
        Rounding: x?.Rounding,
        CalculName: x?.CalculName,
      })
    );
    window["List" + type] = Charges;
    if (type === "Charges") setFieldValue("Charges", a);
    CalcTotal();
  }, [Charges, ChargesN]);

  function CheckHidden(param1, param2) {
    return param1?.toLowerCase()?.includes(param2?.toLowerCase())
      ? "row"
      : "d-none";
  }

  useEffect(() => {
    if (ChargeModel.Show === false) {
      setFilterCharges("");
    }
  }, [ChargeModel]);

  return (
    <div className="row RatesParent">
      <Modal
        size="lg"
        show={ChargeModel.Show}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            className="w-100 row px-5"
          >
            <div className="col-lg-4">Customer Charges</div>
            <div className="col-lg-8">
              <input
                className="form-control"
                placeholder="Filter by Name and Code"
                onChange={(e) => setFilterCharges(e.target.value?.trim() ?? "")}
              />
            </div>
          </Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={() => setChargeModel({ Show: false, Type: "success" })}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          {listdistinctFunction(
            ChargeModel?.Type === "info" ? Charges : ChargesN,
            ChargeModel?.Type,
            ChargeModel?.Show
          )?.map((x, index) => (
            <div
              key={x?.Code + "" + x?.Id + "" + index}
              className={CheckHidden(x?.Name + "," + x?.Code, FilterCharges)}
            >
              <AutoRatingItem
                key={index}
                x={x}
                index={index}
                isAddCharges={true}
                AutoRatingCheck={AutoRatingCheck}
                ChargesId={""}
              />
              <div className={`col-lg-4 mt-5 mb-5`}>
                <b className={`text-black title mr-2`}>
                  {" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="products-edit-tooltip">{x.Code}</Tooltip>
                    }
                  >
                    <a className="btn btn-icon btn-light btn-hover-light btn-sm mr-3">
                      <span className="svg-icon svg-icon-md svg-icon-info">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Info-circle.svg"
                          )}
                        />
                      </span>
                    </a>
                  </OverlayTrigger>
                  {x?.Name}
                </b>
              </div>
              <div className={`col-lg-3 mt-2`}>
                <input
                  className="form-control"
                  disabled={true}
                  defaultValue={x?.Rate}
                />
              </div>
              <div className={`col-lg-3 pl-1 pr-5 mt-2`}>
                <input
                  className="form-control"
                  id={"ChargeN" + index}
                  defaultValue={x?.Quantity ?? 0}
                />{" "}
                <input type="hidden" id={"DateStart" + index} />
                <input type="hidden" id={"DateEnd" + index} />
              </div>
              <div className={`col-lg-2 mt-2`}>
                <button
                  type="button"
                  className={`btn btn-light-${ChargeModel?.Type} w-100`}
                  onClick={() => {
                    const isInfo = ChargeModel?.Type === "info";

                    let a = JSON.parse(JSON.stringify(Charges)) || [];

                    var listCharge = isInfo ? Charges : ChargesN;

                    var c = ChargesN?.filter((y) => y?.Id !== x?.Id);
                    var b = listCharge?.find((y) => y?.Id === x?.Id);

                    if (b || isInfo) {
                      var color = 0;
                      if (ChargeModel?.Type === "primary") color = 1;
                      else if (isInfo) color = 2;
                      a.push({
                        ...b,
                        Id: b?.Id,
                        ChargeId: b?.Id,
                        Rate: b?.Rate,
                        Name: b?.Name,
                        Code: b?.Code,
                        Quantity: +document.getElementById("ChargeN" + index)
                          .value,
                        RateType: b?.RateType,
                        MinCharge: b?.MinCharge,
                        MaxCharge: b?.MaxCharge,
                        Position: b?.Position,
                        CategoryPosition: b?.CategoryPosition,
                        FreeTime: b?.FreeTime,
                        Calcul: b?.Calcul,
                        Category: b?.Category,
                        IsVariable: x?.IsVariable || false,
                        Invoice: "",
                        OriAddSup: color,
                        IsReadOnly: false,
                        DateEnd: FieldID("DateEnd" + index),
                        DateStart: FieldID("DateStart" + index),
                        QuoteId: b?.QuoteId,
                        Rounding: b?.Rounding,
                        CalculName: b?.CalculName,
                      });
                    }

                    setCharges(a);
                    if (!isInfo) setChargesN(c);

                    setTimeout(() => {
                      if (
                        (c?.length === 0 && !isInfo) ||
                        (a?.length === 0 && isInfo)
                      )
                        setChargeModel({ Show: false, Type: "success" });
                    }, 1);
                  }}
                >
                  <i className={`fas fa-plus fa-sm`}></i>
                </button>
              </div>
            </div>
          ))}
          {(ChargeModel?.Type === "info" ? Charges : ChargesN)?.length ===
            0 && (
            <div class="col-lg-12 mt-2 text-center">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                style={{ fontSize: "25px", width: "100px", height: "100px" }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setChargeModel({ Show: false, Type: "success" })}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Auto Rating ---------------------------------------- */}
      <AutoRateComponent
        title="Receivables"
        setAutoRatingModal={setAutoRatingModal}
        AutoRatingModal={AutoRatingModal}
        Charges={Charges}
        setCharges={setCharges}
        AutoRatingCheck={AutoRatingCheck}
        setAutoRatingCheck={setAutoRatingCheck}
      />
      {/* Auto Rating ---------------------------------------- */}

      <Modal
        show={NoteModal.Show}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Note : {NoteModal?.Code}
          </Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={() => setNoteModal({ Show: false })}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body className="row">
          <textarea
            id="NoteCharges"
            className="form-control"
            rows="3"
            defaultValue={NoteModal?.Note}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setNoteModal({ Show: false })}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            {!window.C_ChargesReadOnly && (
              <button
                type="button"
                onClick={() => {
                  var note = JsNative("#NoteCharges").val();

                  if (NoteModal?.index > -1) {
                    let c = Charges;

                    var type = 0;
                    if (!isInbound && !isPayable) type = 1;
                    else if (!isInbound && isPayable) type = 2;
                    else if (isInbound && !isPayable) type = 3;
                    else if (isInbound && isPayable) type = 4;

                    if (c[NoteModal?.index]?.IsReadOnly)
                      PostNote(c[NoteModal?.index]?.Id2, note, type);

                    c[NoteModal?.index].Note = note;
                    setCharges([]);
                    setTimeout(() => {
                      setCharges(c || []);
                      setNoteModal({ Show: false });
                    }, 1);
                  }
                }}
                className="btn btn-primary btn-elevate ml-2"
              >
                OK
              </button>
            )}
          </div>
        </Modal.Footer>
      </Modal>

      {loadingCharges && (
        <div className="col-lg-12">
          <LinearProgress />
        </div>
      )}

      <EventChangeAutoRate />

      <div className="col-md-12">
        <div className="table-responsive">
          <div className="CheckCharges d-none">
            {Charges?.map((x, index) => (
              <p key={x?.ChargeId + "" + index}>{x?.Calcul}</p>
            ))}
          </div>

          <table
            className="table table-head-custom table-head-bg table-vertical-center"
            style={{ width: "95%", margin: "auto", overflow: "hidden" }}
          >
            <thead>
              <tr className="text-left text-uppercase">
                <th>Code</th>
                <th>Name</th>
                <th>Rate</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Remove</th>
                <th>Action</th>
              </tr>
            </thead>

            {SortAndRename(Charges)?.map((x, index) => {
              const iiii = x?.ChargeId + "" + index;
              return (
                <>
                  <tbody
                    className="border-0"
                    key={x?.Code + "" + x?.Id + "" + index + (x?.Invoice ?? "")}
                  >
                    {x?.InvoiceAppend !== "" && (
                      <>
                        <tr className="mt-5 d-block" style={{ height: "35px" }}>
                          <td
                            ref={(element) => {
                              if (element)
                                element.style.setProperty(
                                  "font-size",
                                  "15px",
                                  "important"
                                );
                            }}
                            style={{
                              position: "absolute",
                              left: "9px",
                            }}
                            className={`border-0`}
                          >
                            <b>Invoice</b>
                            <b>
                              <span className="rounded-pill bg-dark text-white px-2 py-1 ml-1 Charges">
                                {x?.Invoice}
                              </span>
                            </b>
                          </td>
                        </tr>
                      </>
                    )}

                    {x?.CategoryAppend !== "" && (
                      <>
                        <tr className="mt-5 d-block CategoryClass">
                          <td
                            className={`border-0  bg-${ChargesColorCategory(
                              x?.Category
                            )} rounded-top`}
                          >
                            <b className={`text-light title mr-2`}>
                              <span
                                className="rounded-pill px-2 py-1 ml-1 Charges2"
                                style={{ fontSize: " 13px" }}
                              >
                                {x?.Category}
                                <span
                                  id={
                                    x?.Category?.replace(/ /g, "") + type + "Id"
                                  }
                                  className={`ml-2 btn btn-light py-0 text-${ChargesColorCategory(
                                    x?.Category
                                  )} pt-1`}
                                  data-Name={x?.Category}
                                  data-Invoice={x?.Invoice}
                                  style={{
                                    fontWeight: "600",
                                    borderRadius: "3px",
                                  }}
                                >
                                  Sub Total : 0.00
                                </span>
                              </span>
                            </b>
                          </td>
                        </tr>
                      </>
                    )}

                    <tr className="bg-light">
                      <td className={`border-0 position-relative`}>
                        {isChangeFunction(x)}
                        <b
                          className={`${
                            x?.Calc
                              ? ""
                              : ChargesColor(
                                  x.OriAddSup ?? 0,
                                  x?.Quantity ?? 0,
                                  x?.Invoice ?? "Empty"
                                )
                          } title mr-2`}
                        >
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="products-edit-tooltip">
                                <p class="w-100 text-center">
                                  <span class="d-inline-block w-100 text-primary">
                                    <b>Quote # </b>
                                  </span>
                                  <span class="w-100">
                                    {x?.QuoteId ?? "Empty"}
                                  </span>
                                  {/* {x?.RatingTable && x?.RatingTable !== null && (
                                    <>
                                      <span class="d-inline-block w-100 mt-5 text-primary">
                                        <b>Rating Table</b>
                                      </span>
                                      <span class="w-100 mt-5">
                                        {x?.RatingTable ?? "Empty"}
                                      </span>
                                    </>
                                  )} */}
                                </p>
                              </Tooltip>
                            }
                          >
                            <a className="btn btn-icon btn-light btn-hover-light btn-sm mr-3">
                              <span className="svg-icon svg-icon-md svg-icon-info">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Code/Info-circle.svg"
                                  )}
                                />
                              </span>
                            </a>
                          </OverlayTrigger>
                          {x?.Code}
                          <span class="ml-2 text-primary">
                            <b>{x?.CalculName}</b>
                          </span>
                        </b>
                      </td>
                      <td className={`border-0`}>{x?.Name}</td>
                      <td className={`border-0 Rate`}>
                        {x?.IsVariable}
                        <input
                          className={RateClassName(
                            window.C_ChargesReadOnly,
                            x,
                            isVariableOpen
                          )}
                          readonly={RateReadOnly(isVariableOpen, x?.IsVariable)}
                          RateReadOnly
                          defaultValue={x?.Rate}
                          disabled={
                            window.C_ChargesReadOnly ? true : x?.IsReadOnly
                          }
                          onClick={(e) => {
                            if (e.detail === 2 && x?.IsVariable === true) {
                              e.target.readOnly = false;
                              $(e.target).removeClass("bg-light");
                            }
                          }}
                          onBlur={(e) => {
                            var b = JSON.parse(JSON.stringify(Charges));
                            var a = b.findIndex(
                              (y) =>
                                y.ChargeId === x?.ChargeId &&
                                y.OriAddSup === x?.OriAddSup &&
                                y?.InvoiceId === x?.InvoiceId
                            );
                            if (a > -1) {
                              b[a].Rate = +e.target.value;
                              setCharges(b);
                            }
                            const c = CalculateCharge({
                              Rate: +e.target.value,
                              Quantity: RoundToFixed(
                                x?.Quantity ?? 0,
                                x?.Rounding
                              ),
                              rateType: x?.RateType,
                              MinCharge: x?.MinCharge,
                              MaxCharge: x?.MaxCharge,
                              FreeTime: x?.FreeTime,
                              OriAddSup: x?.OriAddSup,
                            });
                            $(`.RatesTotal${iiii} input`).val(c.toFixed(2));
                          }}
                        />
                      </td>
                      <td className={`border-0 position-relative`}>
                        <IsRounding
                          x={x}
                          ChargesReadOnly={window.C_ChargesReadOnly}
                        />
                        <input
                          type="number"
                          className={`form-control ${(x?.Quantity || 0) === 0 &&
                            "border border-danger"}`}
                          disabled={
                            window.C_ChargesReadOnly ? true : x?.IsReadOnly
                          }
                          id={`${iiii}Id`}
                          onBlur={(e) => {
                            var b = JSON.parse(JSON.stringify(Charges));
                            var a = b.findIndex(
                              (y) =>
                                y.ChargeId === x?.ChargeId &&
                                y.OriAddSup === x?.OriAddSup &&
                                y?.InvoiceId === x?.InvoiceId
                            );
                            if (a > -1) {
                              b[a].Quantity = +e.target.value;
                              setCharges(b);
                            }
                            const c = CalculateCharge({
                              Rate: x?.Rate,
                              Quantity: e.target.value,
                              rateType: x?.RateType,
                              MinCharge: x?.MinCharge,
                              MaxCharge: x?.MaxCharge,
                              FreeTime: x?.FreeTime,
                              OriAddSup: x?.OriAddSup,
                            });
                            $(`.RatesTotal${iiii} input`).val(c.toFixed(2));
                          }}
                          onChange={(e) => {
                            GetLengthRound(e, x?.Rounding);
                          }}
                          defaultValue={InputValueCharge(
                            x,
                            window.C_ChargesReadOnly
                          )}
                        />
                      </td>
                      <td
                        className={`border-0 RatesTotal-${type} RatesTotal${iiii} `}
                      >
                        <input
                          className="form-control"
                          disabled={true}
                          value={
                            CalculateCharge({
                              Rate: x?.Rate,
                              Quantity: RoundToFixed(
                                checkIs0(x?.Quantity, iiii),
                                x?.Rounding
                              ),
                              rateType: x?.RateType,
                              MinCharge: x?.MinCharge,
                              MaxCharge: x?.MaxCharge,
                              FreeTime: x?.FreeTime,
                              OriAddSup: x?.OriAddSup,
                            })?.toFixed(2) ?? 0
                          }
                        />
                      </td>
                      <td className={`border-0`}>
                        <button
                          type="button"
                          className="btn btn-light-danger w-100"
                          disabled={
                            window.C_ChargesReadOnly ? true : x?.IsReadOnly
                          }
                          onClick={() => {
                            let a = Charges || [];
                            let b = ChargesN || [];
                            a = a?.filter((y) => y !== x);
                            if (x?.OriAddSup !== 2)
                              b.push({
                                ...x,
                                Id: x?.ChargeId,
                                Rate: x?.Rate,
                                Name: x?.Name,
                                Code: x?.Code,
                                Quantity: 1,
                                RateType: x?.RateType,
                                MinCharge: x?.MinCharge,
                                MaxCharge: x?.MaxCharge,
                                FreeTime: x?.FreeTime,
                                Position: x?.Position,
                                CategoryPosition: x?.CategoryPosition,
                                Calcul: x?.Calcul,
                                Category: x?.Category,
                                IsVariable: x?.IsVariable || false,
                                Invoice: x?.Invoice,
                                InvoiceId: x?.InvoiceId,
                                OriAddSup: x?.OriAddSup,
                                IsReadOnly: x?.IsReadOnly || false,
                                DateEnd: x?.DateEnd,
                                DateStart: x?.DateStart,
                                QuoteId: x?.QuoteId,
                                Rounding: x?.Rounding,
                                CalculName: x?.CalculName,
                              });

                            setCharges(a);
                            setChargesN(b);
                          }}
                        >
                          <i className={`fas fa-times fa-sm`}></i>
                        </button>
                      </td>

                      <td className={`border-0`}>
                        <MenuListComposition
                          Child1={
                            <button
                              type="button"
                              className="btn btn-light-warning w-100"
                              onClick={() => {
                                var autoRatingCheck = [x?.Id];
                                setAutoRatingCheck(autoRatingCheck);

                                var find = SortOnlyCharge(
                                  Charges,
                                  autoRatingCheck
                                ).length;

                                if (find > 0) setAutoRatingModal(true);
                                else
                                  AutoRatingCharges({
                                    setAutoRatingModal: setAutoRatingModal,
                                    setCharges: setCharges,
                                    Charges: Charges,
                                    bool: true,
                                    AutoRatingCheck: AutoRatingCheck,
                                  });
                              }}
                            >
                              <i className={`fas fa-magic fa-sm`} />
                            </button>
                          }
                          Child2={
                            <button
                              type="button"
                              className="btn btn-light-primary w-100"
                              onClick={() => {
                                setNoteModal({
                                  Show: true,
                                  Id: x?.ChargeId,
                                  Code: x?.Code,
                                  Note: x?.Note,
                                  index: index,
                                });
                              }}
                            >
                              <i
                                className={`${
                                  x?.Note ? "fas" : "far"
                                } fa-sticky-note fa-sm`}
                              ></i>
                            </button>
                          }
                        />
                      </td>
                    </tr>
                    <tr className="bg-light">
                      <td className={`border-0`}></td>
                      <td className={`border-0`}></td>
                      <td className="row mb-0 border-0 place-content-center py-0">
                        {(x?.FreeTime || null) !== null && (
                          <button
                            type="button"
                            className="btn btn-primary text-center btn-sm p-2"
                          >
                            FT : {ReturnEmptyOrString(x?.FreeTime)}
                          </button>
                        )}
                        {(x?.MinCharge || null) !== null && (
                          <button
                            type="button"
                            className="btn btn-info text-center mx-2 btn-sm p-2"
                          >
                            Min : {ReturnEmptyOrString(x?.MinCharge)}
                          </button>
                        )}
                        {(x?.MaxCharge || null) !== null && (
                          <button
                            type="button"
                            className="btn btn-warning text-center btn-sm p-2"
                          >
                            Max : {ReturnEmptyOrString(x?.MaxCharge)}
                          </button>
                        )}
                      </td>
                      <td className={`border-0`}></td>
                      <td className={`border-0`}></td>
                      <td className={`border-0`}></td>
                      <td className={`border-0`}></td>
                    </tr>

                    {x?.SubTotal && (
                      <tr className="bg-light">
                        <td className={`border-0`} />
                        <td className={`border-0`} />
                        <td className={`border-0`} />
                        <td className={`border-0 text-right`}>
                          <p
                            className={`btn py-1 w-100 text-white bg-${ChargesColorCategory(
                              x?.Category
                            )}`}
                          >
                            <b>Sub Total</b>
                          </p>
                        </td>
                        <td className={`border-0`}>
                          <p
                            className={`btn py-1 w-100 Charges-${RemoveSpace(
                              x?.Category
                            )}-${RemoveSpace(
                              x?.Invoice
                            )}  text-${ChargesColorCategory(
                              x?.Category
                            )} bg-light-${ChargesColorCategory(x?.Category)}`}
                            style={{ height: "29px" }}
                          />
                        </td>
                        <td className={`border-0`} />
                        <td className={`border-0`} />
                      </tr>
                    )}
                  </tbody>
                </>
              );
            })}

            <tbody className="border-0">
              <tr>
                <td colSpan="3" className="border-0"></td>
                <td className="text-right border-0">
                  <p className={`btn py-1 mt-4 w-100 text-dark bg-secondary`}>
                    <b>Total</b>
                  </p>
                </td>
                <td className={`border-0 RateAllTotal-${type}`}>
                  <span className="btn py-1 w-100 bg-light-secondary"></span>
                </td>
                <td className="border-0"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {ChargesRemove?.length > 0 && (
        <ChargesRemoveComponent ChargesRemove={ChargesRemove} />
      )}
      {Charges?.length === 0 && <Spinner2s />}
      <div className="col-lg-8 text-black p-5 font-weight-bold">
        {Charges.findIndex((x) => x?.IsReadOnly === true) === -1 && (
          <button
            type="button"
            className="btn btn-light-success p-2"
            onClick={() => {
              setChargeModel({ Show: true, Type: "success" });
            }}
            disabled={
              window.C_ChargesReadOnly ||
              ChargesN?.length === 0 ||
              loadingCharges
            }
          >
            <i className={`fas fa-plus fa-sm`}></i> Add Charge
          </button>
        )}
        {Charges.findIndex((x) => x?.IsReadOnly === true) > -1 && (
          <>
            {ChargesN && (
              <button
                type="button"
                className={"btn btn-light-primary p-2 ml-2"}
                onClick={() => {
                  setChargeModel({ Show: true, Type: "primary" });
                }}
                disabled={
                  window.C_ChargesReadOnly ||
                  ChargesN?.length === 0 ||
                  Charges.findIndex(
                    (x) => x?.OriAddSup === 2 && x?.Invoice === "Empty"
                  ) > -1
                }
              >
                <i className={`fas fa-plus fa-sm`}></i> Add Additional
              </button>
            )}
            <button
              type="button"
              className="btn btn-light-info p-2 ml-2"
              onClick={() => {
                setChargeModel({ Show: true, Type: "info" });
              }}
              disabled={
                window.C_ChargesReadOnly ||
                Charges?.length === 0 ||
                Charges.findIndex(
                  (x) => x?.OriAddSup === 1 && x?.Invoice === "Empty"
                ) > -1
              }
            >
              <i className={`fas fa-plus fa-sm`}></i> Add Supplemental
            </button>
          </>
        )}
      </div>
      <div className="col-lg-2 text-black p-5 font-weight-bold text-right">
        <button
          id="AutoRatingBtn"
          type="button"
          disabled={window.C_ChargesReadOnly || loadingCharges}
          className="btn btn-light-warning p-2 w-100"
          onClick={() => {
            var list = [];
            Charges.forEach((x) => list.push(x?.Id));
            setAutoRatingCheck(list);

            var find = SortOnlyCharge(Charges).length;
            if (find > 0) setAutoRatingModal(true);
            else
              AutoRatingCharges({
                setAutoRatingModal: setAutoRatingModal,
                setCharges: setCharges,
                Charges: Charges,
                bool: true,
                AutoRatingCheck: AutoRatingCheck,
              });
          }}
        >
          <i className={`fas fa-magic fa-sm`}></i> Auto Rate
        </button>

        <input
          type="hidden"
          data-type=""
          className="BtnAutoRating"
          onClick={(e) => {
            debugger;

            //get type --------------------------------------------------
            var type = e.target.getAttribute("data-type");

            var list = [];
            Charges.filter((x) => type.includes(x.Calcul)).forEach((x) => {
              if (type.includes("perdaychassis")) {
                x.DateStart = FieldID("C_PickedUpDate");
                x.DateEnd = FieldID("C_TerminatedDate");
              }
              list.push(x?.Id);
            });
            var autoRatingCheck = list;
            setAutoRatingCheck(list);

            //get type --------------------------------------------------

            var find = SortOnlyCharge(Charges, autoRatingCheck).length;
            if (find > 0) setAutoRatingModal(true);
            else
              AutoRatingCharges({
                setAutoRatingModal: setAutoRatingModal,
                setCharges: setCharges,
                Charges: Charges,
                bool: true,
                AutoRatingCheck: AutoRatingCheck,
              });
          }}
        />
      </div>
      <div className="col-lg-2 text-black p-5 font-weight-bold text-right">
        <button
          type="button"
          disabled={window.C_ChargesReadOnly || loadingCharges}
          className="btn btn-light-primary p-2"
          onClick={() => {
            GetPayableForVendor({ button: true });
          }}
        >
          {loadingCharges && (
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              style={{
                fontSize: "10px",
                width: "20px",
                height: "20px",
                marginRight: "3px",
              }}
            />
          )}
          <i className={`fas fa-square-root-alt fa-sm`}></i> Calculated
        </button>
      </div>
    </div>
  );
};

async function PostNote(id, text, type) {
  return fetch(window.$apiurl + "/Shipments/postchargesnotes", {
    method: "POST",
    withCredentials: true,
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Id: id,
      Note: text,
      Type: type,
    }),
  }).then(async (response) => {
    const statusCode = response.status;
    const data = await response.json();
    return Promise.all([statusCode, data]);
  });
}

function SaveDisabledFunction(bool) {
  document.querySelectorAll(".SaveDisabled").forEach((el) => {
    if (bool) el.classList.add("disabled");
    else el.classList.remove("disabled");
  });
}

async function PutOsd({ Id, overQuantity, shortQuantity, damagedQuantity }) {
  const someData = {
    OverQuantity:
      overQuantity === "" || overQuantity === null ? 0 : overQuantity,
    ShortQuantity:
      shortQuantity === "" || shortQuantity === null ? 0 : shortQuantity,
    DamagedQuantity:
      damagedQuantity === "" || damagedQuantity === null ? 0 : damagedQuantity,
  };
  return fetch(window.$apiurl + "/Shipments/Osd/" + Id, {
    method: "put",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(someData),
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {});
}

function CheckVandorIsDanger(Vendors, VendorsList = []) {
  var checkBool = false;
  VendorsList.forEach((x) => {
    var checkVendor = Vendors.find((y) => y?.Id === x?.VendorId);
    var checkOrg = vendorsOrg.find(
      (y) => y?.Id === x?.IdOrg && y?.VendorId === x?.VendorId
    );
    if (
      !checkOrg &&
      checkVendor &&
      checkVendor.AlertType === "Danger" &&
      x?.FreightChargeId === 98
    ) {
      checkBool = true;
      const alertMessage = checkVendor?.AlertMessage || [];
      alertMessage.map((x) => {
        const msgType =
          checkVendor?.AlertType === "Danger" ? "error" : "warning";
        Notification(msgType, checkVendor?.Name + " : " + x);
      });
    }
  });
  return checkBool;
}
