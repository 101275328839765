import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Input,
  DateTime30,
  CreatableSelect3,
  Select as SelectDrop,
} from "../../../../../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import {
  DateTime30minInterval,
  IsValidMessage,
  RequiredHtml,
} from "../../../../../../../../_metronic/_helpers/ATHelpers";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import $ from "jquery";
import { Notification } from "../../../../../../Notification";

const catchError = [];

export function AssignToOutboundHeaderForm({
  Consigneesf = [],
  Vendorsf = [],
  Shippersf = [],
  Customersf = [],
  Servicesf = [],
  FreightChargesf = [],
  SHipmentId,
  SHipment = [],
  TrackingDevicesf = [],
  setstatSHipment,
  Warehouse,
  setLineItems,
  LineItems,
  GetShipmentData = false,
  TableShow = true,
  ReloadPallets = false,
  GetOrdersIds = false,
}) {
  const [remark, setremark] = useState(catchError);
  const [Consignee, setConsignee] = useState(catchError);
  const [ConsigneeID, setConsigneeID] = useState();
  const [Vendor, setVendor] = useState(catchError);
  const [VendorID, setVendorID] = useState();
  const [Shippers, setShippers] = useState(catchError);
  const [ShippersID, setShippersID] = useState();
  const [Customers, setCustomers] = useState(catchError);
  const [CustomersID, setCustomersID] = useState();
  const [ConsigneeDetail, setConsigneeDetail] = useState(catchError);
  const [VendorDetail, setVendorDetail] = useState(catchError);
  const [CustomersDetail, setCustomersDetail] = useState(catchError);
  const [ShippersDetail, setShippersDetail] = useState(catchError);
  const [Services, setServices] = useState(catchError);
  const [Modes, setModes] = useState(catchError);
  const [ServiceLevels, setServiceLevels] = useState(catchError);
  const [EquipmentTypes, setEquipmentTypes] = useState(catchError);
  const [EquipmentSizes, setEquipmentSizes] = useState(catchError);
  const [FreightCharges, setFreightCharges] = useState(catchError);
  const [puAppointmentToDate, setPuAppointmentDateTo] = useState(null);
  const [RequestedPickUpDate, setRequestedPickUpDate] = useState();
  const [TrackingDevices, setTrackingDevices] = useState(catchError);

  //Palletized
  const [isPalletized, setPalletizedForm] = useState(false);
  useEffect(() => {
    setConsignee([]);
    if (remark?.CustomerId) {
      var list = Consigneesf.filter((x) => x.CustomerId === remark?.CustomerId);
      setConsignee(list.length > 0 ? list : Consigneesf);
    }
  }, [Consigneesf, remark]);

  useEffect(() => {
    Vendorsf.forEach((x) => {
      let c = "#000000";
      if (x?.AlertType === "Danger") c = "#ff5555";
      else if (x?.AlertType === "Warning") c = "#bbb000";
      x.Color = c;
    });
    setVendor(Vendorsf);
  }, [Vendorsf]);

  useEffect(() => {
    setCustomers(Customersf);
  }, [Customersf]);

  useEffect(() => {
    setShippers(Shippersf);
  }, [Shippersf]);

  useEffect(() => {
    setServices(Servicesf);
  }, [Servicesf]);

  useEffect(() => {
    setFreightCharges(FreightChargesf);
  }, [FreightChargesf]);

  useEffect(() => {
    if (TrackingDevicesf.length > 0 && TrackingDevices.length === 0)
      setTrackingDevices(TrackingDevicesf);
  }, [TrackingDevicesf]);

  useEffect(() => {
    fetchTabeAsync();
  }, [SHipmentId, SHipment]);

  useEffect(() => {
    window.OneMoveMasterVendors = false;
  }, []);

  useEffect(() => {
    if (GetOrdersIds && CustomersID?.value) GetOrdersIds(CustomersID?.value);
  }, [CustomersID]);

  const fetchTabeAsync = () => {
    try {
      var a = SHipment.filter((el) => {
        return el.Id === SHipmentId;
      });
      //fetch LoadType
      try {
        if (a.length > 0 && a[0].PalletType === "Palletized") {
          setPalletizedForm(true);
        }
      } catch (err) {}

      var b = Customersf.find((x) => +x?.Id === +a[0].CustomerId);
      if (b) {
        setCustomersID({
          value: b?.Id,
          label: `${b?.Name} - ${b?.Code} ${
            b?.MiffId !== null ? " - " + b?.MiffId : ""
          }`,
        });
        setCustomersDetail(b);
      }

      var b = Consigneesf.find((x) => +x?.Id === +a[0].ConsigneeId);
      if (b) {
        setConsigneeID({
          value: b?.Id,
          label: `${b?.Name} - ${b?.Code} ${
            b?.MiffId !== null ? " - " + b?.MiffId : ""
          }`,
        });
        setConsigneeDetail(b);
      }

      var b = Vendorsf.find((x) => +x?.Id === +a[0].VendorId);
      if (b) {
        setVendorID(a[0].VendorId);
        setVendorDetail(b);
      }

      var b = Shippersf.find((x) => +x?.Id === +a[0].ShipperId);
      if (b) {
        setShippersID({
          value: b?.Id,
          label: `${b?.Name} - ${b?.Code} ${
            b?.MiffId !== null ? " - " + b?.MiffId : ""
          }`,
        });
        setShippersDetail(b);
      }

      if (!a[0].ServiceId) {
        a[0].ServiceId = 6;
        a[0].ModeId = 3;
        a[0].ServiceLevelId = 8;
        a[0].EquipmentTypeId = 49;
        a[0].EquipmentSizeId = 251;
      }

      let time = new Date(a[0].PickupAppointmentToDate);
      setPuAppointmentDateTo(
        ("0" + time.getHours()).slice(-2) +
          ":" +
          ("0" + time.getMinutes()).slice(-2) +
          ":00"
      );

      setremark(a[0]);
      setTimeout(() => {
        $("#AssignToOutboundHeaderForm").slideDown();
      }, 1000);
    } catch (e) {
      return 0;
    }
  };

  function GetShipmentDataHeader() {
    let idREcipt = $(".OrderFilter.collapse.show").attr("data-filter");

    if (GetShipmentData !== false)
      GetShipmentData().then((e) => {
        $(".LoadingId").fadeOut();
        $("#Loading").addClass("d-none");

        let arr = e?.data?.Shipments || [];

        setstatSHipment([]);
        setstatSHipment(arr);

        var a = arr.filter((el) => {
          return el?.Id === SHipmentId;
        });
        setremark({});
        setremark(a[0]);

        let lineItems = LineItems || []; //.filter((x) => x?.Close === true) || [];

        lineItems = e?.data?.Receipts.concat(lineItems) || [];
        lineItems = lineItems.reduce(
          (acc, x) =>
            acc.concat(acc.find((y) => y.OrderId === x.OrderId) ? [] : [x]),
          []
        );

        setLineItems([]);
        setLineItems(lineItems);

        const filter = $(".OrderFilter.collapse.show").attr("data-filter");
        if (+filter !== +idREcipt) {
          $(".OrderFilter.collapse.show").click();
          $(`.OrderFilter[data-filter="${idREcipt}"]`).click();
        }
      });
  }

  return remark === undefined ? (
    <></>
  ) : (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={remark}
        onSubmit={(values) => {
          if (
            CheckVandorIsDanger(
              Vendor,
              values?.VendorId,
              values?.FreightChargeId
            )
          )
            return false;

          window.IsValid = false;
          values.id = SHipmentId;
          values.RequestedPickUpDate =
            values.RequestedPickUpDate || RequestedPickUpDate;

          IsValidMessage("Customer", values?.CustomerId);
          IsValidMessage("Freight Charge", values?.FreightChargeId);
          if (window.IsValid) return false;

          axios
            .post("api/FindOrderContainers/PutShipment", {
              data: values,
            })
            .then((e) => {
              if (ReloadPallets) ReloadPallets(1);
              GetShipmentDataHeader();
            });
        }}
      >
        {({ setFieldValue, values }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div
                  className={
                    "col-lg-3 " + (Customersf.length === 0 && "d-none")
                  }
                >
                  <label>
                    Customer <RequiredHtml />
                  </label>
                  <Select
                    value={{
                      value: values?.CustomerId,
                      label:
                        Customers.find((x) => x.Id === values?.CustomerId)
                          ?.Name || "Select...",
                    }}
                    onChange={(newValue) => {
                      var a = newValue?.value;
                      setCustomersID(newValue);
                      setFieldValue("CustomerId", a);
                      var b = Customers.find((x) => x.Id === a);
                      setCustomersDetail(b);

                      setConsignee([]);
                      if (a)
                        var list = Consigneesf.filter(
                          (x) => x.CustomerId === a
                        );
                      setConsignee(list.length > 0 ? list : Consigneesf);
                    }}
                    options={Customers.map((x) => {
                      return {
                        value: x?.Id,
                        label: x?.Code + " - " + x?.Name,
                      };
                    })}
                    className="w-100 mr-2"
                    name="CustomerId"
                  />
                </div>
                <div
                  className={
                    "col-lg-3 " + (Customersf.length === 0 && "col-lg-4")
                  }
                >
                  <label>Consignee</label>
                  <CreatableSelect
                    isSearchable={true}
                    onCreateOption={undefined}
                    value={ConsigneeID}
                    createOptionPosition="first"
                    isClearable
                    onChange={(newValue) => {
                      var a = newValue?.value;
                      setConsigneeID(newValue);
                      setFieldValue("ConsigneeId", a);
                      var b = Consignee.find((x) => x.Id === a);
                      setConsigneeDetail(b);
                    }}
                    options={Consignee.map((x) => {
                      return {
                        value: x?.Id,
                        label: x?.Code + " - " + x?.Name,
                      };
                    })}
                    name="ConsigneeId"
                    id="ConsigneeId"
                    className="w-100 mr-2"
                  />
                </div>
                <div
                  className={
                    "col-lg-3 " + (Customersf.length === 0 && "col-lg-4")
                  }
                >
                  <label>Vendor</label>
                  <CreatableSelect3
                    isSearchable={true}
                    onCreateOption={undefined}
                    valueSelect={VendorID}
                    Loading={false}
                    createOptionPosition="first"
                    isClearable
                    onChange={(newValue) => {
                      var a = newValue?.value;
                      setFieldValue("VendorId", a);
                      var b = Vendor.find((x) => x.Id == a);

                      setTimeout(() => {
                        window.OneMoveMasterVendors = true;
                      }, 5000);
                      setVendorDetail(b);
                    }}
                    ColorBool={values?.FreightChargeId === 98}
                    options={Vendor.map((x) => {
                      return {
                        value: x?.Id,
                        label: x?.Code + " - " + x?.Name,
                        color: x?.Color,
                      };
                    })}
                    name="VendorId"
                    id="VendorId"
                    className="w-100 mr-2"
                  />
                  {VendorDetail?.AlertType === "Danger" &&
                    values?.FreightChargeId === 98 && (
                      <b className="text-danger w-100 pt-1">
                        * This Vendor is not Certified
                      </b>
                    )}
                </div>
                <div
                  className={
                    "col-lg-3 " + (Customersf.length === 0 && "col-lg-4")
                  }
                >
                  <label>Shipper</label>
                  <CreatableSelect3
                    name="ShipperId"
                    Loading={false}
                    valueSelect={values?.ShipperId}
                    setFieldValue={setFieldValue}
                    options={Shippers.map((x) => {
                      return {
                        value: x?.Id,
                        label: x?.Code + " - " + x?.Name,
                      };
                    })}
                    onChange={(newValue) => {
                      var a = newValue?.value;
                      var b = Shippers.find((x) => x.Id === a);
                      setShippersDetail(b);
                    }}
                  />
                </div>
                <div
                  className={
                    "col-lg-3 mt-2 " + (Customersf.length === 0 && "d-none")
                  }
                >
                  <div className="form-control h-100">
                    {CustomersDetail?.Name}
                    <br />
                    {CustomersDetail?.Address1} <br />
                    {CustomersDetail?.Address2 !== null &&
                      CustomersDetail?.Address2}
                    {CustomersDetail?.City}. {CustomersDetail?.State}{" "}
                    {CustomersDetail?.ZipCode}
                  </div>
                </div>
                <div
                  className={
                    "col-lg-3 mt-2 " + (Customersf.length === 0 && "col-lg-4")
                  }
                >
                  <div className="form-control h-100">
                    {ConsigneeDetail?.Name}
                    <br />
                    {ConsigneeDetail?.Address1} <br />
                    {ConsigneeDetail?.Address2 !== null &&
                      ConsigneeDetail?.Address2}
                    {ConsigneeDetail?.City}. {ConsigneeDetail?.State}{" "}
                    {ConsigneeDetail?.ZipCode}
                  </div>
                </div>
                <div
                  className={
                    "col-lg-3 mt-2 " + (Customersf.length === 0 && "col-lg-4")
                  }
                >
                  <div className="form-control h-100">
                    {VendorDetail?.Name}
                    <br />
                    {VendorDetail?.Address1} <br />
                    {VendorDetail?.Address2 !== null && VendorDetail?.Address2}
                    {VendorDetail?.City}. {VendorDetail?.State}{" "}
                    {VendorDetail?.ZipCode}
                  </div>
                </div>
                <div
                  className={
                    "col-lg-3 mt-2 " + (Customersf.length === 0 && "col-lg-4")
                  }
                >
                  <div className="form-control h-100">
                    {ShippersDetail?.Name}
                    <br />
                    {ShippersDetail?.Address1} <br />
                    {ShippersDetail?.Address2 !== null &&
                      ShippersDetail?.Address2}
                    {ShippersDetail?.City}. {ShippersDetail?.State}{" "}
                    {ShippersDetail?.ZipCode}
                  </div>
                </div>
                <div
                  className={isPalletized ? "col-lg-2 mt-5" : "col-lg-3 mt-5"}
                >
                  <Field
                    id="EquipmentId"
                    name="Equipment"
                    component={Input}
                    placeholder="Equipment"
                    label="Equipment"
                  />
                </div>
                <div
                  className={isPalletized ? "col-lg-2 mt-5" : "col-lg-3 mt-5"}
                >
                  <Field
                    id="SealNumberId"
                    name="SealNumber"
                    component={Input}
                    placeholder="Seal No."
                    label="Seal No."
                  />
                </div>
                <div
                  className={isPalletized ? "col-lg-2 mt-5" : "col-lg-3 mt-5"}
                >
                  <SelectDrop
                    name="PalletType"
                    label="Load type"
                    className="form-control"
                    onChange={(e) => {
                      if (e.target.value === "Palletized") {
                        setPalletizedForm(true);
                      } else {
                        setPalletizedForm(false);
                      }
                      setFieldValue("PalletType", e.target.value);
                    }}
                  >
                    <option value="Floor loaded">Floor loaded</option>
                    <option value="Palletized">Palletized</option>
                  </SelectDrop>
                </div>
                {/* Pallized Form */}
                {isPalletized && (
                  <>
                    <div className="col-lg-2 mt-5">
                      <SelectDrop
                        name="PalletLoadType"
                        label="Pallet Type"
                        className="form-control"
                      >
                        <option value="">Select</option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="Misc">Misc</option>
                      </SelectDrop>
                    </div>
                    <div className="col-lg-2 mt-5">
                      <Field
                        id="PalletCount"
                        name="PalletCount"
                        type="number"
                        min="0"
                        component={Input}
                        placeholder="Pallet Count"
                        label="PalletCount"
                        onChange={(e) => {
                          setFieldValue("PalletCount", e.target.value);
                        }}
                      />
                    </div>
                  </>
                )}
                <div
                  className={isPalletized ? "col-lg-2 mt-5" : "col-lg-3 mt-5"}
                >
                  <CreatableSelect3
                    name="FreightChargeId"
                    Loading={false}
                    required={true}
                    label="Freight Charge"
                    valueSelect={values?.FreightChargeId}
                    setFieldValue={setFieldValue}
                    options={FreightCharges.map((x) => {
                      return { value: x?.Id, label: x?.Name };
                    })}
                  />
                </div>
                <div className="col-lg-4 mt-5">
                  <DateTime30
                    label="Requested Pick Up Date"
                    name="RequestedPickUpDate"
                    defaultvalue={values?.RequestedPickUpDate}
                    className="w-100 pt-2"
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div className="col-lg-4 mt-5">
                  <DateTime30
                    label="Last ship date"
                    name="LastShipDate"
                    defaultvalue={values?.LastShipDate}
                    className="w-100 pt-2"
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div className="col-lg-3 mt-5">
                  <DateTime30
                    label="P/U Appointment"
                    name="PickupAppointmentDate"
                    defaultvalue={values?.PickupAppointmentDate}
                    className="w-100 pt-2"
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div className="col-lg-1 mt-5">
                  <SelectDrop
                    value={puAppointmentToDate || "0"}
                    name="PuAppointmentTo"
                    label="To Time"
                    className="form-control"
                    onChange={(e) => {
                      setFieldValue("PickupAppointmentToDate", e.target.value);
                      setPuAppointmentDateTo(e.target.value);
                    }}
                  >
                    <DateTime30minInterval />
                  </SelectDrop>
                </div>
                <div className="col-lg-2 mt-5">
                  <label>Service</label>
                  <CreatableSelect3
                    name="ServiceId"
                    Loading={false}
                    valueSelect={values?.ServiceId}
                    setFieldValue={setFieldValue}
                    options={Services.map((x) => {
                      return {
                        value: x.Id,
                        label: x?.Name,
                      };
                    })}
                    onChange={(e) => {
                      const a = Services.find((x) => x?.Id === e?.value);
                      setModes(a?.Modes || []);

                      setModes([]);
                      setTimeout(() => {
                        setModes(a?.Modes || []);
                      }, 1);
                    }}
                  />
                </div>
                <div className="col-lg-2 mt-5">
                  {Modes.length > 0 && (
                    <CreatableSelect3
                      name="ModeId"
                      label="Mode"
                      valueSelect={values?.ModeId}
                      Loading={false}
                      setFieldValue={setFieldValue}
                      options={Modes.map((x) => {
                        return {
                          value: x.Id,
                          label: x?.Name,
                        };
                      })}
                      onChange={(e) => {
                        const a = Modes.find((x) => x?.Id === e?.value);
                        setServiceLevels([]);
                        setTimeout(() => {
                          setServiceLevels(a?.ServiceLevels || []);
                        }, 1);
                      }}
                    />
                  )}{" "}
                </div>
                <div className="col-lg-2 mt-5">
                  {Modes.length > 0 && ServiceLevels.length > 0 && (
                    <CreatableSelect3
                      name="ServiceLevelId"
                      label="Service Level"
                      valueSelect={values?.ServiceLevelId}
                      Loading={false}
                      setFieldValue={setFieldValue}
                      options={ServiceLevels.map((x) => {
                        return {
                          value: x.Id,
                          label: x?.Name,
                        };
                      })}
                      onChange={(e) => {
                        const a = ServiceLevels.find((x) => x?.Id === e?.value);

                        setEquipmentTypes([]);
                        setTimeout(() => {
                          setEquipmentTypes(a?.EquipmentTypes || []);
                        }, 1);
                      }}
                    />
                  )}{" "}
                </div>{" "}
                <div className="col-lg-2 mt-5">
                  {Modes.length > 0 &&
                    ServiceLevels.length > 0 &&
                    EquipmentTypes.length > 0 && (
                      <CreatableSelect3
                        name="EquipmentTypeId"
                        label="Equipment Type"
                        valueSelect={values?.EquipmentTypeId}
                        Loading={false}
                        setFieldValue={setFieldValue}
                        options={EquipmentTypes.map((x) => {
                          return {
                            value: x.Id,
                            label: x?.Name,
                          };
                        })}
                        onChange={(e) => {
                          const a = EquipmentTypes.find(
                            (x) => x?.Id === e?.value
                          );

                          setEquipmentSizes([]);
                          setTimeout(() => {
                            setEquipmentSizes(a?.EquipmentSizes || []);
                          }, 1);
                        }}
                      />
                    )}{" "}
                </div>{" "}
                <div className="col-lg-4 mt-5">
                  {Modes.length > 0 &&
                    ServiceLevels.length > 0 &&
                    EquipmentTypes.length > 0 &&
                    EquipmentSizes.length > 0 && (
                      <CreatableSelect3
                        name="EquipmentSizeId"
                        label="Equipment Size"
                        valueSelect={values?.EquipmentSizeId}
                        Loading={false}
                        setFieldValue={setFieldValue}
                        options={EquipmentSizes.map((x) => {
                          return {
                            value: x.Id,
                            label: x?.Name,
                          };
                        })}
                      />
                    )}
                </div>
                <div className={"col-lg-4 mt-5"}>
                  <CreatableSelect3
                    label="Tracking Device"
                    name="DeviceId"
                    valueSelect={values?.DeviceId}
                    setFieldValue={setFieldValue}
                    options={TrackingDevices.map((x) => {
                      return {
                        value: x.Id,
                        label: x?.TrackiId + " - " + x?.Name,
                      };
                    })}
                  />
                </div>
                <div className={"col-lg-4 mt-5"} />
                <div className="col-lg-4 mt-5">
                  <label>.</label>
                  <button
                    type="submit"
                    className="btn btn-primary btn-elevate w-100"
                  >
                    Save
                  </button>
                </div>
              </div>
              <input
                type="button"
                className="d-none"
                value="0"
                id="GetShipmentDataTable"
                onClick={() => {
                  GetShipmentDataHeader();
                }}
              />
              {TableShow && (
                <div id="ShipmentsTable" className=" py-5">
                  <TableFunction
                    Items={remark}
                    Warehouse={Warehouse}
                    GetShipmentData={GetShipmentData}
                    SHipmentId={SHipmentId}
                    setremark={setremark}
                    GetShipmentDataHeader={GetShipmentDataHeader}
                  />
                </div>
              )}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

const TableFunction = ({
  Items,
  Warehouse,
  GetShipmentData,
  SHipmentId,
  setremark,
  GetShipmentDataHeader,
}) => {
  const [Remark, setRemark] = useState();
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      marginTop: theme.spacing(1),
      width: "100%",
      overflowX: "auto",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    let obj = {
      ShipmentItems: Items?.ShipmentItems || [],
      SumQuantity: Items?.SumQuantity || 0,
      SumWeight: Items?.SumWeight || 0,
      SumCube: Items?.SumCube || 0,
      Uom: Items?.Uom || "",
      WeightType: Items?.WeightType || "",
      CubeType: Items?.CubeType || "",
    };
    setRemark(obj);
  }, [Items]);

  return (
    <>
      <Paper className={classes.paper}>
        <div className="react-bootstrap-table table-responsive TabelFilter">
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Id
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Purchase Order
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Sku
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Quantity
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Unit Of Measure
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Description
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Location
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Weight
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Weight Type
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Cube
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Cube Type
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Remark?.ShipmentItems != [] &&
                Remark?.ShipmentItems.map((item, index) => (
                  <TableTrFunction
                    item={item}
                    key={index}
                    Warehouse={Warehouse}
                    GetShipmentData={GetShipmentData}
                    SHipmentId={SHipmentId}
                    setremark={setremark}
                    GetShipmentDataHeader={GetShipmentDataHeader}
                  />
                ))}
            </TableBody>

            <TableHead>
              <TableRow>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  Total
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5"></TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5"></TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  {Math.round(Remark?.SumQuantity * 100) / 100}
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  {Remark?.Uom}
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5"></TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5"></TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  {Math.round(Remark?.SumWeight * 100) / 100}
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  {Remark?.WeightType}
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  {Math.round(Remark?.SumCube * 100) / 100}
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5">
                  {Remark?.CubeType}
                </TableCell>
                <TableCell className="bg-light text-dark pt-5 pb-5"></TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </div>
      </Paper>
    </>
  );
};

const TableTrFunction = ({ item, Warehouse, GetShipmentDataHeader }) => {
  return (
    <TableRow key={item.Id}>
      <TableCell component="th" scope="row">
        {item.Id}
      </TableCell>
      <TableCell>{item.PurchaseOrder}</TableCell>
      <TableCell>{item.Sku}</TableCell>
      <TableCell>{item.Quantity}</TableCell>
      <TableCell>{item.UnitOfMeasure}</TableCell>
      <TableCell>{item.Description}</TableCell>
      <TableCell>{item.Location}</TableCell>
      <TableCell>{item.Weight?.toFixed(2)}</TableCell>
      <TableCell>{item.WeightType}</TableCell>
      <TableCell>{item.Cube?.toFixed(2)}</TableCell>
      <TableCell>{item.CubeType}</TableCell>
      <TableCell>
        <input
          value="Delete"
          type="button"
          className="btn btn-danger text-white font-weight-bolder font-size-sm w-100 p-1"
          onClick={(event) => {
            DeletehipmentRow(event, item.Id, Warehouse).then(() => {
              GetShipmentDataHeader();
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
};

const DeletehipmentRow = async (event, Id, Warehouse) => {
  $(".LoadingId").fadeIn();
  await axios.post("api/FindOrderContainers/DeleteShipment", {
    data: { Id, Warehouse },
  });
};

function CheckVandorIsDanger(Vendors, x, FreightChargeId) {
  var checkBool = false;
  var checkVendor = Vendors.find((y) => y?.Id === x);
  if (
    checkVendor &&
    checkVendor.AlertType === "Danger" &&
    FreightChargeId === 98
  ) {
    checkBool = true;
    const alertMessage = checkVendor?.AlertMessage || [];
    alertMessage.map((x) => {
      const msgType = checkVendor?.AlertType === "Danger" ? "error" : "warning";
      Notification(msgType, checkVendor?.Name + " : " + x);
    });
  }

  return checkBool;
}
