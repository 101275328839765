import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useLocation } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { Notification } from "../../../../../app/modules/Notification";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";
import { Input } from "../../../../_partials/controls";
const adapter = new LocalStorage("db");
const db = low(adapter);
function createMarkup(dataString) {
  return { __html: dataString };
}
export function AsideMenuList({ layoutProps }) {
  let history = useHistory();
  const [menuType, setmenuType] = useState(1);
  const [menuListLinks, setMenuListLinks] = useState([]);
  const [releaseNotes, setReleaseNotesList] = useState([]);
  const [itemRelease, setItemRelease] = useState(null);

  const [fetchStatus, setFetchStatus] = useState(400);
  const location = useLocation();

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  const CheckIsExist = (url) => {
    if (window.TabsIsExist.includes(url)) {
      return true;
    } else return false;
  };

  const getMenuItemShowing = (menu) => {
    /* if (!window.monuType) window.location.href = "/Home";
    return menu === window.monuType ? "" : "d-none"; */
  };
  async function GetMenuList() {
    return await fetch(window.$apiurl + "/menu", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 401) {
          Swal.fire({
            position: "center",
            icon: "info",
            title: "Session expired. Logout ...",
            showConfirmButton: false,
            timer: 3000,
          });
          setTimeout(function() {
            const toggle = document.getElementById("kt_quick_user_toggle");
            if (toggle) {
              toggle.click();
            }
            history.push("/logout");
          }, 2000);
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const menus = res[1];
        try {
          setFetchStatus(res[0]);
        } catch (err) {}
        menus.map((item) => {
          item.SubMenu.map((itemX) => {
            itemX.Options = JSON.parse(itemX.Options);
          });
        });
        setMenuListLinks(menus);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetReleaseNotesList() {
    return await fetch(window.$apiurl + "/generic/releaseNotes/get", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const list = res[1];
        list.sort(function(a, b) {
          return new Date(b.Created) - new Date(a.Created);
        });
        try {
          if (list.length > 0) {
            if (
              moment(list[0].Created).isSame(Date.now(), "day") &&
              !localStorage[`ReleaseNotesModalFetchSeenToday-${list[0].Id}`]
            ) {
              window.$isSeenToday = true;
              window.$idReleaseToday = list[0].Id;
              onHideModal(true);
            }
          }
        } catch (err) {}
        setItemRelease(list?.length > 0 && list[0]);
        setReleaseNotesList(list || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    GetMenuList();
    GetReleaseNotesList();
  }, []);
  // useEffect(() => {
  //   var href = window.location.pathname;
  //   if (href.indexOf("/transload") > -1) setmenuType(2);
  //   else setmenuType(1);
  // });

  function getSvgItems(name) {
    let svg = "";
    switch (name) {
      case "Dashboard": {
        svg = (
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/vessel.svg")}
          />
        );
        break;
      }
      case "Orders": {
        svg = (
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/orders.svg")}
          />
        );
        break;
      }
      case "Receipts": {
        svg = (
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/receipt.svg")}
          />
        );
        break;
      }
      case "Trips": {
        svg = <SVG src={toAbsoluteUrl("/media/svg/tracking/distance.svg")} />;
        break;
      }
      case "Shipments": {
        svg = (
          <SVG
            src={toAbsoluteUrl(
              "/media/svg/icons/Communication/shipment-list.svg"
            )}
          />
        );
        break;
      }
      case "Purchase Orders": {
        svg = (
          <SVG
            src={toAbsoluteUrl(
              "/media/svg/icons/Communication/shipment-list.svg"
            )}
          />
        );
        break;
      }
      case "Users": {
        svg = (
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
          />
        );
        break;
      }
      case "Customers": {
        svg = <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />;
        break;
      }
      case "Items": {
        svg = <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Menu.svg")} />;
        break;
      }
      case "Warehouses": {
        svg = <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />;
        break;
      }
      case "Admin": {
        svg = <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />;
        break;
      }
      case "Billing": {
        svg = (
          <SVG
            src={toAbsoluteUrl(
              "/media/svg/icons/Communication/purchase-order.svg"
            )}
          />
        );
        break;
      }
      case "Reporting": {
        svg = (
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")}
          />
        );
        break;
      }
      case "Addresses": {
        svg = (
          <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Location-arrow.svg")} />
        );
        break;
      }
      case "Tools": {
        svg = (
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Design/Pen-tool-vector.svg")}
          />
        );
        break;
      }
      case "Services/Modes": {
        svg = (
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} />
        );
        break;
      }
      case "Vessel/Voyage": {
        svg = (
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/vessel.svg")}
          />
        );
        break;
      }
      case "Units of measure": {
        svg = (
          <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Settings.svg")} />
        );
        break;
      }
      case "MIFF": {
        svg = (
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Design/Pen-tool-vector.svg")}
          />
        );
        break;
      }
      case "System": {
        svg = (
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/systemV3.svg")}
          />
        );
        break;
      }
      case "eMpower": {
        svg = (
          <img
            alt="Logo"
            style={{ height: "13px" }}
            src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
          />
        );
        break;
      }
      default: {
        svg = (
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/vessel.svg")}
          />
        );
      }
    }
    return svg;
  }
  const [showModalPassword, onHideModalPassword] = React.useState(false);
  const [passwordValue, setPassword] = React.useState("");
  const [passwordConfirmValue, setConfirmPassword] = React.useState("");

  const [show, onHideModal] = React.useState(false);
  React.useEffect(() => {
    if (localStorage[`UserIsResetedPassword`] === "true") {
      onHideModalPassword(true);
    } else {
      onHideModalPassword(false);
    }
  }, [localStorage[`UserIsResetedPassword`]]);
  const [typePassword, setTypePassword] = useState("password");
  const [passwordVisible, setPasswordVisible] = useState(false);

  React.useEffect(() => {
    if (
      localStorage[
        `ReleaseNotesModalFetchSeen-${db.read().getState().User?.Email}`
      ] === undefined
    ) {
      setTimeout(() => {
        localStorage.setItem(
          `ReleaseNotesModalFetchSeen-${db.read().getState().User?.Email}`,
          true
        );
      }, 1);
    }
    if (
      localStorage[
        `ReleaseNotesModalFetchSeen-${db.read().getState().User?.Email}`
      ] === "true"
    ) {
      onHideModal(true);
    } else {
      onHideModal(false);
    }
  }, [
    localStorage[
      `ReleaseNotesModalFetchSeen-${db.read().getState().User?.Email}`
    ],
  ]);
  function getBuildDate(epoch) {
    const buildDate = moment(epoch).format("MM-DD-YYYY");
    return buildDate;
  }

  //Forgot Password
  async function ResetPassword() {
    return fetch(
      window.$apiurl +
        `/users/resetPassword?email=${
          db.read().getState().User?.Email
        }&password=${passwordValue}`,
      {
        method: "post",
        withCredentials: true,
        headers: {
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        return a;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetBuildVersion() {
    return fetch(
      window.$apiurl + `/generic/GetOptionFromWebConfig/AppVersion`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 401) {
          Swal.fire({
            position: "center",
            icon: "info",
            title: "Session expired. Logout ...",
            showConfirmButton: false,
            timer: 3000,
          });
          setTimeout(function() {
            const toggle = document.getElementById("kt_quick_user_toggle");
            if (toggle) {
              toggle.click();
            }
            history.push("/logout");
          }, 2000);
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        return a;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function ForceClearCache() {
    try {
      const result = await GetBuildVersion();
      if (
        result !== undefined &&
        window.location.host !== "localhost:8000" &&
        window.location.host !== "localhost:8001"
      ) {
        if (result !== window.$BuildVersion) {
          Swal.fire({
            position: "center",
            icon: "info",
            title: "New Build Version Released. Clearing Cache now ...",
            showConfirmButton: false,
            timer: 3000,
          });
          setTimeout(function() {
            if (caches) {
              caches.keys().then((names) => {
                for (const name of names) {
                  caches.delete(name);
                }
              });
            }
            window.location.reload(true);
          }, 2000);
        }
      }
    } catch (err) {}
  }
  return (
    <>
      {
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          {menuListLinks?.map((item, index) => {
            if (item.Name === "Reporting") {
              return (
                <>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/Reporting/Report"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                        to={`/Reporting/Report`}
                      onClick={(e) => {
                        window.CreateTabe = "/Reporting/Report";
                        document.getElementById("CreateTabe").click();
                        if (CheckIsExist("/Reporting/Report")) {
                          e.preventDefault();
                          return false;
                        }
                      }}                    
                    >
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Files/Selected-file.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Reporting</span>
                    </NavLink>
                  </li>
                </>
              );
            } else {
              return (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    `/${item.Name}`
                  )}`}
                  key={index}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink
                    className="menu-link menu-toggle"
                    to={`/${item.Name}`}
                    onClick={() => {
                      ForceClearCache();
                    }}
                  >
                    <span className="svg-icon menu-icon">
                      {getSvgItems(item.Name)}
                    </span>
                    <span className="menu-text">{item.Name}</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    {item.SubMenu?.map((itemX, indexX) => (
                      <ul className="menu-subnav" key={indexX}>
                        <li
                          className={`menu-item ${getMenuItemActive(
                            itemX.Options.url
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link"
                            to={itemX.Options.url}
                            onClick={(e) => {
                              window.CreateTabe = itemX.Options.url;
                              document.getElementById("CreateTabe").click();
                              if (CheckIsExist(itemX.Options.url)) {
                                e.preventDefault();
                                return false;
                              }
                            }}                           
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">{itemX.Name}</span>
                          </NavLink>
                        </li>
                      </ul>
                    ))}
                  </div>
                </li>
              );
            }
          })}
        </ul>
      }
      {/* begin::Menu Nav */}

      {/* Modal Release Notes */}
      {!showModalPassword && fetchStatus === 200 && (
        <Modal
          show={
            window.location.pathname === `/ReleaseNotes/${window.$BuildVersion}`
              ? false
              : show
          }
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Release Notes
            </Modal.Title>
            <IconButton
              
              aria-label="Close"
              onClick={() => {
                try {
                  const isTodaySeen = window.$isSeenToday;
                  const idRelease = window.$idReleaseToday;
                  if (isTodaySeen) {
                    setTimeout(() => {
                      localStorage.setItem(
                        `ReleaseNotesModalFetchSeenToday-${idRelease}`,
                        true
                      );
                    }, 1);
                  }
                  setTimeout(() => {
                    localStorage.setItem(
                      `ReleaseNotesModalFetchSeen-${
                        db.read().getState().User?.Email
                      }`,
                      false
                    );
                  }, 1);
                  onHideModal(false);
                } catch (err) {}
              }}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <div className="col-lg-12">
                <Card className="border-0 mb-5">
                  <div
                    className="label label-lg label-light-primary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Build Version : {itemRelease?.Title} {"   "} | {"   "} Build
                    Date : {moment(itemRelease?.Created).format("MM-DD-YYYY")}
                  </div>
                  <div className="p-3">
                    <div
                      dangerouslySetInnerHTML={createMarkup(itemRelease?.Body)}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                type="button"
                onClick={() => {
                  try {
                    const isTodaySeen = window.$isSeenToday;
                    const idRelease = window.$idReleaseToday;
                    if (isTodaySeen) {
                      setTimeout(() => {
                        localStorage.setItem(
                          `ReleaseNotesModalFetchSeenToday-${idRelease}`,
                          true
                        );
                      }, 1);
                    }
                    setTimeout(() => {
                      localStorage.setItem(
                        `ReleaseNotesModalFetchSeen-${
                          db.read().getState().User?.Email
                        }`,
                        false
                      );
                    }, 1);
                    onHideModal(false);
                  } catch (err) {}
                }}
                className="btn btn-light btn-elevate mr-2"
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        // <Modal
        //   show={
        //     window.location.pathname === `/ReleaseNotes/${window.$BuildVersion}`
        //       ? false
        //       : show
        //   }
        //   // show={true}
        //   size="lg"
        //   // aria-labelledby="contained-modal-title-vcenter"
        //   // centered
        // >
        //   <Modal.Body>
        //     <div className="row form-group">
        //       <img
        //         alt="Logo"
        //         className="max-h-70px"
        //         src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
        //       />

        //       <h3>eMPower Release Notes</h3>
        //     </div>
        //     <hr />
        //     {releaseNotes.length > 0 &&
        //       releaseNotes.map((item) => (
        //         <div className="row from-group mb-4">
        //           <div className="col-lg-12">
        //             <div className="d-flex align-items-center bg-light-info rounded p-5">
        //               <span className="svg-icon svg-icon-info mr-5">
        //                 <SVG
        //                   src={toAbsoluteUrl(
        //                     "/media/svg/icons/General/Attachment2.svg"
        //                   )}
        //                   className="svg-icon svg-icon-lg"
        //                 ></SVG>
        //               </span>

        //               <div className="d-flex flex-column flex-grow-1 mr-2">
        //                 <a
        //                   onClick={() => {
        //                     try {
        //                       setTimeout(() => {
        //                         localStorage.setItem(
        //                           `ReleaseNotesModalFetchSeen-${
        //                             db.read().getState().User?.Email
        //                           }`,
        //                           false
        //                         );
        //                       }, 1);
        //                       onHideModal(false);

        //                     } catch (err) {}
        //                   }}
        //                   role="button"
        //                   // target="_blank"
        //                   // href={`/ReleaseNotes/${window.$BuildVersion}`}
        //                   className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"
        //                 >
        //                   Release Notes V{item.BuildVersion}
        //                 </a>
        //                 <span className="text-muted font-size-sm">
        //                   Build Date{" "}
        //                   <strong>{getBuildDate(item.Created)}</strong>
        //                 </span>
        //               </div>

        //               <span className="font-weight-bolder text-info py-1 font-size-lg">
        //                 <i class="fas fa-arrow-circle-right"></i>
        //               </span>
        //             </div>
        //           </div>
        //         </div>
        //       ))}
        //     {releaseNotes.length === 0 && (
        //       <>
        //         <div className="form-group row">
        //           <div
        //             className="col-lg-12 p-5 mt-md-9"
        //             style={{ textAlign: "center" }}
        //           >
        //             <h3 className="text-muted">No Release notes yet</h3>
        //           </div>
        //         </div>
        //       </>
        //     )}
        //   </Modal.Body>
        //   <Modal.Footer>
        //     <button
        //       type="button"
        //       className="btn btn-light btn-elevate"
        //       onClick={() => {
        //         try {
        //           const isTodaySeen = window.$isSeenToday;
        //           const idRelease = window.$idReleaseToday;
        //           if (isTodaySeen) {
        //             setTimeout(() => {
        //               localStorage.setItem(
        //                 `ReleaseNotesModalFetchSeenToday-${idRelease}`,
        //                 true
        //               );
        //             }, 1);
        //           }
        //           setTimeout(() => {
        //             localStorage.setItem(
        //               `ReleaseNotesModalFetchSeen-${
        //                 db.read().getState().User?.Email
        //               }`,
        //               false
        //             );
        //           }, 1);
        //           onHideModal(false);
        //         } catch (err) {}
        //       }}
        //     >
        //       Close
        //     </button>
        //   </Modal.Footer>
        // </Modal>
      )}

      <Modal
        show={showModalPassword}
        // show={true}
        size="lg"
        // aria-labelledby="contained-modal-title-vcenter"
        // centered
      >
        <Modal.Body>
          <div className="row form-group">
            <div className="col-lg-12">
              <h3>Update Password</h3>
            </div>
          </div>
          <hr />
          <div className="row from-group mb-4">
            <div className="col-lg-12">
              <div className="form-group row">
                <div className="col-lg-12">
                  <label>New Password</label>
                  <span
                    className="passwordEye1 ml-3"
                    onClick={() => {
                      if (passwordVisible) {
                        setTypePassword("password");
                        setPasswordVisible(false);
                      } else {
                        setTypePassword("text");
                        setPasswordVisible(true);
                      }
                    }}
                  >
                    {passwordVisible && <i class="fas fa-eye"></i>}
                    {!passwordVisible && <i class="fas fa-eye-slash"></i>}
                  </span>
                  <input
                    className="form-control"
                    name="Password"
                    type={typePassword}
                    component={Input}
                    placeholder="New Password"
                    value={passwordValue}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-12">
                  <label>Confirm New Password</label>
                  <input
                    className="form-control"
                    name="Password"
                    type={typePassword}
                    component={Input}
                    placeholder="Confirm New Password"
                    value={passwordConfirmValue}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-elevate"
            onClick={async () => {
              try {
                if (passwordValue === passwordConfirmValue) {
                  const result = await ResetPassword();
                  if (result.Success) {
                    Notification("success", "Password updated successfully !");
                    setTimeout(() => {
                      localStorage.setItem(`UserIsResetedPassword`, false);
                    }, 1);
                    onHideModalPassword(false);
                    onHideModal(true);
                  }
                } else {
                  Notification(
                    "error",
                    "Password and Confirm Password does not match"
                  );
                }
              } catch (err) {}
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
