import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, ProgressBar } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import SVG from "react-inlinesvg";
import { Link, useHistory } from "react-router-dom";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  toAbsoluteUrl,
} from "../../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination,
} from "../../../../../_metronic/_partials/controls";
import { DropdownCustomTo } from "../../../../../_metronic/_partials/dropdowns";
import { ClickFun } from "../../../../SpecificTabs";
import { ProductsFilter } from "./products-filter/ProductsFilter";
import * as columnFormatters from "./products-table/column-formatters";
import { useProductsUIContext } from "./ProductsUIContext";
var Spinner = require("react-spinkit");
const adapter = new LocalStorage("db");
const db = low(adapter);

//Pagination Option
var totalItemsNew = 0;
var totalItemsBooked = 0;
var totalItemsAtShipper = 0;
var totalItemsInTransit = 0;
var totalItemsAtReceiver = 0;
var totalItemsDelivered = 0;

export function ProductsCard() {
  const history = useHistory();

  const FilterTable = [
    {
      name: "New Order",
      color: "danger",
    },
    {
      name: "Booked",
      color: "warning",
    },
    {
      name: "Out for Pickup",
      color: "secondary",
    },
    {
      name: "At Shipper",
      color: "primary",
    },
    {
      name: "At Receiver",
      color: "info",
    },
    {
      name: "Delivered",
      color: "success",
    },
  ];
  const [ShowData, setShowData] = useState("");
  const [loadingData, setloadingData] = useState(false);
  const [loadingDataNew, setloadingDataNew] = useState(false);
  const [loadingDataBooked, setloadingDataBooked] = useState(false);
  const [loadingDataAtShipper, setloadingDataAtShipper] = useState(false);
  const [loadingDataInTransit, setloadingDataInTransit] = useState(false);
  const [loadingDataAtReceiver, setloadingDataAtReceiver] = useState(false);
  const [loadingDataDelivered, setloadingDataDelivered] = useState(false);
  const [IsFilterHide, setIsFilterHide] = useState(false);
  const [NewOrderData, setNewOrderData] = useState([]);
  const [BookedData, setBookedData] = useState([]);
  const [AtShipperData, setAtShipperData] = useState([]);
  const [InTransitData, setInTransitData] = useState([]);
  const [AtReceiverData, setAtReceiverData] = useState([]);
  const [DeliveredData, setDeliveredData] = useState([]);
  const productsUIContext = useProductsUIContext();
  const [PaginationProperty, setPaginationProperty] = useState({
    pageNumber: 1,
    pageSize: 4,
    sortField: "Id",
    sortOrder: "desc",
  });
  const [paginationOptionsNew, setpaginationOptionsNew] = useState({
    custom: true,
    totalSize: totalItemsNew,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "4", value: 4 },
      { text: "8", value: 8 },
      { text: "25", value: 25 },
      {
        text: String(totalItemsNew),
        value: totalItemsNew,
      },
    ],
    sizePerPage: 4,
    page: 1,
    fromLoading: true,
  });
  const [paginationOptionsBooked, setpaginationOptionsBooked] = useState({
    custom: true,
    totalSize: totalItemsBooked,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "4", value: 4 },
      { text: "8", value: 8 },
      { text: "25", value: 25 },
      {
        text: String(totalItemsBooked),
        value: totalItemsBooked,
      },
    ],
    sizePerPage: 4,
    page: 1,
  });
  const [paginationOptionsAtShipper, setpaginationOptionsAtShipper] = useState({
    custom: true,
    totalSize: totalItemsAtShipper,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "4", value: 4 },
      { text: "8", value: 8 },
      { text: "25", value: 25 },
      {
        text: String(totalItemsAtShipper),
        value: totalItemsAtShipper,
      },
    ],
    sizePerPage: 4,
    page: 1,
  });
  const [paginationOptionsInTransit, setpaginationOptionsInTransit] = useState({
    custom: true,
    totalSize: totalItemsInTransit,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "4", value: 4 },
      { text: "8", value: 8 },
      { text: "25", value: 25 },
      {
        text: String(totalItemsInTransit),
        value: totalItemsInTransit,
      },
    ],
    sizePerPage: 4,
    page: 1,
  });
  const [
    paginationOptionsAtReceiver,
    setpaginationOptionsAtReceiver,
  ] = useState({
    custom: true,
    totalSize: totalItemsAtReceiver,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "4", value: 4 },
      { text: "8", value: 8 },
      { text: "25", value: 25 },
      {
        text: String(totalItemsAtReceiver),
        value: totalItemsAtReceiver,
      },
    ],
    sizePerPage: 4,
    page: 1,
  });
  const [paginationOptionsDelivered, setpaginationOptionsDelivered] = useState({
    custom: true,
    totalSize: totalItemsDelivered,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "4", value: 4 },
      { text: "8", value: 8 },
      { text: "25", value: 25 },
      {
        text: String(totalItemsDelivered),
        value: totalItemsDelivered,
      },
    ],
    sizePerPage: 4,
    page: 1,
  });
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      queryParams: productsUIContext.queryParams,
      setQueryParams: productsUIContext.setQueryParams,
      newProductButtonClick: productsUIContext.newProductButtonClick,
      newCreateOrderButtonClick: productsUIContext.newCreateOrderButtonClick,
      openDeleteProductsDialog: productsUIContext.openDeleteProductsDialog,
      openEditProductPage: productsUIContext.openEditProductPage,
      openDetailProductPage: productsUIContext.openDetailProductPage,
      openUpdateProductsStatusDialog:
        productsUIContext.openUpdateProductsStatusDialog,
      openFetchProductsDialog: productsUIContext.openFetchProductsDialog,
    };
  }, [productsUIContext]);
  // Table columns
  const columns = [
    {
      dataField: "Id",
      text: "SO#",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <Link
            to={`/TransportManagementSystems/SoDetail/${row.Id}`}
            onClick={(e) => {
              ClickFun(e, `/TransportManagementSystems/SoDetail/`, row.Id);
            }}
          >
            {row.Id}
          </Link>
        );
      },

      classes: "text-center pr-0 text-primary font-weight-bolder",
      headerClasses: "text-center pr-3",
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "FromCity",
      text: "PU City/St",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.FromCity + ", " + row.FromState;
      },
    },
    {
      dataField: "ToCity",
      text: "Del City/St",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.ToCity + ", " + row.ToState;
      },
    },
    {
      dataField: "Mode",
      text: "Mode",
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  //End Pagination option
  // useEffect(() => {
  //   let Paginationvalues = { ...PaginationProperty };
  //   Paginationvalues.pageSize = 10;
  //   setPaginationProperty(Paginationvalues);
  //   paginationOptions.sizePerPage = 10;
  // }, [FilterFormData.StatusCode, FilterFormData.StatusName]);
  async function GetDataLoadBoardFunction() {
    setloadingData(true);
    let filter = productsUIProps.queryParams.filter;
    var carriers = filter.CarrierId;
    var Customers = filter.CustomerId;
    var CapacityOperationId = filter.CapacityOperationId;
    var CommercialAssigneeId = filter.CommercialAssigneeId;
    var CommercialOperationId = filter.CommercialOperationId;
    var search = filter.SearchText;
    var startDate = filter.startDate;
    var endDate = filter.endDate;
    var Modes = filter.ModeId;
    if (carriers !== "" && carriers !== null && carriers !== undefined) {
      var listcarriers = carriers.map((a) => a.value);
      carriers = "&CarrierId=" + listcarriers.join("&CarrierId=");
    }
    if (Customers !== "" && Customers !== null && Customers !== undefined) {
      var listCustomers = Customers.map((a) => a.value);
      Customers = "&CustomerId=" + listCustomers.join("&CustomerId=");
    }
    var listCapacityOperationId = ""
    if (
      CapacityOperationId !== "" &&
      CapacityOperationId !== null &&
      CapacityOperationId !== undefined
    ) {
      listCapacityOperationId = CapacityOperationId.map((a) => {
        if (a.IsGroup) {
          return "&capacityOperationGroupId=" + a.value;
        } else {
          return "&CapacityOperationId=" + a.value;
        }
      });
    }

    var listCommercialAssigneeId ="";
    if (
      CommercialAssigneeId !== "" &&
      CommercialAssigneeId !== null &&
      CommercialAssigneeId !== undefined
    ) {
      listCommercialAssigneeId = CommercialAssigneeId.map((a) => {
        if (a.IsGroup) {
          return "&commercialAssigneeGroupId=" + a.value;
        } else {
          return "&CommercialAssigneeId=" + a.value;
        }
      });
    }

    var listCommercialOperationId ="";
    if (
      CommercialOperationId !== "" &&
      CommercialOperationId !== null &&
      CommercialOperationId !== undefined
    ) {
      listCommercialOperationId = CommercialOperationId.map((a) => {
        if (a.IsGroup) {
          return "&commercialOperationGroupId=" + a.value;
        } else {
          return "&CommercialOperationId=" + a.value;
        }
      });
    }
    if (Modes !== "" && Modes !== null && Modes !== undefined) {
      var listModes = Modes.map((a) => a.value);
      Modes = "&ModeId=" + listModes.join("&ModeId=");
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${
        window.$apiurl
      }/tms?startDate=${startDate}&endDate=${endDate}&search=${search}${
        listCapacityOperationId === null ? "" : listCapacityOperationId
      }
      ${listCommercialAssigneeId === null ? "" : listCommercialAssigneeId}
      ${listCommercialOperationId === null ? "" : listCommercialOperationId}${
        carriers === null ? "" : carriers
      }${Customers === null ? "" : Customers}${Modes === null ? "" : Modes}`,
      requestOptions
    )
      .then(async (response) => {
        if (response.status === 401) {
          history.push("/error/401");
        }
        return response.text();
      })
      .then((result) => {
        var dataObj = JSON.parse(result);
        let _paginationOptionsNew = { ...paginationOptionsNew };
        _paginationOptionsNew.fromLoading = true;
        _paginationOptionsNew.totalSize = dataObj.Data.filter(
          (a) => a.StatusId === 2
        )[0]?.Pagination?.TotalItemCount;
        _paginationOptionsNew.sizePerPageList = [
          { text: "4", value: 4 },
          { text: "8", value: 8 },
          { text: "25", value: 25 },
          {
            text: String(
              dataObj.Data.filter((a) => a.StatusId === 2)[0]?.Pagination
                ?.TotalItemCount
            ),
            value: dataObj.Data.filter((a) => a.StatusId === 2)[0]?.Pagination
              ?.TotalItemCount,
          },
        ];
        _paginationOptionsNew.page = 1;
        setpaginationOptionsNew(_paginationOptionsNew);
        //booked
        var _paginationOptionsBooked = { ...paginationOptionsBooked };

        _paginationOptionsBooked.totalSize = dataObj.Data.filter(
          (a) => a.StatusId === 98
        )[0]?.Pagination?.TotalItemCount;
        _paginationOptionsBooked.sizePerPageList = [
          { text: "4", value: 4 },
          { text: "8", value: 8 },
          { text: "25", value: 25 },
          {
            text: String(
              dataObj.Data.filter((a) => a.StatusId === 98)[0]?.Pagination
                ?.TotalItemCount
            ),
            value: dataObj.Data.filter((a) => a.StatusId === 98)[0]?.Pagination
              ?.TotalItemCount,
          },
        ];
        _paginationOptionsBooked.page = 1;
        setpaginationOptionsBooked(_paginationOptionsBooked);
        //At shipper
        var _paginationOptionsAtShipper = { ...paginationOptionsAtShipper };
        _paginationOptionsAtShipper.totalSize = dataObj.Data.filter(
          (a) => a.StatusId === 99
        )[0]?.Pagination?.TotalItemCount;
        _paginationOptionsAtShipper.sizePerPageList = [
          { text: "4", value: 4 },
          { text: "8", value: 8 },
          { text: "25", value: 25 },
          {
            text: String(
              dataObj.Data.filter((a) => a.StatusId === 99)[0]?.Pagination
                ?.TotalItemCount
            ),
            value: dataObj.Data.filter((a) => a.StatusId === 99)[0]?.Pagination
              ?.TotalItemCount,
          },
        ];
        _paginationOptionsAtShipper.page = 1;
        setpaginationOptionsAtShipper(_paginationOptionsAtShipper);
        //InTransit
        var _paginationOptionsInTransit = { ...paginationOptionsInTransit };
        _paginationOptionsInTransit.totalSize = dataObj.Data.filter(
          (a) => a.StatusId === 4
        )[0]?.Pagination?.TotalItemCount;
        _paginationOptionsInTransit.sizePerPageList = [
          { text: "4", value: 4 },
          { text: "8", value: 8 },
          { text: "25", value: 25 },
          {
            text: String(
              dataObj.Data.filter((a) => a.StatusId === 4)[0]?.Pagination
                ?.TotalItemCount
            ),
            value: dataObj.Data.filter((a) => a.StatusId === 4)[0]?.Pagination
              ?.TotalItemCount,
          },
        ];
        _paginationOptionsInTransit.page = 1;
        setpaginationOptionsInTransit(_paginationOptionsInTransit);
        //AtReceiver
        var _paginationOptionsAtReceiver = { ...paginationOptionsAtReceiver };

        _paginationOptionsAtReceiver.totalSize = dataObj.Data.filter(
          (a) => a.StatusId === 100
        )[0]?.Pagination?.TotalItemCount;
        _paginationOptionsAtReceiver.sizePerPageList = [
          { text: "4", value: 4 },
          { text: "8", value: 8 },
          { text: "25", value: 25 },
          {
            text: String(
              dataObj.Data.filter((a) => a.StatusId === 100)[0]?.Pagination
                ?.TotalItemCount
            ),
            value: dataObj.Data.filter((a) => a.StatusId === 100)[0]?.Pagination
              ?.TotalItemCount,
          },
        ];
        _paginationOptionsAtReceiver.page = 1;
        setpaginationOptionsAtReceiver(_paginationOptionsAtReceiver);
        //AtReceiver
        var _paginationOptionsDelivered = { ...paginationOptionsDelivered };
        _paginationOptionsDelivered.totalSize = dataObj.Data.filter(
          (a) => a.StatusId === 12
        )[0]?.Pagination?.TotalItemCount;
        _paginationOptionsDelivered.sizePerPageList = [
          { text: "4", value: 4 },
          { text: "8", value: 8 },
          { text: "25", value: 25 },
          {
            text: String(
              dataObj.Data.filter((a) => a.StatusId === 12)[0]?.Pagination
                ?.TotalItemCount
            ),
            value: dataObj.Data.filter((a) => a.StatusId === 12)[0]?.Pagination
              ?.TotalItemCount,
          },
        ];
        _paginationOptionsDelivered.page = 1;
        setpaginationOptionsDelivered(_paginationOptionsDelivered);
        setNewOrderData(
          dataObj.Data.filter((a) => a.StatusId === 2)[0]?.Shipments
        );
        setBookedData(
          dataObj.Data.filter((a) => a.StatusId === 98)[0]?.Shipments
        );
        setAtShipperData(
          dataObj.Data.filter((a) => a.StatusId === 99)[0]?.Shipments
        );
        setInTransitData(
          dataObj.Data.filter((a) => a.StatusId === 4)[0]?.Shipments
        );
        setAtReceiverData(
          dataObj.Data.filter((a) => a.StatusId === 100)[0]?.Shipments
        );
        setDeliveredData(
          dataObj.Data.filter((a) => a.StatusId === 12)[0]?.Shipments
        );
        setloadingData(false);
      })
      .catch((error) => console.log("error", error));
  }

  async function GetDataByStatus(
    StatusId,
    page,
    sizePerPage,
    sortField,
    sortOrder
  ) {
    switch (StatusId) {
      case 2:
        setloadingDataNew(true);
        break;
      case 98:
        setloadingDataBooked(true);
        break;
      case 99:
        setloadingDataAtShipper(true);
        break;
      case 4:
        setloadingDataInTransit(true);
        break;
      case 100:
        setloadingDataAtReceiver(true);
        break;
      case 12:
        setloadingDataDelivered(true);
        break;
      default:
        break;
    }
    let filter = productsUIProps.queryParams.filter;
    var carriers = filter.CarrierId;

    var Customers = filter.CustomerId;
    var CapacityOperationId = filter.CapacityOperationId;
    var CommercialAssigneeId = filter.CommercialAssigneeId;
    var CommercialOperationId = filter.CommercialOperationId;
    var search = filter.SearchText;
    var startDate = filter.startDate;
    var endDate = filter.endDate;
    var Modes = filter.ModeId;
    if (carriers !== "" && carriers !== null && carriers !== undefined) {
      var listcarriers = carriers.map((a) => a.value);
      carriers = "&CarrierId=" + listcarriers.join("&CarrierId=");
    }
    if (Customers !== "" && Customers !== null && Customers !== undefined) {
      var listCustomers = Customers.map((a) => a.value);
      Customers = "&CustomerId=" + listCustomers.join("&CustomerId=");
    }
    if (
      CapacityOperationId !== "" &&
      CapacityOperationId !== null &&
      CapacityOperationId !== undefined
    ) {
      var listCapacityOperationId = CapacityOperationId.map((a) => a.value);
      CapacityOperationId =
        "&CapacityOperationId=" +
        listCapacityOperationId.join("&CapacityOperationId=");
    }
    if (
      CommercialAssigneeId !== "" &&
      CommercialAssigneeId !== null &&
      CommercialAssigneeId !== undefined
    ) {
      var listCommercialAssigneeId = CommercialAssigneeId.map((a) => a.value);
      CommercialAssigneeId =
        "&CommercialAssigneeId=" +
        listCommercialAssigneeId.join("&CommercialAssigneeId=");
    }
    if (
      CommercialOperationId !== "" &&
      CommercialOperationId !== null &&
      CommercialOperationId !== undefined
    ) {
      var listCommercialOperationId = CommercialOperationId.map((a) => a.value);
      CommercialOperationId =
        "&CommercialOperationId=" +
        listCommercialOperationId.join("&CommercialOperationId=");
    }
    if (Modes !== "" && Modes !== null && Modes !== undefined) {
      var listModes = Modes.map((a) => a.value);
      Modes = "&ModeId=" + listModes.join("&ModeId=");
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${
        window.$apiurl
      }/tms?startDate=${startDate}&endDate=${endDate}&search=${search}&statusId=${StatusId}${
        CapacityOperationId === null ? "" : CapacityOperationId
      }
      ${CommercialAssigneeId === null ? "" : CommercialAssigneeId}
      ${CommercialOperationId === null ? "" : CommercialOperationId}${
        carriers === null ? "" : carriers
      }${Customers === null ? "" : Customers}${
        Modes === null ? "" : Modes
      }&pageNumber=${page}&pageSize=${sizePerPage}&sortField=${sortField}&sortOrder=${sortOrder}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        var dataObj = JSON.parse(result);
        if (StatusId === 2) {
          paginationOptionsNew.totalSize = dataObj.Data.filter(
            (a) => a.StatusId === 2
          )[0]?.Pagination?.TotalItemCount;
          paginationOptionsNew.sizePerPageList = [
            { text: "4", value: 4 },
            { text: "8", value: 8 },
            { text: "25", value: 25 },
            {
              text: String(
                dataObj.Data.filter((a) => a.StatusId === 2)[0]?.Pagination
                  ?.TotalItemCount
              ),
              value: dataObj.Data.filter((a) => a.StatusId === 2)[0]?.Pagination
                ?.TotalItemCount,
            },
          ];
          setNewOrderData(
            dataObj.Data.filter((a) => a.StatusId === 2)[0]?.Shipments
          );
        } else if (StatusId === 98) {
          //booked
          paginationOptionsBooked.totalSize = dataObj.Data.filter(
            (a) => a.StatusId === 98
          )[0]?.Pagination?.TotalItemCount;
          paginationOptionsBooked.sizePerPageList = [
            { text: "4", value: 4 },
            { text: "8", value: 8 },
            { text: "25", value: 25 },
            {
              text: String(
                dataObj.Data.filter((a) => a.StatusId === 98)[0]?.Pagination
                  ?.TotalItemCount
              ),
              value: dataObj.Data.filter((a) => a.StatusId === 98)[0]
                ?.Pagination?.TotalItemCount,
            },
          ];
          setBookedData(
            dataObj.Data.filter((a) => a.StatusId === 98)[0]?.Shipments
          );
        } else if (StatusId === 99) {
          //At shipper
          paginationOptionsAtShipper.totalSize = dataObj.Data.filter(
            (a) => a.StatusId === 99
          )[0]?.Pagination?.TotalItemCount;
          paginationOptionsAtShipper.sizePerPageList = [
            { text: "4", value: 4 },
            { text: "8", value: 8 },
            { text: "25", value: 25 },
            {
              text: String(
                dataObj.Data.filter((a) => a.StatusId === 99)[0]?.Pagination
                  ?.TotalItemCount
              ),
              value: dataObj.Data.filter((a) => a.StatusId === 99)[0]
                ?.Pagination?.TotalItemCount,
            },
          ];
          setAtShipperData(
            dataObj.Data.filter((a) => a.StatusId === 99)[0]?.Shipments
          );
        } else if (StatusId === 4) {
          //InTransit
          paginationOptionsInTransit.totalSize = dataObj.Data.filter(
            (a) => a.StatusId === 4
          )[0]?.Pagination?.TotalItemCount;
          paginationOptionsInTransit.sizePerPageList = [
            { text: "4", value: 4 },
            { text: "8", value: 8 },
            { text: "25", value: 25 },
            {
              text: String(
                dataObj.Data.filter((a) => a.StatusId === 4)[0]?.Pagination
                  ?.TotalItemCount
              ),
              value: dataObj.Data.filter((a) => a.StatusId === 4)[0]?.Pagination
                ?.TotalItemCount,
            },
          ];
          setInTransitData(
            dataObj.Data.filter((a) => a.StatusId === 4)[0]?.Shipments
          );
        } else if (StatusId === 100) {
          //AtReceiver
          paginationOptionsAtReceiver.totalSize = dataObj.Data.filter(
            (a) => a.StatusId === 100
          )[0]?.Pagination?.TotalItemCount;
          paginationOptionsAtReceiver.sizePerPageList = [
            { text: "4", value: 4 },
            { text: "8", value: 8 },
            { text: "25", value: 25 },
            {
              text: String(
                dataObj.Data.filter((a) => a.StatusId === 100)[0]?.Pagination
                  ?.TotalItemCount
              ),
              value: dataObj.Data.filter((a) => a.StatusId === 100)[0]
                ?.Pagination?.TotalItemCount,
            },
          ];
          setAtReceiverData(
            dataObj.Data.filter((a) => a.StatusId === 100)[0]?.Shipments
          );
        } else if (StatusId === 12) {
          //AtReceiver
          paginationOptionsDelivered.totalSize = dataObj.Data.filter(
            (a) => a.StatusId === 12
          )[0]?.Pagination?.TotalItemCount;
          paginationOptionsDelivered.sizePerPageList = [
            { text: "4", value: 4 },
            { text: "8", value: 8 },
            { text: "25", value: 25 },
            {
              text: String(
                dataObj.Data.filter((a) => a.StatusId === 12)[0]?.Pagination
                  ?.TotalItemCount
              ),
              value: dataObj.Data.filter((a) => a.StatusId === 12)[0]
                ?.Pagination?.TotalItemCount,
            },
          ];
          setDeliveredData(
            dataObj.Data.filter((a) => a.StatusId === 12)[0]?.Shipments
          );
        }

        let values = { ...PaginationProperty };
        values.pageSize = dataObj.Data.filter(
          (a) => a.StatusId === 2
        )[0]?.Pagination?.TotalItemCount;
        setPaginationProperty(values);
        switch (StatusId) {
          case 2:
            setloadingDataNew(false);
            break;
          case 98:
            setloadingDataBooked(false);
            break;
          case 99:
            setloadingDataAtShipper(false);
            break;
          case 4:
            setloadingDataInTransit(false);
            break;
          case 100:
            setloadingDataAtReceiver(false);
            break;
          case 12:
            setloadingDataDelivered(false);
            break;
          default:
            break;
        }
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    GetDataLoadBoardFunction();
  }, [productsUIProps.queryParams]);

  const GetHandlerTableChangeNew = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationOptionsNew };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      values.fromLoading = false;
      setpaginationOptionsNew(values);
      if (
        newState.sortField !== paginationOptionsNew.sortField ||
        newState.sortOrder !== paginationOptionsNew.sortOrder
      ) {
        GetDataByStatus(
          2,
          paginationOptionsNew.page,
          paginationOptionsNew.sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
    if (type === "pagination") {
      let values = { ...paginationOptionsNew };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      values.fromLoading = false;
      setpaginationOptionsNew(values);
      GetDataByStatus(
        2,
        newState.page === undefined ? 1 : newState.page,
        newState.sizePerPage,
        paginationOptionsNew.sortField,
        paginationOptionsNew.sortOrder
      );
    }
  };

  const GetHandlerTableChangeBooked = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationOptionsBooked };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      setpaginationOptionsBooked(values);
      if (
        newState.sortField !== paginationOptionsBooked.sortField ||
        newState.sortOrder !== paginationOptionsBooked.sortOrder
      ) {
        GetDataByStatus(
          98,
          paginationOptionsBooked.page,
          paginationOptionsBooked.sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
    if (type === "pagination") {
      let values = { ...paginationOptionsBooked };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      setpaginationOptionsBooked(values);
      GetDataByStatus(
        98,
        newState.page === undefined ? 1 : newState.page,
        newState.sizePerPage,
        paginationOptionsBooked.sortField,
        paginationOptionsBooked.sortOrder
      );
    }
  };
  const GetHandlerTableChangeAtShipper = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationOptionsAtShipper };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      setpaginationOptionsAtShipper(values);
      if (
        newState.sortField !== paginationOptionsAtShipper.sortField ||
        newState.sortOrder !== paginationOptionsAtShipper.sortOrder
      ) {
        GetDataByStatus(
          99,
          paginationOptionsAtShipper.page,
          paginationOptionsAtShipper.sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
    if (type === "pagination") {
      let values = { ...paginationOptionsAtShipper };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      setpaginationOptionsAtShipper(values);
      GetDataByStatus(
        99,
        newState.page === undefined ? 1 : newState.page,
        newState.sizePerPage,
        paginationOptionsAtShipper.sortField,
        paginationOptionsAtShipper.sortOrder
      );
    }
  };

  const GetHandlerTableChangeInTransit = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationOptionsInTransit };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      setpaginationOptionsInTransit(values);
      if (
        newState.sortField !== paginationOptionsInTransit.sortField ||
        newState.sortOrder !== paginationOptionsInTransit.sortOrder
      ) {
        GetDataByStatus(
          4,
          paginationOptionsInTransit.page,
          paginationOptionsInTransit.sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
    if (type === "pagination") {
      let values = { ...paginationOptionsInTransit };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      setpaginationOptionsInTransit(values);
      GetDataByStatus(
        4,
        newState.page === undefined ? 1 : newState.page,
        newState.sizePerPage,
        paginationOptionsInTransit.sortField,
        paginationOptionsInTransit.sortOrder
      );
    }
  };
  const GetHandlerTableChangeAtReceiver = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationOptionsAtReceiver };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      setpaginationOptionsAtReceiver(values);
      if (
        newState.sortField !== paginationOptionsAtReceiver.sortField ||
        newState.sortOrder !== paginationOptionsAtReceiver.sortOrder
      ) {
        GetDataByStatus(
          100,
          paginationOptionsAtReceiver.page,
          paginationOptionsAtReceiver.sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
    if (type === "pagination") {
      let values = { ...paginationOptionsAtReceiver };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      setpaginationOptionsAtReceiver(values);
      GetDataByStatus(
        100,
        newState.page === undefined ? 1 : newState.page,
        newState.sizePerPage,
        paginationOptionsAtReceiver.sortField,
        paginationOptionsAtReceiver.sortOrder
      );
    }
  };
  const GetHandlerTableChangeDelivered = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationOptionsDelivered };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      setpaginationOptionsDelivered(values);
      if (
        newState.sortField !== paginationOptionsDelivered.sortField ||
        newState.sortOrder !== paginationOptionsDelivered.sortOrder
      ) {
        GetDataByStatus(
          12,
          paginationOptionsDelivered.page,
          paginationOptionsDelivered.sizePerPage,
          newState.sortField,
          newState.sortOrder
        );
      }
    }
    if (type === "pagination") {
      let values = { ...paginationOptionsDelivered };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      setpaginationOptionsDelivered(values);
      GetDataByStatus(
        12,
        newState.page === undefined ? 1 : newState.page,
        newState.sizePerPage,
        paginationOptionsDelivered.sortField,
        paginationOptionsDelivered.sortOrder
      );
    }
  };
  return (
    <Card>
      <CardHeader title="SO Load Board list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              var ele1 = document.getElementById("LoadBoardEdit");
              ele1.value = undefined;
              ele1.click();
            }}
          >
            Create Load Board
          </button>
          <button
            style={{ float: "right" }}
            type="button"
            disabled={loadingData}
            onClick={() => {
              GetDataLoadBoardFunction();
            }}
            className="btn btn-success ml-5"
          >
            Refresh
          </button>
          <button
            style={{ float: "right" }}
            type="button"
            onClick={() => {
              setIsFilterHide(!IsFilterHide);
            }}
            className="btn btn-primary ml-5"
          >
            {IsFilterHide === false ? "Hide" : "Show"} Filter
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ProductsFilter IsFilterHide={IsFilterHide} />
        <div className="col-md-12">
          <div className="row mb-5 justify-content-center">
            <a
              href={() => false}
              className="btn btn-icon btn-light btn-hover-info btn-sm mb-4"
              onClick={() => {
                setShowData("");
              }}
            >
              <span
                className={`svg-icon svg-icon-md svg-icon-info
                 `}
              >
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
              </span>
            </a>
            {FilterTable.map(({ name, color }) => (
              <div className="col-xl col-lg col-md-2 mb-4 pr-0">
                <p
                  className={`mb-0 py-2 px-4 text-center font-size-h6 bg-${color} ${
                    name === "Out for Pickup" ? "text-dark" : "text-white"
                  }`}
                  onClick={() => {
                    setShowData(name);
                  }}
                >
                  {name}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          {loadingData === true ? (
            <div className="col-12">
              <div className="row justify-content-center my-10">
                <Spinner name="folding-cube" color="blue" />
              </div>
            </div>
          ) : (
            ""
          )}
          {loadingData === false ? (
            <>
              {(ShowData === "New Order" || ShowData === "") && (
                <div
                  className={`mb-4 border-bottom ${
                    ShowData === "New Order" ? "col-12" : "col-xl-4 col-lg-6 col-md-6"
                  }`}
                >
                  <p
                    className={`mb-0 py-2 px-4 text-center font-size-h6 bg-danger ${
                      "New Order" === "Out for Pickup" ? "text-dark" : "text-white"
                    }`}
                  >
                    {"New Order"}
                  </p>
                  {loadingDataNew === true ? (
                    <ProgressBar
                      variant="success"
                      animated
                      now={100}
                      style={{ height: "6px", width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                  <PaginationProvider
                    pagination={paginationFactory(paginationOptionsNew)}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <Pagination paginationProps={paginationProps}>
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center"
                            bootstrap4
                            remote
                            id="New"
                            keyField="Id"
                            noDataIndication={
                              <h4 style={{ textAlign: "center" }}>
                                There is no data available at the moment.
                              </h4>
                            }
                            data={NewOrderData}
                            columns={columns}
                            defaultSorted={[{ dataField: "Id", order: "desc" }]}
                            onTableChange={GetHandlerTableChangeNew}
                            {...paginationTableProps}
                          >
                            <PleaseWaitMessage entities={NewOrderData} />
                            <NoRecordsFoundMessage entities={NewOrderData} />
                          </BootstrapTable>
                        </Pagination>
                      );
                    }}
                  </PaginationProvider>
                </div>
              )}
              {(ShowData === "Booked" || ShowData === "") && (
                <div
                  className={`mb-4 border-bottom ${
                    ShowData === "Booked" ? "col-12" : "col-xl-4 col-lg-6 col-md-6"
                  }`}
                >
                  <p
                    className={`mb-0 py-2 px-4 text-center font-size-h6 bg-warning ${
                      "Booked" === "Out for Pickup" ? "text-dark" : "text-white"
                    }`}
                  >
                    {"Booked"}
                  </p>
                  {loadingDataBooked === true ? (
                    <ProgressBar
                      variant="success"
                      animated
                      now={100}
                      style={{ height: "6px", width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                  <PaginationProvider
                    pagination={paginationFactory(paginationOptionsBooked)}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <Pagination paginationProps={paginationProps}>
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center"
                            bootstrap4
                            remote
                            id="Booked"
                            keyField="Id"
                            noDataIndication={
                              <h4 style={{ textAlign: "center" }}>
                                There is no data available at the moment.
                              </h4>
                            }
                            data={BookedData}
                            columns={columns}
                            defaultSorted={[{ dataField: "Id", order: "desc" }]}
                            onTableChange={GetHandlerTableChangeBooked}
                            {...paginationTableProps}
                          >
                            <PleaseWaitMessage entities={BookedData} />
                            <NoRecordsFoundMessage entities={BookedData} />
                          </BootstrapTable>
                        </Pagination>
                      );
                    }}
                  </PaginationProvider>
                </div>
              )}
              {(ShowData === "Out for Pickup" || ShowData === "") && (
                <div
                  className={`mb-4 border-bottom ${
                    ShowData === "Out for Pickup" ? "col-12" : "col-xl-4 col-lg-6 col-md-6"
                  }`}
                >
                  <p
                    className={`mb-0 py-2 px-4 text-center font-size-h6 bg-secondary text-dark`}
                  >
                    {"Out for Pickup"}
                  </p>
                  {loadingDataInTransit === true ? (
                    <ProgressBar
                      variant="success"
                      animated
                      now={100}
                      style={{ height: "6px", width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                  <PaginationProvider
                    pagination={paginationFactory(paginationOptionsInTransit)}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <Pagination paginationProps={paginationProps}>
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center"
                            bootstrap4
                            remote
                            id="InTransit"
                            keyField="Id"
                            noDataIndication={
                              <h4 style={{ textAlign: "center" }}>
                                There is no data available at the moment.
                              </h4>
                            }
                            data={InTransitData}
                            columns={columns}
                            defaultSorted={[{ dataField: "Id", order: "desc" }]}
                            onTableChange={GetHandlerTableChangeInTransit}
                            {...paginationTableProps}
                          >
                            <PleaseWaitMessage entities={InTransitData} />
                            <NoRecordsFoundMessage entities={InTransitData} />
                          </BootstrapTable>
                        </Pagination>
                      );
                    }}
                  </PaginationProvider>
                </div>
              )}
              {(ShowData === "At Shipper" || ShowData === "") && (
                <div
                  className={`mb-4 border-bottom ${
                    ShowData === "At Shipper" ? "col-12" : "col-xl-4 col-lg-6 col-md-6"
                  }`}
                >
                  <p
                    className={`mb-0 py-2 px-4 text-center font-size-h6 bg-primary ${
                      "At Shipper" === "Out for Pickup" ? "text-dark" : "text-white"
                    }`}
                  >
                    {"At Shipper"}
                  </p>
                  {loadingDataAtShipper === true ? (
                    <ProgressBar
                      variant="success"
                      animated
                      now={100}
                      style={{ height: "6px", width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                  <PaginationProvider
                    pagination={paginationFactory(paginationOptionsAtShipper)}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <Pagination paginationProps={paginationProps}>
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center"
                            bootstrap4
                            remote
                            id="AtShipper"
                            keyField="Id"
                            noDataIndication={
                              <h4 style={{ textAlign: "center" }}>
                                There is no data available at the moment.
                              </h4>
                            }
                            data={AtShipperData}
                            columns={columns}
                            defaultSorted={[{ dataField: "Id", order: "desc" }]}
                            onTableChange={GetHandlerTableChangeAtShipper}
                            {...paginationTableProps}
                          >
                            <PleaseWaitMessage entities={AtShipperData} />
                            <NoRecordsFoundMessage entities={AtShipperData} />
                          </BootstrapTable>
                        </Pagination>
                      );
                    }}
                  </PaginationProvider>
                </div>
              )}

              {(ShowData === "At Receiver" || ShowData === "") && (
                <div
                  className={`mb-4 border-bottom ${
                    ShowData === "At Receiver" ? "col-12" : "col-xl-4 col-lg-6 col-md-6"
                  }`}
                >
                  <p
                    className={`mb-0 py-2 px-4 text-center font-size-h6 bg-info ${
                      "At Receiver" === "Out for Pickup"
                        ? "text-dark"
                        : "text-white"
                    }`}
                  >
                    {"At Receiver"}
                  </p>
                  {loadingDataAtReceiver === true ? (
                    <ProgressBar
                      variant="success"
                      animated
                      now={100}
                      style={{ height: "6px", width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                  <PaginationProvider
                    pagination={paginationFactory(paginationOptionsAtReceiver)}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <Pagination paginationProps={paginationProps}>
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center"
                            bootstrap4
                            remote
                            id="AtReceiver"
                            keyField="Id"
                            noDataIndication={
                              <h4 style={{ textAlign: "center" }}>
                                There is no data available at the moment.
                              </h4>
                            }
                            data={AtReceiverData}
                            columns={columns}
                            defaultSorted={[{ dataField: "Id", order: "desc" }]}
                            onTableChange={GetHandlerTableChangeAtReceiver}
                            {...paginationTableProps}
                          >
                            <PleaseWaitMessage entities={AtReceiverData} />
                            <NoRecordsFoundMessage entities={AtReceiverData} />
                          </BootstrapTable>
                        </Pagination>
                      );
                    }}
                  </PaginationProvider>
                </div>
              )}
              {(ShowData === "Delivered" || ShowData === "") && (
                <div
                  className={`mb-4 border-bottom ${
                    ShowData === "Delivered" ? "col-12" : "col-xl-4 col-lg-6 col-md-6"
                  }`}
                >
                  <p
                    className={`mb-0 py-2 px-4 text-center font-size-h6 bg-success ${
                      "Delivered" === "Out for Pickup" ? "text-dark" : "text-white"
                    }`}
                  >
                    {"Delivered"}
                  </p>
                  {loadingDataDelivered === true ? (
                    <ProgressBar
                      variant="success"
                      animated
                      now={100}
                      style={{ height: "6px", width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                  <PaginationProvider
                    pagination={paginationFactory(paginationOptionsDelivered)}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <Pagination paginationProps={paginationProps}>
                          <BootstrapTable
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-head-custom table-vertical-center"
                            bootstrap4
                            remote
                            id="Delivered"
                            keyField="Id"
                            data={DeliveredData}
                            noDataIndication={
                              <h4 style={{ textAlign: "center" }}>
                                There is no data available at the moment.
                              </h4>
                            }
                            columns={columns}
                            defaultSorted={[{ dataField: "Id", order: "desc" }]}
                            onTableChange={GetHandlerTableChangeDelivered}
                            {...paginationTableProps}
                          >
                            <PleaseWaitMessage entities={DeliveredData} />
                            <NoRecordsFoundMessage entities={DeliveredData} />
                          </BootstrapTable>
                        </Pagination>
                      );
                    }}
                  </PaginationProvider>
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </CardBody>
    </Card>
  );
}
