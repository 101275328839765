import React, { useState, useEffect, useMemo } from "react";
import { Notification } from "../../../../../Notification";
import { Card } from "react-bootstrap";
import $ from "jquery";
import low from "lowdb";
import { LoadingDialog } from "../../../../../../../_metronic/_partials/controls";
import LinearProgress from "@material-ui/core/LinearProgress";
import LocalStorage from "lowdb/adapters/LocalStorage";
import {
  DateTime30minInterval,
  momentDate,
} from "../../../../../../../_metronic/_helpers/ATHelpers";
import { useDropzone } from "react-dropzone";
import { SettingsInputHdmiSharp } from "@material-ui/icons";
import CreatableSelect from "react-select/creatable";
import PDFViewer from "pdf-viewer-reactjs";
import FileViewer from "react-file-viewer";
import "../../../../pages/ReceiptsManagementNewVersion/customers-table/FileViewerStyle.css";
import FileSaver from "file-saver";
import { Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { TextField } from "@material-ui/core";
import moment from "moment";

const adapter = new LocalStorage("db");
const db = low(adapter);

export function OrderAttachements({ id, Type, isDashboard }) {
  const [isLoading, setisLoading] = useState(false);
  const [isSave, setisSave] = useState("");
  const [AttachementList, setAttachementList] = useState([]);
  const [AttachmentTypes, setAttachmentTypes] = useState([]);
  const [AccessLevels, setAccessLevels] = useState([]);
  const [editMode, setEditeMode] = useState(false);
  const [smId, setShipmentId] = useState("");
  const [accessLevelPut, setAccessLevelPut] = useState({
    value: "",
    label: "Access Level",
  });
  const [attachmentTypePut, setAttachmentTypePut] = useState({
    value: "",
    label: "Attachment Type",
  });

  const [accessLevelPost, setAccessLevelPost] = useState({
    value: "",
    label: "Access Level",
  });
  const [attachmentTypePost, setAttachmentTypePost] = useState({
    value: "",
    label: "Attachment Type",
  });

  useEffect(() => {
    GetViewmodel();
  }, []);

  useEffect(() => {
    if (id) {
      async function fetchMyAPI() {
        var result = await GetAttachements(id);
        console.log(isDashboard);
        if (isDashboard) {
          result = result?.filter((x) => x.AccessLevel === "Public");
        }
        setAttachementList(result || []);
      }
      fetchMyAPI();
    }
  }, [id]);

  async function GetAttachements(id) {
    return await fetch(
      `${window.$apiurl}/transload/${Type}s/getattachments/${id}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        const list = res[1];
        list.map((item) => {
          item.EditMode = false;
        });
        return list;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetViewmodel() {
    return await fetch(`${window.$apiurl}/attachment/GetViewModel`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        setAttachmentTypes(res[1]?.AttachmentTypes || []);
        setAccessLevels(res[1]?.AccessLevels || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetAttachements(id) {
    return await fetch(
      `${window.$apiurl}/transload/${Type}s/getattachments/${id}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function UpdateShipementAttachement(smId) {
    setisLoading(true);
    const someData = {
      AccessLevelId: accessLevelPut.value,
      AttachmentTypeId: attachmentTypePut.value,
    };
    return fetch(`${window.$apiurl}/generic/attachment/update/${smId}`, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async () => {
        await getAttachments();
        setisLoading(false);
      })
      .catch((error) => {
        getAttachments();
      });
  }
  async function SaveShipementAattachement() {
    setisLoading(true);
    var formdata = new FormData();
    const inputElement = document.getElementById("shipmentAttachment");
    const attachmentTypeId = attachmentTypePost.value;
    const accessLevelId = accessLevelPost.value;

    acceptedFiles.map((file) => {
      formdata.append("File", file, file.name);
    });

    return await fetch(
      `${window.$apiurl}/transload/${Type}s/addattachment?${Type}Id=${id}&attachmentTypeId=${attachmentTypeId}&accessLevelId=${accessLevelId}`,
      {
        method: "POST",
        body: formdata,
        redirect: "follow",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        await getAttachments();
        setisLoading(false);
        Notification("success", "File has been updated successfully");
      })
      .catch((error) => {
        getAttachments();
        Notification("success", "File has been updated successfully");
      });
  }

  async function DeleteAttachment(id) {
    setisLoading(true);
    return await fetch(`${window.$apiurl}/generic/attachment/delete/${id}`, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        getAttachments();
        setisLoading(false);
        Notification("success", "Attachment is Delete");
      })
      .catch((error) => {
        getAttachments();
        setisLoading(false);
        Notification("success", "Attachment is Delete");
      });
  }

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "100px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#3699FF",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#464E5F",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };
  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: "image/*",
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    inputRef,
  } = useDropzone();
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      <strong>{file.path}</strong> - {file.size} bytes
    </li>
  ));
  const [loaderData, setLoaderData] = useState(false);
  function getExtension(path) {
    var basename = path.split(/[\\/]/).pop(),
      pos = basename.lastIndexOf(".");
    if (basename === "" || pos < 1) return "";
    return basename.slice(pos + 1);
  }
  const [showAttachment, setShowAttachment] = useState(false);
  const [disabledDownload, setDisabledDownload] = useState(true);

  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };
  const [type, setFileType] = useState("");
  const [filePath, setFilePath] = useState("");
  async function SecureFileFetch(receiptId, attachmentId) {
    return await fetch(
      window.$apiurl +
        `/generic/attachment/secureFile/${receiptId}/${attachmentId}?isReceipt=false&isQuote=false`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        try {
          const data = res[1];
          const fileType = getExtension(data.AttachmentFile);
          setFileType(fileType.toLowerCase());
          setFilePath(data.AttachmentFile);
          setDisabledDownload(false);
        } catch (err) {}
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function CardFunction({ x, index, New }) {
    return (
      <>
        <Card
          className={
            "mb-2 m-auto border-secondary " + (index % 2 === 0 && "bg-light")
          }
        >
          <Card.Body>
            <Card.Title className="d-inline mr-5">
              <i class="fas fa-user mr-2"></i> {x?.CreatedBy}
            </Card.Title>
            <p className="mb-2 d-inline card-subtitle text-primary ">
              {momentDate(x?.Created)}
            </p>
            <Card.Text className="mt-5 ml-5">
              <div className={"row"}>
                <div className="col-lg-9">
                  <b className="text-primary mr-5">Download :</b>
                  <b>
                    <a
                      onClick={async () => {
                        setShowAttachment(true);
                        await SecureFileFetch(id, x.Id);
                      }}
                      // href={x?.FilePath}
                      // target="_blank"
                      role="button"
                      className="btn btn-light-primary btn-elevate py-1"
                    >
                      {x?.FileName}
                    </a>
                  </b>
                </div>
                <div className="col-lg-2">
                  {(db.getState().User?.Email === x?.CreatedBy ||
                    db.getState().User?.RoleId === 12 ||
                    db.getState().User?.RoleId === 13 ||
                    db.getState().User?.RoleId === 14) && (
                    <div className="text-right">
                      <button
                        type="button"
                        class="btn btn-light-danger"
                        onClick={async () => {
                          await DeleteAttachment(x?.Id);
                          New();
                        }}
                      >
                        <i class="fas fa-trash ml-1"></i>
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-lg-1 px-0">
                  <div className="text-left">
                    <button
                      type="button"
                      class="btn btn-light-primary"
                      onClick={async () => {
                        const data = [...AttachementList];
                        if (!data[index].EditMode) {
                          data[index].EditMode = true;
                          data.map((item) => {
                            if (item.Id !== x.Id) {
                              item.EditMode = false;
                            }
                          });
                        }
                        setAttachementList(data);
                        setShipmentId(x?.Id);
                        setAttachmentTypePut({
                          value: "",
                          label: "Select",
                        });
                        setAccessLevelPut({
                          value: "",
                          label: "Select",
                        });
                      }}
                    >
                      <i class="fas fa-edit ml-1"></i>
                    </button>
                  </div>
                </div>
              </div>
              {/* Detail Attachments */}
              <div className={x?.EditMode ? "row mb-md-12" : "row"}>
                <div className="col-lg-6">
                  <b className="text-primary mr-5">Attachment Type :</b>{" "}
                  <b> {x?.Type}</b>
                </div>
                <div className="col-lg-6">
                  <b className="text-primary mr-5">Access Level :</b>{" "}
                  <b> {x?.AccessLevel}</b>
                </div>
              </div>
              {/* Edit Mode */}
              {x.EditMode && (
                <div className="form-group row">
                  <div className="mb-5 col-lg-4 w-100">
                    <CreatableSelect
                      name="AttachmentTypeId"
                      id="AttachmentTypeId"
                      value={attachmentTypePut || "Attachment Type"}
                      options={AttachmentTypes?.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      onChange={(newValue) => {
                        setAttachmentTypePut(newValue);
                        const accessLevelItem = AttachmentTypes.find(
                          (x) => x.Id === newValue.value
                        );
                        const accessLevelId =
                          accessLevelItem.AccessLevel === "Public" ? 4 : 5;
                        setAccessLevelPut({
                          value: accessLevelId,
                          label: accessLevelItem.AccessLevel,
                        });
                      }}
                      placeholder="Attachment Type"
                    />
                  </div>
                  <div className="mb-2 col-lg-4 w-100">
                    <CreatableSelect
                      name="AccessLevelId"
                      id="AccessLevelId"
                      value={accessLevelPut || "Access Level"}
                      options={AccessLevels?.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      onChange={(newValue) => {
                        setAccessLevelPut(newValue);
                      }}
                      placeholder="Access Level"
                    />
                  </div>
                  <div className="col-lg-4">
                    <button
                      type="submit"
                      onClick={async () => {
                        UpdateShipementAttachement(smId);
                        setisLoading(false);
                        // Notification("success","Success");
                      }}
                      className="btn btn-primary btn-elevate mr-5 w-100"
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </Card.Text>
          </Card.Body>
        </Card>
        <br />
      </>
    );
  }
  async function getAttachments() {
    setAttachementList(await GetAttachements(id));
  }
  const [Private, setPrivate] = useState(5);
  return (
    <>
      <div className="form-group row">
        <div className="mb-5 col-lg-12 pb-1 text-left pl-4">
          <div className="">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <svg
                aria-hidden="true"
                focusable="false"
                className="uppy-c-icon uppy-DragDrop-arrow"
                width="40"
                height="40"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11 10V0H5v10H2l6 6 6-6h-3zm0 0"
                  fill-rule="evenodd"
                ></path>
              </svg>
              <div className="uppy-DragDrop-label">
                Drop files here or{" "}
                <span className="uppy-DragDrop-browse">browse</span>
              </div>
            </div>
            <aside>
              <h4 className="pt-3">Files</h4>
              <ul>{files}</ul>
            </aside>
          </div>
        </div>

        <div className="mb-5 col-lg-4 w-100">
          <CreatableSelect
            name="AttachmentTypeId"
            id="AttachmentTypeId"
            options={AttachmentTypes?.map((x) => {
              return {
                value: x?.Id,
                label: x?.Name,
              };
            })}
            onChange={(newValue) => {
              setAttachmentTypePost(newValue);
              const accessLevelItem = AttachmentTypes.find(
                (x) => x.Id === newValue.value
              );
              const accessLevelId =
                accessLevelItem.AccessLevel === "Public" ? 4 : 5;
              setAccessLevelPost({
                value: accessLevelId,
                label: accessLevelItem.AccessLevel,
              });
            }}
            value={attachmentTypePost || "Attachment Type"}
          />
        </div>
        <div className="mb-5 col-lg-4 w-100">
          <CreatableSelect
            name="AccessLevelsId"
            id="AccessLevelsId"
            value={accessLevelPost || "Access Level"}
            options={AccessLevels?.map((x) => {
              return {
                value: x?.Id,
                label: x?.Name,
              };
            })}
            onChange={(newValue) => {
              setAccessLevelPost(newValue);
            }}
            placeholder="Access Levels"
          />
        </div>
        <div className="col-lg-4">
          <button
            type="submit"
            onClick={SaveShipementAattachement}
            disabled={
              isLoading ||
              (accessLevelPost.value !== "" && attachmentTypePost.value !== ""
                ? false
                : true)
            }
            className="btn btn-primary btn-elevate mr-5 w-100"
          >
            Save
          </button>
        </div>
        <div className="col-lg-12">{isLoading && <LinearProgress />}</div>

        <div className="col-lg-12">
          {AttachementList?.map((x, index) => (
            <CardFunction
              x={x}
              key={index}
              index={index}
              New={() => getAttachments()}
            />
          ))}
        </div>
      </div>

      {/* Modal Attachment Viewer */}
      <Modal
        size="xl"
        show={showAttachment}
        onHide={() => {
          setShowAttachment(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>Attachment Viewer for SM #: {id}</Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setShowAttachment(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="form-group row mb-5">
              <div className="col-lg-8"></div>
              <div className="col-lg-4" style={{ textAlignLast: "right" }}>
                <button
                  type="submit"
                  disabled={disabledDownload}
                  className="btn btn-primary btn-elevate"
                  onClick={() => {
                    const fileName = filePath.replace(/^.*[\\\/]/, "");
                    console.log(fileName);
                    FileSaver(filePath, fileName);
                  }}
                >
                  Download
                </button>{" "}
              </div>
            </div>
            {type === "pdf" ? (
              <div className="form-group row" key={Math.random()}>
                <div className="col-lg-12 pdfViewer" key={Math.random()}>
                  <PDFViewer
                    document={{
                      url: filePath,
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="form-group row" key={Math.random()}>
                <div className="col-lg-12" key={Math.random()}>
                  <FileViewer
                    fileType={type}
                    filePath={filePath}
                    onError={onError}
                    className="w-100"
                  />
                </div>
              </div>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              setShowAttachment(false);
            }}
            className="btn btn-light btn-elevate w-25"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export function OrderAttachementsV2({
  id,
  AttachmentList,
  AttachmentTypes,
  AccessLevels,
  Type,
  isDashboard,
  row,
}) {
  const [isLoading, setisLoading] = useState(false);
  const [isSave, setisSave] = useState("");
  const [AttachementList, setAttachementList] = useState(AttachmentList || []);
  const [attachmentTypes, setAttachmentTypes] = useState(AttachmentTypes || []);
  const [accessLevels, setAccessLevels] = useState(AccessLevels || []);
  const [editMode, setEditeMode] = useState(false);
  const [smId, setShipmentId] = useState("");
  const [accessLevelPut, setAccessLevelPut] = useState({
    value: "",
    label: "Access Level",
  });
  const [attachmentTypePut, setAttachmentTypePut] = useState({
    value: "",
    label: "Attachment Type",
  });

  const [accessLevelPost, setAccessLevelPost] = useState({
    value: "",
    label: "Access Level",
  });
  const [attachmentTypePost, setAttachmentTypePost] = useState({
    value: "",
    label: "Attachment Type",
  });

  useEffect(() => {
    if (isDashboard) {
      GetViewmodel();
    }
  }, []);

  useEffect(() => {
    if (id && isDashboard) {
      async function fetchMyAPI() {
        var result = await GetAttachements(id);
        if (isDashboard) {
          result = result?.filter((x) => x.AccessLevel === "Public");
        }
        setAttachementList(result || []);
      }
      fetchMyAPI();
    }
  }, [id]);

  async function GetAttachements(id) {
    return await fetch(
      `${window.$apiurl}/transload/${Type}s/getattachments/${id}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        const list = res[1];
        list.map((item) => {
          item.EditMode = false;
        });
        return list;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetViewmodel() {
    return await fetch(`${window.$apiurl}/attachment/GetViewModel`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        setAttachmentTypes(res[1]?.AttachmentTypes || []);
        setAccessLevels(res[1]?.AccessLevels || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetAttachements(id) {
    return await fetch(
      `${window.$apiurl}/transload/${Type}s/getattachments/${id}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function UpdateShipementAttachement(smId) {
    setisLoading(true);
    const someData = {
      AccessLevelId: accessLevelPut.value,
      AttachmentTypeId: attachmentTypePut.value,
    };
    return fetch(`${window.$apiurl}/generic/attachment/update/${smId}`, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async () => {
        await getAttachments();
        setisLoading(false);
      })
      .catch((error) => {
        getAttachments();
      });
  }
  async function SaveShipementAattachement() {
    setisLoading(true);
    var formdata = new FormData();
    const inputElement = document.getElementById("shipmentAttachment");
    const attachmentTypeId = attachmentTypePost.value;
    const accessLevelId = accessLevelPost.value;

    acceptedFiles.map((file) => {
      formdata.append("File", file, file.name);
    });

    return await fetch(
      `${window.$apiurl}/transload/${Type}s/addattachment?${Type}Id=${id}&attachmentTypeId=${attachmentTypeId}&accessLevelId=${accessLevelId}`,
      {
        method: "POST",
        body: formdata,
        redirect: "follow",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        await getAttachments();
        setisLoading(false);
        Notification("success", "File has been updated successfully");
      })
      .catch((error) => {
        getAttachments();
        Notification("success", "File has been updated successfully");
      });
  }

  async function DeleteAttachment(id) {
    setisLoading(true);
    return await fetch(`${window.$apiurl}/generic/attachment/delete/${id}`, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        getAttachments();
        setisLoading(false);
        Notification("success", "Attachment is Delete");
      })
      .catch((error) => {
        getAttachments();
        setisLoading(false);
        Notification("success", "Attachment is Delete");
      });
  }

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "100px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#3699FF",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#464E5F",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };
  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: "image/*",
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    inputRef,
  } = useDropzone();
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      <strong>{file.path}</strong> - {file.size} bytes
    </li>
  ));
  const [loaderData, setLoaderData] = useState(false);
  function getExtension(path) {
    var basename = path.split(/[\\/]/).pop(),
      pos = basename.lastIndexOf(".");
    if (basename === "" || pos < 1) return "";
    return basename.slice(pos + 1);
  }
  const [showAttachment, setShowAttachment] = useState(false);
  const [disabledDownload, setDisabledDownload] = useState(true);

  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };
  const [type, setFileType] = useState("");
  const [filePath, setFilePath] = useState("");
  async function SecureFileFetch(receiptId, attachmentId) {
    return await fetch(
      window.$apiurl +
        `/generic/attachment/secureFile/${receiptId}/${attachmentId}?isReceipt=false&isQuote=false`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        try {
          const data = res[1];
          const fileType = getExtension(data.AttachmentFile);
          setFileType(fileType.toLowerCase());
          setFilePath(data.AttachmentFile);
          setDisabledDownload(false);
        } catch (err) {}
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function CardFunction({ x, index, New }) {
    return (
      <>
        <Card
          className={`mb-2 m-auto border-secondary ${
            index % 2 === 0 ? "bg-light" : ""
          } ${
            x?.Type === "Loading Manifest Document" &&
            (row?.StatusPickedUpDate === null ||
              row?.StatusPickedUpDate === "" ||
              row?.StatusPickedUpDate === undefined)
              ? "disabledAttachmentElement"
              : ""
          }`}
        >
          <Card.Body>
            <Card.Title className="d-inline mr-5">
              <i class="fas fa-user mr-2"></i> {x?.CreatedBy}
            </Card.Title>
            <p className="mb-2 d-inline card-subtitle text-primary ">
              {momentDate(x?.Created)}
            </p>
            <Card.Text className="mt-5 ml-5">
              <div className={"row"}>
                <div className="col-lg-9">
                  <b className="text-primary mr-5">Download :</b>
                  <b>
                    <a
                      href={() => false}
                      onClick={async () => {
                        try {
                          if (
                            !(
                              x?.Type === "Loading Manifest Document" &&
                              (row?.StatusPickedUpDate === null ||
                                row?.StatusPickedUpDate === "" ||
                                row?.StatusPickedUpDate === undefined)
                            )
                          ) {
                            setShowAttachment(true);
                            await SecureFileFetch(id, x.Id);
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      role="button"
                      className="btn btn-light-primary btn-elevate py-1"
                    >
                      {x?.FileName}
                    </a>
                  </b>
                </div>
                <div className="col-lg-2">
                  {(db.getState().User?.Email === x?.CreatedBy ||
                    db.getState().User?.RoleId === 12 ||
                    db.getState().User?.RoleId === 13 ||
                    db.getState().User?.RoleId === 14) && (
                    <div className="text-right">
                      <button
                        type="button"
                        class="btn btn-light-danger"
                        onClick={async () => {
                          if (
                            !(
                              x?.Type === "Loading Manifest Document" &&
                              (row?.StatusPickedUpDate === null ||
                                row?.StatusPickedUpDate === "" ||
                                row?.StatusPickedUpDate === undefined)
                            )
                          ) {
                            await DeleteAttachment(x?.Id);
                            New();
                          }
                        }}
                      >
                        <i class="fas fa-trash ml-1"></i>
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-lg-1 px-0">
                  <div className="text-left">
                    <button
                      type="button"
                      class="btn btn-light-primary"
                      onClick={async () => {
                        if (
                          !(
                            x?.Type === "Loading Manifest Document" &&
                            (row?.StatusPickedUpDate === null ||
                              row?.StatusPickedUpDate === "" ||
                              row?.StatusPickedUpDate === undefined)
                          )
                        ) {
                          const data = [...AttachementList];
                          if (!data[index].EditMode) {
                            data[index].EditMode = true;
                            data.map((item) => {
                              if (item.Id !== x.Id) {
                                item.EditMode = false;
                              }
                            });
                          }
                          setAttachementList(data);
                          setShipmentId(x?.Id);
                          setAttachmentTypePut({
                            value: "",
                            label: "Select",
                          });
                          setAccessLevelPut({
                            value: "",
                            label: "Select",
                          });
                        }
                      }}
                    >
                      <i class="fas fa-edit ml-1"></i>
                    </button>
                  </div>
                </div>
              </div>
              {/* Detail Attachments */}
              <div className={x?.EditMode ? "row mb-md-12" : "row"}>
                <div className="col-lg-6">
                  <b className="text-primary mr-5">Attachment Type :</b>{" "}
                  <b> {x?.Type}</b>
                </div>
                <div className="col-lg-6">
                  <b className="text-primary mr-5">Access Level :</b>{" "}
                  <b> {x?.AccessLevel}</b>
                </div>
              </div>
              {/* Edit Mode */}
              {x.EditMode && (
                <div className="form-group row">
                  <div className="mb-5 col-lg-4 w-100">
                    <CreatableSelect
                      name="AttachmentTypeId"
                      id="AttachmentTypeId"
                      value={attachmentTypePut || "Attachment Type"}
                      options={attachmentTypes?.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      onChange={(newValue) => {
                        setAttachmentTypePut(newValue);
                        const accessLevelItem = attachmentTypes.find(
                          (x) => x.Id === newValue.value
                        );
                        const accessLevelId =
                          accessLevelItem.AccessLevel === "Public" ? 4 : 5;
                        setAccessLevelPut({
                          value: accessLevelId,
                          label: accessLevelItem.AccessLevel,
                        });
                      }}
                      placeholder="Attachment Type"
                    />
                  </div>
                  <div className="mb-2 col-lg-4 w-100">
                    <CreatableSelect
                      name="AccessLevelId"
                      id="AccessLevelId"
                      value={accessLevelPut || "Access Level"}
                      options={accessLevels?.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      onChange={(newValue) => {
                        setAccessLevelPut(newValue);
                      }}
                      placeholder="Access Level"
                    />
                  </div>
                  <div className="col-lg-4">
                    <button
                      type="submit"
                      onClick={async () => {
                        UpdateShipementAttachement(smId);
                        setisLoading(false);
                        // Notification("success","Success");
                      }}
                      className="btn btn-primary btn-elevate mr-5 w-100"
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </Card.Text>
          </Card.Body>
        </Card>
        <br />
      </>
    );
  }
  async function getAttachments() {
    setAttachementList(await GetAttachements(id));
  }
  const [Private, setPrivate] = useState(5);
  return (
    <>
      <div className="form-group row">
        <div className="mb-5 col-lg-12 pb-1 text-left pl-4">
          <div className="">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <svg
                aria-hidden="true"
                focusable="false"
                className="uppy-c-icon uppy-DragDrop-arrow"
                width="40"
                height="40"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11 10V0H5v10H2l6 6 6-6h-3zm0 0"
                  fill-rule="evenodd"
                ></path>
              </svg>
              <div className="uppy-DragDrop-label">
                Drop files here or{" "}
                <span className="uppy-DragDrop-browse">browse</span>
              </div>
            </div>
            <aside>
              <h4 className="pt-3">Files</h4>
              <ul>{files}</ul>
            </aside>
          </div>
        </div>

        <div className="mb-5 col-lg-4 w-100">
          <CreatableSelect
            name="AttachmentTypeId"
            id="AttachmentTypeId"
            options={attachmentTypes?.map((x) => {
              return {
                value: x?.Id,
                label: x?.Name,
              };
            })}
            onChange={(newValue) => {
              setAttachmentTypePost(newValue);
              const accessLevelItem = attachmentTypes.find(
                (x) => x.Id === newValue.value
              );
              const accessLevelId =
                accessLevelItem.AccessLevel === "Public" ? 4 : 5;
              setAccessLevelPost({
                value: accessLevelId,
                label: accessLevelItem.AccessLevel,
              });
            }}
            value={attachmentTypePost || "Attachment Type"}
          />
        </div>
        <div className="mb-5 col-lg-4 w-100">
          <CreatableSelect
            name="AccessLevelsId"
            id="AccessLevelsId"
            value={accessLevelPost || "Access Level"}
            options={accessLevels?.map((x) => {
              return {
                value: x?.Id,
                label: x?.Name,
              };
            })}
            onChange={(newValue) => {
              setAccessLevelPost(newValue);
            }}
            placeholder="Access Levels"
          />
        </div>
        <div className="col-lg-4">
          <button
            type="submit"
            onClick={SaveShipementAattachement}
            disabled={
              isLoading ||
              (accessLevelPost.value !== "" && attachmentTypePost.value !== ""
                ? false
                : true)
            }
            className="btn btn-primary btn-elevate mr-5 w-100"
          >
            Save
          </button>
        </div>
        <div className="col-lg-12">{isLoading && <LinearProgress />}</div>

        <div className="col-lg-12">
          {row?.StatusPickedUpDate !== null &&
            row?.StatusPickedUpDate !== undefined &&
            row?.StatusPickedUpDate !== "" &&
            isDashboard &&
            AttachementList?.map((x, index) => (
              <CardFunction
                x={x}
                key={index}
                index={index}
                New={() => getAttachments()}
              />
            ))}
          {!isDashboard &&
            AttachementList?.map((x, index) => (
              <CardFunction
                x={x}
                key={index}
                index={index}
                New={() => getAttachments()}
              />
            ))}
        </div>
      </div>

      {/* Modal Attachment Viewer */}
      <Modal
        size="xl"
        show={showAttachment}
        onHide={() => {
          setShowAttachment(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>Attachment Viewer for SM #: {id}</Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setShowAttachment(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="form-group row mb-5">
              <div className="col-lg-8"></div>
              <div className="col-lg-4" style={{ textAlignLast: "right" }}>
                <button
                  type="submit"
                  disabled={disabledDownload}
                  className="btn btn-primary btn-elevate"
                  onClick={() => {
                    const fileName = filePath.replace(/^.*[\\\/]/, "");
                    console.log(fileName);
                    FileSaver(filePath, fileName);
                  }}
                >
                  Download
                </button>{" "}
              </div>
            </div>
            {type === "pdf" ? (
              <div className="form-group row" key={Math.random()}>
                <div className="col-lg-12 pdfViewer" key={Math.random()}>
                  <PDFViewer
                    document={{
                      url: filePath,
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="form-group row" key={Math.random()}>
                <div className="col-lg-12" key={Math.random()}>
                  <FileViewer
                    fileType={type}
                    filePath={filePath}
                    onError={onError}
                    className="w-100"
                  />
                </div>
              </div>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              setShowAttachment(false);
            }}
            className="btn btn-light btn-elevate w-25"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export function TmsAttachementsStatus({
  id,
  Type,
  GetWarehousDataPageChanges,
  paginationOptions,
  setshowModalPickedUp,
  IsMultu,
}) {
  function NextStatusPickedUp(id, statusId) {
    var ShippingDepartureDate = new Date();

    var ShippingDeparture = ShippingDepartureDate ;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ShipmentIds: IsMultu === true ? id : [id],
      ShipmentStatus: statusId,
      ShippingDepartureDate: ShippingDeparture,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/warehouseManagement/UpdateMultipleStatus`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("infoClick", result[1].Message);
            GetWarehousDataPageChanges(paginationOptions);
          } else {
            Notification("success", "Shipment has been Updated successfully");
            GetWarehousDataPageChanges(paginationOptions);
          }
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Notification("errorClick", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "Bad Request");
          }
        }
        setisLoading(false);
        setshowModalPickedUp(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  const [isLoading, setisLoading] = useState(false);

  const [accessLevelPost, setAccessLevelPost] = useState({
    value: "5",
    label: "Private",
  });

  async function SaveShipementAattachementtms() {
    setisLoading(true);
    document.getElementById("SaveCarrierCheckOutPickUpDetails").click();
    var formdata = new FormData();
    const attachmentTypeId = 238;
    const accessLevelId = accessLevelPost.value;

    acceptedFiles.forEach((file) => {
      formdata.append("File", file, file.name);
    });

    return await fetch(
      `${window.$apiurl}/transload/${Type}s/addattachment?${Type}Id=${
        IsMultu === true ? id[0] : id
      }&attachmentTypeId=${attachmentTypeId}&accessLevelId=${accessLevelId}`,
      {
        method: "POST",
        body: formdata,
        redirect: "follow",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        await NextStatusPickedUp(IsMultu === true ? id : id, 7);
       
      })
      .catch(async (error) => {
        await NextStatusPickedUp(IsMultu === true ? id : id, 7);
     
      });
  }

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "100px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#3699FF",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#464E5F",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };
  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: "image/*",
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    inputRef,
  } = useDropzone();
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      <strong>{file.path}</strong> - {file.size} bytes
    </li>
  ));

  return (
    <>
      <div className="form-group row">
        <div className="col-lg-12">
          <label>
          <b>Shipping Departure Date</b>

          </label>
          <TextField
            style={{ marginTop: "10px", width: "100%" }}
            id="ShippingDepartureDate"
            name="ShippingDepartureDate"
            disabled={true}
            type="datetime-local"
            defaultValue={moment().format("YYYY-MM-DDThh:mm")}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e)=>{
              console.log("e.target.value",e.target.value)
            }}
          />
        </div>
   
      </div>
      <div className="form-group row">
        <div className="mb-5 col-lg-12 pb-1 text-left pl-4">
          <div className="">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <svg
                aria-hidden="true"
                focusable="false"
                className="uppy-c-icon uppy-DragDrop-arrow"
                width="40"
                height="40"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11 10V0H5v10H2l6 6 6-6h-3zm0 0"
                  fill-rule="evenodd"
                ></path>
              </svg>
              <div className="uppy-DragDrop-label">
                Drop files here or{" "}
                <span className="uppy-DragDrop-browse">browse</span>
              </div>
            </div>
            <aside>
              <h4 className="pt-3">Files</h4>
              <ul>{files}</ul>
            </aside>
          </div>
        </div>
        <div className="mb-5 col-lg-8 w-100">
          <CreatableSelect
            name="AccessLevelsId"
            id="AccessLevelsId"
            value={accessLevelPost || "Access Level"}
            options={[
              {
                value: 4,
                label: "Public",
              },
              {
                value: 5,
                label: "Private",
              },
            ]}
            onChange={(newValue) => {
              setAccessLevelPost(newValue);
            }}
            placeholder="Access Levels"
          />
        </div>
        <div className="col-lg-4">
          <button
            type="submit"
            onClick={SaveShipementAattachementtms}
            disabled={isLoading}
            className="btn btn-primary btn-elevate mr-5 w-100"
          >
            Save
          </button>
        </div>
        <div className="col-lg-12">{isLoading && <LinearProgress />}</div>
      </div>
    </>
  );
}
